import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "framework7-icons";
import { Page, Link } from "framework7-react";
import InfiniteScroll from "../components/InfiniteScroll/";
import CommunityPanel from "../components/CommunityPanel/";
import NoData from "../components/NoData";
import { apiCall, apiConfig } from "../api";
import "../css/pageCommunityChoose.less";

class CommunityChoose extends React.Component {
  constructor(props) {
    super(props);
    this.onRef = this.onRef.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.renderData = this.renderData.bind(this);

    this.state = {
      typeLocation: 1,
      typeCategory: 2,
      unfold: false,
      unfoldParentList: [],
      unfoldChildrenList: [],
      pageIndex: 1,
      focusCategoryId: 0,
      focusCategoryName: "全部类别",
      categoryList: [
        { id: 0, name: "全部类别" },
        { id: 1, name: "标准社" },
        { id: 2, name: "专业社" }
      ],
      focusLocationId: 0,
      focusLocationName: "全上海",
      locationList: [
        {
          id: -1,
          name: "全上海",
          focus: true,
          children: [{ id: 0, name: "全上海" }]
        }
        // {
        //   id: 2,
        //   name: "宝山区",
        //   focus: false,
        //   children: [
        //     { id: 21, name: "罗店镇" },
        //     { id: 22, name: "大场镇" },
        //     { id: 23, name: "杨行镇" },
        //     { id: 24, name: "月浦镇" },
        //     { id: 25, name: "罗泾镇" },
        //     { id: 26, name: "顾村镇" },
        //     { id: 27, name: "高静镇" },
        //     { id: 28, name: "庙行镇" },
        //     { id: 29, name: "淞南镇" }
        //   ]
        // }
      ],
      communityList: [
        // {
        //   id: 5,
        //   title: "灯塔村益农社",
        //   img: ImgSample32,
        //   rate: 4,
        //   views: 6782,
        //   location: "上海市嘉定区嘉定工业园区灯塔村1-43号",
        //   distance: "2km"
        // },
      ]
    };
  }

  componentDidMount() {
    const _this = this;
    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      this.isComponentMounted = true;
      apiCall(
        "/app/orgList",
        "GET",
        {
          id: this.communityId
        },
        data => {
          if (this.isComponentMounted) {
            let locationList = [];
            data.content.forEach(parent => {
              let locationItem = {};
              locationItem.id = parent.organizeId;
              locationItem.name = parent.organizeName;
              locationItem.focus = false;
              locationItem.children = [];
              parent.children.forEach(child => {
                locationItem.children.push({
                  id: child.organizeId,
                  name: child.organizeName
                });
              });
              locationList.push(locationItem);
            });
            this.setState(
              {
                locationList: this.state.locationList.concat(locationList)
              },
              () => {}
            );
          }
        },
        (xhr, status) => {
          //console.log(xhr, status);
          this.$f7.dialog.alert(xhr);
        }
      );
    });
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  onRef(ref) {
    this.InfiniteScroll = ref;
  }

  fetchData(func) {
    //if (this.state.categories.length == 0) return;

    apiCall(
      "/app/getYinongInfoList",
      "POST",
      {
        page: this.state.pageIndex,
        pageSize: apiConfig.pageSize,
        type: this.state.focusCategoryId
          ? String(this.state.focusCategoryId)
          : "",
        town: this.state.focusLocationId
          ? String(this.state.focusLocationId)
          : "",
        longitude: this.props.pageState.longitude
          ? String(this.props.pageState.longitude)
          : "",
        latitude: this.props.pageState.latitude
          ? String(this.props.pageState.latitude)
          : ""
      },
      data => {
        //console.log("fetchData", this.state.pageIndex, data);
        //console.log(data);

        if (this.isComponentMounted) {
          this.setState(prev => ({
            pageIndex: prev.pageIndex + 1
          }));
        }

        let list =
          data.content.list.length > 0 ? data.content.list : data.tag.records;

        if (func && list.length != 0) func(list);
        if (func && list.length == 0) func(list, true);
      },
      function(xhr, status) {
        //console.log(xhr, status);
      }
    );
  }

  renderData(data, isDataEnd) {
    //console.log(data);
    if (data.length == 0 && !isDataEnd) {
      if (this.isComponentMounted) {
        this.setState(prev => ({
          communityList: []
        }));
      }
    } else {
      let communityList = data.map(item => {
        return {
          id: item.id,
          title: item.ynName,
          img: item.images,
          rate: 5,
          views: item.pv,
          location: item.address,
          distance: "2km"
        };
      });
      if (this.isComponentMounted) {
        this.setState(prev => ({
          communityList: prev.communityList.concat(communityList)
        }));
      }
    }
  }

  clickParentType(index, e) {
    let showIndex = !this.state.unfold
      ? index
      : this.state.unfold != index
      ? index
      : false;
    let focusId = this.state.focusLocationId;
    let unfoldParentList = [],
      unfoldChildrenList = [];
    if (showIndex == this.state.typeLocation) {
      // LOCATION SHOW
      focusId = this.state.focusLocationId;
      unfoldParentList = this.state.locationList;
      unfoldParentList.forEach(item => {
        item.focus = false;
        item.children.forEach(child => {
          if (child.id == this.state.focusLocationId) {
            unfoldChildrenList = item.children.map(i => {
              return Object.assign({}, i);
            });
            item.focus = true;
          }
        });
      });
    }
    if (showIndex == this.state.typeCategory) {
      // CATEGORY SHOW
      focusId = this.state.focusCategoryId;
      unfoldChildrenList = this.state.categoryList.map(i => {
        return Object.assign({}, i);
      });
    }
    unfoldChildrenList.map(item => {
      if (item.id == focusId) {
        item.focus = true;
      } else {
        item.focus = false;
      }
    });
    if (this.isComponentMounted) {
      this.setState(
        {
          unfold: showIndex,
          unfoldParentList: unfoldParentList,
          unfoldChildrenList: unfoldChildrenList
        },
        () => {
          //this.clickSubType(focusId)();
        }
      );
    }
  }

  clickSubType(focusId, e) {
    let unfoldChildrenList = [];
    if (this.state.unfold == this.state.typeLocation) {
      this.state.unfoldParentList.map(item => {
        if (item.id == focusId) {
          item.focus = true;
          unfoldChildrenList = item.children.map(i => {
            if (i.id == this.state.focusLocationId) {
              i.focus = true;
            } else {
              i.focus = false;
            }
            return Object.assign({}, i);
          });
        } else {
          item.focus = false;
        }
      });
    }
    if (this.state.unfold == this.state.typeCategory) {
      unfoldChildrenList = this.state.categoryList.map(i => {
        if (i.id == this.state.focusCategoryId) {
          i.focus = true;
        } else {
          i.focus = false;
        }
        return Object.assign({}, i);
      });
    }
    if (this.isComponentMounted) {
      this.setState({
        unfoldParentList: this.state.unfoldParentList,
        unfoldChildrenList: unfoldChildrenList
      });
    }
  }

  clickFinalType(focusId, e) {
    let changedState = [];
    this.state.unfoldChildrenList.map(item => {
      if (item.id == focusId) {
        item.focus = true;
        if (this.state.unfold == this.state.typeLocation) {
          changedState.focusLocationId = item.id;
          changedState.focusLocationName = item.name;
        }
        if (this.state.unfold == this.state.typeCategory) {
          changedState.focusCategoryId = item.id;
          changedState.focusCategoryName = item.name;
        }
      } else {
        item.focus = false;
      }
    });
    changedState.unfoldChildrenList = this.state.unfoldChildrenList;
    changedState.unfold = false;
    changedState.pageIndex = 1;
    changedState.communityList = [];

    if (this.isComponentMounted) {
      this.setState(changedState, () => {
        this.InfiniteScroll.reInfinite();
        // this.fetchData(this.renderData);
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {}

  render() {
    return (
      <InfiniteScroll
        pageName="CommunityChoose"
        fetchData={this.fetchData}
        renderData={this.renderData}
        onRef={this.onRef}
        externalClass={this.state.unfold ? "unfold" : ""}>
        <div
          className={"community-choose " + (this.state.unfold ? "unfold" : "")}>
          <div>
            <div
              className={
                this.state.unfold == this.state.typeLocation ? "up" : ""
              }
              onClick={this.clickParentType.bind(
                this,
                this.state.typeLocation
              )}>
              <span>{this.state.focusLocationName}</span>
              <span />
            </div>
            <div
              className={
                this.state.unfold == this.state.typeCategory ? "up" : ""
              }
              onClick={this.clickParentType.bind(
                this,
                this.state.typeCategory
              )}>
              <span>{this.state.focusCategoryName}</span>
              <span />
            </div>
          </div>
          <div className="community-choose-select ">
            <div>
              {this.state.unfoldParentList.map(item => (
                <div
                  className={item.focus ? "focus" : ""}
                  key={item.id}
                  onClick={this.clickSubType.bind(this, item.id)}>
                  {item.name}
                </div>
              ))}
            </div>
            <div>
              {this.state.unfoldChildrenList.map(item => (
                <div
                  className={item.focus ? "focus" : ""}
                  key={item.id}
                  onClick={this.clickFinalType.bind(this, item.id)}>
                  <span>{item.name}</span>
                  <span />
                </div>
              ))}
            </div>
          </div>
        </div>

        {this.state.communityList.length > 0 &&
          this.state.communityList.map(item => {
            return (
              <CommunityPanel
                key={item.id}
                link={"/communityinfo/" + item.id}
                img={item.img}
                title={item.title}
                // rate={item.rate}
                // views={item.views}
                location={item.location}
                //distance={item.distance}
              />
            );
          })}

        {this.state.communityList.length == 0 && <NoData />}
      </InfiniteScroll>
    );
  }
}

function mapStateToProps(state) {
  const { userState, pageState } = state;
  return { userState, pageState };
}

CommunityChoose.propTypes = {
  userState: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(CommunityChoose);
