import React from "react";
import { Page } from "framework7-react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "./InfiniteScroll.less";

class InfiniteScroll extends React.Component {
  constructor(props) {
    super(props);
    this.props.onRef(this);
    this.onInfinite = this.onInfinite.bind(this);
    this.reInfinite = this.reInfinite.bind(this);
    this.infinite = this.infinite.bind(this);

    this.state = {
      showPreloader: true,
      // Loading flag
      allowInfinite: true,
      // Last loaded index
      lastItemIndex: 0,
      // Max items to load
      maxItems: this.props.maxItems ? this.props.maxItems : 20000
    };
  }

  componentDidMount() {
    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      this.isComponentMounted = true;

      this.onInfinite();
    });
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  onInfinite() {
    if (!this.state.allowInfinite) return;

    this.infinite();
  }

  reInfinite() {
    //console.log("reInfinite");
    this.props.renderData([]);

    this.infinite();
  }

  infinite() {
    const self = this;
    const app = self.$f7;
    const $ = self.$$;

    if (this.state.lastItemIndex >= self.state.maxItems) {
      if (this.isComponentMounted) {
        this.setState({ showPreloader: false });
      }
      return;
    }

    if (this.isComponentMounted) {
      this.setState(() => ({
        allowInfinite: false,
        showPreloader: true
      }));
    }

    this.props.fetchData(data => {
      //console.log(data, data.length);
      if (data.length == 0) {
        if (this.isComponentMounted) {
          this.setState({ showPreloader: false });
        }
        return;
      }

      this.props.renderData(data);

      if (this.isComponentMounted) {
        this.setState(prevState => ({
          showPreloader: false,
          allowInfinite: true,
          lastItemIndex: prevState.lastItemIndex + data.length
        }));
      }
    });
  }

  render() {
    return (
      <Page
        name={this.props.pageName}
        infinite
        infiniteDistance={50}
        infinitePreloader={false}
        onInfinite={this.onInfinite}
        pageContent={false}
        onPageAfterIn={this.props.onPageAfterIn}>
        <div
          className={
            "page-content infinite-scroll-content " +
            (this.props.externalClass ? this.props.externalClass : "")
          }
          data-infinite-distance="50">
          {this.props.children}
          <div
            className={
              "preloader infinite-scroll-preloader " +
              (this.state.showPreloader ? "" : "hide")
            }
          />
        </div>
      </Page>
    );
  }
}

function mapStateToProps(state) {
  const { pageState } = state;
  return { pageState };
}

InfiniteScroll.propTypes = {
  pageName: PropTypes.string.isRequired,
  fetchData: PropTypes.func.isRequired,
  renderData: PropTypes.func.isRequired,
  onRef: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(InfiniteScroll);
