import React from "react";
import { Link } from "framework7-react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "framework7-icons";
import "./nodata.less";

class NoData extends React.Component {
  componentDidMount() {
    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;
    });
  }

  render() {
    return <div className="NoData" />;
  }
}

function mapStateToProps(state) {
  const { pageState } = state;
  return { pageState };
}

NoData.propTypes = {
  pageState: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(NoData);
