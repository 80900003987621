import "../public/css/mobile.css";
import "../public/js/mobile.js";
import "normalize.css";
import "../public/css/framework7.min.css";
import React from "react";
import ReactDOM from "react-dom";
import { hot, cold, setConfig } from "react-hot-loader";
import Framework7, {
  Device,
  Request
} from "framework7/framework7.esm.bundle.js";
import Framework7React, {
  App,
  NavRight,
  Statusbar,
  Panel,
  View,
  Views,
  Page,
  Navbar,
  Block,
  Popup,
  Link
} from "framework7-react";
import PropTypes from "prop-types";
import { Provider, connect } from "react-redux";
import configureStore from "./configureStore";
import routes from "./routes.js";
import Header from "./components/Header/";
import Footer from "./components/Footer/";
import {
  showHeader,
  hideHeader,
  changeFooterIndex
} from "./actions/PageAction";
import { setupBaseApiConfig, apiCall, apiConfig, callOrigin } from "./api";
import "./css/index.less";
import { login, changeUUID } from "./actions/UserAction";

Framework7.use(Framework7React);

let preloadedState;
const store = configureStore(preloadedState);

const f7params = {
  // Array with app routes
  routes: routes(store),
  // App Name
  name: "",
  theme: "ios",
  // Extended by Panel component:
  init: true,
  panel: {
    // swipe: "left"
    // leftBreakpoint: 768,
    // rightBreakpoint: 1024
  },
  // Extended by Dialog component:
  dialog: {
    title: "",
    buttonOk: "确定",
    buttonCancel: "取消"
  },
  // Extended by Statusbar component:
  statusbar: {
    iosOverlaysWebview: true
  },
  on: {
    init: function() {
      //console.log("App initialized");
    },
    pageInit: function() {
      //console.log("Page initialized");
    },
    pageBeforeIn: (e, page) => {
      //console.log(e, page);
    },
    routeChange: (newRoute, previousRoute, router) => {
      //console.log(newRoute, previousRoute, router);
    }
  }
};

class Root extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    // this.$f7router
    // this.$f7.views.main.router
    // this.$f7.views.main.router.navigate(queryargs["path"], {
    //   props: {
    //     foo: 'bar',
    //     bar: true,
    //   }
    // });

    // store.subscribe(() => {
    //   this.f7params.routes = routes(store);
    //   console.log(this.f7params.routes);
    //   this.setState({
    //     f7params: this.f7params
    //   });
    // });

    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;
      setupBaseApiConfig(app, this.props.dispatch);
      // 获取登录信息
      if (localStorage.getItem("userinfo")) {
        this.props.dispatch(
          login(JSON.parse(localStorage.getItem("userinfo")))
        );
      }
      // 获得扫描结果
      window.showCapture = function(uuid) {
        self.props.dispatch(changeUUID(uuid));
        self.$f7.views.main.router.navigate("/tvlogin", {
          props: {}
        });
      };

      let ua = navigator.userAgent.toLowerCase();
      //判断是否是QQ
      let isQQ = ua.match(/QQ/i) == "qq";

      //判断是否是微信
      let isWeixin = ua.match(/MicroMessenger/i) == "micromessenger";

      let isHideFooter = false;

      if (!isWeixin && !isQQ) {
        // 获取经纬度
        // app.dialog.alert("开始请求app经纬度信息......");
        callOrigin(
          () => {
            window.webkit.messageHandlers.getLocation.postMessage("");
            self.props.dispatch(changeFooterIndex(0));
            isHideFooter = true;
          },
          () => {
            android.getLocation();
          }
        );
      }

      app.on("pageBeforeIn", page => {
        let ext = page.route.params.ext ? page.route.params.ext : "";
        let title = "12316问答";
        let showConsulateSearch = false;
        switch (ext) {
          case "nongchanpinanquan":
            title = "农产品安全";
            break;
          case "chongwu":
            title = "宠物园艺";
            break;
          case "zhengcedayi":
            title = "政策答疑";
            break;
          case "shishinongqing":
            title = "实时农情";
            break;
          case String(apiConfig.farmColumnId):
            title = "种植类";
            showConsulateSearch = true;
            break;
          case String(apiConfig.animalColumnId):
            title = "畜牧类";
            showConsulateSearch = true;
            break;
          case String(apiConfig.waterColumnId):
            title = "养殖类";
            showConsulateSearch = true;
            break;
          case String(apiConfig.machineColumnId):
            title = "农机类";
            showConsulateSearch = true;
            break;
          case String(apiConfig.estateColumnId):
            title = "农资类";
            showConsulateSearch = true;
            break;
        }
        if (page.to != "current") {
          return;
        }
        //console.log(page.name);
        if (page.name == "Index") {
          self.props.dispatch(showHeader("首页", "scan", "bell", "/message"));
          if (!isHideFooter) self.props.dispatch(changeFooterIndex(1));
        } else if (page.name == "Consulate") {
          self.props.dispatch(showHeader("在线咨询", true, "consulatemy"));
          if (!isHideFooter) self.props.dispatch(changeFooterIndex(0));
        } else if (page.name == "Country") {
          self.props.dispatch(showHeader("市民服务", true));
          if (!isHideFooter) self.props.dispatch(changeFooterIndex(0));
        } else if (page.name == "Infos") {
          self.props.dispatch(hideHeader());
        } else if (page.name == "My") {
          self.props.dispatch(showHeader("我的"));
          if (!isHideFooter) self.props.dispatch(changeFooterIndex(4));
        } else if (page.name == "Setting") {
          self.props.dispatch(showHeader("设置", true));
          if (!isHideFooter) self.props.dispatch(changeFooterIndex(0));
        } else if (page.name == "Login") {
          self.props.dispatch(showHeader("", true));
          if (!isHideFooter) self.props.dispatch(changeFooterIndex(0));
        } else if (page.name == "Register") {
          self.props.dispatch(showHeader("注册", true, "login", "/login"));
          if (!isHideFooter) self.props.dispatch(changeFooterIndex(0));
        } else if (page.name == "RegisterPassword") {
          //self.props.dispatch(showHeader("注册", true, "registerpass"));
          self.props.dispatch(showHeader("注册", true));
          if (!isHideFooter) self.props.dispatch(changeFooterIndex(0));
        } else if (page.name == "Retrieve") {
          self.props.dispatch(showHeader("找回密码", true));
          if (!isHideFooter) self.props.dispatch(changeFooterIndex(0));
        } else if (page.name == "RetrievePassword") {
          self.props.dispatch(showHeader("找回密码", true));
          if (!isHideFooter) self.props.dispatch(changeFooterIndex(0));
        } else if (page.name == "ChangePassword") {
          self.props.dispatch(showHeader("设置密码", true, "changepassword"));
          if (!isHideFooter) self.props.dispatch(changeFooterIndex(0));
        } else if (page.name == "ChangePhone") {
          self.props.dispatch(showHeader("更换手机号", true, "changephone"));
          if (!isHideFooter) self.props.dispatch(changeFooterIndex(0));
        } else if (page.name == "Prices") {
          self.props.dispatch(showHeader("农产品价格", true));
          if (!isHideFooter) self.props.dispatch(changeFooterIndex(0));
        } else if (page.name == "Products") {
          self.props.dispatch(hideHeader());
        } else if (page.name == "Example") {
          self.props.dispatch(hideHeader());
        } else if (page.name == "Editname") {
          self.props.dispatch(showHeader("编辑姓名", true));
          if (!isHideFooter) self.props.dispatch(changeFooterIndex(0));
        } else if (page.name == "Map") {
          self.props.dispatch(hideHeader());
          if (!isHideFooter) self.props.dispatch(changeFooterIndex(2));
        } else if (
          page.name == "Authentication" ||
          page.name == "AuthenticationSelectgroup" ||
          page.name == "AuthenticationResult"
        ) {
          self.props.dispatch(showHeader("身份认证", true));
          if (!isHideFooter) self.props.dispatch(changeFooterIndex(0));
        } else if (page.name == "AuthenticationThird") {
          //self.props.dispatch(showHeader("身份认证", "返回"));
          self.props.dispatch(hideHeader());
          if (!isHideFooter) self.props.dispatch(changeFooterIndex(0));
        } else if (page.name == "Followproducts") {
          self.props.dispatch(showHeader("我关注的农产品", true));
          if (!isHideFooter) self.props.dispatch(changeFooterIndex(0));
        } else if (page.name == "Sites") {
          self.props.dispatch(showHeader("乡村休闲游", true));
          if (!isHideFooter) self.props.dispatch(changeFooterIndex(0));
        } else if (page.name == "Featureproducts") {
          self.props.dispatch(showHeader("地产农产品", true));
          if (!isHideFooter) self.props.dispatch(changeFooterIndex(0));
        } else if (
          page.name == "FeatureproductInfo" ||
          page.name == "SiteInfo" ||
          page.name == "CultureInfo"
        ) {
          self.props.dispatch(showHeader("详情", true));
          if (!isHideFooter) self.props.dispatch(changeFooterIndex(0));
        }
        // else if (page.name == "Culture") {
        //   self.props.dispatch(showHeader("乡村风情", true));
        //   if(!isHideFooter) self.props.dispatch(changeFooterIndex(0));
        // }
        else if (page.name == "Community") {
          self.props.dispatch(showHeader("益农社"));
          if (!isHideFooter) self.props.dispatch(changeFooterIndex(3));
        } else if (page.name == "CommunityChoose") {
          self.props.dispatch(showHeader("全部益农社", true));
          if (!isHideFooter) self.props.dispatch(changeFooterIndex(0));
        } else if (page.name == "CommunityInfo") {
          self.props.dispatch(showHeader("益农社详情", true));
          if (!isHideFooter) self.props.dispatch(changeFooterIndex(0));
        } else if (page.name == "CommunityDetail") {
          self.props.dispatch(showHeader("益农社简介", true));
          if (!isHideFooter) self.props.dispatch(changeFooterIndex(0));
        } else if (page.name == "Consulaterecords") {
          self.props.dispatch(showHeader("咨询记录", true));
          if (!isHideFooter) self.props.dispatch(changeFooterIndex(0));
        } else if (page.name == "ConsulateMy") {
          self.props.dispatch(showHeader("我的咨询", true));
          if (!isHideFooter) self.props.dispatch(changeFooterIndex(0));
        } else if (page.name == "ConsulateSub") {
          //this.props.dispatch(showHeader(title, true, "consulatemy"));
          this.props.dispatch(
            showHeader(
              title,
              true,
              showConsulateSearch ? "consulatesearch" : ""
            )
          );
          if (!isHideFooter) this.props.dispatch(changeFooterIndex(0));
        } else if (page.name == "ConsulateAsk") {
          self.props.dispatch(showHeader("我要咨询", true));
          if (!isHideFooter) self.props.dispatch(changeFooterIndex(0));
        } else if (page.name == "ConsulateInfo") {
          self.props.dispatch(showHeader("咨询详情", true));
          if (!isHideFooter) self.props.dispatch(changeFooterIndex(0));
        } else if (page.name == "ConsulateSearch") {
          self.props.dispatch(hideHeader());
          if (!isHideFooter) self.props.dispatch(changeFooterIndex(0));
        } else if (page.name == "Expert") {
          self.props.dispatch(showHeader("选专家", true));
          if (!isHideFooter) self.props.dispatch(changeFooterIndex(0));
        } else if (page.name == "Viewimage") {
          self.props.dispatch(showHeader("", true, "deleteConsulatePicture"));
          if (!isHideFooter) self.props.dispatch(changeFooterIndex(0));
        } else if (page.name == "Information") {
          if (!isWeixin && !isQQ) {
            self.props.dispatch(showHeader("详情", true, "share"));
          } else {
            self.props.dispatch(showHeader("详情", true));
          }
          if (!isHideFooter) self.props.dispatch(changeFooterIndex(0));
        } else if (page.name == "Bill") {
          self.props.dispatch(showHeader("涉农账单", true));
          if (!isHideFooter) self.props.dispatch(changeFooterIndex(0));
        } else if (page.name == "FarmService") {
          self.props.dispatch(showHeader("农事服务", true, "farmservicemonth"));
          if (!isHideFooter) self.props.dispatch(changeFooterIndex(0));
        } else if (page.name == "Affair") {
          self.props.dispatch(showHeader("涉农账单", true));
          if (!isHideFooter) self.props.dispatch(changeFooterIndex(0));
        } else if (page.name == "BillAsset") {
          self.props.dispatch(
            showHeader("资产概况", true, "billInAndOut", "/billinandout")
          );
          if (!isHideFooter) self.props.dispatch(changeFooterIndex(0));
        } else if (page.name == "BillInAndOut") {
          self.props.dispatch(showHeader("收支情况", true));
          if (!isHideFooter) self.props.dispatch(changeFooterIndex(0));
        } else if (page.name == "BillSubsidy") {
          self.props.dispatch(showHeader("涉农补贴查询", true));
          if (!isHideFooter) self.props.dispatch(changeFooterIndex(0));
        } else if (page.name == "BillLand") {
          self.props.dispatch(showHeader("土地承包/土地流转", true));
          if (!isHideFooter) self.props.dispatch(changeFooterIndex(0));
        } else if (page.name == "CitizenService") {
          self.props.dispatch(showHeader("市民服务", true));
          if (!isHideFooter) self.props.dispatch(changeFooterIndex(0));
        } else if (page.name == "News") {
          self.props.dispatch(showHeader("资讯", true));
          if (!isHideFooter) self.props.dispatch(changeFooterIndex(0));
        } else if (page.name == "Message") {
          self.props.dispatch(showHeader("消息", true));
          if (!isHideFooter) self.props.dispatch(changeFooterIndex(0));
        }
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {}

  render() {
    let subpath = "";
    if (process.env.NODE_ENV == "production") {
      subpath = apiConfig.subpath;
    }
    return (
      /* Main Framework7 App component where we pass Framework7 params */
      <App params={f7params} id="cn.shac.yn12316">
        {/* Status bar overlay for full screen mode (Cordova or PhoneGap) */}
        <Statusbar />

        {/* Left Panel with "cover" effect */}
        <Panel left cover>
          <View>
            <Page>
              <Navbar title="Left Panel" />
              <Block>
                <p>Here comes the left panel text</p>
              </Block>
            </Page>
          </View>
        </Panel>

        {/* Right Panel with "reveal" effect */}
        <Panel right reveal>
          <View>
            <Page>
              <Navbar title="Right Panel" />
              <Block>
                <p>Here comes the right panel text</p>
              </Block>
            </Page>
          </View>
        </Panel>

        <Views>
          {/**f7router={this.$f7router} */}
          <Header />
          {/*  Main view  pushState={true} pushStateSeparator="" pushStateRoot="" reloadPages={true}*/}
          <View
            main
            //routes={routes}
            pushState={true}
            pushStateSeparator=""
            pushStateRoot={subpath}
            animate={true}
          />
          <Footer />
        </Views>

        {/* Popup. All modals should be outside of Views */}
        <Popup id="my-popup">
          <View>
            <Page>
              <Navbar title="Popup">
                {/* Link to close popup */}
                <NavRight>
                  <Link popupClose>Close</Link>
                </NavRight>
              </Navbar>
              <Block>
                <p>Here comes popup text</p>
              </Block>
            </Page>
          </View>
        </Popup>
      </App>
    );
  }
}

function mapStateToProps(state) {
  const { pageState, userState } = state;
  return { pageState, userState };
}

Root.propTypes = {
  pageState: PropTypes.object.isRequired,
  userState: PropTypes.object.isRequired
};

let ROOT = connect(mapStateToProps)(Root);
if (process.env.NODE_ENV !== "production") {
  // setConfig({ pureSFC: true });
  // setConfig({
  //   onComponentRegister: (type, name, file) =>
  //     (String(type).indexOf("useState") > 0 ||
  //       String(type).indexOf("useEffect") > 0) &&
  //     cold(type)
  // });
  //ROOT = cold(module)(ROOT);
  ROOT = hot(module)(ROOT);
}

ReactDOM.render(
  <Provider store={store}>
    <ROOT />
  </Provider>,
  document.getElementById("app")
);

// if (Device.ios) {
//   // Request.get('http://google.com');
//   // console.log("You are in ios");
// }
