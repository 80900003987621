import React from "react";
import { Link } from "framework7-react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "framework7-icons";
import "./SitePanel.less";
import AutoImg from "../AutoImg/";

class SitePanel extends React.Component {
  componentDidMount() {
    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;
      // this.$f7.views.main.router
    });
  }

  render() {
    let stars = [],
      tags = "";
    for (let i = 0; i < this.props.rate; i++) {
      stars.push(true);
    }
    for (let i = this.props.rate; i < 5; i++) {
      stars.push(false);
    }
    for (let i = 0; i < this.props.tags.length; i++) {
      if (i > 0) {
        tags += " + ";
      }
      tags += this.props.tags[i];
    }
    return (
      <Link className="SitePanel" href={this.props.link}>
        <div>
          <AutoImg src={this.props.img} ratio={2} />
          <div>{tags}</div>
        </div>
        <div>
          <div>{this.props.title}</div>
          {stars.length > 0 && (
            <div>
              {stars.map((star, si) => (
                <span className={star ? "star" : ""} key={si} />
              ))}
            </div>
          )}
        </div>
        {this.props.location && (
          <div>
            <span />
            <span>{this.props.location}</span>
          </div>
        )}
      </Link>
    );
  }
}

function mapStateToProps(state) {
  const { userState } = state;
  return { userState };
}

SitePanel.propTypes = {
  userState: PropTypes.object.isRequired,
  link: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
  //rate: PropTypes.number.isRequired,
  //location: PropTypes.string.isRequired
};

export default connect(mapStateToProps)(SitePanel);
