import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "framework7-icons";
import { Page } from "framework7-react";
import { apiCall, apiConfig } from "../api";
import "../css/pageCountry.less";
import Carousel from "../components/Carousel";
import CountryPanel from "../components/CountryPanel";

class Country extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      banners: [],
      cat1Id: apiConfig.featureColumnId,
      cat2Id: apiConfig.siteColumnId,
      cat3Id: apiConfig.cultureColumnId,
      cat4Id: apiConfig.countryColumnId,
      cat1: [
        //{ id: 1, title: "马陆葡萄", img: ImgSample11, url: "/featureproductinfo/1"},
      ],
      cat2: [
        //{ id: 1, title: "青浦练塘镇徐练村", img: ImgSample3, url: "/country" },
      ],
      cat3: [
        //{ id: 1, title: "春节", img: ImgSample4, url: "/country" },
      ],
      cat4: [
        //{ id: 1, title: "一岗村益农社", img: ImgSample7, url: "/country" },
      ]
    };
  }

  componentDidMount() {
    this.isComponentMounted = true;
    const that = this;
    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      apiCall(
        "/app/getVillageInfo",
        "POST",
        {},
        data => {
          //console.log(data);
          if (this.state.banners.length == 0) {
            let banners = [];
            data.content.banner.forEach(item => {
              if (item.previewImage) {
                banners.push({
                  img: item.previewImage,
                  url: "/information/" + item.id,
                  title: item.title
                });
              }
            });
            if (this.isComponentMounted) {
              this.setState({
                banners
              });
            }
          }

          let cats = [];
          cats[that.state.cat1Id] = [];
          cats[that.state.cat2Id] = [];
          cats[that.state.cat3Id] = [];
          let cat4 = [];
          data.content.dataList.forEach(column => {
            let urlPrefix = "/information/";
            if (column.column_id == that.state.cat1Id) {
              urlPrefix = "/featureproductinfo/";
            }
            if (column.column_id == that.state.cat2Id) {
              urlPrefix = "/siteinfo/";
            }
            if (column.column_id == that.state.cat3Id) {
              urlPrefix = "/cultureinfo/";
            }
            if (column.column_id == that.state.cat4Id) {
              urlPrefix = "/cultureinfo/";
            }
            cats[column.column_id] = column.data.map(item => {
              return {
                id: item.id,
                title: item.title,
                img: item.previewImage,
                url: urlPrefix + item.id
              };
            });
          });
          cat4 = data.content.yinong.map(item => {
            return {
              id: item.id,
              title: item.ynName,
              img: item.images,
              url: "/communityinfo/" + item.id
            };
          });
          //console.log(cats, cat4);
          if (that.isComponentMounted) {
            that.setState({
              cat1: cats[that.state.cat1Id],
              cat2: cats[that.state.cat2Id],
              cat3: cats[that.state.cat3Id],
              cat4: cats[that.state.cat4Id]
              //cat4
            });
          }
        },
        function(xhr, status) {
          //console.log(xhr, status);
        }
      );
    });
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  render() {
    return (
      <Page name="Country">
        {this.state.banners.length > 0 && (
          <Carousel data={this.state.banners} />
        )}

        <CountryPanel
          sectionTitle="地产农产品"
          location="/featureproducts"
          data={this.state.cat1}
          externalClass="pop2"
        />
        <CountryPanel
          sectionTitle="乡村休闲游"
          location="/sites"
          data={this.state.cat2}
          externalClass="pop2"
        />
        <CountryPanel
          sectionTitle="乡村文化"
          location={"/culture/" + apiConfig.cultureColumnId}
          data={this.state.cat3}
          externalClass="pop2"
        />
        <CountryPanel
          sectionTitle="美丽乡村、乡村振兴示范村"
          location={"/culture/" + apiConfig.countryColumnId}
          data={this.state.cat4}
          externalClass="pop2"
        />
        {/**
         <CountryPanel
          sectionTitle="益农社介绍"
          location="/community"
          data={this.state.cat4}
          externalClass="pop1"
        />
        */}
      </Page>
    );
  }
}

function mapStateToProps(state) {
  const { userState } = state;
  return { userState };
}

Country.propTypes = {
  userState: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(Country);
