import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "framework7-icons";
import { Page } from "framework7-react";
import { apiCall, apiConfig } from "../api";
import "../css/pageViewimage.less";
import RcViewer from "@hanyk/rc-viewer";

class Viewimage extends React.Component {
  constructor(props) {
    super(props);
    this.index = this.$f7route.params.index;
    this.state = {};
  }

  componentDidMount() {
    this.isComponentMounted = true;
    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;
    });
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  render() {
    return (
      <Page name="Viewimage">
        <RcViewer
          options={{
            title: false,
            toolbar: {
              zoomIn: true,
              zoomOut: true,
              oneToOne: false,
              reset: false,
              prev: false,
              play: {
                show: false,
                size: "large"
              },
              next: false,
              rotateLeft: false,
              rotateRight: false,
              flipHorizontal: false,
              flipVertical: false
            }
          }}
          alt={""}
        >
          <img src={this.props.userState.consulatePictures[this.index]} />
        </RcViewer>
      </Page>
    );
  }
}

function mapStateToProps(state) {
  const { userState } = state;
  return { userState };
}

Viewimage.propTypes = {
  userState: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(Viewimage);
