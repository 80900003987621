export const pageActionType = {
  SETLOCATION: Symbol("SETLOCATION"),
  SHOWHEADER: Symbol("SHOWHEADER"),
  HIDEHEADER: Symbol("HIDEHEADER"),
  CHANGEFOOTERINDEX: Symbol("CHANGEFOOTERINDEX"),
  FEATURECATEGORIES: Symbol("FEATURECATEGORIES"),
  SITECATEGORIES: Symbol("SITECATEGORIES"),
  CULTURECATEGORIES: Symbol("CULTURECATEGORIES"),
  SHOWSHAREPOPUP: Symbol("SHOWSHAREPOPUP"),
  TRIGGERFARMSERVICEMONTH: Symbol("TRIGGERFARMSERVICEMONTH"),
  FARMSERVICEMONTH: Symbol("FARMSERVICEMONTH")
};

export function setLocation(longitude, latitude) {
  return { type: pageActionType.SETLOCATION, longitude, latitude };
}

export function showHeader(title, left, right, rightLocation) {
  let action = { type: pageActionType.SHOWHEADER };
  action.title = title ? title : "";
  action.left = left ? left : false;
  action.right = right ? right : false;
  action.rightLocation = rightLocation ? rightLocation : false;
  return action;
}

export function hideHeader() {
  return { type: pageActionType.HIDEHEADER };
}

export function changeFooterIndex(footerIndex) {
  return { type: pageActionType.CHANGEFOOTERINDEX, footerIndex };
}

export function setCategories(fromtype, data) {
  let type = pageActionType.FEATURECATEGORIES;
  if (fromtype == "feature") {
    type = pageActionType.FEATURECATEGORIES;
  } else if (fromtype == "site") {
    ptype = pageActionType.SITECATEGORIES;
  } else if (fromtype == "culture") {
    ptype = pageActionType.CULTURECATEGORIES;
  }
  return { type, data };
}

export function showShare() {
  return { type: pageActionType.SHOWSHAREPOPUP };
}

export function triggerFarmServiceMonth() {
  return { type: pageActionType.TRIGGERFARMSERVICEMONTH };
}

export function farmServiceMonth(farmServiceMonth) {
  return { type: pageActionType.FARMSERVICEMONTH, farmServiceMonth };
}
