import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "framework7-icons";
import { Page, Toolbar, Tabs, Tab, Link } from "framework7-react";
import "../css/pageBillInAndOut.less";

class BillInAndOut extends React.Component {
  constructor(props) {
    super(props);

    this.userId = this.$f7route.params.userId;
    this.computeWidth = this.computeWidth.bind(this);

    this.state = {
      incomelist: [
        {
          id: "cooperation2",
          name: "村经济合作社",
          value: 11.0,
          list: [
            { name: "经营收入", value: 8.0 },
            { name: "其他收入", value: 3.0 }
          ]
        },
        {
          id: "enterprise2",
          name: "村集体企业",
          value: 12,
          list: [{ name: "经营收入", value: 12.0 }]
        },
        {
          id: "committee2",
          name: "村民委员会",
          value: 41.0,
          list: [
            { name: "管理费用", value: 26.0 },
            { name: "公益收入", value: 3.0 },
            { name: "其他收入", value: 12.0 }
          ]
        }
      ],
      outcomelist: [
        {
          id: "cooperation3",
          name: "村经济合作社",
          value: 64.0,
          list: [
            { name: "管理费用", value: 26.0 },
            { name: "福利支出", value: 15.0 },
            { name: "支农支出", value: 12.8 },
            { name: "经营支出", value: 8.0 },
            { name: "其他支出", value: 3.0 }
          ]
        },
        {
          id: "enterprise3",
          name: "村集体企业",
          value: 12,
          list: [{ name: "经营支出", value: 12.0 }]
        },
        {
          id: "committee3",
          name: "村民委员会",
          value: 41.0,
          list: [
            { name: "管理费用", value: 26.0 },
            { name: "公益支出", value: 3.0 },
            { name: "其他支出", value: 12.0 }
          ]
        },
        {
          id: "salary3",
          name: "村干部薪酬（2018年）",
          value: 36.0,
          list: [
            {
              name: "李建国",
              value: 22.0,
              title: "书记",
              list: [
                { name: "基本报酬", value: 12.0 },
                { name: "业绩奖励", value: 10.0 }
              ]
            },
            {
              name: "刘复星",
              value: 14.0,
              title: "主任",
              list: [
                { name: "基本报酬", value: 8.0 },
                { name: "业绩奖励", value: 6.0 }
              ]
            }
          ]
        }
      ]
    };
  }

  computeWidth($, prelist) {
    prelist.map(type => {
      const totalWidth = window.document.documentElement.clientWidth;
      let leftWidth = 0,
        rightWidth = 0;
      let maxValue = 0;
      $(".bill-asset-body-" + type.id + " > div").forEach(item => {
        let left = $(item)
          .children()
          .eq(0)
          .outerWidth();
        let right = $(item)
          .children()
          .eq(1)
          .children()
          .eq(0)
          .children()
          .eq(1)
          .outerWidth();

        let value = parseFloat($(item).attr("data-value"));
        if (leftWidth < left) {
          leftWidth = left;
        }
        if (rightWidth < right) {
          rightWidth = right;
        }
        if (maxValue < value) {
          maxValue = value;
        }
      });
      const fullWidth = totalWidth - leftWidth - rightWidth;
      type.list.map(item => {
        item.width = Math.floor((item.value / maxValue) * fullWidth);
      });
    });
  }

  componentDidMount() {
    this.isComponentMounted = true;
    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      this.computeWidth($, this.state.incomelist);
      this.computeWidth($, this.state.outcomelist);

      if (this.isComponentMounted) {
        this.setState(prev => ({
          incomelist: prev.incomelist,
          outcomelist: prev.outcomelist
        }));
      }
    });
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  render() {
    const self = this;
    return (
      <Page name="BillInAndOut" className="BillInAndOut">
        <div className="BillInAndOutToolbar">
          <Toolbar tabbar>
            <Link tabLink="#bill-in-out-1" tabLinkActive>
              收入情况
            </Link>
            <Link tabLink="#bill-in-out-2">支出情况</Link>
          </Toolbar>
          <Tabs animated>
            <Tab id="bill-in-out-1" className="page-content" tabActive>
              <div className="bill-asset-container">
                {this.state.incomelist.map(type => {
                  return (
                    <div key={type.id}>
                      <div className="bill-asset-title">
                        <div>{type.name}</div>
                        {type.list.length == 0 ? (
                          <div>暂无信息</div>
                        ) : (
                          <div>收入合计：{type.value.toFixed(2)}</div>
                        )}
                      </div>
                      <div
                        className={"bill-asset-body bill-asset-body-" + type.id}
                      >
                        {type.list.map((item, index) => {
                          return (
                            <div key={item.name} data-value={item.value}>
                              <div>
                                <div>{item.name}</div>
                                {item.title ? <div>( {item.title} )</div> : ""}
                              </div>
                              <div>
                                <div>
                                  <div
                                    className="bill-asset-bar"
                                    style={{ width: item.width }}
                                  />
                                  <div>{item.value.toFixed(2)}</div>
                                </div>
                                {item.list && item.list.length > 0 ? (
                                  <div>
                                    {item.list.map(i => (
                                      <div key={i.name}>
                                        {i.name}: {i.value.toFixed(2)}
                                      </div>
                                    ))}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </Tab>
            <Tab id="bill-in-out-2" className="page-content">
              <div className="bill-asset-container">
                {this.state.outcomelist.map(type => {
                  return (
                    <div key={type.id}>
                      <div className="bill-asset-title">
                        <div>{type.name}</div>
                        {type.list.length == 0 ? (
                          <div>暂无信息</div>
                        ) : (
                          <div>支出合计：{type.value.toFixed(2)}</div>
                        )}
                      </div>
                      <div
                        className={"bill-asset-body bill-asset-body-" + type.id}
                      >
                        {type.list.map((item, index) => {
                          return (
                            <div key={item.name} data-value={item.value}>
                              <div>
                                <div>{item.name}</div>
                                {item.title ? <div>( {item.title} )</div> : ""}
                              </div>
                              <div>
                                <div>
                                  <div
                                    className="bill-asset-bar"
                                    style={{ width: item.width }}
                                  />
                                  <div>{item.value.toFixed(2)}</div>
                                </div>
                                {item.list && item.list.length > 0 ? (
                                  <div>
                                    {item.list.map(i => (
                                      <div key={i.name}>
                                        {i.name}: {i.value.toFixed(2)}
                                      </div>
                                    ))}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </Tab>
          </Tabs>
        </div>
      </Page>
    );
  }
}

function mapStateToProps(state) {
  const { userState } = state;
  return { userState };
}

BillInAndOut.propTypes = {
  userState: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(BillInAndOut);
