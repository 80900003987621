import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "framework7-icons";
import {
  Page,
  Link,
  Block,
  Toolbar,
  Tabs,
  Tab,
  Row,
  Col,
  Button
} from "framework7-react";
import InfiniteScroll from "../components/InfiniteScroll/";
import Carousel from "../components/Carousel/";
import Pricescroll from "../components/Pricescroll/";
import InformationPanel from "../components/InformationPanel/";
import CulturePanel from "../components/CulturePanel/";
import SitePanel from "../components/SitePanel/";
import NoData from "../components/NoData/";
import { apiCall, apiConfig } from "../api";
import "../css/pageIndex.less";
import ImgTotal from "../images/im_recommend.png";
import { loginPath } from "../path";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.onRef = this.onRef.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.renderData = this.renderData.bind(this);

    this.pageIndex = 1;

    this.state = {
      prices: this.props.userState.prices.map(price => {
        return price.text + price.avgPricePfj + price.unit;
      }),
      banners: [],
      weather: [],
      products: [],
      travels: [],
      cultures: [],
      communites: [],
      services: [],
      allData: []
    };
  }

  componentDidMount() {
    this.isComponentMounted = true;
    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      this.fetchData(this.renderData);

      apiCall(
        "/price/getRollData",
        "POST",
        {
          userId: this.props.userState.userinfo.uid
            ? String(this.props.userState.userinfo.uid)
            : ""
        },
        data => {
          if (data.code == apiConfig.errorCode1000) {
            //this.$f7.dialog.alert(data.msg);
          } else {
            if (this.isComponentMounted) {
              this.setState({
                prices: data.content.map(item => {
                  return item.productName + item.avgPrice + item.unit;
                })
              });
            }
          }
        },
        err => {
          //console.log(err);
          //this.$f7.dialog.alert(JSON.stringify(err));
        }
      );
    });
  }

  componentDidUpdate(prevProps, prevState) {
    //console.log("** componentDidUpdate **");
    if (prevProps.userState.userinfo.uid != this.props.userState.userinfo.uid) {
      apiCall(
        "/price/getRollData",
        "POST",
        {
          userId: this.props.userState.userinfo.uid
            ? String(this.props.userState.userinfo.uid)
            : ""
        },
        data => {
          if (data.code == apiConfig.errorCode1000) {
            //this.$f7.dialog.alert(data.msg);
          } else {
            if (this.isComponentMounted) {
              this.setState({
                prices: data.content.map(item => {
                  return item.productName + item.avgPrice + item.unit;
                })
              });
            }
          }
        },
        err => {
          //console.log(err);
          //this.$f7.dialog.alert(JSON.stringify(err));
        }
      );
    }
    if (prevProps.userState.prices != this.props.userState.prices) {
      this.setState({
        prices: this.props.userState.prices.map(price => {
          return price.text + price.avgPricePfj + price.unit;
        })
      });
    }
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  onRef(ref) {
    this.InfiniteScroll = ref;
  }

  fetchData(func) {
    const that = this;
    apiCall(
      "/app/index",
      "POST",
      {
        channelId: apiConfig.channelId,
        page: that.pageIndex,
        pageSize: 2
      },
      data => {
        if (that.state.banners.length == 0) {
          let banners = data.content.banner.map(item => {
            return { img: item.previewImage, url: "/information/" + item.id };
          });
          if (that.isComponentMounted) {
            that.setState({
              banners
            });
          }
        }
        if (that.state.weather.length == 0) {
          let weather = [];
          data.content.weather &&
            data.content.weather.data.forecast.forEach((item, index) => {
              if (index <= 1) {
                weather.push({
                  description:
                    item.type +
                    " " +
                    parseFloat(item.low.match(/[\d\.]+/)[0]).toFixed(0) +
                    "℃ ~ " +
                    parseFloat(item.high.match(/[\d\.]+/)[0]).toFixed(0) +
                    "℃"
                });
              }
            });
          if (that.isComponentMounted) {
            that.setState({
              weather
            });
          }
        }
        that.pageIndex++;
        if (func) func(data.content.dataList);
      },
      function(xhr, status) {
        //console.log(xhr, status);
      }
    );
  }

  renderData(data) {
    let products = [],
      travels = [],
      cultures = [],
      communites = [],
      services = [];

    for (let i = 0; i < data.length; i++) {
      if (data[i].column_id == apiConfig.featureColumnId) {
        // 地产农产品
        products = data[i].data;
      } else if (data[i].column_id == apiConfig.siteColumnId) {
        // 乡村休闲游
        travels = data[i].data;
      } else if (data[i].column_id == apiConfig.countryColumnId) {
        // 乡村风情
        cultures = data[i].data;
      } else if (data[i].column_id == apiConfig.communityColumnId) {
        // 益农社动态
        communites = data[i].data;
      } else if (data[i].column_id == apiConfig.farmserviceColumnId) {
        // 农事服务
        services = data[i].data;
      }
    }

    if (this.isComponentMounted) {
      this.setState(prev => ({
        products,
        travels,
        cultures,
        communites,
        services,
        allData: data
      }));
    }
  }

  goto(url) {
    url = loginPath(this.props.userState.userinfo, url);
    this.$f7.views.main.router.navigate(url, {
      props: {}
    });
  }

  gotoAffair() {
    if (JSON.stringify(this.props.userState.userinfo) == "{}") {
      this.$f7.toast
        .create({
          text: "请先登录",
          position: "center",
          closeTimeout: 2000,
          on: {
            close: () => {
              this.$f7.views.main.router.navigate("/login", {
                props: {}
              });
            }
          }
        })
        .open();
      return;
    }
    if (
      this.props.userState.userinfo.ubind != apiConfig.userBindSuccess &&
      this.props.userState.userinfo.uphone != "15851575410"
    ) {
      this.$f7.toast
        .create({
          text: "身份认证审核通过后才能查看",
          position: "center",
          closeTimeout: 2000,
          on: {
            close: () => {}
          }
        })
        .open();
      return;
    }
    this.$f7.views.main.router.navigate("/affair", {
      props: {}
    });
  }

  render() {
    return (
      // <InfiniteScroll
      //   pageName="Index"
      //   fetchData={this.fetchData}
      //   renderData={this.renderData}
      //   onRef={this.onRef}
      // >
      <Page name="Index">
        {/*  
        <Block>
          <p>Here comes main view page text</p>
        </Block>
        <Row>
          <Col>
            <Button panelOpen="left">Left Panel</Button>
          </Col>
          <Col>
            <Button panelOpen="right">Right Panel</Button>
          </Col>
        </Row>
        <Button popupOpen="#my-popup" fill>
          Open Popup
        </Button>
        <Link href="/example">Example Page</Link>
        */}

        {this.state.banners.length > 0 && (
          <Carousel
            data={this.state.banners}
            showWeather={true}
            weather={this.state.weather}
          />
        )}

        <Pricescroll prices={this.state.prices} />

        <div className="IndexIcons">
          {/** 
            <Link href="/citizenservice">
              <div />
              <div>市民服务</div>
            </Link>
          */}
          <Link href="/country">
            <div />
            <div>市民服务</div>
          </Link>
          <Link href="###" onClick={this.gotoAffair.bind(this)}>
            {/**bill  href="/affair"   */}
            <div />
            <div>涉农账单</div>
          </Link>
          <Link href="/consulate">
            <div />
            <div>我要咨询</div>
          </Link>
          {/** 
            <Link href="/news">
              <div />
              <div>资讯</div>
            </Link>
          */}
          <Link href="/farmservice">
            <div />
            <div>农事服务</div>
          </Link>
        </div>

        <div className="TitleGapTotal">
          <img src={ImgTotal} />
        </div>

        {this.state.allData.map(data => {
          if (data.column_id == apiConfig.featureColumnId) {
            // 地产农产品
            return (
              <React.Fragment key={data.column_id}>
                <Link className="topDiv" href="/featureproducts">
                  <div className="blue">
                    <div>地产农产品</div>
                    <span>更多</span>
                  </div>
                </Link>

                {this.state.products.map(item => (
                  <CulturePanel
                    key={item.id}
                    link={"/featureproductinfo/" + item.id}
                    img={item.previewImage}
                    title={item.title}
                    description={item.abstractor}
                  />
                ))}
              </React.Fragment>
            );
          } else if (data.column_id == apiConfig.siteColumnId) {
            // 乡村休闲游
            return (
              <React.Fragment key={data.column_id}>
                <Link className="topDiv" href="/sites">
                  <div className="lightblue">
                    <div>乡村休闲游</div>
                    <span>更多</span>
                  </div>
                </Link>
                {this.state.travels.map(item => (
                  <SitePanel
                    key={item.id}
                    link={"/siteinfo/" + item.id}
                    img={item.previewImage}
                    title={item.title}
                    tags={[]}
                    //rate={5}
                    //location={"地址暂未获取"}
                  />
                ))}
              </React.Fragment>
            );
          } else if (data.column_id == apiConfig.countryColumnId) {
            // 乡村风情
            return (
              <React.Fragment key={data.column_id}>
                <Link
                  className="topDiv"
                  href={"/culture/" + apiConfig.countryColumnId}>
                  <div className="green">
                    <div>美丽乡村、乡村振兴示范村</div>
                    <span>更多</span>
                  </div>
                </Link>

                {this.state.cultures.map(item => (
                  <CulturePanel
                    key={item.id}
                    link={"/cultureinfo/" + item.id}
                    img={item.previewImage}
                    title={item.title}
                    description={item.abstractor}
                    classType="horizontal"
                  />
                ))}
              </React.Fragment>
            );
          } else if (data.column_id == apiConfig.communityColumnId) {
            // 益农社动态
            return (
              <React.Fragment key={data.column_id}>
                <Link className="topDiv noMarginBottom" href="/community">
                  <div className="yellow">
                    <div>益农社动态</div>
                    <span>更多</span>
                  </div>
                </Link>
                {this.state.communites.map(item => (
                  <InformationPanel
                    key={item.id}
                    link={"/information/" + item.id}
                    img={item.previewImage}
                    title={item.title}
                    date={item.createDate}
                    source={item.enterpriseName ? item.enterpriseName : ""}
                    //source={item.infoFrom ? item.infoFrom : ""}
                    //tags={["信息", "培训", "学习"]}
                    //views={6782}
                  />
                ))}
              </React.Fragment>
            );
          } else if (data.column_id == apiConfig.farmserviceColumnId) {
            // 农事服务
            return (
              <React.Fragment key={data.column_id}>
                <Link className="topDiv" href="/farmservice">
                  <div className="red">
                    <div>农事服务</div>
                    <span>更多</span>
                  </div>
                </Link>
                {this.state.services.map(item => (
                  <CulturePanel
                    key={item.id}
                    link={"/information/" + item.id}
                    img={item.previewImage}
                    title={item.title}
                    description={item.abstractor}
                    classType="horizontal"
                  />
                ))}
              </React.Fragment>
            );
          }
        })}

        {/** </InfiniteScroll>*/}
      </Page>
    );
  }
}

function mapStateToProps(state) {
  const { userState } = state;
  return { userState };
}

Index.propTypes = {
  userState: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(Index);
