import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "framework7-icons";
import { Page, Link, Searchbar, Input, Button } from "framework7-react";
import "../css/pageConsulateSearch.less";
import { loginPath } from "../path";
import { apiCall, apiConfig } from "../api";
import InfiniteScroll from "../components/InfiniteScroll";
import NoData from "../components/NoData";

class ConsulateSearch extends React.Component {
  constructor(props) {
    super(props);

    this.fetchData = this.fetchData.bind(this);
    this.renderData = this.renderData.bind(this);
    this.onRef = this.onRef.bind(this);
    this.changeSearchText = this.changeSearchText.bind(this);
    this.getSearchHistory = this.getSearchHistory.bind(this);
    this.addSearchHistory = this.addSearchHistory.bind(this);

    this.searchref = React.createRef();
    this.page = 1;
    this.state = {
      searchHistory: this.getSearchHistory(),
      searchText: "",
      consulateList: [],
      showSubmitConsult: true
    };
  }

  componentDidUpdate() {}

  componentDidMount() {
    this.isComponentMounted = true;
    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      app.on("searchbarSearch", (searchbar, query, previousQuery) => {
        if (query) {
          this.changeSearchText(query);
        }
      });
      app.on("searchbarClear", (searchbar, previousQuery) => {
        this.changeSearchText("");
      });
      //this.searchref.current.refs.inputEl.focus();
    });
  }

  onPageAfterIn() {
    this.searchref.current.refs.inputEl.focus();

    // this.$f7ready(f7 => {
    //   this.searchref.current.refs.inputEl.focus();
    // });
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  goto(url) {
    url = loginPath(this.props.userState.userinfo, url);
    this.$f7.views.main.router.navigate(url, {
      props: {}
    });
  }

  goBack() {
    this.$f7.views.main.router.back();
  }

  reSearch(searchText) {
    this.searchref.current.search(searchText);
  }
  changeSearchText(searchText) {
    if (!this.isComponentMounted) {
      return;
    }
    if (this.prevSearchText == searchText) {
      return;
    }
    this.prevSearchText = searchText;
    this.page = 1;
    this.setState(
      () => ({
        searchText: searchText
      }),
      () => {
        if (this.searchTime && this.searchTime <= new Date().getTime() + 300) {
          // 太频繁
          if (this.searchTimeout) clearTimeout(this.searchTimeout);
          this.searchTimeout = setTimeout(() => {
            this.InfiniteScroll.reInfinite();
          }, 300);
          return;
        }
        this.InfiniteScroll.reInfinite();
      }
    );
  }

  onRef(ref) {
    this.InfiniteScroll = ref;
  }

  getSearchHistory() {
    return localStorage.getItem("consulateSearchHistory")
      ? JSON.parse(localStorage.getItem("consulateSearchHistory"))
      : [];
  }
  addSearchHistory(newHistoryItem) {
    let history = this.getSearchHistory();
    let indexFind = history.findIndex(item => {
      return item == newHistoryItem;
    });
    if (indexFind >= 0 || history.length >= 10) {
      history.splice(indexFind, 1);
    }
    history.unshift(newHistoryItem);
    localStorage.setItem("consulateSearchHistory", JSON.stringify(history));
    if (this.isComponentMounted) {
      this.setState(
        () => ({
          searchHistory: history
        }),
        () => {}
      );
    }
  }

  fetchData(func) {
    if (!this.state.searchText) {
      if (func) func([], true);
      return;
    }
    this.searchTime = new Date().getTime();
    apiCall(
      "/app/agricultureKnowledgeSearch",
      "POST",
      {
        findword: this.state.searchText,
        page: this.page,
        pageSize: apiConfig.pageSize
      },
      data => {
        if (data.code == apiConfig.errorCode1000) {
          //this.$f7.dialog.alert(data.msg);
        } else {
          this.addSearchHistory(this.state.searchText);
          this.page++;
          if (func && data.content.length != 0) func(data.content);
          if (func && data.content.length == 0) func(data.content, true);
        }
      },
      err => {
        console.log(err);
        //this.$f7.dialog.alert(JSON.stringify(err));
      }
    );
  }

  renderData(data, isDataEnd) {
    if (data.length == 0 && !isDataEnd) {
      if (this.isComponentMounted) {
        this.setState(prev => ({
          consulateList: []
        }));
      }
    } else {
      let consulateList = data.map(item => {
        return {
          id: item.id,
          abstractor: "", //item.texts1,
          title: item.title.replace(
            new RegExp("\\" + this.state.searchText + "", "gi"),
            "<span style='color:#0000B6;margin-left:0;' >$&</span>"
          ),
          url: "/information/" + item.id + "/hotwirekb" //  consulateinfo
        };
      });

      if (this.isComponentMounted) {
        this.setState(prev => ({
          consulateList: prev.consulateList.concat(consulateList)
        }));
      }
    }
  }

  render() {
    return (
      <InfiniteScroll
        pageName="ConsulateSearch"
        fetchData={this.fetchData}
        renderData={this.renderData}
        onRef={this.onRef}
        onPageAfterIn={this.onPageAfterIn.bind(this)}>
        <div className="SearchbarContainer">
          <Searchbar
            className="Searchbar"
            placeholder="请输入要查询的关键词"
            customSearch={true}
            backdrop={false}
            clearButton={true}
            disableButton={false}
            init={true}
            ref={this.searchref}
          />
          <div onClick={this.goBack.bind(this)}>取消</div>
        </div>
        <div className="ConsulateItemContainer">
          {this.state.consulateList.length > 0 &&
            this.state.consulateList.map(item => (
              <Link className="ConsulateItem" href={item.url} key={item.id}>
                <div dangerouslySetInnerHTML={{ __html: item.title }} />
                {item.abstractor && (
                  <div>
                    <div
                      dangerouslySetInnerHTML={{ __html: item.abstractor }}
                    />
                  </div>
                )}
              </Link>
            ))}
          {this.state.consulateList.length == 0 && !!this.state.searchText && (
            <NoData />
          )}
          {this.state.consulateList.length == 0 &&
            !this.state.searchText &&
            this.state.searchHistory.length != 0 && (
              <div className="searchHistory">
                <div>搜索历史</div>
                <div>
                  {this.state.searchHistory.map((history, index) => (
                    <div
                      key={index}
                      onClick={this.reSearch.bind(this, history)}>
                      {history}
                    </div>
                  ))}
                </div>
              </div>
            )}
        </div>
      </InfiniteScroll>
    );
  }
}

function mapStateToProps(state) {
  const { userState } = state;
  return { userState };
}

ConsulateSearch.propTypes = {
  userState: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(ConsulateSearch);
