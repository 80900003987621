import React from "react";
import { Link, Swiper, SwiperSlide } from "framework7-react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "./CountryPanel.less";
import AutoImg from "../AutoImg/";

class CountryPanel extends React.Component {
  componentDidMount() {
    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;
    });
  }

  handleClick(url) {
    return e => {
      this.$f7.views.main.router.navigate(url);
    };
  }

  render() {
    let data = this.props.data;
    let isClassPop =
      this.props.externalClass &&
      (this.props.externalClass == "pop1" || this.props.externalClass == "pop2")
        ? true
        : false;
    return (
      <div className="country-section">
        <Link className="topDiv" href={this.props.location}>
          <div>{this.props.sectionTitle}</div>
          <span>
            {this.props.sectionMore ? this.props.sectionMore : "更多"}
          </span>
        </Link>

        {isClassPop && data.length >= 3 && (
          <div className={"cs-container " + this.props.externalClass}>
            <div>
              <Link key={data[0].id} href={data[0].url}>
                <AutoImg src={data[0].img} ratio={1} />
                <div>{data[0].title}</div>
              </Link>
            </div>
            <div>
              <Link key={data[1].id} href={data[1].url}>
                <AutoImg src={data[1].img} ratio={2.2} />
                <div>{data[1].title}</div>
              </Link>
              <Link key={data[2].id} href={data[2].url}>
                <AutoImg src={data[2].img} ratio={2.2} />
                <div>{data[2].title}</div>
              </Link>
            </div>
          </div>
        )}

        {!isClassPop && (
          <Swiper
            className="topSwiper"
            params={{
              speed: 500,
              slidesPerView: "auto",
              on: {
                click: function(e) {}
              }
            }}
          >
            {data.map(item => (
              <SwiperSlide
                className={
                  "showSlider " +
                  (this.props.externalClass ? this.props.externalClass : "")
                }
                key={item.id}
              >
                <div onClick={this.handleClick.call(this, item.url)}>
                  <AutoImg src={item.img} ratio={4 / 3} />
                  <div>{item.title}</div>
                  {item.location ? (
                    <div>
                      <span />
                      <span>{item.location}</span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { pageState } = state;
  return { pageState };
}

CountryPanel.propTypes = {
  sectionTitle: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  pageState: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(CountryPanel);
