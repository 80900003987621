import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "framework7-icons";
import { Page, Link, Button } from "framework7-react";
import VillageGroupPicker from "../components/VillageGroupPicker";
import "framework7-icons";
import { apiCall, apiConfig } from "../api";
import "../css/pageAuthenticationSelectgroup.less";
import { login } from "../actions/UserAction";

class AuthenticationSelectgroup extends React.Component {
  constructor(props) {
    super(props);

    //this.productId = this.$f7route.params.productId;

    this.selectedCallback = this.selectedCallback.bind(this);
    this.state = {
      userGroup: "",
      userGroupCode: "",
      initSelectedValue: []
    };
  }

  componentDidMount() {
    this.isComponentMounted = true;
    const that = this;
    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;
    });
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  selectedCallback(values, displayValues) {
    if (this.isComponentMounted) {
      this.setState({
        userGroup: displayValues[2],
        userGroupCode: values[2]
      });
    }
  }

  nextStep() {
    if (!this.state.userGroupCode) {
      this.$f7.toast
        .create({
          text: "请先选择所属村组",
          position: "center",
          closeTimeout: 2000,
          on: {
            close: () => {}
          }
        })
        .open();
      return;
    }
    if (!localStorage.getItem("userinfo")) {
      this.$f7.toast
        .create({
          text: "请先登录",
          position: "center",
          closeTimeout: 2000,
          on: {
            close: () => {
              this.$f7.views.main.router.navigate("/login", {
                props: {}
              });
            }
          }
        })
        .open();
      return;
    }
    let userinfo = localStorage.getItem("userinfo")
      ? JSON.parse(localStorage.getItem("userinfo"))
      : {};
    apiCall(
      "/sms/bindVillage",
      "POST",
      {
        phone: userinfo.uphone,
        userId: userinfo.uid,
        areaCode: this.state.userGroupCode,
        sign: userinfo.sign
      },
      data => {
        if (data.code == apiConfig.errorCode1000) {
          this.$f7.dialog.alert(data.msg);
        } else {
          if (localStorage.getItem("userinfo")) {
            this.props.dispatch(
              login(JSON.parse(localStorage.getItem("userinfo")))
            );
          }
          this.$f7.toast
            .create({
              text: "绑定成功",
              position: "center",
              closeTimeout: 2000,
              on: {
                close: () => {
                  this.$f7.views.main.router.navigate("/authenticationresult", {
                    props: {}
                  });
                }
              }
            })
            .open();
        }
      },
      (xhr, status) => {
        //console.log(xhr, status);
      }
    );
  }

  render() {
    return (
      <Page name="AuthenticationSelectgroup">
        <div className="MyItem">
          <div id="au-picker-user-group">
            <div className="iconGroup">所属村组</div>
            <div className={this.state.userGroup ? "selected" : ""}>
              <div>
                {this.state.userGroup ? this.state.userGroup : "选择村组"}
              </div>
            </div>
          </div>
        </div>

        <div className="AsgComplete">
          <Button onClick={this.nextStep.bind(this)}>完成</Button>
        </div>

        <div className="selectgroupInfo">
          注：该功能仅用于农业从业者查询本人相关涉农信息以及郊区村民查看本村相关信息，非以上人员无需进行身份认证。
        </div>

        <VillageGroupPicker
          parentElement="#au-picker-user-group"
          selectedCallback={this.selectedCallback}
          initSelectedValue={this.state.initSelectedValue}
        />
      </Page>
    );
  }
}

function mapStateToProps(state) {
  const { userState, pageState } = state;
  return { userState, pageState };
}

AuthenticationSelectgroup.propTypes = {
  userState: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(AuthenticationSelectgroup);
