import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "framework7-icons";
import { Page, Toolbar, Tabs, Tab, Link } from "framework7-react";
import "../css/pageBillSubsidy.less";

class BillSubsidy extends React.Component {
  constructor(props) {
    super(props);

    this.userId = this.$f7route.params.userId;
    this.computeWidth = this.computeWidth.bind(this);

    this.state = {
      datatab1: {
        name: "李建国",
        code: "362*********2545",
        value: 8092.0
      },
      datatab2: {
        name: "李建国",
        code: "362*********2545",
        value: 7089.0
      },
      listtab1: [
        {
          id: "201801",
          name: "水稻生产补贴资金",
          list: [
            { name: "种植面积（ 亩 ）", value: 382.0 },
            { name: "补贴金额（ 元 ）", value: 1002.0 }
          ]
        },
        {
          id: "201802",
          name: "小麦生产补贴资金",
          list: [
            { name: "种植面积（ 亩 ）", value: 382.0 },
            { name: "补贴金额（ 元 ）", value: 1000.0 }
          ]
        }
      ],
      listtab2: [
        {
          id: "201701",
          name: "水稻生产补贴资金",
          list: [
            { name: "种植面积（ 亩 ）", value: 382.0 },
            { name: "补贴金额（ 元 ）", value: 600.0 }
          ]
        },
        {
          id: "201702",
          name: "小麦生产补贴资金",
          list: [
            { name: "种植面积（ 亩 ）", value: 382.0 },
            { name: "补贴金额（ 元 ）", value: 500.0 }
          ]
        }
      ]
    };
  }

  computeWidth($, prelist) {
    prelist.map(type => {
      const totalWidth = window.document.documentElement.clientWidth;
      let leftWidth = 0,
        rightWidth = 0;
      let maxValue = 0;
      $(".bill-asset-body-" + type.id + " > div").forEach(item => {
        let left = $(item)
          .children()
          .eq(0)
          .outerWidth();
        let right = $(item)
          .children()
          .eq(1)
          .children()
          .eq(0)
          .children()
          .eq(1)
          .outerWidth();

        let value = parseFloat($(item).attr("data-value"));
        if (leftWidth < left) {
          leftWidth = left;
        }
        if (rightWidth < right) {
          rightWidth = right;
        }
        if (maxValue < value) {
          maxValue = value;
        }
      });
      const fullWidth = totalWidth - leftWidth - rightWidth;
      type.list.map(item => {
        item.width = Math.floor((item.value / maxValue) * fullWidth);
      });
    });
  }

  componentDidMount() {
    this.isComponentMounted = true;
    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      this.computeWidth($, this.state.listtab1);
      this.computeWidth($, this.state.listtab2);

      if (this.isComponentMounted) {
        this.setState(prev => ({
          listtab1: prev.listtab1,
          listtab2: prev.listtab2
        }));
      }
    });
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  render() {
    const self = this;
    return (
      <Page name="BillSubsidy" className="BillSubsidy">
        <div className="BillInAndOutToolbar">
          <Toolbar tabbar>
            <Link tabLink="#bill-in-out-1" tabLinkActive>
              2018年
            </Link>
            <Link tabLink="#bill-in-out-2">2017年</Link>
          </Toolbar>
          <Tabs animated>
            <Tab id="bill-in-out-1" className="page-content" tabActive>
              <div className="bill-subsidy-man">
                <div>姓名/名称：{this.state.datatab1.name}</div>
                <div>身份证号码/机构代码：{this.state.datatab1.code}</div>
                <div>总计（元）：{this.state.datatab1.value}</div>
              </div>
              <div className="bill-asset-container">
                {this.state.listtab1.map(type => {
                  return (
                    <div key={type.id}>
                      <div className="bill-asset-title">
                        <div>{type.name}</div>
                      </div>
                      <div
                        className={"bill-asset-body bill-asset-body-" + type.id}
                      >
                        {type.list.map((item, index) => {
                          return (
                            <div key={item.name} data-value={item.value}>
                              <div>
                                <div>{item.name}</div>
                                {item.title ? <div>( {item.title} )</div> : ""}
                              </div>
                              <div>
                                <div>
                                  <div
                                    className="bill-asset-bar"
                                    style={{ width: item.width }}
                                  />
                                  <div>{item.value.toFixed(2)}</div>
                                </div>
                                {item.list && item.list.length > 0 ? (
                                  <div>
                                    {item.list.map(i => (
                                      <div key={i.name}>
                                        {i.name}: {i.value.toFixed(2)}
                                      </div>
                                    ))}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </Tab>
            <Tab id="bill-in-out-2" className="page-content">
              <div className="bill-subsidy-man">
                <div>姓名/名称：{this.state.datatab2.name}</div>
                <div>身份证号码/机构代码：{this.state.datatab2.code}</div>
                <div>总计（元）：{this.state.datatab2.value}</div>
              </div>
              <div className="bill-asset-container">
                {this.state.listtab2.map(type => {
                  return (
                    <div key={type.id}>
                      <div className="bill-asset-title">
                        <div>{type.name}</div>
                      </div>
                      <div
                        className={"bill-asset-body bill-asset-body-" + type.id}
                      >
                        {type.list.map((item, index) => {
                          return (
                            <div key={item.name} data-value={item.value}>
                              <div>
                                <div>{item.name}</div>
                                {item.title ? <div>( {item.title} )</div> : ""}
                              </div>
                              <div>
                                <div>
                                  <div
                                    className="bill-asset-bar"
                                    style={{ width: item.width }}
                                  />
                                  <div>{item.value.toFixed(2)}</div>
                                </div>
                                {item.list && item.list.length > 0 ? (
                                  <div>
                                    {item.list.map(i => (
                                      <div key={i.name}>
                                        {i.name}: {i.value.toFixed(2)}
                                      </div>
                                    ))}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </Tab>
          </Tabs>
        </div>
      </Page>
    );
  }
}

function mapStateToProps(state) {
  const { userState } = state;
  return { userState };
}

BillSubsidy.propTypes = {
  userState: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(BillSubsidy);
