import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "framework7-icons";
import { Page, Popup } from "framework7-react";
import { apiCall, apiConfig, pageOperation, callOrigin } from "../api";
import "../css/pageInformation.less";
import NoData from "../components/NoData";

class Information extends React.Component {
  constructor(props) {
    super(props);
    this.newsId = this.$f7route.params.informationId;
    this.isHotwirekb = this.$f7route.params.isHotwirekb;
    this.refContent = React.createRef();

    this.popupRef = React.createRef();

    this.isShareOpened = false;
    this.state = {
      isLiked: false,
      isShowShareInformation: true,
      isFromShare: false,
      shareLink: "",
      shareTitle: "",
      shareContent: "",
      shareImage: ""
    };
  }

  componentDidMount() {
    this.isComponentMounted = true;
    this.isShareOpened = false;
    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      app.on("popupOpened", () => {
        this.isShareOpened = true;
      });
      app.on("popupClosed", () => {
        this.isShareOpened = false;
      });

      if (this.isHotwirekb) {
        apiCall(
          "/hotWireKb/getHotWireKbById",
          "GET",
          {
            id: this.newsId
          },
          data => {
            if (data.code == apiConfig.errorCode1000) {
              //this.$f7.dialog.alert(data.msg);
            } else {
              if (this.isComponentMounted) {
                let content = data.content;
                let updateState = {
                  title: content.title,
                  createDate: content.createDate.split(" ")[0],
                  //enterpriseName: content.origin,
                  content: content.texts1,
                  liked: content.likedAPP
                };

                updateState.shareLink =
                  location.origin + location.pathname + "?f=share";
                updateState.shareTitle = updateState.title;
                updateState.shareContent = "";
                updateState.shareImage = "";

                // 暂时隐藏
                // // 打开了分享链接
                // if (location.search) {
                //   updateState.isFromShare = true;
                //   updateState.isShowShareInformation = false;
                // }
                this.setState(() => updateState);
              }
            }
          },
          err => {
            console.log(err);
            //this.$f7.dialog.alert(JSON.stringify(err));
          }
        );
      } else {
        apiCall(
          "/app/getCmsNewsById",
          "POST",
          {
            cmsId: this.newsId
          },
          data => {
            // console.log(data);
            pageOperation.setPV(data.content.detail.id);
            if (this.isComponentMounted) {
              data.content.detail.isLiked = pageOperation.getLike(
                data.content.detail.id
              );
              let updateState = data.content.detail;
              updateState.liked = data.content.detail.likedAPP;

              updateState.shareLink =
                location.origin + location.pathname + "?f=share";
              updateState.shareTitle = updateState.title;
              updateState.shareContent = "";
              updateState.shareImage = "";

              // 暂时隐藏
              // // 打开了分享链接
              // if (location.search) {
              //   updateState.isFromShare = true;
              //   updateState.isShowShareInformation = false;
              // }
              this.setState(() => updateState, () => {});
            }
          },
          (xhr, status) => {
            //console.log(xhr, status);
            this.$f7.dialog.alert(xhr);
          }
        );
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.pageState.shareState != this.props.pageState.shareState) {
      if (this.isShareOpened) {
        this.popupRef.current.close();
      } else {
        this.popupRef.current.open();
      }
    }
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  doLike() {
    if (this.state.isLiked) {
      // 取消点赞暂时不做
    } else {
      if (this.isComponentMounted) {
        pageOperation.setLike(this.state.id).then(() => {
          this.setState({
            likes: ++this.state.liked,
            isLiked: true
          });
        });
      }
    }
  }

  shareAction(channel, e) {
    // this.$f7.dialog.alert("即将上线，敬请期待", () => {
    //   this.popupRef.current.close();
    // });
    callOrigin(
      () => {
        window.webkit.messageHandlers.appShare.postMessage({
          content: this.state.shareTitle,
          title: this.state.shareTitle,
          link: this.state.shareLink,
          channel: channel
        });
      },
      () => {
        android.appShare(
          this.state.shareTitle,
          this.state.shareTitle,
          this.state.shareLink,
          channel
        );
      }
    );
  }
  shareCloseAction() {
    this.popupRef.current.close();
  }

  showShareInformation() {
    this.setState(() => ({
      isShowShareInformation: true,
      isFromShare: false
    }));
  }

  render() {
    return (
      <Page
        name={"Information"}
        className={this.isHotwirekb ? "noBottom" : ""}
        pageContent={false}>
        <div
          className={
            "page-content " +
            (this.state.isShowShareInformation ? "" : "noscroll")
          }>
          <div className="InformationContent">
            <div>{this.state.title}</div>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  (!!this.state.createDate
                    ? "日期：" + this.state.createDate
                    : "") +
                  (!!this.state.enterpriseName
                    ? "&nbsp;&nbsp;&nbsp;&nbsp;作者：" +
                      this.state.enterpriseName
                    : "")
              }}
            />

            <div
              dangerouslySetInnerHTML={{
                __html: this.state.content
                  ? this.state.content
                  : "内容加载中，请稍后..."
              }}
            />
          </div>
        </div>
        <div
          className={
            "information-view " +
            (this.isHotwirekb || this.state.isFromShare ? "hide" : "")
          }>
          <div>{/**
      <span /> {this.state.pv} 个预览
      */}</div>
          <div
            onClick={this.doLike.bind(this)}
            className={this.state.isLiked ? "focused" : ""}>
            <span /> {this.state.liked}
          </div>
        </div>

        <div className={"share-link " + (this.state.isFromShare ? "" : "hide")}>
          <div onClick={this.showShareInformation.bind(this)} />
          <div>打开APP阅读全文</div>
        </div>

        <Popup className="share-popup" opened={false} ref={this.popupRef}>
          <div>
            <div onClick={this.shareAction.bind(this, "QQ")}>
              <div />
              <div>QQ好友</div>
            </div>
            <div onClick={this.shareAction.bind(this, "QZone")}>
              <div />
              <div>QQ空间</div>
            </div>
            <div onClick={this.shareAction.bind(this, "Wechat")}>
              <div />
              <div>微信好友</div>
            </div>
            <div onClick={this.shareAction.bind(this, "WechatMoments")}>
              <div />
              <div>朋友圈</div>
            </div>
          </div>
          <div onClick={this.shareCloseAction.bind(this)}>取消</div>
        </Popup>
      </Page>
    );
  }
}

function mapStateToProps(state) {
  const { userState, pageState } = state;
  return { userState, pageState };
}

Information.propTypes = {
  userState: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(Information);
