import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "framework7-icons";
import { Page, Swiper, SwiperSlide, Link } from "framework7-react";
import InformationPanel from "../components/InformationPanel/";
import Carousel from "../components/Carousel/";
import InfiniteScroll from "../components/InfiniteScroll/";
import NoData from "../components/NoData";
import { apiCall, apiConfig } from "../api";
import "../css/pageCommunity.less";
import CountryPanel from "../components/CountryPanel";

class Community extends React.Component {
  constructor(props) {
    super(props);
    this.onRef = this.onRef.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.renderData = this.renderData.bind(this);
    this.state = {
      pageIndex: 1,
      focusCategoryId: 0,
      focusCategoryName: "全部",
      categories: [
        //{ categoryId: 0, categoryName: "全部" },
        { categoryId: 1, categoryName: "标准社" },
        { categoryId: 2, categoryName: "专业社" }
      ],
      products: [
        // {
        //   id: 1,
        //   title: "马陆葡萄",
        //   description: "第一批通过上海市优质农产品认证的水果",
        //   img: ImgSample
        // },
      ],
      banners: [],
      news: [],
      list: []
    };
  }

  componentDidMount() {
    const _this = this;
    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      this.isComponentMounted = true;

      //this.fetchData(this.renderData);

      // const swiper = app.swiper.get(".CommunityNavbarSwiper");
      // swiper.on("tap", function() {
      //   swiper.slides.map(function(index) {
      //     $(swiper.slides[index]).removeClass("swiper-active");
      //   });
      //   $(swiper.slides[swiper.clickedIndex]).addClass("swiper-active");
      //   //swiper.slideTo(swiper.clickedIndex, 500);

      //   let state = {
      //     pageIndex: 1,
      //     focusCategoryId:
      //       _this.state.categories[swiper.clickedIndex].categoryId,
      //     focusCategoryName:
      //       _this.state.categories[swiper.clickedIndex].categoryName
      //   };
      //   _this.setState(
      //     prev => state,
      //     () => {
      //       _this.InfiniteScroll.reInfinite();
      //     }
      //   );
      // });
    });
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  onRef(ref) {
    this.InfiniteScroll = ref;
  }

  fetchData(func) {
    if (this.state.categories.length == 0) return;

    apiCall(
      "/app/getYinongList",
      "POST",
      {
        page: 1,
        pageSize: 6, //apiConfig.pageSize
        activityPage: this.state.pageIndex,
        activitySize: apiConfig.pageSize,
        type: this.state.focusCategoryId
          ? String(this.state.focusCategoryId)
          : "",
        longitude: this.props.pageState.longitude
          ? String(this.props.pageState.longitude)
          : "",
        latitude: this.props.pageState.latitude
          ? String(this.props.pageState.latitude)
          : ""
      },
      data => {
        //console.log("fetchData", this.state.pageIndex, data);

        if (this.isComponentMounted) {
          this.setState(prev => ({
            pageIndex: prev.pageIndex + 1
          }));
        }

        if (this.state.banners.length == 0 && data.content.bannerList) {
          let banners = [];
          data.content.bannerList.forEach(item => {
            if (item.previewImage) {
              banners.push({
                img: item.previewImage,
                url: "/information/" + item.id,
                title: item.title
              });
            }
          });
          if (this.isComponentMounted) {
            this.setState({
              banners
            });
          }
        }

        if (this.state.list.length == 0 && data.content.list) {
          if (this.isComponentMounted) {
            this.setState({
              list: data.content.list.map(item => {
                return {
                  id: item.id,
                  title: item.ynName,
                  img: item.images,
                  url: "/communityinfo/" + item.id,
                  location: item.address ? item.address : "无数据"
                };
              })
            });
          }
        }

        if (func && data.content.activityList.length != 0)
          func(data.content.activityList);
        if (func && data.content.activityList.length == 0)
          func(data.content.activityList, true);
      },
      function(xhr, status) {
        //console.log(xhr, status);
      }
    );
  }

  renderData(data, isDataEnd) {
    //console.log(data);
    if (data.length == 0 && !isDataEnd) {
      if (this.isComponentMounted) {
        this.setState(prev => ({
          news: []
        }));
      }
    } else {
      let news = data.map(item => {
        return {
          id: item.id,
          title: item.title,
          previewImage: item.previewImage,
          showCreateDate: item.showCreateDate ? item.showCreateDate : "",
          infoFrom: item.enterpriseName ? item.enterpriseName : ""
        };
      });
      if (this.isComponentMounted) {
        this.setState(prev => ({
          news: prev.news.concat(news)
        }));
      }
    }
  }

  gotoCommunityInfo(communityId) {
    this.$f7.views.main.router.navigate("/communityinfo/", {
      props: {
        communityId
      }
    });
  }

  render() {
    const self = this;
    return (
      <InfiniteScroll
        pageName="Community"
        fetchData={this.fetchData}
        renderData={this.renderData}
        onRef={this.onRef}>
        {this.state.banners.length > 0 && (
          <Carousel data={this.state.banners} />
        )}

        <CountryPanel
          sectionTitle="益农社介绍"
          location="/communitychoose"
          sectionMore="查看全部"
          externalClass="comintro"
          data={this.state.list}
        />

        <div className="TitleGap">
          <div>最新活动</div>
        </div>

        {this.state.news.length > 0 &&
          this.state.news.map(news => (
            <InformationPanel
              key={news.id}
              title={news.title}
              img={news.previewImage}
              date={news.showCreateDate}
              source={news.infoFrom ? news.infoFrom : ""}
              link={"/information/" + news.id}
              //tags={["信息", "培训", "学习"]}
              //views={6782}
            />
          ))}
        {this.state.news.length == 0 && <NoData />}
      </InfiniteScroll>
    );
  }
}

function mapStateToProps(state) {
  const { userState, pageState } = state;
  return { userState, pageState };
}

Community.propTypes = {
  userState: PropTypes.object.isRequired,
  pageState: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(Community);
