import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "framework7-icons";
import { Page, Toolbar, Tabs, Tab, Link } from "framework7-react";
import "../css/pageMessage.less";
import { apiCall, apiConfig } from "../api";
import InfiniteScroll from "../components/InfiniteScroll";
import NoData from "../components/NoData";

class Message extends React.Component {
  constructor(props) {
    super(props);

    this.userId = this.$f7route.params.userId;

    this.fetchData = this.fetchData.bind(this);
    this.renderData = this.renderData.bind(this);
    this.onRef = this.onRef.bind(this);

    this.page = 1;
    this.state = {
      tabIndex: 1,
      columnId: apiConfig.affairNoticeColumnId,
      listtab1: [
        // {
        //   id: "201801",
        //   title: "2019年上海市黄浦江和内陆水域禁渔期公告",
        //   link: "/information/344",
        //   date: "2019-02-11 15:17",
        //   source: "上海市渔政监督管理处"
        // },
        // {
        //   id: "201802",
        //   title: "2019年上海市黄浦江和内陆水域禁渔期公告",
        //   link: "/information/344",
        //   date: "2019-02-11 15:17",
        //   source: "上海市渔政监督管理处"
        // }
      ],
      listtab2: [
        // {
        //   id: "201803",
        //   title: "2019年上海市黄浦江和内陆水域禁渔期公告",
        //   link: "/information/344",
        //   date: "2019-02-11 15:17",
        //   source: "上海市渔政监督管理处"
        // },
      ]
    };
  }

  componentDidMount() {
    this.isComponentMounted = true;
    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.userState.userinfo.ugroupcode !=
      this.props.userState.userinfo.ugroupcode
    ) {
      this.page = 1;
      this.InfiniteScroll.reInfinite();
    }
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  tabClick(index) {
    this.setState(
      () => ({
        tabIndex: index,
        columnId:
          index == 1
            ? apiConfig.affairNoticeColumnId
            : apiConfig.affairWarnColumnId
      }),
      () => {
        this.page = 1;
        this.InfiniteScroll.reInfinite();
      }
    );
  }

  onRef(ref) {
    this.InfiniteScroll = ref;
  }

  fetchData(func) {
    if (!this.props.userState.userinfo.ugroupcode) {
      return;
    }
    apiCall(
      "/app/getPushCmsNews",
      "POST",
      {
        //channelId: apiConfig.channelId,
        columnId: this.state.columnId,
        village: this.props.userState.userinfo.ugroupcode, // "CA0D27F4171D3534B2132FBE4593F452"
        page: this.page,
        size: apiConfig.pageSize
      },
      data => {
        if (data.code == apiConfig.errorCode1000) {
          //this.$f7.dialog.alert(data.msg);
        } else {
          this.page++;
          if (func && data.data.length != 0) func(data.data);
          if (func && data.data.length == 0) func(data.data, true);
        }
      },
      err => {
        console.log(err);
        //this.$f7.dialog.alert(JSON.stringify(err));
      }
    );
  }

  renderData(data, isDataEnd) {
    if (data.length == 0 && !isDataEnd) {
      if (this.isComponentMounted) {
        this.setState(prev => ({
          listtab1: [],
          listtab2: []
        }));
      }
    } else {
      let listtab = data.map(item => {
        return {
          id: item.id,
          title: item.title,
          link: "/information/" + item.id,
          date: item.showAuditDate,
          source: item.enterpriseName
        };
      });

      if (this.isComponentMounted) {
        this.setState(prev => ({
          ["listtab" + this.state.tabIndex]: prev[
            "listtab" + this.state.tabIndex
          ].concat(listtab)
        }));
      }
    }
  }

  render() {
    return (
      <InfiniteScroll
        pageName="Message"
        fetchData={this.fetchData}
        renderData={this.renderData}
        onRef={this.onRef}>
        {/** 
        <div className="Toolbar">
          <Toolbar tabbar>
            <Link tabLink="#Toolbar-1" tabLinkActive>
              村务通知
            </Link>
            <Link tabLink="#Toolbar-2">农业预警</Link>
          </Toolbar>
          <Tabs animated>
            <Tab id="Toolbar-1" className="page-content" tabActive>
              {this.state.listtab1.length > 0 &&
                this.state.listtab1.map((item, index) => (
                  <Link className="MessageItem" key={item.id}>
                    <div>
                      <span>{item.date}</span>
                      <span>来源：{item.source}</span>
                    </div>
                    <div>
                      <div>{item.title}</div>
                    </div>
                  </Link>
                ))}
              {this.state.listtab1.length == 0 && <NoData />}
            </Tab>
            <Tab id="Toolbar-2" className="page-content">
              {this.state.listtab2.length > 0 &&
                this.state.listtab2.map((item, index) => (
                  <Link className="MessageItem" key={item.id}>
                    <div>
                      <span>{item.date}</span>
                      <span>来源：{item.source}</span>
                    </div>
                    <div>
                      <div>{item.title}</div>
                    </div>
                  </Link>
                ))}
              {this.state.listtab2.length == 0 && <NoData />}
            </Tab>
          </Tabs>
        </div>
        */}
        <div className="Tab">
          <div
            onClick={this.tabClick.bind(this, 1)}
            className={this.state.tabIndex == 1 ? "focus" : ""}>
            村务（党务）动态
          </div>
          <div
            onClick={this.tabClick.bind(this, 2)}
            className={this.state.tabIndex == 2 ? "focus" : ""}>
            农事预警
          </div>
        </div>
        <div
          className={
            this.state.tabIndex == 1 ? "TabContent " : "TabContent hide"
          }>
          {this.state.listtab1.length > 0 &&
            this.state.listtab1.map((item, index) => (
              <Link
                className="MessageItem"
                key={item.id}
                href={"/information/" + item.id}>
                <div>
                  <span>{item.date}</span>
                  <span>{item.source ? "来源：" + item.source : ""}</span>
                </div>
                <div>
                  <div>{item.title}</div>
                </div>
              </Link>
            ))}
          {this.state.listtab1.length == 0 && <NoData />}
        </div>
        <div
          className={
            this.state.tabIndex == 2 ? "TabContent " : "TabContent hide"
          }>
          {this.state.listtab2.length > 0 &&
            this.state.listtab2.map((item, index) => (
              <Link
                className="MessageItem"
                key={item.id}
                href={"/information/" + item.id}>
                <div>
                  <span>{item.date}</span>
                  <span>{item.source ? "来源：" + item.source : ""}</span>
                </div>
                <div>
                  <div>{item.title}</div>
                </div>
              </Link>
            ))}
          {this.state.listtab2.length == 0 && <NoData />}
        </div>
      </InfiniteScroll>
    );
  }
}

function mapStateToProps(state) {
  const { userState } = state;
  return { userState };
}

Message.propTypes = {
  userState: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(Message);
