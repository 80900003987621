import React from "react";
import { Link } from "framework7-react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "./CategoryPicker.less";

/**
 * 
 * [
        [
          { parentId: 0, id: 1, name: "浦东新区" },
        ],
        [
          { parentId: 1, id: 21, name: "外冈镇" },
        ],
        [
          { parentId: 21, id: 31, name: "仙龙村" },
        ]
      ]
 */
class CategoryPicker extends React.Component {
  constructor(props) {
    super(props);
    this.updateCols = this.updateCols.bind(this);
    this.state = {
      parentElement: this.props.parentElement,
      nodes: this.props.nodes,
      initSelectedValue: this.props.initSelectedValue
        ? this.props.initSelectedValue
        : [],
      initShowSelectedValue: false,
      picker: null,
      pickerColInitTimes: 0
    };
  }

  updateCols() {
    const that = this;
    return new Promise(resolve => {
      let cols = [];
      that.state.nodes.forEach((item, itemIndex) => {
        let itemId = [],
          itemName = [];
        item.forEach(function(node) {
          let parentId;
          if (itemIndex > 0) {
            parentId = cols[itemIndex - 1].values[0];
            if (that.state.initSelectedValue.length) {
              parentId = that.state.initSelectedValue[itemIndex - 1];
            }
          }
          if (itemIndex == 0 || node.parentId == parentId) {
            itemId.push(node.id);
            itemName.push(node.name);
          }
        });
        cols.push({
          values: itemId,
          displayValues: itemName,
          onChange: (index => {
            return function(picker, value, displayValue) {
              if (!that.isComponentMounted) {
                return;
              }
              that.setState(
                prev => ({
                  pickerColInitTimes: ++prev.pickerColInitTimes
                }),
                () => {
                  if (
                    that.state.pickerColInitTimes <= that.state.nodes.length
                  ) {
                    return;
                  }
                  let parentId = value;
                  for (let i = index + 1; i < that.state.nodes.length; i++) {
                    let changedValues = [],
                      changedDisplayValues = [];
                    for (let j = 0; j < that.state.nodes[i].length; j++) {
                      if (that.state.nodes[i][j].parentId == parentId) {
                        changedValues.push(that.state.nodes[i][j].id);
                        changedDisplayValues.push(that.state.nodes[i][j].name);
                      }
                    }
                    //console.log(changedValues, changedDisplayValues);
                    if (!changedValues.length) {
                      that.$f7.dialog.alert(
                        "该目录下没有子目录，请联系管理人员"
                      );
                      return false;
                    } else {
                      parentId = changedValues[0];
                      picker.cols[i].replaceValues(
                        changedValues,
                        changedDisplayValues
                      );
                    }
                  }
                }
              );
            };
          })(cols.length)
        });
      });
      resolve(cols);
    });
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  componentDidMount() {
    const that = this;

    this.isComponentMounted = true;

    try {
      that.updateCols().then(function(cols) {
        that.state.picker = that.$f7.picker.create({
          inputEl: that.props.parentElement,
          routableModals: false,
          url: "",
          rotateEffect: true,
          openIn: "sheet",
          cssClass: "picker-custom",
          renderToolbar: function() {
            return (
              '<div class="toolbar toolbar-custom toolbar-picker-user-group">' +
              '<div class="toolbar-inner">' +
              '<div class="left">' +
              '<a href="#" class="link sheet-close popover-close">取消</a>' +
              "</div>" +
              '<div class="right">' +
              '<a href="#" class="link sheet-close popover-close user-group-sure">完成</a>' +
              "</div>" +
              "</div>" +
              "</div>"
            );
          },
          cols: cols,
          on: {
            open: function(picker) {
              that.$$(".popup-backdrop").addClass("show");

              if (
                !that.state.initShowSelectedValue &&
                that.state.initSelectedValue.length
              ) {
                picker.cols[0].setValue(that.state.initSelectedValue[0]);
                that.state.initShowSelectedValue = true;
              }

              picker.$el.find(".user-group-sure").on("click", function() {
                that.props.selectedCallback(picker.value, picker.displayValue);
              });
            },
            close: function(picker) {
              that.$$(".popup-backdrop").removeClass("show");
            },
            change: function(picker, value, displayValue) {}
          }
        });
      });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    return null;
  }
}

function mapStateToProps(state) {
  const { userState, pageState } = state;
  return { userState, pageState };
}

CategoryPicker.propTypes = {
  nodes: PropTypes.array.isRequired,
  parentElement: PropTypes.string.isRequired,
  selectedCallback: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(CategoryPicker);
