import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "framework7-icons";
import { Page } from "framework7-react";

class Infos extends React.Component {
  componentDidMount() {
    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;
    });
  }

  render() {
    return <Page name="Infos">益农信息</Page>;
  }
}

function mapStateToProps(state) {
  const { userState } = state;
  return { userState };
}

Infos.propTypes = {
  userState: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(Infos);
