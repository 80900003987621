import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "framework7-icons";
import { Page } from "framework7-react";
import "../css/pageAuthenticationThird.less";

class AuthenticationThird extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      url: this.props.userState.userinfo.uphone
        ? "http://yn12316.shac.cn/yn_gateway/oauth/realName?mobile=" +
          this.props.userState.userinfo.uphone +
          "&channel=app"
        : ""
    };
  }

  componentDidMount() {
    this.isComponentMounted = true;
    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      if (this.state.url) {
        location.href = this.state.url;
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.userState.userinfo.uphone !=
      this.props.userState.userinfo.uphone
    ) {
      if (this.isComponentMounted) {
        this.setState(
          {
            url:
              "http://yn12316.shac.cn/yn_gateway/oauth/realName?mobile=" +
              this.props.userState.userinfo.uphone +
              "&channel=app"
          },
          () => {
            if (this.state.url) {
              location.href = this.state.url;
            }
          }
        );
      }
    }
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  onLoad() {
    this.$f7.dialog.alert(this.state.url);
  }

  render() {
    {
      /**
      <Page name="AuthenticationThird">
        {!!this.state.url && <iframe src={this.state.url} />}
      </Page>
      */
    }
    return <Page name="AuthenticationThird" />;
  }
}

function mapStateToProps(state) {
  const { userState } = state;
  return { userState };
}

AuthenticationThird.propTypes = {
  userState: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(AuthenticationThird);
