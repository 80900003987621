import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "framework7-icons";
import {
  Page,
  Link,
  Toolbar,
  Tabs,
  Tab,
  Searchbar,
  Input,
  Button
} from "framework7-react";
import "../css/pageConsulate.less";
import { loginPath } from "../path";
import { detectPhone, localStorageExpiration } from "../../public/js/utils";
import { apiCall, apiConfig, callOrigin } from "../api";
import InfiniteScroll from "../components/InfiniteScroll/";
import NoData from "../components/NoData";
import Img12306 from "../images/im_consult_12316.png";
import ImgConsult_1 from "../images/consult/im_consult_1.png";
import ImgConsult_2 from "../images/consult/im_consult_2.png";
import ImgConsult_3 from "../images/consult/im_consult_3.png";
import ImgConsult_4 from "../images/consult/im_consult_4.png";
import ImgConsult_6 from "../images/consult/im_consult_6.png";
import ImgConsult_7 from "../images/consult/im_consult_7.png";
import ImgConsult_8 from "../images/consult/im_consult_8.png";
import ImgConsult_9 from "../images/consult/im_consult_9.png";
import ImgConsult_10 from "../images/consult/im_consult_10.png";

class Consulate extends React.Component {
  constructor(props) {
    super(props);
    this.fetchData = this.fetchData.bind(this);
    this.renderData = this.renderData.bind(this);
    this.onRef = this.onRef.bind(this);
    this.selectedCallback = this.selectedCallback.bind(this);
    this.tabClick = this.tabClick.bind(this);
    this.changeSearchText = this.changeSearchText.bind(this);

    this.refSearchbar = React.createRef();

    this.page = 1;
    this.state = {
      tabIndex: 1,
      searchText: "",
      consulateList: [],
      showSubmitConsult: true,
      questionNodes: [
        [
          { parentId: 0, id: 1, name: "畜牧业" },
          { parentId: 0, id: 2, name: "种植业" }
        ],
        [
          { parentId: 1, id: 21, name: "奶牛" },
          { parentId: 1, id: 22, name: "山鸡" },
          { parentId: 2, id: 27, name: "水果" },
          { parentId: 2, id: 27, name: "蔬菜" },
          { parentId: 2, id: 27, name: "花卉" }
        ]
      ]
    };
  }

  componentDidMount() {
    this.isComponentMounted = true;
    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      this.fetchData(this.renderData);
    });
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  selectedCallback(values, displayValues) {
    //console.log(values, displayValues);
  }

  tabClick(index) {
    let showSubmitConsult = true;
    if (index != 1) {
      showSubmitConsult = false;
    }
    if (this.isComponentMounted) {
      this.setState({
        showSubmitConsult: showSubmitConsult,
        tabIndex: index
      });
    }
  }

  goto(url, e) {
    e && e.preventDefault();
    e && e.stopPropagation();
    url = loginPath(this.props.userState.userinfo, url);
    this.$f7.views.main.router.navigate(url, {
      props: {}
    });
  }

  callPhone() {
    if (detectPhone() == "android") {
      callOrigin(
        () => {},
        phone => {
          android.callPhone(phone);
        },
        ["02112316"]
      );
    } else {
      location.href = "tel:02112316";
    }
  }

  changeSearchText(e) {
    let searchText = e ? e.target.value : "";
    this.page = 1;
    if (!this.isComponentMounted) {
      return;
    }
    this.setState(
      () => ({
        searchText: searchText,
        consulateList: []
      }),
      () => {
        this.InfiniteScroll.reInfinite();
      }
    );
  }

  onRef(ref) {
    this.InfiniteScroll = ref;
  }

  fetchData(func) {
    //if (this.state.categories.length == 0) return;

    apiCall(
      "/reply/getQuestionListAll",
      "GET",
      {
        page: this.page,
        limit: apiConfig.pageSize,
        title: this.state.searchText
      },
      data => {
        if (data.code == apiConfig.errorCode1000) {
          //this.$f7.dialog.alert(data.msg);
        } else {
          this.page++;

          if (func && data.content.list.length != 0) func(data.content.list);
          if (func && data.content.list.length == 0)
            func(data.content.list, true);
        }
      },
      err => {
        //console.log(err);
        //this.$f7.dialog.alert(JSON.stringify(err));
      }
    );
  }

  renderData(data, isDataEnd) {
    if (data.length == 0 && !isDataEnd) {
      if (this.isComponentMounted) {
        this.setState(prev => ({
          consulateList: []
        }));
      }
    } else {
      let consulateList = data.map(item => {
        return {
          id: item.QID,
          reply: item.reply,
          replyUser: item.replyUser,
          content: item.content
        };
      });
      if (this.isComponentMounted) {
        this.setState(prev => ({
          consulateList: prev.consulateList.concat(consulateList)
        }));
      }
    }
  }

  render() {
    return (
      // <InfiniteScroll
      //   pageName="Consulate"
      //   fetchData={this.fetchData}
      //   renderData={this.renderData}
      //   onRef={this.onRef}
      // >
      <Page name="Consulate">
        {/** 
        <div className="ConsulateToolbar">
          <Toolbar tabbar>
            <Link
              tabLink="#consulate1"
              tabLinkActive
              onClick={this.tabClick(1)}
            >
              12316问答
            </Link>
            <Link tabLink="#consulate2" onClick={this.tabClick(2)}>
              12316电话服务
            </Link>
            <Link tabLink="#consulate3" onClick={this.tabClick(3)}>
              农业技术
            </Link>
          </Toolbar>
          <Tabs animated>
            <Tab id="consulate1" className="page-content" tabActive>
              <div
                className="checkDiv"
                onClick={this.goto.bind(
                  this,
                  "/consulatesub/nongchanpinanquan"
                )}
              >
                <img src={ImgConsult_1} />
              </div>
              <div
                className="checkDiv"
                onClick={this.goto.bind(this, "/consulatesub/chongwu")}
              >
                <img src={ImgConsult_2} />
              </div>
              <div className="checkDiv">
                <img
                  src={ImgConsult_3}
                  onClick={this.goto.bind(this, "/consulatesub/zhengcedayi")}
                />
              </div>
              <div className="checkDiv">
                <img
                  src={ImgConsult_4}
                  onClick={this.goto.bind(this, "/consulatesub/shishinongqing")}
                />
              </div>
            </Tab>
            <Tab id="consulate2" className="page-content">
              <img src={Img12306} />
              <Button
                className="footerBtnOrigin"
                onClick={this.callPhone.bind(this)}
              >
                拨通热线
              </Button>
            </Tab>
            <Tab id="consulate3" className="page-content">
              <div
                className="checkDiv"
                onClick={this.goto.bind(
                  this,
                  "/consulatesub/" + apiConfig.farmColumnId
                )}
              >
                <img src={ImgConsult_6} />
              </div>
              <div
                className="checkDiv"
                onClick={this.goto.bind(
                  this,
                  "/consulatesub/" + apiConfig.animalColumnId
                )}
              >
                <img src={ImgConsult_7} />
              </div>
              <div
                className="checkDiv"
                onClick={this.goto.bind(
                  this,
                  "/consulatesub/" + apiConfig.waterColumnId
                )}
              >
                <img src={ImgConsult_8} />
              </div>
              <div
                className="checkDiv"
                onClick={this.goto.bind(
                  this,
                  "/consulatesub/" + apiConfig.machineColumnId
                )}
              >
                <img src={ImgConsult_9} />
              </div>
              <div
                className="checkDiv"
                onClick={this.goto.bind(
                  this,
                  "/consulatesub/" + apiConfig.estateColumnId
                )}
              >
                <img src={ImgConsult_10} />
              </div>
            </Tab>
          </Tabs>
        </div>
        */}

        <div className="Tab">
          <div
            onClick={this.tabClick.bind(this, 1)}
            className={this.state.tabIndex == 1 ? "focus" : ""}>
            12316问答
          </div>
          <div
            onClick={this.tabClick.bind(this, 2)}
            className={this.state.tabIndex == 2 ? "focus" : ""}>
            12316电话服务
          </div>
          <div
            onClick={this.tabClick.bind(this, 3)}
            className={this.state.tabIndex == 3 ? "focus" : ""}>
            农业技术
          </div>
        </div>

        <div
          className={
            this.state.tabIndex == 1 ? "TabContent " : "TabContent hide"
          }>
          <div
            className="checkDiv"
            onClick={this.goto.bind(this, "/consulatesub/nongchanpinanquan")}>
            <img src={ImgConsult_1} />
          </div>
          <div
            className="checkDiv"
            onClick={this.goto.bind(this, "/consulatesub/chongwu")}>
            <img src={ImgConsult_2} />
          </div>
          <div className="checkDiv">
            <img
              src={ImgConsult_3}
              onClick={this.goto.bind(this, "/consulatesub/zhengcedayi")}
            />
          </div>
          <div className="checkDiv">
            <img
              src={ImgConsult_4}
              onClick={this.goto.bind(this, "/consulatesub/shishinongqing")}
            />
          </div>

          <Link
            className={
              "ConsulateAsk " + (this.state.showSubmitConsult ? " " : " hide")
            }
            href="###"
            onClick={this.goto.bind(this, "/consulateask")}
          />
        </div>

        <div
          className={
            this.state.tabIndex == 2 ? "TabContent " : "TabContent hide"
          }>
          <img src={Img12306} />
          <Button
            className="footerBtnOrigin"
            onClick={this.callPhone.bind(this)}
            href="###">
            拨通热线
          </Button>
        </div>

        <div
          className={
            this.state.tabIndex == 3 ? "TabContent " : "TabContent hide"
          }>
          <div
            className="SearchbarContainer"
            onClick={this.goto.bind(this, "/consulatesearch")}>
            {/**
              <Searchbar
              className="Searchbar"
              placeholder="请输入要查询的关键词"
              form={false}
              clearButton={true}
              disableButton={false}
              init={true}
              ref={this.refSearchbar}
            />
             */}
            <div className="Searchbar searchbar">
              <div className="searchbar-inner">
                <div className="searchbar-input-wrap">
                  <div
                    className="searchbar-input"
                    type="search"
                    value=""
                    disabled>
                    请输入要查询的关键词
                  </div>
                  <i className="searchbar-icon" />
                  <span className="input-clear-button" />
                </div>
              </div>
            </div>
          </div>

          <div
            className="checkDiv"
            onClick={this.goto.bind(
              this,
              "/consulatesub/" + apiConfig.farmColumnId
            )}>
            <img src={ImgConsult_6} />
          </div>
          <div
            className="checkDiv"
            onClick={this.goto.bind(
              this,
              "/consulatesub/" + apiConfig.animalColumnId
            )}>
            <img src={ImgConsult_7} />
          </div>
          <div
            className="checkDiv"
            onClick={this.goto.bind(
              this,
              "/consulatesub/" + apiConfig.waterColumnId
            )}>
            <img src={ImgConsult_8} />
          </div>
          <div
            className="checkDiv"
            onClick={this.goto.bind(
              this,
              "/consulatesub/" + apiConfig.machineColumnId
            )}>
            <img src={ImgConsult_9} />
          </div>
          <div
            className="checkDiv"
            onClick={this.goto.bind(
              this,
              "/consulatesub/" + apiConfig.estateColumnId
            )}>
            <img src={ImgConsult_10} />
          </div>
        </div>

        <Button className={"footerBtn submitConsult hide"}>提交</Button>

        {/** </InfiniteScroll>*/}
      </Page>
    );
  }
}

function mapStateToProps(state) {
  const { userState } = state;
  return { userState };
}

Consulate.propTypes = {
  userState: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(Consulate);
