import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "framework7-icons";
import "../../public/js/polyfill";
import { Page, Swiper, SwiperSlide } from "framework7-react";
import { apiCall, apiConfig, apiCallJSON, getTag } from "../api";
import AutoImg from "../components/AutoImg";
import CategorySelect from "../components/CategorySelect/";
import InfiniteScroll from "../components/InfiniteScroll/";
import RcViewer from "@hanyk/rc-viewer";
// import ImgCwcy from "../images/cwcy.png";
// import ImgCwjd from "../images/cwjd.png";
// import Imgnd1 from "../images/nd1.png";
// import Imgnd2 from "../images/nd2.png";
// import Imgnd3 from "../images/nd3.png";
// import zcls1 from "../images/zcls_1.png";
// import zcls2 from "../images/zcls_2.png";
// import zcls3 from "../images/zcls_3.png";
// import zcls4 from "../images/zcls_4.png";
// import zcls5 from "../images/zcls_5.png";
// import zcls6 from "../images/zcls_6.png";
// import zcls7 from "../images/zcls_7.png";
// import zcls8 from "../images/zcls_8.png";
// import zcls9 from "../images/zcls_9.png";
// import zcls10 from "../images/zcls_10.png";
// import zcls11 from "../images/zcls_11.png";
// import zcls12 from "../images/zcls_12.png";
// import tdlz from "../images/tdlz.png";
// import cjtsz from "../images/cjtsz.jpg";
// import bg1 from "../images/bg1.png";
// import bg2 from "../images/bg2.png";
// import xz1 from "../images/xz/电费（2018.2）.png";
// import xz2 from "../images/xz/电费2019年1月.png";
// import xz3 from "../images/xz/电费2019年1月（2）.png";
// import xz4 from "../images/xz/电费2019年1月（3）.png";
// import xz5 from "../images/xz/电费2019年2月.png";
// import xz6 from "../images/xz/电费2019年2月（2）.png";
// import xz7 from "../images/xz/电费2019年2月（3）.png";
// import xz8 from "../images/xz/电费2019年2月（4）.png";
// import xz9 from "../images/xz/电费2019年2月（5）.png";
// import xz10 from "../images/xz/电费2019年2月（6）.png";
// import xz11 from "../images/xz/电费2019年2月（7）.png";
// import xz12 from "../images/xz/各点代缴电费.png";
// import xz13 from "../images/xz/各点代缴电费(2019.2).png";
// import xz14 from "../images/xz/话费.png";
// import xz15 from "../images/xz/话费（2019.1）.png";
// import xz16 from "../images/xz/话费2019年2月.png";
// import xz17 from "../images/xz/话费2019年2月（2）.png";
// import xz18 from "../images/xz/话费2019年2月（3）.png";
// import xz19 from "../images/xz/话费2019年2月（4）.png";
// import xz20 from "../images/xz/水费（2019.2）.png";
// import xz21 from "../images/xz/水费2019年1月.png";
// import xz22 from "../images/xz/水费2019年2月.png";
// import xz23 from "../images/xz/水费2019年2月（2）.png";
// import xz24 from "../images/xz/水费2019年2月（3）.png";
// import xz25 from "../images/xz/水费2019年2月（4）.png";
// import xz26 from "../images/xz/水费2019年2月（5）.png";
// import zd1 from "../images/zd/业务招待费使用情况表（2018.9）.png";
// import zd2 from "../images/zd/招待费使用情况（2018.12）.png";
// import zc1 from "../images/zc/村集体土地发包收入情况表（2018年2季度）.png";
// import zc2 from "../images/zc/村委会资产负债表（2018.9）.png";
// import zc3 from "../images/zc/集体资产营运收益表（2018.2季度）.png";
// import zc4 from "../images/zc/土地补偿费收入及使用情况表（2018.2季度）.png";
// import zc5 from "../images/zc/资产负债情况公布表（2018.12）.png";
// import other1 from "../images/other/村干部工资情况表（2017）.png";
// import other2 from "../images/other/村委会收入支出明细表（2018.9）.png";
// import other3 from "../images/other/村委会收入支出明细表(2018.12).png";
// import sz1 from "../images/sz/村集体三资 (2).png";
// import sz2 from "../images/sz/村集体三资 (3)000.png";
// import sz3 from "../images/sz/个人土地流转.png";
// import snbt1 from "../images/snbt/村集体三资 (3).png";
import {
  showHeader,
  hideHeader,
  changeFooterIndex
} from "../actions/PageAction";
import "../css/pageAffairInfo.less";
import NoData from "../components/NoData";

Date.prototype.format = function(fmt) {
  var o = {
    "m+": this.getMonth() + 1, //月份
    "d+": this.getDate(), //日
    "h+": this.getHours(), //小时
    "i+": this.getMinutes(), //分
    "s+": this.getSeconds(), //秒
    "q+": Math.floor((this.getMonth() + 3) / 3), //季度
    S: this.getMilliseconds() //毫秒
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (this.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }
  for (var k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
    }
  }
  return fmt;
};

class AffairInfo extends React.Component {
  constructor(props) {
    super(props);
    this.infoType = this.$f7route.params.infoType;
    this.getSnbt = this.getSnbt.bind(this);

    this.pageRef = React.createRef();

    this.getUrl = this.getUrl.bind(this);

    // this.page = 1;
    // this.fetchData = this.fetchData.bind(this);
    // this.renderData = this.renderData.bind(this);
    // this.onRef = this.onRef.bind(this);

    this.state = {
      versions: this.props.userState.userinfo.versions
        ? this.props.userState.userinfo.versions
        : apiConfig.typeAccountCountry,
      currentYear: parseInt(new Date().format("yyyy")),
      year: parseInt(new Date().format("yyyy")),
      url: "",
      snbt: [],
      xkcf: {
        show: "permit",
        permit: [
          //["行政处罚决定案号", "第2111293920"]
        ],
        punish: [
          //["行政处罚决定案号", "第2111293920"]
        ]
      },
      finance: [],
      categories: [
        // {
        //   id: 1,
        //   name: "办公类",
        //   value: "bg",
        //   focus: true
        // },
        // {
        //   id: 2,
        //   name: "行政类",
        //   value: "xz"
        // },
        // {
        //   id: 3,
        //   name: "业务招待类",
        //   value: "yw"
        // },
        // {
        //   id: 4,
        //   name: "集体资产收入支出类",
        //   value: "zc"
        // },
        // {
        //   id: 6,
        //   name: "其他类",
        //   value: "other"
        // }
      ]
    };
  }

  //获得涉农补贴
  getSnbt(year) {
    if (!this.props.userState.userinfo.ucard) {
      return;
    }

    if (this.isComponentMounted) {
      this.setState({
        year: year
      });
    }

    apiCallJSON(
      "/allInOne/getShenongSubsidy",
      "POST",
      {
        memberCode: this.props.userState.userinfo.ucard, // "320523197103317114"
        yearI: year
      },
      data => {
        if (data.code == apiConfig.errorCode1000) {
          //this.$f7.dialog.alert(data.msg);
        } else {
          let snbtConstants = {
            1: {
              title: "水稻生产补贴",
              plant_area_f: "水稻种植面积（亩）",
              real_subsi_subtotal_f: "实际补贴金额（元）"
            },
            2: {
              title: "小麦生产补贴",
              plant_area_f: "小麦种植面积（亩）",
              subsi_amount_subtotal_f: "实际补贴金额（元）"
            },
            3: {
              title: "玉米生产补贴",
              plant_area_f: "玉米种植面积（亩）",
              real_subsi_subtotal_f: "实际补贴金额（元）"
            },
            4: {
              title: "棉花生产补贴",
              plant_area_f: "棉花种植面积（亩）",
              real_subsi_subtotal_f: "实际补贴金额（元）"
            },
            5: {
              title: "油菜生产补贴",
              plant_area_f: "油菜种植面积（亩）",
              real_subsi_subtotal_f: "实际补贴金额（元）"
            },
            9: {
              title: "绿肥种植补贴",
              plant_area_f: "绿肥种植面积（亩）",
              real_subsi_subtotal_f: "实际补贴金额（元）"
            },
            10: {
              title: "商品有机肥补贴",
              plant_area_f: "肥料数量（吨）",
              real_subsi_subtotal_f: "实际补贴金额（元）"
            },
            20: {
              title: "农业保险补贴",
              insurance_type_name: "险种",
              insurance_object_name: "标的",
              plant_area_a: "投保数量（亩、头、羽、艘、台等）",
              insurance_fee_f: "保单总保费（元）",
              real_subsi_subtotal_f:
                "中央、市、区三级财政合计补贴保费金额（元）",
              real_subsi_subtotal_f: "乡镇等基层单位财政追加补贴金额（元）"
            },
            22: {
              title: "农机具购置补贴",
              equipment_item_name: "机具品目",
              purchased_num_i: "数量（台）",
              real_subsi_subtotal_f: "实际补贴金额（元）"
            },
            26: {
              title: "参加农民培训情况",
              train_unit_name_a: "培训单位名称",
              trained_type_name: "培训类型"
            },
            27: {
              title: "蔬菜生产补贴",
              plant_area_city_level_f: "市级补贴面积（亩）",
              real_subsi_subtotal_f: "实际补贴金额（元）"
            },
            28: {
              title: "“夏淡”绿叶菜种植补贴",
              plant_area_city_level_f: "市级补贴面积（亩）",
              real_subsi_subtotal_f: "实际补贴金额（元）"
            },
            36: {
              title: "海洋渔船改造定额补贴",
              boat_name_a: "船名",
              real_subsi_subtotal_f: "实际补贴金额（元）"
            },
            37: {
              title: "渔业油价补贴",
              boat_name_a: "船名",
              oil_months: "油补月数（月）",
              account_allowance_f: "核算补助金额（元）"
            }
          };

          var content = {};
          try {
            content = JSON.parse(data.content);
          } catch (e) {
            throw new Error("can't JSON.parse(data.content)");
          }
          let snbt = [];
          for (let key in snbtConstants) {
            if (content["tab" + key].length) {
              let keynames = snbtConstants[key];
              let tab = content["tab" + key];
              for (var i = 0; i < tab.length; i++) {
                let sub = {
                  title: keynames.title,
                  data: []
                };
                for (let subkey in keynames) {
                  if (subkey == "title") {
                    continue;
                  }
                  if (tab[i][subkey]) {
                    sub.data.push([[keynames[subkey]], tab[i][subkey]]);
                  }
                }
                snbt.push(sub);
              }
            }
          }

          if (this.isComponentMounted) {
            this.setState({
              snbt: snbt
            });
          }
        }
      },
      err => {
        console.log(err);
        //this.$f7.dialog.alert(JSON.stringify(err));
      }
    );
  }

  //行政许可处罚
  getXkcf(type) {
    if (!this.props.userState.userinfo.ucard) {
      return;
    }

    let prevXkcf = this.state.xkcf;
    if (type) {
      prevXkcf.show = type;
      this.setState({ xkcf: prevXkcf });
    }

    if (this.isGetXkcf) {
      return;
    }

    //idCard: "340321197910122778",	//有许可
    //name: "周士浩"

    //idCard: "340321197903122778",	//有处罚
    //name: "王筱萱"

    apiCall(
      "/allInOne/searchPersonalAdministration",
      "POST",
      {
        idCard: this.props.userState.userinfo.ucard, //   this.props.userState.userinfo.ucard
        name: this.props.userState.userinfo.uname // this.props.userState.userinfo.uname
      },
      data => {
        if (data.code == apiConfig.errorCode1000) {
          //this.$f7.dialog.alert(data.msg);

          this.isGetXkcf = true;
        } else {
          let xkcfConstants = {
            permit: {
              XK_SXMC: "事项名称",
              XK_FR: "行政相对人",
              XK_SSQDQXID: "业务情形",
              XK_WSH: "许可证号",
              XK_XZBM: "许可部门",
              XK_JDRQ: "发证日期",
              XK_BJRQ: "有效期起",
              XK_JZQ: "有效期止"
            },
            punish: {
              CF_WSH: "行政处罚决定书文号",
              CF_CFMC: "处罚名称",
              CF_XDR_MC: "行政相对人名称",
              CF_XDR_SHXYM: "统一社会信用",
              CF_FR: "法定代表人姓名",
              CF_ZYWFSS: "主要违法事实",
              CF_CFLB: "处罚类别",
              CF_YJ: "处罚依据",
              CF_JG: "处罚结果",
              CF_XZJG: "处罚机关",
              CF_JDRQ: "处罚决定日期",
              CF_JZRQ: "处罚截止日期",
              CF_ZT: "当前状态",
              CF_DQZTBZ: "当前状态备注"
            }
          };

          var content = { permit: "", punish: "" };

          if (data.content.permit)
            content.permit = JSON.parse(data.content.permit);
          if (data.content.punish)
            content.punish = JSON.parse(data.content.punish);

          let xkcf = {};
          xkcf.show = type == "punish" ? "punish" : "permit";
          xkcf.permit = [];
          xkcf.punish = [];

          for (let type in xkcfConstants) {
            for (let column in xkcfConstants[type]) {
              if (content[type][column]) {
                xkcf[type].push([
                  xkcfConstants[type][column],
                  content[type][column]
                ]);
              }
            }
          }

          this.isGetXkcf = true;
          if (this.isComponentMounted) {
            this.setState({
              xkcf: xkcf
            });
          }
        }
      },
      err => {
        console.log(err);
        //this.$f7.dialog.alert(JSON.stringify(err));
      }
    );
  }

  // 财务收支
  getFinance(year) {
    if (!this.props.userState.userinfo.ugroupcode) {
      return;
    }
    if (this.isComponentMounted && year) {
      this.setState({
        year: year
      });
    }
    year = year || this.state.year;

    let category = this.state.categories.find(cat => {
      return cat.focus;
    });
    let extTag = category ? category.value : "";

    if (this.prevExtTag != extTag || this.prevYear != year) {
      this.isLoadEnd = false;
    }

    if (this.isLoading || this.isLoadEnd) {
      return;
    }

    this.isLoading = true;
    this.financePage =
      this.financePage && this.prevExtTag == extTag && this.prevYear == year
        ? this.financePage + 1
        : 1;
    apiCallJSON(
      "/cms/getPersonFinancial",
      "GET",
      {
        year: year,
        month: "",
        ext1: extTag,
        page: this.financePage,
        limit: 20,
        village: "B55F49F8EB9E3445B77F6F6F3C158A5E" // "B55F49F8EB9E3445B77F6F6F3C158A5E"  this.props.userState.userinfo.ugroupcode
      },
      data => {
        if (data.code == apiConfig.errorCode1000) {
          //this.$f7.dialog.alert(data.msg);
        } else {
          let finances = data.data.concat();
          if (this.prevExtTag == extTag && this.prevYear == year) {
            finances = this.state.finance.concat(finances);
          }
          if (data.data.length == 0) {
            this.isLoadEnd = true;
          }
          this.prevExtTag = extTag;
          this.prevYear = year;
          if (this.isComponentMounted) {
            this.setState({
              finance: finances
            });
          }
        }
        this.isLoading = false;
      },
      (xhr, status) => {
        console.log(xhr, status);
        this.isLoading = false;
        //this.$f7.dialog.alert(JSON.stringify(xhr));
      }
    );
  }

  // 页面滚动
  pageScroll() {
    // console.log(
    //   this.pageRef.current.scrollTop,
    //   this.pageRef.current.scrollHeight
    // );
    if (
      this.pageRef.current.scrollTop + document.documentElement.clientHeight >=
      this.pageRef.current.scrollHeight
    ) {
      //console.log("scroll");
      if (parseInt(this.infoType) == apiConfig.financialColumnId) {
        this.getFinance();
      }
    }
  }

  // 集体三资 土地流转 涉农补贴
  getUrl() {
    if (!this.props.userState.userinfo.uid) {
      return;
    }
    apiCall(
      "/addressBinding/CheckAddressBinding",
      "GET",
      {
        userId: this.props.userState.userinfo.uid
      },
      data => {
        // 接口方说此接口返回1000代表正确
        // if (data.code == apiConfig.errorCode1000) {
        //   return;
        // }
        if (data.content) {
          let deviceId = data.content.DEVICE_ID;
          let villageCode = data.content.LEVEL_CODE;
          let postData = {};
          if (this.infoType == 15) {
            // 集体“三资”
            postData = { deviceId };
          } else if (this.infoType == 14) {
            // 涉农补贴
            postData = { villageCode };
          }
          apiCall(
            "/app/searchShenongSubsidy",
            "POST",
            postData,
            data => {
              if (data.code == apiConfig.errorCode1000) {
                return;
              }
              if (data.content) {
                if (this.isComponentMounted) {
                  this.setState({ url: data.content });
                }
              }
            },
            err => {
              console.log(err);
              //this.$f7.dialog.alert(JSON.stringify(err));
            }
          );
        }
      },
      err => {
        console.log(err);
        //this.$f7.dialog.alert(JSON.stringify(err));
      }
    );
  }

  componentDidMount() {
    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      this.getUrl();

      this.isComponentMounted = true;

      app.on("pageBeforeIn", page => {
        if (page.name == "AffairInfo") {
          let affairName = "信息";
          switch (parseInt(this.infoType)) {
            case apiConfig.countrySummaryColumnId:
              affairName = "本村概况";
              break;
            case apiConfig.affairNoticeColumnId:
              affairName = "村务通知";
              break;
            case apiConfig.guidelineColumnId:
              affairName = "办事指南";
              break;
            case apiConfig.regulationsColumnId:
              affairName = "村规民约";
              break;
            case apiConfig.utilitiesColumnId:
              affairName = "公共设施";
              break;
            case apiConfig.committeeMemberColumnId:
              affairName = "村委成员";
              break;
            case apiConfig.supervisionColumnId:
              affairName = "村务监督";
              break;
            case apiConfig.annualTargetColumnId:
              affairName = "年度目标";
              break;
            case apiConfig.policyImplementationColumnId:
              affairName = "政策落实";
              break;
            case apiConfig.financialColumnId:
              affairName = "财务收支";
              getTag(1, apiConfig.financialColumnId).then(cats => {
                let categories = cats.map((cat, catIndex) => {
                  return {
                    id: cat.columnId,
                    name: cat.tagName,
                    value: cat.tagValue,
                    focus: catIndex == 0 ? true : false
                  };
                });

                if (this.isComponentMounted) {
                  this.setState(
                    {
                      categories
                    },
                    () => {
                      this.getFinance(this.state.currentYear);
                    }
                  );
                }
              });
              break;
            case apiConfig.materialColumnId:
              affairName = "物资资金接收管理";
              break;
            case apiConfig.assistColumnId:
              affairName = "协助政府开展工作";
              break;
            case apiConfig.meetingDecisionColumnId:
              affairName = "村民（代表）会议决定事项";
              break;
            case 14:
              affairName = "涉农补贴";
              this.getSnbt(this.state.currentYear);
              break;
            case 15:
              affairName = "集体“三资”";
              break;
            case 16:
              affairName = "土地流转（村级）";
              break;
            case apiConfig.otherColumnId:
              affairName = "其他事项";
              break;
            case 18:
              affairName = "行政许可处罚";
              this.getXkcf();
              break;
            case 19:
              affairName = "农业保险";
              break;
            case 20:
              affairName = "土地流转";
              break;
          }
          self.props.dispatch(showHeader(affairName, true));
          self.props.dispatch(changeFooterIndex(0));
        }
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.userState.userinfo.ucard != this.props.userState.userinfo.ucard
    ) {
      if (parseInt(this.infoType) == 14) {
        this.getSnbt(this.state.currentYear);
      }
      if (parseInt(this.infoType) == 18) {
        this.getXkcf();
      }
    }
    if (
      prevProps.userState.userinfo.ugroupcode !=
      this.props.userState.userinfo.ugroupcode
    ) {
      if (parseInt(this.infoType) == apiConfig.financialColumnId) {
        this.getFinance(this.state.currentYear);
      }
    }
    if (prevProps.userState.userinfo.uid != this.props.userState.userinfo.uid) {
      this.getUrl();
    }
    if (
      this.props.userState.userinfo.versions !=
      prevProps.userState.userinfo.versions
    ) {
      if (this.isComponentMounted) {
        this.setState({
          versions: this.props.userState.userinfo.versions
        });
      }
    }
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  // onRef(ref) {
  //   this.InfiniteScroll = ref;
  // }

  // fetchData(func) {
  //   apiCall(
  //     "/allInOne/getShenongSubsidy",
  //     "POST",
  //     {
  //       memberCode: this.page,
  //       yearI: new Date().format("yyyy")
  //     },
  //     data => {
  //       if (data.code == apiConfig.errorCode1000) {
  //         //this.$f7.dialog.alert(data.msg);
  //       } else {
  //         this.page++;

  //         if (func && data.content.length != 0) func(data.content.list);
  //         if (func && data.content.length == 0) func(data.content.list, true);
  //       }
  //     },
  //     err => {
  //       console.log(err);
  //       //this.$f7.dialog.alert(JSON.stringify(err));
  //     }
  //   );
  // }

  // renderData(data, isDataEnd) {
  //   if (data.length == 0 && !isDataEnd) {
  //     if (this.isComponentMounted) {
  //       this.setState(prev => ({
  //         consulateList: []
  //       }));
  //     }
  //   } else {
  //     let consulateList = data.map(item => {
  //       return {
  //         id: item.QID,
  //         time: item.consult_time,
  //         content: item.content,
  //         status: parseInt(item.status),
  //         reply: item.reply ? item.reply : "",
  //         replyUser: item.replyName ? item.replyName : "",
  //         replyProfessional: item.professional ? item.professional : "",
  //         replyTitle: item.the_title ? item.the_title : ""
  //       };
  //     });
  //     if (this.isComponentMounted) {
  //       this.setState(prev => ({
  //         consulateList: prev.consulateList.concat(consulateList)
  //       }));
  //     }
  //   }
  // }

  render() {
    return (
      <Page name="AffairInfo" pageContent={false}>
        <div
          className="page-content"
          onScroll={this.pageScroll.bind(this)}
          ref={this.pageRef}>
          {/** 农业保险 */}
          {this.infoType == 19 && <div className="typeWhite">暂无内容</div>}

          {/** 涉农补贴村级 */}
          {/**
          {this.infoType == 14 &&
            this.state.versions == apiConfig.typeAccountCountry && (
              <iframe src="http://snbt.shac.cn:8080/shsn_v2017/SelectShanghai/Master.aspx?villageCode=310116102214&tabCode=1" />
            )}
          */}
          {this.infoType == 14 &&
            this.state.versions == apiConfig.typeAccountCountry &&
            !!this.state.url && <iframe src={this.state.url} />}

          {/** 涉农补贴个人
          {this.infoType == 14 &&
            this.state.versions == apiConfig.typeAccountPerson && (
              <iframe src={this.state.url} />
            )}
           */}
          {this.infoType == 14 &&
            this.state.versions == apiConfig.typeAccountPerson && (
              <React.Fragment>
                <div className="Tab">
                  <div
                    onClick={this.getSnbt.bind(this, this.state.currentYear)}
                    className={
                      this.state.year == this.state.currentYear ? "focus" : ""
                    }>
                    {this.state.currentYear}年
                  </div>
                  <div
                    onClick={this.getSnbt.bind(
                      this,
                      this.state.currentYear - 1
                    )}
                    className={
                      this.state.year == this.state.currentYear - 1
                        ? "focus"
                        : ""
                    }>
                    {this.state.currentYear - 1}年
                  </div>
                </div>
                {this.state.snbt.length != 0 &&
                  this.state.snbt.map(bt => (
                    <React.Fragment key={bt.title}>
                      <div className="affair-title">{bt.title}</div>
                      {bt.data.map((value, key) => (
                        <div className="affair-item" key={key}>
                          <div>{value[0]}</div>
                          <div>{value[1]}</div>
                        </div>
                      ))}
                    </React.Fragment>
                  ))}

                {this.state.snbt.length == 0 && <NoData />}
              </React.Fragment>
            )}

          {/** 集体“三资” 村级 */}
          {this.infoType == 15 &&
            this.state.versions == apiConfig.typeAccountCountry &&
            !!this.state.url && <iframe src={this.state.url} />}

          {/** 集体“三资” 个人 */}
          {this.infoType == 15 &&
            this.state.versions == apiConfig.typeAccountPerson &&
            !!this.state.url && <iframe src={this.state.url} />}

          {/* 行政许可处罚 */}
          {this.infoType == 18 && (
            <React.Fragment>
              <div className="Tab">
                <div
                  onClick={this.getXkcf.bind(this, "permit")}
                  className={this.state.xkcf.show == "permit" ? "focus" : ""}>
                  行政许可
                </div>
                <div
                  onClick={this.getXkcf.bind(this, "punish")}
                  className={this.state.xkcf.show == "punish" ? "focus" : ""}>
                  行政处罚
                </div>
              </div>
              {this.state.xkcf[this.state.xkcf.show].length != 0 && (
                <div className="xkcfContainer">
                  <div>
                    {this.state.xkcf.show == "permit" ? "行政许可" : "行政处罚"}
                  </div>
                  <div>
                    {this.state.xkcf[this.state.xkcf.show].map(value => (
                      <div key={value[0]}>
                        <div>{value[0]}</div>
                        <div>{value[1]}</div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {this.state.xkcf[this.state.xkcf.show].length == 0 && <NoData />}
            </React.Fragment>
          )}

          {/* 财务收支 */}
          {this.infoType == apiConfig.financialColumnId && (
            <React.Fragment>
              <div className="Tab">
                <div
                  onClick={this.getFinance.bind(this, this.state.currentYear)}
                  className={
                    this.state.year == this.state.currentYear ? "focus" : ""
                  }>
                  {this.state.currentYear}年
                </div>
                <div
                  onClick={this.getFinance.bind(
                    this,
                    this.state.currentYear - 1
                  )}
                  className={
                    this.state.year == this.state.currentYear - 1 ? "focus" : ""
                  }>
                  {this.state.currentYear - 1}年
                </div>
              </div>

              <Swiper
                params={{
                  speed: 500,
                  slidesPerView: "auto",
                  slidesOffsetBefore: 15,
                  slidesOffsetAfter: 15,
                  on: {
                    tap: e => {
                      const targetValue = e.target.dataset.value;
                      if (!targetValue) return;
                      this.state.categories.map(item => {
                        if (item.value == targetValue) {
                          item.focus = true;
                        } else {
                          item.focus = false;
                        }
                      });
                      if (this.isComponentMounted) {
                        this.setState(() => ({
                          categories: this.state.categories
                        }));
                      }
                      this.getFinance();
                    }
                  }
                }}
                className="incomeSwiper">
                {this.state.categories.map((item, index) => (
                  <SwiperSlide
                    key={index}
                    className={item.focus == true ? "swiper-active" : ""}>
                    <span data-value={item.value}>{item.name}</span>
                  </SwiperSlide>
                ))}
              </Swiper>

              <div className="financeContainer">
                {this.state.finance.length == 0 && <NoData />}
                {this.state.finance.length != 0 &&
                  this.state.finance.map(item => (
                    <div key={item.id}>
                      <RcViewer
                        options={{
                          title: false,
                          toolbar: {
                            zoomIn: true,
                            zoomOut: true,
                            oneToOne: false,
                            reset: false,
                            prev: false,
                            play: {
                              show: false,
                              size: "large"
                            },
                            next: false,
                            rotateLeft: false,
                            rotateRight: false,
                            flipHorizontal: false,
                            flipVertical: false
                          }
                        }}
                        alt={item.title}>
                        <img src={item.content} />
                      </RcViewer>
                      <div>{item.title}</div>
                    </div>
                  ))}
              </div>
            </React.Fragment>
          )}
        </div>
      </Page>
    );
  }
}

function mapStateToProps(state) {
  const { userState } = state;
  return { userState };
}

AffairInfo.propTypes = {
  userState: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(AffairInfo);
