import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "framework7-icons";
import {
  showHeader,
  hideHeader,
  changeFooterIndex
} from "../actions/PageAction";
import { Page, Swiper, SwiperSlide, Link } from "framework7-react";
import InfiniteScroll from "../components/InfiniteScroll";
import NoData from "../components/NoData";
import { apiCall, apiConfig } from "../api";
import "../css/pageCommunityType.less";

class CommunityType extends React.Component {
  constructor(props) {
    super(props);
    this.onRef = this.onRef.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.renderData = this.renderData.bind(this);
    this.typeId = this.$f7route.params.typeId;
    this.typeName = this.typeId == 1 ? "标准社" : "专业社";
    this.props.dispatch(showHeader(this.typeName, true));
    this.props.dispatch(changeFooterIndex(0));
    this.state = {
      pageIndex: 1,
      focusCategoryId: this.typeId,
      focusCategoryName: this.typeName,
      products: [
        // {
        //   id: 1,
        //   title: "马陆葡萄",
        //   description: "第一批通过上海市优质农产品认证的水果",
        //   img: ImgSample
        // },
      ]
    };
  }

  componentDidMount() {
    this.isComponentMounted = true;
    const _this = this;
    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;
    });
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  onRef(ref) {
    this.InfiniteScroll = ref;
  }

  fetchData(func) {
    const that = this;

    apiCall(
      "/app/getYinongList",
      "POST",
      {
        page: this.state.pageIndex,
        pageSize: apiConfig.pageSize,
        type: this.state.focusCategoryId
          ? String(this.state.focusCategoryId)
          : ""
      },
      data => {
        //console.log("fetchData", this.state.pageIndex, data);

        if (this.isComponentMounted) {
          this.setState(prev => ({
            pageIndex: prev.pageIndex + 1
          }));
        }

        if (func) func(data.content.list);
      },
      function(xhr, status) {
        //console.log(xhr, status);
      }
    );
  }

  renderData(data) {
    //console.log(data);
    if (data.length == 0) {
      if (this.isComponentMounted) {
        this.setState(prev => ({
          products: []
        }));
      }
    } else {
      let products = data.map(item => {
        return {
          id: item.id,
          title: item.ynName,
          description: item.address,
          img: item.images
        };
      });
      //console.log(products);
      if (this.isComponentMounted) {
        this.setState(prev => ({
          products: prev.products.concat(products)
        }));
      }
    }
  }

  gotoCommunityInfo(communityId) {
    this.$f7.views.main.router.navigate("/communityinfo/", {
      props: {
        communityId
      }
    });
  }

  render() {
    const self = this;
    return (
      <InfiniteScroll
        pageName="CommunityType"
        fetchData={this.fetchData}
        renderData={this.renderData}
        onRef={this.onRef}
      >
        {this.state.products.length > 0 &&
          this.state.products.map(item => {
            return (
              <Link
                className="CommunityItem"
                key={item.id}
                href={"/communityinfo/" + item.id}
              >
                <div>
                  <img src={item.img} />
                </div>
                <div>
                  <div>{item.title}</div>
                  <div>{item.description}</div>
                </div>
                <div>
                  <div>{"详情"}</div>
                  {/** <div>距您 {item.distance}</div>}*/}
                </div>
              </Link>
            );
          })}

        {this.state.products.length == 0 && <NoData />}
      </InfiniteScroll>
    );
  }
}

function mapStateToProps(state) {
  const { userState } = state;
  return { userState };
}

CommunityType.propTypes = {
  userState: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(CommunityType);
