import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "framework7-icons";
import { Page, Link, Button, Preloader, Input } from "framework7-react";
import "framework7-icons";
import { apiCall, apiConfig } from "../api";
import { retrieveCode } from "../actions/UserAction";
import { checkPhone } from "../../public/js/utils";
import "../css/pageRetrieve.less";

class Retrieve extends React.Component {
  constructor(props) {
    super(props);

    this.codeTime = this.codeTime.bind(this);
    this.existPhone = this.existPhone.bind(this);
    this.refPhone = React.createRef();
    this.refCode = React.createRef();

    this.state = {
      phone: "",
      code: "",
      codeLeftTime: 0,
      loading: false
    };
  }

  componentDidMount() {
    this.isComponentMounted = true;
    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;
    });
    let lastCodeTime = localStorage.getItem("smsCodeTime")
      ? parseFloat(localStorage.getItem("smsCodeTime"))
      : 0;
    let lastCodeLeft = localStorage.getItem("smsCodeLeft")
      ? parseInt(localStorage.getItem("smsCodeLeft"))
      : 0;
    if (
      lastCodeTime &&
      lastCodeTime + lastCodeLeft * 1000 > new Date().getTime()
    ) {
      //距离上次发送短信验证码小于60秒
      this.codeTime(lastCodeLeft);
      return;
    }
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
    if (this.codeInterval) {
      clearInterval(this.codeInterval);
    }
  }

  changePhone(e) {
    if (!this.isComponentMounted) {
      return false;
    }
    let phone = e.target.value;
    this.setState(() => ({
      phone: phone
    }));
  }

  clearPhone(e) {
    if (!this.isComponentMounted) {
      return false;
    }
    this.setState(() => ({
      phone: ""
    }));
  }

  changeCode(e) {
    if (!this.isComponentMounted) {
      return false;
    }
    let code = e.target.value;
    this.setState(() => ({
      code: code
    }));
  }

  clearCode(e) {
    if (!this.isComponentMounted) {
      return false;
    }
    this.setState(() => ({
      code: ""
    }));
  }

  existPhone(callback) {
    apiCall(
      "/sms/exsitPhone",
      "POST",
      {
        phone: this.state.phone,
        token: apiConfig.token
      },
      data => {
        if (data.code == apiConfig.errorCode1000) {
          if (callback) callback();
        } else {
          this.$f7.dialog.alert("该手机号尚未注册");
        }
      },
      err => {
        //console.log(err);
        this.$f7.dialog.alert(JSON.stringify(err));
      }
    );
  }

  codeTime(initTime) {
    apiCall(
      "/sms/codeByPassword",
      "POST",
      {
        phone: this.state.phone,
        token: apiConfig.token
      },
      data => {
        //console.log(data);
        if (!this.isComponentMounted) {
          return false;
        }
        this.setState(
          () => ({
            loading: true
          }),
          () => {
            if (initTime <= 0 || !initTime) {
              initTime = 60;
            }
            if (this.codeInterval) {
              clearInterval(this.codeInterval);
            }
            this.codeInterval = setInterval(() => {
              if (!this.isComponentMounted) {
                return false;
              }
              this.setState(
                prevState => ({
                  codeLeftTime: initTime--,
                  loading: false
                }),
                () => {
                  this.$f7.preloader.hide();
                  localStorage.setItem("smsCodeTime", new Date().getTime());
                  localStorage.setItem("smsCodeLeft", this.state.codeLeftTime);
                  if (this.state.codeLeftTime <= 0) {
                    clearInterval(this.codeInterval);
                  }
                }
              );
            }, 1000);
          }
        );
      },
      err => {
        //console.log(err);
        this.$f7.dialog.alert(JSON.stringify(err));
      }
    );
  }

  sendCode() {
    let lastCodeTime = localStorage.getItem("smsCodeTime")
      ? parseFloat(localStorage.getItem("smsCodeTime"))
      : 0;
    let lastCodeLeft = localStorage.getItem("smsCodeLeft")
      ? parseInt(localStorage.getItem("smsCodeLeft"))
      : 0;
    if (
      lastCodeTime &&
      lastCodeTime + lastCodeLeft * 1000 > new Date().getTime()
    ) {
      //距离上次发送短信验证码小于60秒
      return;
    }
    if (!this.state.phone) {
      this.$f7.dialog.alert("请输入手机号作为您的账号", () => {
        //this.refPhone.current.focus();
      });
      return;
    }
    if (!checkPhone(this.state.phone)) {
      this.$f7.dialog.alert("手机号格式不正确，请重新输入", () => {
        //this.refPhone.current.focus();
      });
      return;
    }
    this.existPhone(() => {
      this.codeTime();
    });
  }

  goto(url) {
    if (!this.state.phone) {
      this.$f7.dialog.alert("请输入手机号作为您的账号", () => {
        //this.refPhone.current.focus();
      });
      return;
    }
    if (!checkPhone(this.state.phone)) {
      this.$f7.dialog.alert("手机号格式不正确，请重新输入", () => {
        //this.refPhone.current.focus();
      });
      return;
    }
    if (!this.state.code) {
      this.$f7.dialog.alert("请输入验证码", () => {
        //this.refCode.current.focus();
      });
      return;
    }
    this.existPhone(() => {
      this.props.dispatch(retrieveCode(this.state.phone, this.state.code));
      this.$f7.views.main.router.navigate(url, {
        props: {}
      });
    });
  }

  render() {
    return (
      <Page name="Retrieve">
        <div className="register-code">
          <div>
            <div>
              <span>账号</span>
              <Input
                type="tel"
                placeholder="请输入绑定的手机号"
                clearButton={true}
                onChange={this.changePhone.bind(this)}
                onInputClear={this.clearPhone.bind(this)}
                value={this.state.phone}
                ref={this.refPhone}
              />
            </div>
            <div>
              <span>验证码</span>
              <Input
                type="tel"
                placeholder="请输入验证码"
                clearButton={true}
                onChange={this.changeCode.bind(this)}
                onInputClear={this.clearCode.bind(this)}
                //value={this.state.code}
                ref={this.refCode}
                maxlength={6}
              />
              <div
                onClick={this.sendCode.bind(this)}
                className={
                  this.state.codeLeftTime > 0 || this.state.loading == true
                    ? "sended"
                    : ""
                }>
                {this.state.loading == false &&
                  (this.state.codeLeftTime > 0
                    ? "" + this.state.codeLeftTime + " 秒"
                    : "获取验证码")}
                {this.state.loading == true && <Preloader />}
              </div>
            </div>
          </div>
          <div className="RegisterNext">
            <Button onClick={this.goto.bind(this, "/retrievepassword")}>
              下一步
            </Button>
          </div>
        </div>
      </Page>
    );
  }
}

function mapStateToProps(state) {
  const { userState, pageState } = state;
  return { userState, pageState };
}

Retrieve.propTypes = {
  userState: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(Retrieve);
