import { userActionType } from "../actions/UserAction";

export default (
  state = {
    searchText: "",
    uuid: "",
    userinfo: {},
    registerPhone: "",
    registerCode: "",
    retrievePhone: "",
    retrieveCode: "",
    prepassword: "",
    newpassword: "",
    setcode: "",
    changephone: "",
    changecode: "",
    changegroup: "",
    changegroupcode: "",
    consulatePictures: [],
    consulatePictureIndex: 0,
    prices: []
  },
  action
) => {
  switch (action.type) {
    case userActionType.LOGIN:
      if (
        JSON.stringify(action.userinfo) != "{}"
        // && JSON.stringify(action.userinfo) != localStorage.getItem("userinfo")
      ) {
        localStorage.setItem("userinfo", JSON.stringify(action.userinfo));
        return Object.assign({}, state, { userinfo: action.userinfo });
      } else {
        return Object.assign({}, state);
      }
    case userActionType.LOGOUT:
      localStorage.removeItem("userinfo");
      return Object.assign({}, state, { userinfo: {} });
    case userActionType.CHANGEGROUP:
      return Object.assign({}, state, {
        changegroup: action.group,
        changegroupcode: action.groupCode
      });
    case userActionType.REGISTER_CODE:
      return Object.assign({}, state, {
        registerPhone: action.phone,
        registerCode: action.code
      });
    case userActionType.RETRIEVE_CODE:
      return Object.assign({}, state, {
        retrievePhone: action.phone,
        retrieveCode: action.code
      });
    case userActionType.CHANGEPASSWORD:
      return Object.assign({}, state, {
        prepassword: action.prepassword,
        newpassword: action.newpassword
      });
    case userActionType.SETCODE:
      return Object.assign({}, state, {
        setcode: action.code
      });
    case userActionType.CHANGEPHONE:
      return Object.assign({}, state, {
        changephone: action.phone,
        changecode: action.code
      });
    case userActionType.CHANGEUUID:
      return Object.assign({}, state, {
        uuid: action.uuid
      });
    case userActionType.CHANGECONSULATEPICTURES:
      return Object.assign({}, state, {
        consulatePictures: action.pictures
      });
    case userActionType.CHANGECONSULATEPICTUREINDEX:
      return Object.assign({}, state, {
        consulatePictureIndex: action.index
      });
    case userActionType.UPDATEFOCUSPRICES:
      return Object.assign({}, state, {
        prices: action.prices
      });
    default:
      return state;
  }
};
