import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "framework7-icons";
import { Page, Toolbar, Tabs, Tab, Link } from "framework7-react";
import "../css/pageAffairInfoCommon.less";
import { apiCall, apiConfig } from "../api";
import InfiniteScroll from "../components/InfiniteScroll";
import NoData from "../components/NoData";
import {
  showHeader,
  hideHeader,
  changeFooterIndex
} from "../actions/PageAction";

function AffairInfoCommon(props) {
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  useEffect(() => {
    let infoType = props.infoType;
    let affairName = "信息";
    switch (parseInt(infoType)) {
      case apiConfig.countrySummaryColumnId:
        affairName = "本村概况";
        break;
      case apiConfig.affairNoticeColumnId:
        affairName = "村务（党务）动态";
        break;
      case apiConfig.guidelineColumnId:
        affairName = "办事指南";
        break;
      case apiConfig.regulationsColumnId:
        affairName = "村规民约";
        break;
      case apiConfig.utilitiesColumnId:
        affairName = "公共设施";
        break;
      case apiConfig.committeeMemberColumnId:
        affairName = "村委成员";
        break;
      case apiConfig.supervisionColumnId:
        affairName = "村务监督";
        break;
      case apiConfig.annualTargetColumnId:
        affairName = "年度目标";
        break;
      case apiConfig.policyImplementationColumnId:
        affairName = "政策落实";
        break;
      case apiConfig.materialColumnId:
        affairName = "物资资金接收管理";
        break;
      case apiConfig.assistColumnId:
        affairName = "协助政府开展工作";
        break;
      case apiConfig.meetingDecisionColumnId:
        affairName = "村民（代表）会议决定事项";
        break;
      case 15:
        affairName = "集体“三资”";
        break;
      case 16:
        affairName = "土地流转（村级）";
        break;
      case apiConfig.otherColumnId:
        affairName = "其他事项";
        break;
      case 18:
        affairName = "行政处罚许可";
        break;
      case 19:
        affairName = "农业保险";
        break;
      case apiConfig.landColumnId:
        affairName = "土地流转";
        break;
    }
    props.dispatch(showHeader(affairName, true));
    props.dispatch(changeFooterIndex(0));
  }, []);

  useEffect(() => {
    fetchData(renderData);
  }, [props.userState.userinfo.ugroupcode]);

  function onRef(ref) {
    // InfiniteScroll = ref;
  }

  function fetchData(func) {
    if (!props.userState.userinfo.ugroupcode) {
      if (func) func([]);
      return;
    }
    apiCall(
      "/app/getCmsNews",
      "POST",
      {
        channelId: apiConfig.channelId,
        columnId: props.infoType,
        areaCode: props.userState.userinfo.ugroupcode,
        page: page,
        size: apiConfig.pageSize
      },
      data => {
        if (data.code == apiConfig.errorCode1000) {
          //this.$f7.dialog.alert(data.msg);
        } else {
          setPage(page + 1);
          if (func && data.data.list.length != 0) func(data.data.list);
          if (func && data.data.list.length == 0) func(data.data.list, true);
        }
      },
      err => {
        console.log(err);
        //this.$f7.dialog.alert(JSON.stringify(err));
      }
    );
  }

  function renderData(data, isDataEnd) {
    setList(data);
  }

  return (
    <InfiniteScroll
      pageName="AffairInfoCommon"
      fetchData={fetchData}
      renderData={renderData}
      onRef={onRef}>
      {list.length > 0 &&
        list.map((item, index) => (
          <Link
            className="MessageItem"
            key={item.id}
            href={"/information/" + item.id}>
            <div>
              <span>{item.showCreateDate}</span>
              <span>来源：{item.enterpriseName}</span>
            </div>
            <div>
              <div>{item.title}</div>
            </div>
          </Link>
        ))}
      {list.length == 0 && <NoData />}
    </InfiniteScroll>
  );
}

function mapStateToProps(state) {
  const { userState } = state;
  return { userState };
}

AffairInfoCommon.propTypes = {
  userState: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(AffairInfoCommon);
