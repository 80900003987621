import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "framework7-icons";
import { Page, Link } from "framework7-react";
import "../css/pageBillAsset.less";

class BillAsset extends React.Component {
  constructor(props) {
    super(props);
    this.userId = this.$f7route.params.userId;

    this.interval = null;

    this.state = {
      name: "李建国",
      country: "嘉定区工业园区灯塔村",
      time: "2018-12-31",
      datalist: [
        {
          id: "cooperation",
          name: "村经济合作社",
          value: 261.7,
          list: [
            { name: "固定资产", value: 128.8 },
            { name: "在建工程", value: 0.0 },
            { name: "总负债", value: 78.8 },
            { name: "净资产", value: 205.7 },
            { name: "总收入", value: 55.0 },
            { name: "总支出", value: 64.2 },
            { name: "净收益", value: 14.6 }
          ]
        },
        {
          id: "enterprise",
          name: "村集体企业",
          value: 0,
          list: []
        },
        {
          id: "committee",
          name: "村民委员会",
          value: 290.0,
          list: [
            { name: "固定资产", value: 128.8 },
            { name: "在建工程", value: 28.7 },
            { name: "总负债", value: 14.6 },
            { name: "净资产", value: 205.7 },
            { name: "总收入", value: 55.0 },
            { name: "总支出", value: 65.3 },
            { name: "净收益", value: 9.6 }
          ]
        }
      ]
    };
  }

  componentDidMount() {
    this.isComponentMounted = true;
    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      this.state.datalist.map(type => {
        const totalWidth = window.document.documentElement.clientWidth;
        let leftWidth = 0,
          rightWidth = 0;
        let maxValue = 0;
        $(".bill-asset-body-" + type.id + " > div").forEach(item => {
          let left = $(item)
            .children()
            .eq(0)
            .outerWidth();
          let right = $(item)
            .children()
            .eq(2)
            .outerWidth();
          let value = parseFloat($(item).attr("data-value"));
          if (leftWidth < left) {
            leftWidth = left;
          }
          if (rightWidth < right) {
            rightWidth = right;
          }
          if (maxValue < value) {
            maxValue = value;
          }
        });
        const fullWidth = totalWidth - leftWidth - rightWidth;

        type.list.map(item => {
          item.width = Math.floor((item.value / maxValue) * fullWidth);
        });
      });

      if (this.isComponentMounted) {
        this.setState(prev => ({
          datalist: prev.datalist
        }));
      }
    });
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  render() {
    const self = this;
    return (
      <Page name="BillAsset" className="BillAsset">
        <div className="bill-asset-top">
          <div>
            <div>{this.state.name}</div>
            <div>{this.state.country}</div>
          </div>
          <div>
            <div>统计时间：{this.state.time}</div>
            <div>单位：万元</div>
          </div>
        </div>

        <div className="bill-asset-container">
          {this.state.datalist.map(type => {
            return (
              <div key={type.id}>
                <div className="bill-asset-title">
                  <div>{type.name}</div>
                  {type.list.length == 0 ? (
                    <div>暂无信息</div>
                  ) : (
                    <div>总资产：{type.value.toFixed(2)}</div>
                  )}
                </div>
                <div className={"bill-asset-body bill-asset-body-" + type.id}>
                  {type.list.map((item, index) => {
                    return (
                      <div key={item.name} data-value={item.value}>
                        <div>{item.name}</div>
                        <div
                          className="bill-asset-bar"
                          style={{ width: item.width }}
                        />
                        <div>{item.value.toFixed(2)}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </Page>
    );
  }
}

function mapStateToProps(state) {
  const { userState } = state;
  return { userState };
}

BillAsset.propTypes = {
  userState: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(BillAsset);
