import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "framework7-icons";
import { Page } from "framework7-react";
import "../css/pageMap.less";

class Map extends React.Component {
  componentDidMount() {
    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;
    });
  }

  render() {
    return (
      <Page name="Map">
        <iframe src="http://116.228.18.58:8080/public/index.html#/home" />
      </Page>
    );
  }
}

function mapStateToProps(state) {
  const { userState } = state;
  return { userState };
}

Map.propTypes = {
  userState: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(Map);
