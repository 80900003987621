import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "framework7-icons";
import { Page } from "framework7-react";

class Example extends React.Component {
  componentDidMount() {
    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;
    });
  }

  render() {
    return <Page name="Example">example</Page>;
  }
}

function mapStateToProps(state) {
  const { userState } = state;
  return { userState };
}

Example.propTypes = {
  userState: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(Example);
