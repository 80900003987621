import React from "react";
import { Link } from "framework7-react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "./footer.less";
import { loginPath } from "../../path";

class Footer extends React.Component {
  componentDidMount() {
    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;
      // app.views.main.router.url
    });
  }

  handleFooterClick(index, path) {
    const that = this;
    if (path.indexOf("http") >= 0) {
      location.href = path;
    } else {
      that.$f7.views.main.router.navigate(
        loginPath(this.props.userState.userinfo, path)
      );
    }
  }

  render() {
    if (this.props.pageState.footerIndex != 0) {
      return (
        <div className="Footer">
          <Link
            onClick={this.handleFooterClick.bind(this, 1, "/")}
            className={this.props.pageState.footerIndex == 1 ? "focus" : ""}
          >
            <div />
            <div>首页</div>
          </Link>
          <Link
            onClick={this.handleFooterClick.bind(
              this,
              2,
              //"http://116.228.18.58:8080/public/index.html#/home"
              "/map"
            )}
            className={this.props.pageState.footerIndex == 2 ? "focus" : ""}
          >
            <div />
            <div>便民地图</div>
          </Link>
          <Link
            onClick={this.handleFooterClick.bind(this, 3, "/community")}
            className={this.props.pageState.footerIndex == 3 ? "focus" : ""}
          >
            <div />
            <div>益农社</div>
          </Link>
          <Link
            onClick={this.handleFooterClick.bind(this, 4, "/my")}
            className={this.props.pageState.footerIndex == 4 ? "focus" : ""}
          >
            <div />
            <div>我的</div>
          </Link>
        </div>
      );
    } else {
      return null;
    }
  }
}

function mapStateToProps(state) {
  const { pageState, userState } = state;
  return { pageState, userState };
}

Footer.propTypes = {
  pageState: PropTypes.object.isRequired,
  userState: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(Footer);
