import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "framework7-icons";
import { Page, Link, Button, Input } from "framework7-react";
import "../css/pageAuthentication.less";

class Autentication extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthentication: false
    };
  }
  componentDidMount() {
    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;
    });
  }

  nextStep() {
    // this.$f7.views.main.router.navigate("/authenticationselectgroup", {
    //   props: {}
    // });
    var dialog = this.$f7.dialog.create({
      title: "温馨提示",
      text:
        "益申农APP的实名认证服务由上海市数字证书认证中心（上海CA中心）提供。",
      buttons: [
        {
          text: "知道了",
          onClick: () => {
            // this.$f7.views.main.router.navigate("/authenticationthird", {
            //   props: {}
            // });

            location.href =
              "http://yn12316.shac.cn/yn_gateway/oauth/realName?mobile=" +
              this.props.userState.userinfo.uphone +
              "&channel=app";
          }
        }
      ]
    });
    dialog.open();
  }

  render() {
    return (
      <Page name="Authentication">
        <div
          className={
            "unauthentication " + (this.state.isAuthentication ? "hide" : "")
          }>
          <div>认证后您将获得以下权限</div>
          <div>
            <div>
              <div />
              <div>查看涉农账单</div>
            </div>
            <div>
              <div />
              <div>本地政策推送</div>
            </div>
            <div>
              <div />
              <div>天气预警推送</div>
            </div>
          </div>
          <div className="hide">
            <div>
              <div>登录手机号</div>
              <div>{this.props.userState.userinfo.uphone}</div>
            </div>
            <div>
              <div>姓名</div>
              <div>
                <Input
                  type="text"
                  placeholder="填写登录手机号所属人的真实姓名"
                  clearButton={true}
                />
              </div>
            </div>
            <div>
              <div>身份证号</div>
              <div>
                <Input
                  type="text"
                  placeholder="用于实名认证的证件号码"
                  clearButton={true}
                />
              </div>
            </div>
          </div>
          <div>
            <Button onClick={this.nextStep.bind(this)}>去验证</Button>
            {/** 下一步 */}
          </div>
          <div>
            注：该功能仅用于农业从业者查询本人相关涉农信息以及郊区村民查看本村相关信息，非以上人员无需进行身份认证。
          </div>
        </div>

        <div
          className={
            "authentication " + (this.state.isAuthentication ? "" : "hide")
          }>
          <div>
            <div />
            <div>认证成功</div>
          </div>
          <div>
            <div>姓名</div>
            <div>{this.props.userState.userinfo.uname}</div>
            <div>身份证号</div>
            <div>{this.props.userState.userinfo.ucard}</div>
          </div>
        </div>
      </Page>
    );
  }
}

function mapStateToProps(state) {
  const { userState } = state;
  return { userState };
}

Autentication.propTypes = {
  userState: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(Autentication);
