import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "framework7-icons";
import { Page, Link } from "framework7-react";
import "../css/pageConsulateMy.less";
import { apiCall, apiConfig } from "../api";
import InfiniteScroll from "../components/InfiniteScroll/";
import NoData from "../components/NoData";

class ConsulateMy extends React.Component {
  constructor(props) {
    super(props);

    this.page = 1;
    this.fetchData = this.fetchData.bind(this);
    this.renderData = this.renderData.bind(this);
    this.onRef = this.onRef.bind(this);

    this.state = { consulateList: [] };
  }

  componentDidMount() {
    this.isComponentMounted = true;
    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;
    });
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.userState.userinfo) !=
      JSON.stringify(this.props.userState.userinfo)
    ) {
      this.page = 1;
      this.InfiniteScroll.reInfinite();
    }
  }

  onRef(ref) {
    this.InfiniteScroll = ref;
  }

  fetchData(func) {
    apiCall(
      "/reply/getQuestionByUser",
      "POST",
      {
        page: this.page,
        limit: apiConfig.pageSize,
        consult_user: this.props.userState.userinfo.uid
      },
      data => {
        if (data.code == apiConfig.errorCode1000) {
          //this.$f7.dialog.alert(data.msg);
        } else {
          this.page++;

          if (func && data.content.length != 0) func(data.content.list);
          if (func && data.content.length == 0) func(data.content.list, true);
        }
      },
      err => {
        console.log(err);
        //this.$f7.dialog.alert(JSON.stringify(err));
      }
    );
  }

  renderData(data, isDataEnd) {
    if (data.length == 0 && !isDataEnd) {
      if (this.isComponentMounted) {
        this.setState(prev => ({
          consulateList: []
        }));
      }
    } else {
      let consulateList = data.map(item => {
        return {
          id: item.qid,
          time: item.consult_time,
          content: item.content,
          status: parseInt(item.status),
          reply: item.reply ? item.reply : "",
          replyUser: item.replyName ? item.replyName : "",
          replyProfessional: item.professional ? item.professional : "",
          replyTitle: item.the_title ? item.the_title : ""
        };
      });
      if (this.isComponentMounted) {
        this.setState(prev => ({
          consulateList: prev.consulateList.concat(consulateList)
        }));
      }
    }
  }

  render() {
    return (
      <InfiniteScroll
        pageName="ConsulateMy"
        fetchData={this.fetchData}
        renderData={this.renderData}
        onRef={this.onRef}>
        {this.state.consulateList.length > 0 &&
          this.state.consulateList.map(item => (
            <Link
              className={
                "ConsulateItem " + (item.status == 0 ? "unanswer" : "")
              }
              href={"/consulateinfo/" + item.id}
              key={item.id}>
              <div>
                <div>{item.time}</div>
                <div>{item.status == 0 ? "未回复" : "已回复"}</div>
              </div>
              <div>{item.content}</div>
              {item.reply && item.replyUser && (
                <div>
                  <div>
                    <div>
                      {item.replyProfessional}
                      {item.replyUser}回答：
                    </div>
                    <div>{item.reply}</div>
                  </div>
                </div>
              )}
            </Link>
          ))}

        {this.state.consulateList.length == 0 && <NoData />}
        {/**
        <Link className="ConsulateItem unanswer" href="/consulateinfo/1">
          <div>
            <div>2018-09-21 10:23</div>
            <div>未回复</div>
          </div>
          <div>
            我想问一下有没有关于上海、浙江地区蔬菜什么时候育苗、什么时间种植的资料？
          </div>
        </Link>

        <Link className="ConsulateItem" href="/consulateinfo/1">
          <div>
            <div>2018-09-16 14:36</div>
            <div>已回复</div>
          </div>
          <div>
            我想问一下有没有关于上海、浙江地区蔬菜什么时候育苗、什么时间种植的资料？
          </div>
          <div>
            <div>
              <div>蔬菜植保专家高文琪回答：</div>
              <div>建议去实体书店或网上书店购买一本相关书籍</div>
            </div>
          </div>
        </Link>
      */}
      </InfiniteScroll>
    );
  }
}

function mapStateToProps(state) {
  const { userState } = state;
  return { userState };
}

ConsulateMy.propTypes = {
  userState: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(ConsulateMy);
