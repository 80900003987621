import React from "react";
import { Link } from "framework7-react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "framework7-icons";
import "./pricescroll.less";

class Pricescroll extends React.Component {
  constructor(props) {
    super(props);
    this.roll = this.roll.bind(this);
    this.state = {
      // prices: [
      //   "青菜2.38元/公斤",
      // ]
      prices: props.prices
    };
  }

  roll() {
    const $ = this.$$;

    let parentNode = $(".pricesItems");
    let containerWidth = parentNode.parent().width();
    let totalWidth = 0;
    let restartWidth = 0;
    let addedPrices = [];
    this.state.prices.forEach((item, index) => {
      let width = parentNode
        .children("div")
        .eq(index)
        .width();
      totalWidth += width;
      if (restartWidth < containerWidth) {
        restartWidth += width;
        totalWidth += width;
        addedPrices.push(item);
      }
    });

    parentNode.css({ width: totalWidth + "px" });

    if (this.interval) clearInterval(this.interval);
    if (restartWidth < containerWidth) {
      parentNode.css({ left: "0px" });
      return;
    }

    if (this.isComponentMounted) {
      this.setState(prevState => {
        return {
          prices: prevState.prices.concat(addedPrices)
        };
      });
    }

    let leftWidth = 0;
    this.interval = setInterval(function() {
      leftWidth += 1;
      parentNode.css({ left: -1 * leftWidth + "px" });
      if (leftWidth + containerWidth >= totalWidth) {
        leftWidth = restartWidth - containerWidth;
        parentNode.css({ left: -1 * leftWidth + "px" });
      }
    }, 25);
  }

  componentDidMount() {
    this.isComponentMounted = true;
    const _this = this;
    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      this.roll();
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.prices != this.props.prices) {
      if (this.isComponentMounted) {
        this.setState(
          {
            prices: this.props.prices
          },
          () => {
            this.roll();
          }
        );
      }
    }
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  goto() {
    if (JSON.stringify(this.props.userState.userinfo) == "{}") {
      this.$f7.toast
        .create({
          text: "请先登录",
          position: "center",
          closeTimeout: 2000,
          on: {
            close: () => {
              this.$f7.views.main.router.navigate("/login", {
                props: {}
              });
            }
          }
        })
        .open();
    } else {
      this.$f7.views.main.router.navigate("/prices", {
        props: {}
      });
    }
  }

  render() {
    return (
      <Link className="Pricescroll" onClick={this.goto.bind(this)}>
        <div>农产品批发价:</div>
        <div>
          <div className="pricesItems">
            {this.state.prices.map((item, index) => (
              <div key={index}>{item}&nbsp;</div>
            ))}
          </div>
        </div>
        <div />
      </Link>
    );
  }
}

function mapStateToProps(state) {
  const { userState } = state;
  return { userState };
}

Pricescroll.propTypes = {
  userState: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(Pricescroll);
