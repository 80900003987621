import { pageActionType } from "../actions/PageAction";

export default (
  state = {
    showHeader: true,
    headerTitle: "",
    headerLeft: false,
    headerRight: false,
    headerRightLocation: false,
    footerIndex: 1,
    featureCategories: [],
    siteCategories: [],
    cultureCategories: [],
    longitude: false,
    latitude: false,
    shareState: false,
    triggerFarmServiceMonth: false,
    farmServiceMonth: 0
  },
  action
) => {
  switch (action.type) {
    case pageActionType.SETLOCATION:
      return Object.assign({}, state, {
        longitude: action.longitude,
        latitude: action.latitude
      });
    case pageActionType.SHOWHEADER:
      return Object.assign({}, state, {
        showHeader: true,
        headerTitle: action.title,
        headerLeft: action.left,
        headerRight: action.right,
        headerRightLocation: action.rightLocation
      });
    case pageActionType.HIDEHEADER:
      return Object.assign({}, state, {
        showHeader: false
      });
    case pageActionType.CHANGEFOOTERINDEX:
      return Object.assign({}, state, { footerIndex: action.footerIndex });
    case pageActionType.FEATURECATEGORIES:
      return Object.assign({}, state, { featureCategories: action.data });
    case pageActionType.SITECATEGORIES:
      return Object.assign({}, state, { siteCategories: action.data });
    case pageActionType.CULTURECATEGORIES:
      return Object.assign({}, state, { cultureCategories: action.data });
    case pageActionType.SHOWSHAREPOPUP:
      return Object.assign({}, state, { shareState: !state.shareState });
    case pageActionType.TRIGGERFARMSERVICEMONTH:
      return Object.assign({}, state, {
        triggerFarmServiceMonth: !state.triggerFarmServiceMonth
      });
    case pageActionType.FARMSERVICEMONTH:
      return Object.assign({}, state, {
        farmServiceMonth: action.farmServiceMonth
      });
    default:
      return state;
  }
};
