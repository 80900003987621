import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "framework7-icons";
import { Page, Link } from "framework7-react";
import InfiniteScroll from "../components/InfiniteScroll";
import NoData from "../components/NoData";
import InformationPanel from "../components/InformationPanel";
import { apiCall, apiConfig } from "../api";
import "../css/pageCitizenService.less";
import ImgNews1 from "../images/im_news_1.png";
import ImgNews2 from "../images/imgsample40.png";
import ImgNews3 from "../images/imgsample41.png";
import ImgNews4 from "../images/imgsample42.png";

class CitizenService extends React.Component {
  constructor(props) {
    super(props);
    this.onRef = this.onRef.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.renderData = this.renderData.bind(this);
    this.state = {
      pageIndex: 1,
      articles: [
        {
          id: 1,
          title: "今冬明春，我与草莓有个约会",
          img: ImgNews1,
          date: "2019年1月24日",
          source: "市农委信息中心",
          link: "/information/344",
          tags: ["信息", "培训", "学习"],
          views: 58267
        },
        {
          id: 2,
          title: "到中国“草莓之乡”赴一场“莓”好约会！",
          img: [ImgNews2, ImgNews3, ImgNews4],
          date: "2019年1月24日",
          source: "市农委信息中心",
          link: "/information/344",
          tags: ["信息", "培训", "学习"],
          views: 58267
        },
        {
          id: 3,
          title: "今冬明春，我与草莓有个约会",
          img: ImgNews1,
          date: "2019年1月24日",
          source: "市农委信息中心",
          link: "/information/344",
          tags: ["信息", "培训", "学习"],
          views: 58267
        },
        {
          id: 4,
          title: "到中国“草莓之乡”赴一场“莓”好约会！",
          img: [ImgNews2, ImgNews3, ImgNews4],
          date: "2019年1月24日",
          source: "市农委信息中心",
          link: "/information/344",
          tags: ["信息", "培训", "学习"],
          views: 58267
        }
      ]
    };
  }

  componentDidMount() {
    const _this = this;
    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;
    });

    // apiCall(
    //   "/app/getCmsNews",
    //   "POST",
    //   {
    //     channelId: apiConfig.channelId,
    //     columnId: apiConfig.siteColumnId,
    //     ext1: this.state.ext1,
    //     ext2: this.state.ext2,
    //     ext3: this.state.ext3,
    //     page: this.state.pageIndex,
    //     size: apiConfig.pageSize
    //   },
    //   data => {
    //     //console.log("constructor", this.state.pageIndex, data);
    //     if (this.state.categories.length == 0) {
    //       let categories = [];
    //       data.data.tags.forEach(tags => {
    //         let cate = {
    //           id: tags.type_id,
    //           name: tags.type_name,
    //           child: []
    //         };
    //         cate.child = tags.data.map(tag => {
    //           return {
    //             id: tag.id,
    //             name: tag.tagName,
    //             value: tag.tagValue
    //           };
    //         });
    //         categories.push(cate);
    //       });
    //       this.setState(prev => ({
    //         categories: categories
    //       }));
    //     }

    //     if (this.state.banners.length == 0) {
    //       let banners = [];
    //       data.data.banner.forEach(item => {
    //         if (item.previewImage) {
    //           banners.push({
    //             img: item.previewImage,
    //             url: "/information/" + item.id
    //           });
    //         }
    //       });
    //       this.setState({
    //         banners
    //       });
    //     }
    //   },
    //   function(xhr, status) {
    //     //console.log(xhr, status);
    //   }
    // );
  }

  onRef(ref) {
    this.InfiniteScroll = ref;
  }

  fetchData(func) {
    if (this.state.articles.length == 0) return;

    if (func) func([]);

    // apiCall(
    //   "/app/getCmsNews",
    //   "POST",
    //   {
    //     channelId: apiConfig.channelId,
    //     columnId: apiConfig.siteColumnId,
    //     ext1: this.state.ext1,
    //     ext2: this.state.ext2,
    //     ext3: this.state.ext3,
    //     page: this.state.pageIndex,
    //     size: apiConfig.pageSize
    //   },
    //   data => {
    //     //console.log("fetchData", this.state.pageIndex, data);

    //     this.setState(prev => ({
    //       pageIndex: prev.pageIndex + 1
    //     }));

    //     if (func) func(data.data.list);
    //   },
    //   function(xhr, status) {
    //     //console.log(xhr, status);
    //   }
    // );
  }

  renderData(data) {
    //console.log(data);
    // if (data.length == 0) {
    //   this.setState(prev => ({
    //     articles: []
    //   }));
    // } else {
    //   let articles = data.map(item => {
    //     return {
    //       id: item.id,
    //       title: item.title,
    //       description: item.abstractor,
    //       img: item.previewImage,
    //       distance: item.distance ? item.distance : ""
    //     };
    //   });
    //   this.setState(prev => ({
    //     articles: prev.articles.concat(articles)
    //   }));
    // }
  }

  render() {
    const self = this;
    return (
      <InfiniteScroll
        pageName="CitizenService"
        fetchData={this.fetchData}
        renderData={this.renderData}
        onRef={this.onRef}
      >
        <div>
          {this.state.articles.length > 0 &&
            this.state.articles.map((item, index) => {
              return (
                <InformationPanel
                  key={item.id}
                  title={item.title}
                  img={item.img}
                  date={item.date}
                  source={item.source ? item.source : ""}
                  link={item.link}
                  tags={["信息", "培训", "学习"]}
                  views={6782}
                />
              );
            })}

          {this.state.articles.length == 0 && <NoData />}
        </div>
      </InfiniteScroll>
    );
  }
}

function mapStateToProps(state) {
  const { userState } = state;
  return { userState };
}

CitizenService.propTypes = {
  userState: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(CitizenService);
