import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "framework7-icons";
import { Page, Link, Button, Input } from "framework7-react";
import "framework7-icons";
import { apiCall, apiConfig } from "../api";
import md5 from "js-md5";
import { login } from "../actions/UserAction";
import "../css/pageRegisterPassword.less";

class RegisterPassword extends React.Component {
  constructor(props) {
    super(props);
    this.refPassword = React.createRef();

    this.state = {
      password: ""
    };
  }

  componentDidMount() {
    this.isComponentMounted = true;
    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;
    });
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  changePassword(e) {
    if (!this.isComponentMounted) {
      return false;
    }
    let password = e.target.value;
    this.setState(() => ({
      password: password
    }));
  }

  clearPassword(e) {
    if (!this.isComponentMounted) {
      return false;
    }
    this.setState(() => ({
      password: ""
    }));
  }

  register(url) {
    let phone = this.props.userState.registerPhone;
    let code = this.props.userState.registerCode;

    if (this.state.password.length < 6 || this.state.password.length > 16) {
      this.$f7.dialog.alert("请设置6-16位字符", () => {
        //this.refPassword.current.focus();
      });
      return;
    }

    apiCall(
      "/sms/register",
      "POST",
      {
        phone: phone,
        password: this.state.password ? md5(String(this.state.password)) : "",
        phoneCode: code,
        token: apiConfig.token
      },
      data => {
        //console.log(data);
        if (data.code == apiConfig.errorCode1000) {
          //error
          this.$f7.dialog.alert(data.msg);
        } else {
          this.$f7.toast
            .create({
              text: "注册成功",
              position: "center",
              closeTimeout: 2000,
              on: {
                close: () => {
                  this.props.dispatch(
                    login({ sign: data.content, uphone: phone })
                  );
                  this.$f7.views.main.router.navigate("/my", {
                    props: {}
                  });
                }
              }
            })
            .open();
        }
      },
      err => {
        //console.log(err);
        this.$f7.dialog.alert(JSON.stringify(err));
      }
    );
  }

  render() {
    return (
      <Page name="RegisterPassword">
        <div>
          <div>
            <span>设置密码</span>
            <Input
              type="password"
              placeholder="请设置6-16位字符"
              onChange={this.changePassword.bind(this)}
              onInputClear={this.clearPassword.bind(this)}
              value={this.state.password}
              ref={this.refPassword}
              clearButton={true}
            />
          </div>
        </div>
        <div>
          <Button onClick={this.register.bind(this)}>完成</Button>
        </div>
      </Page>
    );
  }
}

function mapStateToProps(state) {
  const { userState, pageState } = state;
  return { userState, pageState };
}

RegisterPassword.propTypes = {
  userState: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(RegisterPassword);
