import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "framework7-icons";
import { Page, Link } from "framework7-react";
import "../css/pageBillLand.less";

class BillLand extends React.Component {
  constructor(props) {
    super(props);
    this.userId = this.$f7route.params.userId;

    this.interval = null;

    this.state = {
      name: "李建国",
      code: "362********2545",
      datalist: [
        {
          id: "land1",
          name: "本户农村土地承包合同",
          list: [
            { name: "所在村组", text: "上海市嘉定区嘉定工业园区灯塔村2组" },
            { name: "发包方名称", text: "嘉定区嘉定工业园区灯塔村村委会" },
            { name: "承包合同编号", text: "H98389928382383939293" },
            { name: "签约日期", text: "2012年12月1日" },
            { name: "承包方代表", text: "李建国" },
            { name: "权利共有人情况", text: "李建国、王芳" },
            { name: "当前确权面积（ 亩 ）", value: 382.0 },
            { name: "确权确地面积（ 亩 ）", value: 200.0 },
            { name: "地块编号与面积", text: "1000 (382.0 亩)" },
            { name: "是否确权登记", text: "已登记" },
            { name: "是否发放权证", text: "已发放" },
            { name: "发证日期", text: "2013年1月15日" },
            { name: "最近更新时间", text: "2013年1月15日" }
          ]
        },
        {
          id: "land2",
          name: "本地土地委托流转情况",
          value: 0,
          list: [
            { name: "序号", text: "1" },
            { name: "委托流转面积", text: "382.0 亩" },
            { name: "有效期", text: "2013年1月15日 - 2028年1月15日" },
            { name: "状态", text: "有效期内" }
          ]
        }
      ]
    };
  }

  componentDidMount() {
    this.isComponentMounted = true;
    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      this.state.datalist.map(type => {
        const totalWidth = window.document.documentElement.clientWidth;
        let leftWidth = 0,
          rightWidth = 0;
        let maxValue = 0;
        $(".bill-asset-body-" + type.id + " > div").forEach(item => {
          let left = $(item)
            .children()
            .eq(0)
            .outerWidth();
          let right = $(item)
            .children()
            .eq(2)
            .outerWidth();
          let value = parseFloat($(item).attr("data-value"));
          if (value < 0) {
            return;
          }
          if (leftWidth < left) {
            leftWidth = left;
          }
          if (rightWidth < right) {
            rightWidth = right;
          }
          if (maxValue < value) {
            maxValue = value;
          }
        });
        const fullWidth = totalWidth - leftWidth - rightWidth;

        type.list.map(item => {
          if (!item.value) return;
          item.width = Math.floor((item.value / maxValue) * fullWidth);
        });
      });

      if (this.isComponentMounted) {
        this.setState(prev => ({
          datalist: prev.datalist
        }));
      }
    });
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  render() {
    const self = this;
    return (
      <Page name="BillLand" className="BillLand">
        <div className="bill-land-top">
          <div>姓名/名称：{this.state.name}</div>
          <div>身份证号码/机构代码：{this.state.code}</div>
        </div>

        <div className="bill-asset-container">
          {this.state.datalist.map(type => {
            return (
              <div key={type.id}>
                <div className="bill-asset-title">
                  <div>{type.name}</div>
                </div>
                <div className={"bill-asset-body bill-asset-body-" + type.id}>
                  {type.list.map((item, index) => {
                    return (
                      <div
                        key={item.name}
                        data-value={item.value ? item.value : -1}
                        className={item.value ? "bartype" : ""}
                      >
                        <div>{item.name}</div>
                        <div
                          className="bill-asset-bar"
                          style={{ width: item.width }}
                        />
                        {item.text ? (
                          <div className="bill-land-text">{item.text}</div>
                        ) : (
                          <div>{item.value.toFixed(2)}</div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </Page>
    );
  }
}

function mapStateToProps(state) {
  const { userState } = state;
  return { userState };
}

BillLand.propTypes = {
  userState: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(BillLand);
