import config from "../config";
import { detectPhone, localStorageExpiration } from "../public/js/utils";
import { setLocation, showShare } from "./actions/PageAction";

let app, dispatch;
export function setupBaseApiConfig(_app, _dispatch) {
  app = _app;
  dispatch = _dispatch;
  app.request.setup({
    dataType: "json",
    timeout: 5000,
    error: function(xhr, status) {
      console.log(xhr, status);
      app.preloader.hide();
    },
    success: function(data, status, xhr) {
      //console.log("base success", data, status, xhr);
      app.preloader.hide();
    },
    complete: function(xhr, status) {
      //console.log("base complete", xhr, status);
      app.preloader.hide();
    },
    statusCode: {
      404: function(xhr) {
        app.dialog.alert("page not found");
      }
    }
  });
}

export function apiCall(
  url,
  method,
  data,
  successCallback,
  errorCallback,
  isShowLoading
) {
  if (isShowLoading) {
    app.preloader.show();
  }

  app.request({
    url: config.apiBaseUrl + url,
    method: method,
    data: data,
    error: function(xhr, status) {
      if (errorCallback) errorCallback(xhr, status);
    },
    success: function(data, status, xhr) {
      if (successCallback) successCallback(data, status, xhr);
    }
  });
}

export function apiCallJSON(
  url,
  method,
  data,
  successCallback,
  errorCallback,
  isShowLoading
) {
  if (isShowLoading) {
    app.preloader.show();
  }

  app.request({
    url: config.apiBaseUrl + url,
    method: method,
    data: data,
    contentType: "application/json",
    //processData: false,
    error: function(xhr, status) {
      if (errorCallback) errorCallback(xhr, status);
    },
    success: function(data, status, xhr) {
      if (successCallback) successCallback(data, status, xhr);
    }
  });
}

export let apiConfig = config;

export let pageOperation = {
  setData: pageData => {
    localStorageExpiration.setItem(
      "pageOperation",
      pageData,
      60 * 60 * 24 * 365
    );
  },
  getData: () => {
    return localStorageExpiration.getItem("pageOperation") || {};
  },
  setPV: function(articleId) {
    let pageData = this.getData();
    return new Promise((resolve, reject) => {
      if (
        (pageData[articleId] && !pageData[articleId].setPV) ||
        !pageData[articleId]
      ) {
        // not setPV before
        apiCall(
          "/cms/articleAttribute",
          "POST",
          {
            channel: 2, //渠道,参数(1.PC 2.APP 3.TV 4.一点通)
            articleType: 1, // 文章类型,参数(1.nw_cms_info 2.nw_yinong_info 3.rural_information)
            articleId: articleId,
            PV: 1
          },
          data => {
            pageData[articleId] = pageData[articleId] || {};
            pageData[articleId].setPV = true;
            this.setData(pageData);
            resolve();
          },
          (xhr, status) => {
            console.log("/cms/articleAttribute PV error : ", xhr, status);
            reject(xhr);
          }
        );
      }
      resolve();
    });
  },
  getLike: function(articleId) {
    let pageData = this.getData();
    return !!(pageData[articleId] && pageData[articleId].setLike);
  },
  setLike: function(articleId) {
    let pageData = this.getData();
    return new Promise((resolve, reject) => {
      if (!this.getLike(articleId)) {
        // not setLike before
        apiCall(
          "/cms/articleAttribute",
          "POST",
          {
            channel: 2, //渠道,参数(1.PC 2.APP 3.TV 4.一点通)
            articleType: 1, // 文章类型,参数(1.nw_cms_info 2.nw_yinong_info 3.rural_information)
            articleId: articleId,
            liked: 1
          },
          data => {
            pageData[articleId] = pageData[articleId] || {};
            pageData[articleId].setLike = true;
            this.setData(pageData);
            resolve();
          },
          (xhr, status) => {
            console.log("/cms/articleAttribute liked error : ", xhr, status);
            reject(xhr);
          }
        );
      }
      resolve();
    });
  }
};

export function getTag(typeId, columnId) {
  typeId = typeId || 1;
  return new Promise((resolve, reject) => {
    apiCall(
      "/cms/tag",
      "POST",
      {
        typeId,
        columnId
      },
      data => {
        resolve(data);
      },
      err => {
        reject(err);
      }
    );
  });
}

/***
 *
 * Hybrid App 接口调用
 * js 调用 android 方法
 * ios 调用 js 方法
 * window.webkit.messageHandlers.getLocation.postMessage("")
 *
 */
window.phone = detectPhone();
function alertFunc(text) {
  if (typeof android == "object") {
    android.showToast(text);
  } else if (app.dialog) {
    app.dialog.alert(text);
  } else {
    window.alert(text);
  }
}
window.alertFunc = alertFunc;

export let callIOS = func => {
  if (typeof window.webkit == "object") {
    if (func) func();
  }
};

export let callAndroid = (func, args = []) => {
  if (window.phone == "android") {
    if (typeof android == "object") {
      if (func) func(...args);
    } else {
      //alertFunc("全局没有找到 android 对象");
    }
  }
};

export let callOrigin = (iosFunc, androidFunc, args = []) => {
  //console.log("** callOrigin **");
  if (window.phone == "iphone" && typeof window.webkit == "object") {
    callIOS(iosFunc);
  } else if (window.phone == "android") {
    callAndroid(androidFunc, args);
  } else {
    //alertFunc("不是app环境无法调用");
  }
};

// 定位信息
window.uploadLocation = function(jsonStr) {
  //alertFunc("经纬度原始数据=" + JSON.stringify(jsonStr));
  if (!jsonStr) {
    return;
  }
  try {
    let json = JSON.parse(jsonStr);
    //alertFunc("try 经度=" + json[0].longitude + ", 纬度=" + json[0].latitude);
    dispatch(setLocation(json[0].longitude, json[0].latitude));
  } catch (e) {
    let json = jsonStr;
    //alertFunc("catch 经度=" + json[0].longitude + ", 纬度=" + json[0].latitude);
    dispatch(setLocation(json[0].longitude, json[0].latitude));
  }
};
export let uploadLocation = window.uploadLocation;

// 上传推送ID
window.uploadRegId = function(regId) {
  alertFunc(regId);
};
export let uploadRegId = window.uploadRegId;

// 获得扫描结果
// export let showCapture = window.showCapture;

// 分享回调
window.resultShare = function(code) {
  if (code == "分享成功") {
  } else if (code == "分享失败") {
  } else if (code == "分享取消") {
  }
  dispatch(showShare());
};
export let resultShare = window.resultShare;
