import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "framework7-icons";
import { Page, Link, Button } from "framework7-react";
import CategoryPicker from "../components/CategoryPicker";
import "framework7-icons";
import { apiCall, apiConfig } from "../api";
import "../css/pageAuthenticationResult.less";

class AuthenticationResult extends React.Component {
  constructor(props) {
    super(props);

    this.selectedCallback = this.selectedCallback.bind(this);

    let userinfo = this.props.userState.userinfo;
    let status = "waiting";
    if (userinfo.ubind == apiConfig.userBindWaiting) {
      status = "waiting";
    } else if (userinfo.ubind == apiConfig.userBindSuccess) {
      status = "success";
    } else if (userinfo.ubind == apiConfig.userBindFail) {
      status = "fail";
    }

    this.state = {
      status: status, // waiting success fail

      userGroup: "",
      initSelectedValue: [],
      groupNodes: [
        [
          { parentId: 0, id: 1, name: "浦东新区" },
          { parentId: 0, id: 2, name: "静安区" }
        ],
        [
          { parentId: 1, id: 21, name: "外冈镇" },
          { parentId: 1, id: 22, name: "嘉定工业区" },
          { parentId: 2, id: 27, name: "嘉定工业区2" }
        ],
        [
          { parentId: 21, id: 31, name: "仙龙村" },
          { parentId: 21, id: 32, name: "胜利村" },
          { parentId: 22, id: 33, name: "灯塔村" },
          { parentId: 22, id: 34, name: "朱家桥村" },
          { parentId: 27, id: 35, name: "黎明村" }
        ]
      ]
    };
  }

  componentDidMount() {
    this.isComponentMounted = true;
    const that = this;
    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;
    });
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.userState.userinfo.ugroup !=
      prevProps.userState.userinfo.ugroup
    ) {
      if (this.isComponentMounted) {
        this.setState({
          userGroup: this.props.userState.userinfo.ugroup
        });
      }
    }
  }

  selectedCallback(values, displayValues) {
    if (this.isComponentMounted) {
      this.setState({
        userGroup: displayValues[2]
      });
    }
  }

  nextStep() {
    this.$f7.views.main.router.navigate("/authenticationselectgroup", {
      props: {}
    });
  }

  render() {
    return (
      <Page name="AuthenticationResult">
        <div className="MyItem">
          <div>
            <div className="iconUser">姓名</div>
            <div>
              <div>{this.props.userState.userinfo.uname}</div>
            </div>
          </div>
          <div>
            <div className="iconGroup">所属村组</div>
            <div>
              <div>{this.props.userState.userinfo.ugroup}</div>
            </div>
          </div>
        </div>

        <div className={"statusShow " + this.state.status}>
          <div />
          {this.state.status == "waiting" && <div>等待信息员审核</div>}
          {this.state.status == "success" && <div>认证成功</div>}
          {this.state.status == "fail" && (
            <div>
              <div>认证失败</div>
              <div>您的信息未通过信息员审核，请确认所选村组是否正确</div>
            </div>
          )}
        </div>

        <div className="AsgComplete">
          <Button onClick={this.nextStep.bind(this)} id="aur-picker-user-group">
            重选村组
          </Button>
        </div>

        {/**
        <CategoryPicker
          nodes={this.state.groupNodes}
          parentElement="#aur-picker-user-group"
          selectedCallback={this.selectedCallback}
          initSelectedValue={this.state.initSelectedValue}
        />
        */}
      </Page>
    );
  }
}

function mapStateToProps(state) {
  const { userState, pageState } = state;
  return { userState, pageState };
}

AuthenticationResult.propTypes = {
  userState: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(AuthenticationResult);
