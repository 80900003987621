import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "framework7-icons";
import { Page, Link } from "framework7-react";
import "../css/pageAffair.less";
import { apiCall, apiConfig } from "../api";
import Img1 from "../images/sunny/im_sunny_1.png";
import Img1Gray from "../images/sunny/im_sunny_1_gray.png";
import Img2 from "../images/sunny/im_sunny_2.png";
import Img2Gray from "../images/sunny/im_sunny_2_gray.png";
import Img3 from "../images/sunny/im_sunny_3.png";
import Img3Gray from "../images/sunny/im_sunny_3_gray.png";
import Img4 from "../images/sunny/im_sunny_4.png";
import Img4Gray from "../images/sunny/im_sunny_4_gray.png";
import Img5 from "../images/sunny/im_sunny_5.png";
import Img5Gray from "../images/sunny/im_sunny_5_gray.png";
import Img6 from "../images/sunny/im_sunny_6.png";
import Img6Gray from "../images/sunny/im_sunny_6_gray.png";
import Img7 from "../images/sunny/im_sunny_7.png";
import Img7Gray from "../images/sunny/im_sunny_7_gray.png";
import Img8 from "../images/sunny/im_sunny_8.png";
import Img8Gray from "../images/sunny/im_sunny_8_gray.png";
import Img9 from "../images/sunny/im_sunny_9.png";
import Img9Gray from "../images/sunny/im_sunny_9_gray.png";
import Img10 from "../images/sunny/im_sunny_10.png";
import Img10Gray from "../images/sunny/im_sunny_10_gray.png";
import Img11 from "../images/sunny/im_sunny_11.png";
import Img11Gray from "../images/sunny/im_sunny_11_gray.png";
import Img12 from "../images/sunny/im_sunny_12.png";
import Img12Gray from "../images/sunny/im_sunny_12_gray.png";
import Img13 from "../images/sunny/im_sunny_13.png";
import Img13Gray from "../images/sunny/im_sunny_13_gray.png";
import Img14 from "../images/sunny/im_sunny_15.png";
import Img14Gray from "../images/sunny/im_sunny_15_gray.png";
import Img15 from "../images/sunny/im_sunny_16.png";
import Img15Gray from "../images/sunny/im_sunny_16_gray.png";
import Img16 from "../images/sunny/im_sunny_17.png";
import Img16Gray from "../images/sunny/im_sunny_17_gray.png";
import Img17 from "../images/sunny/im_sunny_18.png";
import Img17Gray from "../images/sunny/im_sunny_18_gray.png";
import Img18 from "../images/sunny/im_sunny_19.png";
import Img18Gray from "../images/sunny/im_sunny_19_gray.png";
import Img19 from "../images/sunny/im_sunny_20.png";
import Img19Gray from "../images/sunny/im_sunny_20_gray.png";
import Img20 from "../images/sunny/im_sunny_16.png";
import Img20Gray from "../images/sunny/im_sunny_16_gray.png";
import Img21 from "../images/sunny/im_sunny_14.png";
import Img21Gray from "../images/sunny/im_sunny_14_gray.png";

class Affair extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      versions: this.props.userState.userinfo.versions
        ? this.props.userState.userinfo.versions
        : apiConfig.typeAccountCountry,
      //本村概况
      hasCountrySummary: false,
      //村务通知
      hasAffairNotice: false,
      //办事指南
      hasGuideline: false,
      //村规民约
      hasRegulations: false,
      //公共设施
      hasUtilities: false,
      //村委成员
      hasCommitteeMember: false,
      //村务监督
      hasSupervision: false,
      //年度目标
      hasAnnualTarget: false,
      //政策落实
      hasPolicyImplementation: false,
      //财务收支
      hasFinancial: false,
      //物资资金接收管理
      hasMaterial: false,
      //协助政府开展工作
      hasAssist: false,
      //村民（代表）会议决定事项
      hasMeetingDecision: false,
      //其他事项
      hasOther: false,
      //土地流转
      hasLand: false
    };
  }

  componentDidMount() {
    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      this.isComponentMounted = true;

      apiCall(
        "/app/getSunBis",
        "POST",
        {
          ogId: "B55F49F8EB9E3445B77F6F6F3C158A5E"
        },
        data => {
          if (data.code == apiConfig.errorCode1000) {
            this.$f7.dialog.alert(data.msg);
          } else {
            let newState = {};
            data.content.columnList.forEach(column => {
              if (column.columnId == apiConfig.countrySummaryColumnId) {
                newState.hasCountrySummary = true;
              }
              if (column.columnId == apiConfig.affairNoticeColumnId) {
                newState.hasAffairNotice = true;
              }
              if (column.columnId == apiConfig.guidelineColumnId) {
                newState.hasGuideline = true;
              }
              if (column.columnId == apiConfig.regulationsColumnId) {
                newState.hasRegulations = true;
              }
              if (column.columnId == apiConfig.utilitiesColumnId) {
                newState.hasUtilities = true;
              }
              if (column.columnId == apiConfig.committeeMemberColumnId) {
                newState.hasCommitteeMember = true;
              }
              if (column.columnId == apiConfig.supervisionColumnId) {
                newState.hasSupervision = true;
              }
              if (column.columnId == apiConfig.annualTargetColumnId) {
                newState.hasAnnualTarget = true;
              }
              if (column.columnId == apiConfig.policyImplementationColumnId) {
                newState.hasPolicyImplementation = true;
              }
              if (column.columnId == apiConfig.financialColumnId) {
                newState.hasFinancial = true;
              }
              if (column.columnId == apiConfig.materialColumnId) {
                newState.hasMaterial = true;
              }
              if (column.columnId == apiConfig.assistColumnId) {
                newState.hasAssist = true;
              }
              if (column.columnId == apiConfig.meetingDecisionColumnId) {
                newState.hasMeetingDecision = true;
              }
              if (column.columnId == apiConfig.otherColumnId) {
                newState.hasOther = true;
              }
              if (column.columnId == apiConfig.landColumnId) {
                newState.hasLand = true;
              }
            });
            if (this.isComponentMounted) {
              this.setState(newState);
            }
          }
        },
        (xhr, status) => {
          console.log(xhr, status);
          this.$f7.dialog.alert(JSON.stringify(xhr));
        }
      );
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.userState.userinfo.versions !=
      prevProps.userState.userinfo.versions
    ) {
      if (this.isComponentMounted) {
        this.setState({
          versions: this.props.userState.userinfo.versions
        });
      }
    }
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  gotoInfo(affairType) {
    let url = "/affairinfo/" + affairType;
    if (
      [
        apiConfig.countrySummaryColumnId,
        apiConfig.affairNoticeColumnId,
        apiConfig.guidelineColumnId,
        apiConfig.regulationsColumnId,
        apiConfig.utilitiesColumnId,
        apiConfig.committeeMemberColumnId,
        apiConfig.supervisionColumnId,
        apiConfig.annualTargetColumnId,
        apiConfig.policyImplementationColumnId,
        apiConfig.materialColumnId,
        apiConfig.assistColumnId,
        apiConfig.meetingDecisionColumnId,
        apiConfig.otherColumnId,
        apiConfig.landColumnId
      ].find(element => {
        return element == affairType;
      })
    ) {
      url = "/affairinfocommon/" + affairType;
    }
    this.$f7.views.main.router.navigate(url, {
      props: { affairType }
    });
  }

  render() {
    return (
      <Page name="Affair" className="Affair">
        <div>
          <div className="AffairTitle">阳光村务</div>
          <div>
            <Link
              className={this.state.hasCountrySummary ? "" : "noContent"}
              onClick={
                this.state.hasCountrySummary
                  ? this.gotoInfo.bind(this, apiConfig.countrySummaryColumnId)
                  : ""
              }>
              <img src={this.state.hasCountrySummary ? Img1 : Img1Gray} />
              <div>本村概况</div>
            </Link>
            <Link
              className={this.state.hasAffairNotice ? "" : "noContent"}
              onClick={
                this.state.hasAffairNotice
                  ? this.gotoInfo.bind(this, apiConfig.affairNoticeColumnId)
                  : ""
              }>
              <img src={this.state.hasAffairNotice ? Img2 : Img2Gray} />
              <div>村务（党务）动态</div>
            </Link>
            <Link
              className={this.state.hasGuideline ? "" : "noContent"}
              onClick={
                this.state.hasGuideline
                  ? this.gotoInfo.bind(this, apiConfig.guidelineColumnId)
                  : ""
              }>
              <img src={this.state.hasGuideline ? Img3 : Img3Gray} />
              <div>办事指南</div>
            </Link>
            <Link
              className={this.state.hasRegulations ? "" : "noContent"}
              onClick={
                this.state.hasRegulations
                  ? this.gotoInfo.bind(this, apiConfig.regulationsColumnId)
                  : ""
              }>
              <img src={this.state.hasRegulations ? Img4 : Img4Gray} />
              <div>村规民约</div>
            </Link>
          </div>
          <div>
            <Link
              className={this.state.hasUtilities ? "" : "noContent"}
              onClick={
                this.state.hasUtilities
                  ? this.gotoInfo.bind(this, apiConfig.utilitiesColumnId)
                  : ""
              }>
              <img src={this.state.hasUtilities ? Img5 : Img5Gray} />
              <div>公共设施</div>
            </Link>
            <Link
              className={this.state.hasCommitteeMember ? "" : "noContent"}
              onClick={
                this.state.hasCommitteeMember
                  ? this.gotoInfo.bind(this, apiConfig.committeeMemberColumnId)
                  : ""
              }>
              <img src={this.state.hasCommitteeMember ? Img6 : Img6Gray} />
              <div>村委成员</div>
            </Link>
            <Link
              className={this.state.hasSupervision ? "" : "noContent"}
              onClick={
                this.state.hasSupervision
                  ? this.gotoInfo.bind(this, apiConfig.supervisionColumnId)
                  : ""
              }>
              <img src={this.state.hasSupervision ? Img7 : Img7Gray} />
              <div>村务监督</div>
            </Link>
            <Link
              className={this.state.hasAnnualTarget ? "" : "noContent"}
              onClick={
                this.state.hasAnnualTarget
                  ? this.gotoInfo.bind(this, apiConfig.annualTargetColumnId)
                  : ""
              }>
              <img src={this.state.hasAnnualTarget ? Img8 : Img8Gray} />
              <div>年度目标</div>
            </Link>
          </div>
          <div>
            <Link
              className={this.state.hasPolicyImplementation ? "" : "noContent"}
              onClick={
                this.state.hasPolicyImplementation
                  ? this.gotoInfo.bind(
                      this,
                      apiConfig.policyImplementationColumnId
                    )
                  : ""
              }>
              <img src={this.state.hasPolicyImplementation ? Img9 : Img9Gray} />
              <div>政策落实</div>
            </Link>
            <Link
              className={this.state.hasFinancial ? "" : "noContent"}
              onClick={
                this.state.hasFinancial
                  ? this.gotoInfo.bind(this, apiConfig.financialColumnId)
                  : ""
              }>
              <img src={this.state.hasFinancial ? Img10 : Img10Gray} />
              <div>财务收支</div>
            </Link>
            <Link
              className={this.state.hasMaterial ? "" : "noContent"}
              onClick={
                this.state.hasMaterial
                  ? this.gotoInfo.bind(this, apiConfig.materialColumnId)
                  : ""
              }>
              <img src={this.state.hasMaterial ? Img11 : Img11Gray} />
              <div>物资资金接收管理</div>
            </Link>
            <Link
              className={this.state.hasAssist ? "" : "noContent"}
              onClick={
                this.state.hasAssist
                  ? this.gotoInfo.bind(this, apiConfig.assistColumnId)
                  : ""
              }>
              <img src={this.state.hasAssist ? Img12 : Img12Gray} />
              <div>协助政府开展工作</div>
            </Link>
          </div>
          <div>
            <Link
              className={this.state.hasMeetingDecision ? "" : "noContent"}
              onClick={
                this.state.hasMeetingDecision
                  ? this.gotoInfo.bind(this, apiConfig.meetingDecisionColumnId)
                  : ""
              }>
              <img src={this.state.hasMeetingDecision ? Img13 : Img13Gray} />
              <div>村民（代表）会议决定事项</div>
            </Link>
            {this.state.versions == 2 && (
              <Link onClick={this.gotoInfo.bind(this, 14)}>
                <img src={Img21} />
                <div>涉农补贴</div>
              </Link>
            )}
            <Link onClick={this.gotoInfo.bind(this, 15)}>
              <img src={Img14} />
              <div>集体“三资”</div>
            </Link>
            <Link
              className={this.state.hasLand ? "" : "noContent"}
              onClick={
                this.state.hasLand
                  ? this.gotoInfo.bind(this, apiConfig.landColumnId)
                  : ""
              }>
              <img src={this.state.hasLand ? Img15 : Img15Gray} />
              <div>土地流转（村级）</div>
            </Link>
            {this.state.versions == apiConfig.typeAccountPerson && (
              <Link
                className={this.state.hasOther ? "" : "noContent"}
                onClick={
                  this.state.hasOther
                    ? this.gotoInfo.bind(this, apiConfig.otherColumnId)
                    : ""
                }>
                <img src={this.state.hasOther ? Img16 : Img16Gray} />
                <div>其他事项</div>
              </Link>
            )}
          </div>
          {this.state.versions == apiConfig.typeAccountCountry && (
            <React.Fragment>
              <div>
                <Link
                  className={this.state.hasOther ? "" : "noContent"}
                  onClick={
                    this.state.hasOther
                      ? this.gotoInfo.bind(this, apiConfig.otherColumnId)
                      : ""
                  }>
                  <img src={this.state.hasOther ? Img16 : Img16Gray} />
                  <div>其他事项</div>
                </Link>
                <Link />
                <Link />
                <Link />
              </div>
            </React.Fragment>
          )}
        </div>

        {this.state.versions == apiConfig.typeAccountPerson && (
          <div>
            <div className="AffairTitle">个人详单</div>
            <div>
              <Link onClick={this.gotoInfo.bind(this, 14)}>
                <img src={Img17} />
                <div>涉农补贴</div>
              </Link>
              <Link onClick={this.gotoInfo.bind(this, 18)}>
                {/**onClick={this.gotoInfo.bind(this, 18)} */}
                <img src={Img18} />
                <div>行政许可处罚</div>
              </Link>
              <Link className="noContent">
                {/**onClick={this.gotoInfo.bind(this, 19)} */}
                <img src={Img19Gray} />
                <div>农业保险</div>
              </Link>
              <Link
                className={"hidden " + (this.state.hasLand ? "" : "noContent")}
                onClick={
                  this.state.hasLand
                    ? this.gotoInfo.bind(this, apiConfig.landColumnId)
                    : ""
                }>
                <img src={this.state.hasLand ? Img20 : Img20Gray} />
                <div>土地流转</div>
              </Link>
            </div>
          </div>
        )}
      </Page>
    );
  }
}

function mapStateToProps(state) {
  const { userState } = state;
  return { userState };
}

Affair.propTypes = {
  userState: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(Affair);
