import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "framework7-icons";
import {
  Page,
  Swiper,
  SwiperSlide,
  Toolbar,
  Tabs,
  Tab,
  Link
} from "framework7-react";
// import F2 from "@antv/f2";
import "../css/pagePrices.less";
import { apiCall, apiConfig } from "../api";
import { arrayRemove } from "../../public/js/utils";
import { updateFocusPrices } from "../actions/UserAction";
import ImgDefault from "../images/default_43.png";
// import Img_19 from "../images/prices/19.jpg";
// import Img_32 from "../images/prices/32.jpg";
// import Img_34 from "../images/prices/34.jpg";
// import Img_55 from "../images/prices/55.jpg";
// import Img_129 from "../images/prices/129.jpg";
// import Img_187 from "../images/prices/187.jpg";
// import Img_194 from "../images/prices/194.jpg";
// import Img_203 from "../images/prices/203.jpg";
// import Img_209 from "../images/prices/209.jpg";
// import Img_212 from "../images/prices/212.jpg";

// let defaultImgId = {
//   22: Img_194,
//   20: Img_55,
//   7: Img_34,
//   8: Img_32,
//   23: Img_19,
//   11: Img_129,
//   12: Img_203,
//   5: Img_209,
//   13: Img_212,
//   6: Img_187
// };

class Prices extends React.Component {
  constructor(props) {
    super(props);
    this.editPricenavbar = this.editPricenavbar.bind(this);
    this.addPricenavbar = this.addPricenavbar.bind(this);
    this.cleanPricenavbar = this.cleanPricenavbar.bind(this);
    this.setUserCare = this.setUserCare.bind(this);

    this.state = {
      tabIndex: 1,
      isEditPricenavbar: false,
      imgShow: {},
      addedList: [
        // {
        //   id: 1,
        //   text: "青菜",
        //   img: im_cai_qingcai
        // },
      ],
      unaddedList: []
    };
  }

  handleChildClick(index) {
    //console.log("handleChildClick-" + index);
    const app = this.$f7;
    const $ = this.$$;
    const swiper = app.swiper.get(".PricesNavbarSwiper");
    swiper.slides.map(function(index) {
      $(swiper.slides[index]).removeClass("swiper-active");
    });
    $(swiper.slides[index]).addClass("swiper-active");
    swiper.update();
    swiper.slideTo(index, 500);
    //swiper.slideTo(swiper.clickedIndex, 500);

    if (this.isComponentMounted) {
      this.setState(() => ({
        imgShow: this.state.addedList[index]
      }));
    }
  }

  getData() {
    apiCall(
      "/price/getDataByAPP",
      "POST",
      {
        userId: this.props.userState.userinfo.uid
          ? String(this.props.userState.userinfo.uid)
          : ""
      },
      data => {
        //console.log(data);
        if (data.code == apiConfig.errorCode1000) {
          this.$f7.dialog.alert(data.msg);
        } else {
          let changeState = {};

          [
            {
              data: data.content.like,
              name: "addedList"
            },
            {
              data: data.content.unlike,
              name: "unaddedList"
            }
          ].map(list => {
            let listData = list.data.pfjData.map(pfj => {
              // let findTtj = {};
              // // 接口方表示田头价只能去unlike里面获取
              // // for (let i = 0; i < list.data.ttjData.length; i++) {
              //   let _ttj = list.data.ttjData[i];
              //   if (_ttj.sortId == pfj.sortId) {
              //     findTtj = _ttj;
              //     break;
              //   }
              // }
              let findTtj =
                data.content.unlike.ttjData.find(element => {
                  // 居然批发价和田头价的sortId都不一样
                  //return element.sortId == pfj.sortId;
                  return element.productName.indexOf(pfj.productName) > -1;
                }) || {};

              return {
                id: pfj.sortId,
                text: pfj.productName,
                //defaultImgId[pfj.sortId]
                img: pfj.image ? pfj.image : ImgDefault,
                specifications: pfj.specifications
                  ? pfj.specifications
                  : "未上报",
                unit: pfj.unit ? pfj.unit : "",
                minPricePfj: pfj.minPrice ? pfj.minPrice : "",
                minMarketNamePfj: pfj.minMarketName
                  ? pfj.minMarketName
                  : "未上报",
                maxPricePfj: pfj.maxPrice ? pfj.maxPrice : "",
                maxMarketNamePfj: pfj.maxMarketName
                  ? pfj.maxMarketName
                  : "未上报",
                avgPricePfj: pfj.avgPrice ? pfj.avgPrice : "",
                priceDatePfj: pfj.priceDate ? pfj.priceDate : "未上报",
                minPriceTtj:
                  JSON.stringify(findTtj) == "{}" ? "" : findTtj.minPrice,
                maxPriceTtj:
                  JSON.stringify(findTtj) == "{}" ? "" : findTtj.maxPrice,
                avgPriceTtj:
                  JSON.stringify(findTtj) == "{}" ? "" : findTtj.avgPrice,
                priceDateTtj: findTtj.priceDate ? findTtj.priceDate : "未上报"
              };
            });
            changeState[list.name] = listData;

            if (list.name == "addedList") {
              changeState.imgShow = listData.length ? listData[0] : {};
            }
          });

          if (this.isComponentMounted) {
            this.setState(changeState, () => {
              const swiper = this.$f7.swiper.get(".PricesNavbarSwiper");
              swiper.update();
            });
          }
        }
      },
      err => {
        //console.log(err);
        this.$f7.dialog.alert(JSON.stringify(err));
      }
    );
  }

  componentDidMount() {
    this.isComponentMounted = true;
    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      // const swiper = app.swiper.get(".PricesNavbarSwiper");
      // swiper.on("tap", () => {
      //   swiper.slides.map(function(index) {
      //     $(swiper.slides[index]).removeClass("swiper-active");
      //   });
      //   console.log(swiper.clickedIndex);
      //   $(swiper.slides[swiper.clickedIndex]).addClass("swiper-active");
      //   //swiper.slideTo(swiper.clickedIndex, 500);
      //   this.setState(() => ({
      //     imgShow: this.state.addedList[swiper.clickedIndex]
      //   }));
      // });

      // const data = [
      //   { date: "08/12", value: 4.3 },
      //   { date: "08/13", value: 4.75 },
      //   { date: "08/14", value: 4.25 },
      //   { date: "08/15", value: 5.5 },
      //   { date: "08/16", value: 5.25 },
      //   { date: "08/17", value: 5.6 },
      //   { date: "08/18", value: 5.4 }
      // ];
      // const chart1 = new F2.Chart({
      //   id: "priceTrend1",
      //   pixelRatio: window.devicePixelRatio
      // });
      // chart1.source(data);
      // chart1.line().position("date*value");
      // chart1.area().position("date*value");
      // chart1.render();

      // const chart2 = new F2.Chart({
      //   id: "priceTrend2",
      //   pixelRatio: window.devicePixelRatio
      // });
      // chart2.source(data);
      // chart2.line().position("date*value");
      // chart2.area().position("date*value");
      // chart2.render();

      // const chart3 = new F2.Chart({
      //   id: "priceTrend3",
      //   pixelRatio: window.devicePixelRatio
      // });
      // chart3.source(data);
      // chart3.line().position("date*value");
      // chart3.area().position("date*value");
      // chart3.render();
    });
    this.getData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userState.userinfo.uid != this.props.userState.userinfo.uid) {
      this.getData();
    }
  }

  setUserCare(careList, callback) {
    apiCall(
      "/price/setUserCare",
      "POST",
      {
        userId: this.props.userState.userinfo.uid
          ? String(this.props.userState.userinfo.uid)
          : "",
        careData: JSON.stringify(careList)
      },
      data => {
        if (data.code == apiConfig.errorCode1000) {
          this.$f7.dialog.alert(data.msg);
        } else {
          if (callback) callback();
        }
      },
      err => {
        //console.log(err);
        this.$f7.dialog.alert(JSON.stringify(err));
      },
      true
    );
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  editPricenavbar() {
    if (!this.isComponentMounted) {
      return;
    }
    this.setState(prevState => ({
      isEditPricenavbar: !prevState.isEditPricenavbar
    }));
  }

  addPricenavbar(id) {
    let addedList = this.state.addedList.slice();
    let unaddedList = this.state.unaddedList.slice();
    let added = arrayRemove(unaddedList, id, "id");
    addedList.push(added[0]);
    this.setUserCare(
      addedList.map(item => {
        return parseInt(item.id);
      }),
      () => {
        if (!this.isComponentMounted) {
          return;
        }
        this.setState(
          prevState => {
            let state = {
              addedList: addedList,
              unaddedList: unaddedList
            };
            if (addedList.length == 1) {
              state.imgShow = addedList[0];
            }
            return state;
          },
          () => {
            this.props.dispatch(updateFocusPrices(addedList));
          }
        );
      }
    );
  }

  cleanPricenavbar(id) {
    if (!this.isComponentMounted) {
      return;
    }
    if (this.state.addedList.length <= 1) {
      this.$f7.toast
        .create({
          text: "至少关注一种农产品",
          position: "center",
          closeTimeout: 2000,
          on: {
            close: () => {}
          }
        })
        .open();
      return;
    }
    let addedList = this.state.addedList.slice();
    let unaddedList = this.state.unaddedList.slice();
    const cleaned = arrayRemove(addedList, id, "id");
    unaddedList.push(cleaned[0]);

    this.setUserCare(
      addedList.map(item => {
        return parseInt(item.id);
      }),
      () => {
        if (!this.isComponentMounted) {
          return;
        }
        this.setState(
          prevState => {
            let changeState = {
              addedList: addedList,
              unaddedList: unaddedList
            };
            if (cleaned[0].id == this.state.imgShow.id) {
              changeState.imgShow = addedList[0];
            }
            return changeState;
          },
          () => {
            this.props.dispatch(updateFocusPrices(addedList));
          }
        );
      }
    );
  }

  tabClick(index) {
    if (this.isComponentMounted) {
      this.setState({
        tabIndex: index
      });
    }
  }

  render() {
    return (
      <Page name="Prices" className="Prices">
        <div className="PricesNavbar">
          <Swiper
            params={{
              speed: 500,
              slidesPerView: "auto",
              slidesOffsetBefore: 15,
              slidesOffsetAfter: 15
              // preventClicks: true,
              // freeMode: true
            }}
            className="PricesNavbarSwiper">
            {this.state.addedList.map((item, index) => (
              <div
                className={
                  (this.state.imgShow.id == item.id ? "swiper-active" : "") +
                  " swiper-slide"
                }
                key={item.id}
                onClick={this.handleChildClick.bind(this, index)}>
                <SwiperSlide>{item.text}</SwiperSlide>
              </div>

              // <SwiperSlide
              //   key={index}
              //   className={index == 0 ? "swiper-active" : ""}
              // >
              //   {item.text}
              // </SwiperSlide>
            ))}
          </Swiper>
          <Link popupOpen=".pricenavbar-popup" />
        </div>

        <div className="PricesImg">
          <div className="PricesImgPicture">
            {this.state.imgShow.img && <img src={this.state.imgShow.img} />}
          </div>

          {JSON.stringify(this.state.imgShow) != "{}" && (
            <div className="PricesImgSpecifications">
              规格：{this.state.imgShow.specifications}
            </div>
          )}
        </div>

        {/* &nbsp;&nbsp;&nbsp;&nbsp;计量单位：{this.state.imgShow.unit} */}

        <div className="PricesToolbar">
          <div className="Tab">
            <div
              onClick={this.tabClick.bind(this, 1)}
              className={this.state.tabIndex == 1 ? "focus" : ""}>
              批发价
            </div>
            <div
              onClick={this.tabClick.bind(this, 2)}
              className={this.state.tabIndex == 2 ? "focus" : ""}>
              田头价
            </div>
          </div>
          {/**
          <Toolbar tabbar>
            <Link tabLink="#price2" tabLinkActive>
              批发价
            </Link>
            <Link tabLink="#price3">田头价</Link>
          </Toolbar>
         */}
          <div
            className={
              this.state.tabIndex == 1 ? "TabContent " : "TabContent hide"
            }>
            <div>
              <div>{/**今日市场行情*/}</div>
              <div>
                <div className="gray">
                  <div>日期</div>
                  <div>{this.state.imgShow.priceDatePfj}</div>
                </div>
                <div>
                  <div>最高价</div>
                  {this.state.imgShow.maxPricePfj && (
                    <div>
                      {this.state.imgShow.maxPricePfj}
                      {this.state.imgShow.unit}
                    </div>
                  )}
                  {!this.state.imgShow.maxPricePfj && <div>未上报</div>}
                </div>
                {/**
                  <div>
                  <div>最高价的上报点</div>
                  <div>{this.state.imgShow.maxMarketNamePfj}</div>
                </div>
                 */}
                <div>
                  <div>最低价</div>
                  {this.state.imgShow.minPricePfj && (
                    <div>
                      {this.state.imgShow.minPricePfj}
                      {this.state.imgShow.unit}
                    </div>
                  )}
                  {!this.state.imgShow.minPricePfj && <div>未上报</div>}
                </div>
                {/** 
                <div>
                  <div>最低价的上报点</div>
                  <div>{this.state.imgShow.minMarketNamePfj}</div>
                </div>
                */}
                <div>
                  <div>均价</div>
                  {this.state.imgShow.avgPricePfj && (
                    <div>
                      {this.state.imgShow.avgPricePfj}
                      {this.state.imgShow.unit}
                    </div>
                  )}
                  {!this.state.imgShow.avgPricePfj && <div>未上报</div>}
                </div>
              </div>
              {/** 
                <div>
                  <div>7日平均价格走势</div>
                  <div>
                    <canvas id="priceTrend2" />
                  </div>
                </div>
                */}
            </div>
          </div>
          <div
            className={
              this.state.tabIndex == 2 ? "TabContent " : "TabContent hide"
            }>
            <div>
              <div>{/**今日市场行情*/}</div>
              <div>
                <div className="gray">
                  <div>日期</div>
                  <div>{this.state.imgShow.priceDateTtj}</div>
                </div>
                <div>
                  <div>价格</div>
                  {this.state.imgShow.avgPriceTtj && (
                    <div>
                      {this.state.imgShow.avgPriceTtj}
                      {this.state.imgShow.unit}
                    </div>
                  )}
                  {!this.state.imgShow.avgPriceTtj && <div>未上报</div>}
                </div>
                {/**
                  <div>
                  <div>最高价</div>
                  {this.state.imgShow.maxPriceTtj && (
                    <div>
                      {this.state.imgShow.maxPriceTtj}
                      {this.state.imgShow.unit}
                    </div>
                  )}
                  {!this.state.imgShow.maxPriceTtj && <div>未上报</div>}
                </div>
                <div>
                  <div>最低价</div>
                  {this.state.imgShow.minPriceTtj && (
                    <div>
                      {this.state.imgShow.minPriceTtj}
                      {this.state.imgShow.unit}
                    </div>
                  )}
                  {!this.state.imgShow.minPriceTtj && <div>未上报</div>}
                </div>
                 */}
              </div>
              {/** 
          <div>
            <div>7日平均价格走势</div>
            <div>
              <canvas id="priceTrend3" />
            </div>
          </div>
          */}
            </div>
          </div>
        </div>

        <div className="popup pricenavbar-popup">
          <div className="view">
            <div className="page">
              <div className="page-content">
                <div>
                  <a className="link popup-close" />
                </div>
                <div>
                  <div>我关注的</div>
                  <div
                    className="editPricenavbar"
                    onClick={this.editPricenavbar}>
                    {this.state.isEditPricenavbar ? "完成" : "编辑"}
                  </div>
                </div>
                <div className="addedPricenavbar">
                  {this.state.addedList.map((item, key) => {
                    return (
                      <div key={item.id}>
                        {item.text}
                        <span
                          className={this.state.isEditPricenavbar ? "show" : ""}
                          onClick={this.cleanPricenavbar.bind(this, item.id)}
                        />
                      </div>
                    );
                  })}
                </div>
                <div>
                  <div>
                    未关注的
                    <span>点击添加关注</span>
                  </div>
                </div>
                <div className="unaddedPricenavbar">
                  {this.state.unaddedList.map((item, key) => {
                    return (
                      <div
                        key={item.id}
                        onClick={this.addPricenavbar.bind(this, item.id)}>
                        +{item.text}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Page>
    );
  }
}

function mapStateToProps(state) {
  const { userState } = state;
  return { userState };
}

Prices.propTypes = {
  userState: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(Prices);
