import React from "react";
import { Link } from "framework7-react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "framework7-icons";
import "./AutoImg.less";
import ImgDefault from "../../images/default_43.png";

class AutoImg extends React.Component {
  constructor(props) {
    super(props);

    this.isAutoImgMounted = false;

    this.getImgWidthAndHeight = this.getImgWidthAndHeight.bind(this);

    this.autoImg = React.createRef();

    this.state = {
      style: {}
    };
  }

  componentDidMount() {
    this.$f7ready(async f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      this.isAutoImgMounted = true;

      const designRatio = this.props.ratio ? this.props.ratio : 1;
      const outerWidth = this.props.outerWidth
        ? this.props.outerWidth
        : this.autoImg.current.clientWidth;

      await this.getImgWidthAndHeight(ImgDefault, designRatio, outerWidth);
      if (this.props.src) {
        await this.getImgWidthAndHeight(
          this.props.src,
          designRatio,
          outerWidth
        );
      }
    });
  }

  componentWillUnmount() {
    this.isAutoImgMounted = false;
  }

  getImgWidthAndHeight(imgsrc, designRatio, outerWidth) {
    const self = this;
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.onload = function() {
        let style = {
          backgroundImage: "url(" + imgsrc + ")",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          height: Math.floor((1 / designRatio) * outerWidth) + "px"
        };
        if (designRatio > this.width / this.height) {
          style.backgroundSize = "100% auto";
        } else if (designRatio < this.width / this.height) {
          style.backgroundSize = "auto 100%";
        } else {
          style.backgroundSize = "100% 100%";
        }

        if (self.isAutoImgMounted) {
          self.setState(prevState => ({
            style: style
          }));
        }

        resolve({ width: this.width, height: this.height });
      };
      img.onerror = function(e) {
        reject(e);
      };
      img.src = imgsrc;
    });
  }

  render() {
    return (
      <div className="AutoImg" ref={this.autoImg} style={this.state.style} />
    );
  }
}

function mapStateToProps(state) {
  const { userState, pageState } = state;
  return { userState, pageState };
}

AutoImg.propTypes = {
  userState: PropTypes.object.isRequired,
  pageState: PropTypes.object.isRequired
  //src: PropTypes.string.isRequired,
  //ratio: PropTypes.number.isRequired
};

export default connect(mapStateToProps)(AutoImg);
