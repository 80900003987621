// 接口文档地址
// http://47.100.176.155:9090/swagger-ui.html
// http://yn12316.shac.cn/ynpt/swagger-ui.html

// 测试地址
// http://180.167.43.126:82/dist/
// http://180.167.43.126:82/distcun/

// 更改后的测试地址
// http://180.167.43.126:82/app/
// http://180.167.43.126:82/cun/

// 正式地址 http://116.228.18.47/
// http://yn12316.shac.cn/app/
// http://yn12316.shac.cn/cun/

// 身份认证
// http://yn12316.shac.cn:8080/yn_gateway/oauth/realName?mobile=17316599313&channel=app

// 身份验证回调地址
// http://180.167.43.126:82/dist/authenticationselectgroup
// http://180.167.43.126:82/distcun/authenticationselectgroup

// 更改后的测试身份验证回调地址
// http://180.167.43.126:82/app/authenticationselectgroup
// http://180.167.43.126:82/cun/authenticationselectgroup

// 正式身份验证回调地址
// http://yn12316.shac.cn/app/authenticationselectgroup
// http://yn12316.shac.cn/cun/authenticationselectgroup

// http://127.0.0.1:9090/yinong/yinongGetkg?longitude=121.48941&latitude=31.40527

// http://yn12316.shac.cn/app/
// http://47.100.176.155:9090

// ios下载 https://www.pgyer.com/ASeh

// http://localhost:8000/communityinfo/31011401404

let subpath = "/app"; // /app  /cun

module.exports = {
  subpath: subpath,

  token: "72a99eb2-4b3d-4fa1-b397-fbe8592c8848",

  apiBaseUrl: "http://yn12316.shac.cn/ynpt",
  pageSize: 20,
  channelId: 1, // 1 PC门户 2 手机APP 3 一点通

  errorCode1000: "1000",
  errorCode9999: "9999",

  // 个人
  typeAccountPerson: 1,
  // 村级
  typeAccountCountry: 2,

  // 标准社
  typeCommunityStand: 1,
  // 专业社
  typeCommunityProfessional: 2,

  // 用户未实名
  userStateUnReal: 1,
  // 用户已实名
  userStateReal: 2,

  // 用户绑定村组审核中
  userBindWaiting: 0,
  // 用户绑定村组已通过
  userBindSuccess: 1,
  // 用户绑定村组已否决
  userBindFail: 2,

  // 乡村风情 美丽乡村、乡村振兴示范村
  countryColumnId: 6,
  // 地产农产品
  featureColumnId: 7,
  // 旅游产品 乡村休闲游
  siteColumnId: 8,
  // 农业文化 乡村文化
  cultureColumnId: 9,
  // 益农社动态
  communityColumnId: 27,
  // 农事服务
  farmserviceColumnId: 28,

  // 农业预警
  affairWarnColumnId: 11,
  // 村务通知
  affairNoticeColumnId: 32,
  // 本村概况
  countrySummaryColumnId: 34,
  // 办事指南
  guidelineColumnId: 35,
  // 村规民约
  regulationsColumnId: 36,
  // 公共设施
  utilitiesColumnId: 37,
  // 村委成员
  committeeMemberColumnId: 38,
  // 村务监督
  supervisionColumnId: 39,
  // 年度目标
  annualTargetColumnId: 40,
  // 政策落实
  policyImplementationColumnId: 41,
  // 财务收支
  financialColumnId: 42,
  // 物资资金接收管理
  materialColumnId: 43,
  // 协助政府开展工作
  assistColumnId: 44,
  // 村民（代表）会议决定事项
  meetingDecisionColumnId: 45,
  // 其他事项
  otherColumnId: 46,
  // 土地流转
  landColumnId: 49,

  // 种植类
  farmColumnId: 693,
  // 畜牧类
  animalColumnId: 694,
  // 水产类
  waterColumnId: 695,
  // 农机类
  machineColumnId: 696,
  // 农资类
  estateColumnId: 697,
  // 农经类
  economyColumnId: 698,
  // 其他
  infoOtherColumnId: 701,

  // 农产品安全 宠物园艺 政策答疑 实时农情
  consulateColumnId: 101
};

/**
栏目ID	栏目
1	新闻公告
2	三农资讯
3	市场行情
4	个人中心
5	新闻详情
6	乡村风情
7	特色农产品
8	旅游产品
9	农业文化
10	政策资讯
11	农业预警
12	市场动态
13	价格行情
14	村务公开
15	三资公示
16	涉农账单
17	电信郊区优惠套餐
18	村规民约
19	村务活动
20	2017年示范村
21	2016年示范村
22	2015年示范村
23	2014年示范村
24	专业社
25	标准社
34 本村概况
35 办事指南
36 村规民约
37 公共设施
38 村委成员
39 村务监督
40 年度目标
41 政策落实
42 财务收支
43 物资资金接收管理
44 协助政府开展工作
45 村民（代表）会议决定事项
46 其他事项
47 村务通知
48 集体三资
49 土地流转
50 涉农补贴

*/
