import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "framework7-icons";
import { Page, Link } from "framework7-react";
import CategoryPicker from "../components/CategoryPicker";
import "framework7-icons";
import { apiCall, apiConfig } from "../api";
import "../css/pageSetting.less";
import { logout } from "../actions/UserAction";

class Setting extends React.Component {
  constructor(props) {
    super(props);

    this.goto = this.goto.bind(this);

    this.selectedCallback = this.selectedCallback.bind(this);
    this.state = {
      userGroup: "灯塔村",
      initSelectedValue: [2, 27, 33],
      groupNodes: [
        [
          { parentId: 0, id: 1, name: "浦东新区" },
          { parentId: 0, id: 2, name: "静安区" }
        ],
        [
          { parentId: 1, id: 21, name: "外冈镇" },
          { parentId: 1, id: 22, name: "嘉定工业区" },
          { parentId: 2, id: 27, name: "嘉定工业区2" }
        ],
        [
          { parentId: 21, id: 31, name: "仙龙村" },
          { parentId: 21, id: 32, name: "胜利村" },
          { parentId: 22, id: 33, name: "灯塔村" },
          { parentId: 22, id: 34, name: "朱家桥村" },
          { parentId: 27, id: 35, name: "黎明村" }
        ]
      ]
    };
  }

  componentDidMount() {
    this.isComponentMounted = true;
    const that = this;
    this.$f7ready(f7 => {
      // const self = this;
      // const app = self.$f7;
      // const $ = self.$$;
    });
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  selectedCallback(values, displayValues) {
    if (!this.isComponentMounted) {
      return false;
    }
    this.setState({
      userGroup: displayValues[2]
    });
  }

  goto(url) {
    this.$f7.views.main.router.navigate(url, {
      props: {}
    });
  }

  logout() {
    apiCall(
      "/sms/logout",
      "POST",
      {
        phone: this.props.userState.userinfo.uphone,
        sign: this.props.userState.userinfo.sign
      },
      data => {
        // 异端登录导致sign验证不通过
        // if (data.code == apiConfig.errorCode1000) {
        //   this.$f7.dialog.alert(data.msg);
        // } else {
        this.props.dispatch(logout());
        this.$f7.toast
          .create({
            text: "退出登录成功",
            position: "center",
            closeTimeout: 2000,
            on: {
              close: () => {
                this.$f7.views.main.router.navigate("/", {
                  props: {}
                });
              }
            }
          })
          .open();
        // }
      },
      (xhr, status) => {
        //console.log(xhr, status);
      }
    );
  }

  render() {
    return (
      <Page name="Setting">
        <div className="MyItem mt20">
          <Link href="/changepassword">
            <div className="">设置密码</div>
            <div className="">
              <div>&nbsp;</div>
            </div>
          </Link>
          <Link href="/changephone">
            <div className="">更换手机号</div>
            <div>&nbsp;</div>
          </Link>
        </div>

        {JSON.stringify(this.props.userState.userinfo) != "{}" && (
          <div className="MyItemLogout" onClick={this.logout.bind(this)}>
            <Link href="">退出登录</Link>
          </div>
        )}
      </Page>
    );
  }
}

function mapStateToProps(state) {
  const { userState, pageState } = state;
  return { userState, pageState };
}

Setting.propTypes = {
  userState: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(Setting);
