import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "framework7-icons";
import {
  Page,
  Link,
  Searchbar,
  Input,
  Button,
  Swiper,
  SwiperSlide
} from "framework7-react";
import "../css/pageConsulateSub.less";
import { loginPath } from "../path";
import { apiCall, apiConfig } from "../api";
import InfiniteScroll from "../components/InfiniteScroll";
import NoData from "../components/NoData";

class ConsulateSub extends React.Component {
  constructor(props) {
    super(props);
    this.ext = this.$f7route.params.ext;
    this.isGetHotWireKbListParam = false;
    switch (this.ext) {
      case String(apiConfig.farmColumnId):
      case String(apiConfig.animalColumnId):
      case String(apiConfig.waterColumnId):
      case String(apiConfig.machineColumnId):
      case String(apiConfig.estateColumnId):
        this.isGetHotWireKbListParam = true;
        break;
    }

    this.fetchData = this.fetchData.bind(this);
    this.renderData = this.renderData.bind(this);
    this.onRef = this.onRef.bind(this);
    this.selectedCallback = this.selectedCallback.bind(this);
    this.changeSearchText = this.changeSearchText.bind(this);
    this.page = 1;
    this.state = {
      searchText: "",
      consulateList: [],
      showSubmitConsult: true,
      consulateTypeList: [
        {
          id: 0,
          text: "全部",
          focus: true
        },
        {
          id: 1,
          text: "测试类别1"
        },
        {
          id: 2,
          text: "测试类别2"
        },
        {
          id: 3,
          text: "测试类别3"
        },
        {
          id: 4,
          text: "测试类别4"
        },
        {
          id: 5,
          text: "测试类别5"
        },
        {
          id: 6,
          text: "测试类别6"
        }
      ]
    };
  }

  componentDidUpdate() {}

  componentDidMount() {
    this.isComponentMounted = true;
    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      app.on("searchbarClear", () => {
        this.changeSearchText();
      });
    });
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  handleTypeClick(index, id) {
    //console.log("handleTypeClick-" + index);
    const app = this.$f7;
    const $ = this.$$;
    const swiper = app.swiper.get(".ConsulateSubNavbarSwiper");
    swiper.slides.map(function(index) {
      $(swiper.slides[index]).removeClass("swiper-active");
    });
    $(swiper.slides[index]).addClass("swiper-active");
    swiper.update();
    swiper.slideTo(index, 500);
    // swiper.slideReset();

    let prevStateConsulateTypeList = this.state.consulateTypeList;
    prevStateConsulateTypeList.map(item => {
      if (item.id == id) {
        item.focus = true;
      } else {
        item.focus = false;
      }
      return item;
    });
    if (this.isComponentMounted) {
      this.setState(() => ({
        consulateTypeList: prevStateConsulateTypeList
      }));
    }

    if (app.popup.get(".consulatesubnavbar-popup")) {
      app.popup.get(".consulatesubnavbar-popup").close();
    }
  }

  selectedCallback(values, displayValues) {
    //console.log(values, displayValues);
  }

  goto(url) {
    url = loginPath(this.props.userState.userinfo, url);
    this.$f7.views.main.router.navigate(url, {
      props: {}
    });
  }

  callPhone() {
    location.href = "tel:12316";
  }

  changeSearchText(e) {
    let searchText = e ? e.target.value : "";
    this.page = 1;
    if (!this.isComponentMounted) {
      return;
    }
    this.setState(
      () => ({
        searchText: searchText,
        consulateList: []
      }),
      () => {
        this.InfiniteScroll.reInfinite();
      }
    );
  }

  onRef(ref) {
    this.InfiniteScroll = ref;
  }

  fetchData(func) {
    //if (this.state.categories.length == 0) return;

    if (this.isGetHotWireKbListParam) {
      apiCall(
        "/hotWireKb/getHotWireKbListParam",
        "POST",
        {
          channelId: this.ext,
          pageNum: this.page,
          pageSize: apiConfig.pageSize
          //attrsFirstTag: "番茄"
        },
        data => {
          if (data.code == apiConfig.errorCode1000) {
            //this.$f7.dialog.alert(data.msg);
          } else {
            this.page++;

            if (func && data.content.length != 0) func(data.content);
            if (func && data.content.length == 0) func(data.content, true);
          }
        },
        err => {
          console.log(err);
          //this.$f7.dialog.alert(JSON.stringify(err));
        }
      );
    } else {
      apiCall(
        "/app/getCmsNews",
        "POST",
        {
          channelId: apiConfig.channelId,
          columnId: apiConfig.consulateColumnId,
          ext1: this.ext,
          page: this.page,
          size: apiConfig.pageSize
        },
        data => {
          if (data.code == apiConfig.errorCode1000) {
            //this.$f7.dialog.alert(data.msg);
          } else {
            this.page++;

            if (func && data.data.list.length != 0) func(data.data.list);
            if (func && data.data.list.length == 0) func(data.data.list, true);
          }
        },
        err => {
          console.log(err);
          //this.$f7.dialog.alert(JSON.stringify(err));
        }
      );
    }
  }

  renderData(data, isDataEnd) {
    if (data.length == 0 && !isDataEnd) {
      if (this.isComponentMounted) {
        this.setState(prev => ({
          consulateList: []
        }));
      }
    } else {
      let consulateList;
      if (this.isGetHotWireKbListParam) {
        consulateList = data.map(item => {
          return {
            id: item.id,
            abstractor: "", //item.texts1,
            title: item.title,
            url: "/information/" + item.id + "/hotwirekb" // consulateinfo
          };
        });
      } else {
        consulateList = data.map(item => {
          return {
            id: item.id,
            abstractor: item.abstractor,
            title: item.title,
            url: "/information/" + item.id
          };
        });
      }

      if (this.isComponentMounted) {
        this.setState(prev => ({
          consulateList: prev.consulateList.concat(consulateList)
        }));
      }
    }
  }

  render() {
    return (
      <InfiniteScroll
        pageName="ConsulateSub"
        fetchData={this.fetchData}
        renderData={this.renderData}
        onRef={this.onRef}>
        {/**
        {this.isGetHotWireKbListParam && (
          <div className="ConsulateSubNavbar">
            <div>
              <Swiper
                params={{
                  speed: 500,
                  slidesPerView: "auto",
                  slidesOffsetBefore: 15,
                  slidesOffsetAfter: 15
                  // preventClicks: true,
                  // freeMode: true
                }}
                className="ConsulateSubNavbarSwiper">
                {this.state.consulateTypeList.map((item, index) => (
                  <div
                    className={
                      (item.focus ? "swiper-active" : "") + " swiper-slide"
                    }
                    key={item.id}
                    onClick={this.handleTypeClick.bind(this, index, item.id)}>
                    <SwiperSlide>{item.text}</SwiperSlide>
                  </div>

                  // <SwiperSlide
                  //   key={index}
                  //   className={index == 0 ? "swiper-active" : ""}
                  // >
                  //   {item.text}
                  // </SwiperSlide>
                ))}
              </Swiper>
              <Link popupOpen=".consulatesubnavbar-popup" />
            </div>
          </div>
        )}
        
        */}

        <div
          className={
            "ConsulateItemContainer " +
            (this.isGetHotWireKbListParam ? "" : "nosearch")
          }>
          {this.state.consulateList.length > 0 &&
            this.state.consulateList.map(item => (
              <Link className="ConsulateItem" href={item.url} key={item.id}>
                <div>{item.title}</div>
                {item.abstractor && (
                  <div>
                    {/** <div>{item.replyUser}回答：</div>*/}
                    <div
                      dangerouslySetInnerHTML={{ __html: item.abstractor }}
                    />
                  </div>
                )}
              </Link>
            ))}
          {this.state.consulateList.length == 0 && <NoData />}
        </div>

        {/**
          <Link
          className={
            "ConsulateAsk " + (this.state.showSubmitConsult ? " " : " hide")
          }
          href="###"
          onClick={this.goto.bind(this, "/consulateask")}
        />
         */}

        <div className="popup consulatesubnavbar-popup">
          <div className="view">
            <div className="page">
              <div className="page-content">
                <div>
                  <a className="link popup-close" />
                </div>
                <div>
                  <div>选择分类</div>
                </div>
                <div>
                  {this.state.consulateTypeList.map((item, index) => {
                    return (
                      <div
                        key={item.id}
                        className={item.focus ? "focus" : ""}
                        onClick={this.handleTypeClick.bind(
                          this,
                          index,
                          item.id
                        )}>
                        {item.text}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </InfiniteScroll>
    );
  }
}

function mapStateToProps(state) {
  const { userState } = state;
  return { userState };
}

ConsulateSub.propTypes = {
  userState: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(ConsulateSub);
