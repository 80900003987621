import React from "react";
import {
  Navbar,
  Searchbar,
  Button,
  NavLeft,
  NavRight,
  NavTitle,
  Link
} from "framework7-react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "framework7-icons";
import "./header.less";
import { callOrigin, apiCall, apiConfig } from "../../api";
import { login, changeConsulatePictures } from "../../actions/UserAction";
import { showShare, triggerFarmServiceMonth } from "../../actions/PageAction";
import md5 from "js-md5";
import { loginPath } from "../../path";

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      farmServiceMonth: "月份筛选"
    };
  }

  componentDidMount() {
    this.isComponentMounted = true;
    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;
      // this.$f7.views.main.router
    });
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.pageState.farmServiceMonth !=
      this.props.pageState.farmServiceMonth
    ) {
      this.setState({
        farmServiceMonth: this.props.pageState.farmServiceMonth
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  getBackPath() {
    let currentPath = "",
      prePath = "/",
      backPath = "";
    if (this.$f7) {
      let history = this.$f7.views.current.history;
      currentPath = history[history.length - 1];
      prePath = history.length >= 2 ? history[history.length - 2] : "/";
    }

    if (currentPath == "/register") {
      backPath = "/login";
    }
    if (currentPath == "/login") {
      backPath = "/";
    }
    if (currentPath.indexOf("/consulatemy") > -1) {
      prePath = "/consulate";
    }
    if (
      currentPath.indexOf("/authenticationselectgroup") > -1 ||
      currentPath.indexOf("/authenticationthird") > -1 ||
      currentPath.indexOf("/authenticationresult") > -1 ||
      currentPath.indexOf("/authentication") > -1
    ) {
      backPath = "/my";
    }

    //backPath = loginPath(this.props.userState.userinfo, backPath);

    //console.log(this.$f7.views.current.history, prePath, currentPath, backPath);

    return { backPath, prePath };
  }

  leftAction() {
    {
      //e.stopPropagation = true;
      //this.$f7.views.main.router.back();
      //this.$f7.views.main.router.navigate("/");
      switch (this.props.pageState.headerLeft) {
        case "scan":
          if (JSON.stringify(this.props.userState.userinfo) == "{}") {
            this.$f7.toast
              .create({
                text: "请先登录",
                position: "center",
                closeTimeout: 2000,
                on: {
                  close: () => {
                    this.$f7.views.main.router.navigate("/login", {
                      props: {}
                    });
                  }
                }
              })
              .open();
            return;
          }
          callOrigin(
            () => {
              window.webkit.messageHandlers.getCapture.postMessage("");
            },
            () => {
              android.getCapture();
            }
          );
          break;
        default:
          //console.log("** back **");
          let pathinfo = this.getBackPath();
          //console.log(backPath, this.$f7.views.main.router.back);

          if (pathinfo.backPath) {
            this.$f7.views.main.router.navigate(pathinfo.backPath, {
              animate: false
            });
          } else {
            this.$f7.views.main.router.back(pathinfo.prePath, {
              //force: true
            });
          }
          break;
      }
    }
  }

  render() {
    // if (this.props.pageState.showHeaderTitle) {
    //   return (
    //     <div className="Header HeaderTitle">{this.props.pageState.title}</div>
    //   );
    // } else if (this.props.pageState.showHeader) {
    //   return (
    //     <div className="Header">
    //       {/**
    //     <NavLeft>
    //       <Link onClick={() => this.props.f7router.back("/")}>
    //         <i className="f7-icons">left</i>
    //       </Link>
    //     </NavLeft>
    //     <NavTitle title={this.props.title} />
    //     <NavRight />
    //     */}
    //       <Searchbar placeholder="" form={false} />
    //       <Button
    //         className="searchBtn"
    //         clearButton={false}
    //         form={false}
    //         customSearch={true}
    //       >
    //         搜索
    //       </Button>
    //     </div>
    //   );
    // } else {
    //   return null;
    // }

    if (this.props.pageState.showHeader) {
      return (
        <div className="Header">
          <Link
            onClick={this.leftAction.bind(this)}
            className={
              "left " + (this.props.pageState.headerLeft ? "" : "hidden")
            }>
            {(() => {
              switch (this.props.pageState.headerLeft) {
                case "scan":
                  return (
                    <i className="cicon-scan">
                      <span className="hide" />
                    </i>
                  );
                  break;
                case "返回":
                  return <span>返回</span>;
                  break;
                default:
                  return <i className="f7-icons">left</i>;
              }
            })()}
          </Link>
          <div className="title">
            <div>{this.props.pageState.headerTitle}</div>
          </div>
          <Link
            onClick={e => {
              //this.$f7.views.main.router.back("/", { reloadAll: true })
              //this.$f7.views.main.router.navigate("/")
              e.stopPropagation = true;
              if (this.props.pageState.headerRightLocation) {
                this.$f7.views.main.router.navigate(
                  loginPath(
                    this.props.userState.userinfo,
                    this.props.pageState.headerRightLocation
                  )
                );
                return;
              }
              switch (this.props.pageState.headerRight) {
                case "farmservicemonth":
                  this.props.dispatch(triggerFarmServiceMonth());
                  break;
                case "share":
                  this.props.dispatch(showShare());
                  break;
                case "consulatemy":
                  this.$f7.views.main.router.navigate(
                    loginPath(this.props.userState.userinfo, "/consulatemy")
                  );
                  break;
                case "consulatesearch":
                  this.$f7.views.main.router.navigate("/consulatesearch");
                  break;
                case "deleteConsulatePicture":
                  this.$f7.dialog.confirm("确认要删除么？", () => {
                    let pictures = this.props.userState.consulatePictures;
                    let index = this.props.userState.consulatePictureIndex;
                    let newPictures = pictures
                      .slice(0, index)
                      .concat(pictures.slice(index + 1));
                    this.props.dispatch(changeConsulatePictures(newPictures));
                    this.$f7.views.main.router.back(
                      this.getBackPath().backPath,
                      {
                        //force: true
                      }
                    );
                  });
                  break;
                case "changepassword":
                  if (!this.props.userState.newpassword.trim()) {
                    //this.$f7.dialog.alert("请设置密码");
                    return;
                  }
                  if (!this.props.userState.setcode.trim()) {
                    //this.$f7.dialog.alert("请输入验证码");
                    return;
                  }
                  apiCall(
                    "/sms/changePwd",
                    "POST",
                    {
                      phone: this.props.userState.userinfo.uphone,
                      code: this.props.userState.setcode,
                      newpwd: md5(this.props.userState.newpassword),
                      sign: this.props.userState.userinfo.sign
                    },
                    data => {
                      if (data.code == apiConfig.errorCode1000) {
                        //error
                        this.$f7.dialog.alert(data.msg);
                      } else {
                        this.$f7.toast
                          .create({
                            text: "设置成功",
                            position: "center",
                            closeTimeout: 2000,
                            on: {
                              close: () => {
                                this.$f7.views.main.router.navigate("/my", {
                                  props: {}
                                });
                              }
                            }
                          })
                          .open();
                      }
                    },
                    err => {
                      this.$f7.dialog.alert(JSON.stringify(err));
                    }
                  );
                  break;
                case "changephone":
                  apiCall(
                    "/sms/changePhone",
                    "POST",
                    {
                      oldphone: this.props.userState.userinfo.uphone,
                      newphone: this.props.userState.changephone,
                      phoneCode: this.props.userState.changecode,
                      sign: this.props.userState.userinfo.sign
                    },
                    data => {
                      if (data.code == apiConfig.errorCode1000) {
                        //error
                        this.$f7.dialog.alert(data.msg);
                      } else {
                        this.$f7.toast
                          .create({
                            text: "修改成功",
                            position: "center",
                            closeTimeout: 2000,
                            on: {
                              close: () => {
                                this.$f7.views.main.router.navigate("/my", {
                                  props: {}
                                });
                              }
                            }
                          })
                          .open();
                      }
                    },
                    err => {
                      this.$f7.dialog.alert(JSON.stringify(err));
                    }
                  );
                  break;
                case "registerpass":
                  apiCall(
                    "/sms/register",
                    "POST",
                    {
                      phone: this.props.userState.registerPhone,
                      password: "",
                      phoneCode: this.props.userState.registerCode,
                      token: apiConfig.token
                    },
                    data => {
                      if (data.code == apiConfig.errorCode1000) {
                        //error
                        this.$f7.dialog.alert(data.msg);
                      } else {
                        this.$f7.toast
                          .create({
                            text: "注册成功",
                            position: "center",
                            closeTimeout: 2000,
                            on: {
                              close: () => {
                                this.props.dispatch(
                                  login({
                                    sign: data.content,
                                    uphone: this.props.userState.registerPhone
                                  })
                                );
                                this.$f7.views.main.router.navigate("/my", {
                                  props: {}
                                });
                              }
                            }
                          })
                          .open();
                      }
                    },
                    err => {
                      console.log(err);
                      this.$f7.dialog.alert(JSON.stringify(err));
                    }
                  );
                  break;
              }
            }}
            className={
              "right " + (this.props.pageState.headerRight ? "" : "hidden")
            }>
            {(() => {
              switch (this.props.pageState.headerRight) {
                case "bell":
                  return (
                    <i className="cicon-bell">
                      <span className="hide" />
                    </i>
                  );
                case "consulateRecords":
                  return <span>咨询记录</span>;
                case "consulatesearch":
                  return <i className="cicon-search" />;
                case "consulatemy":
                  return (
                    <div>
                      <span>我的咨询</span>
                      <span className="" />
                      {/** actived */}
                    </div>
                  );
                case "billInAndOut":
                  return <span>收支情况</span>;
                case "login":
                  return <span>登录</span>;
                case "registerpass":
                  return <span>跳过</span>;
                case "changepassword":
                  return (
                    <span
                      className={
                        this.props.userState.setcode.trim() != "" &&
                        this.props.userState.newpassword.trim() != ""
                          ? ""
                          : "unactive"
                      }>
                      确定
                    </span>
                  );
                case "changephone":
                  return <span>确定</span>;
                case "deleteConsulatePicture":
                  return <span>删除</span>;
                case "share":
                  return <i className="cicon-share" />;
                case "farmservicemonth":
                  return <span>{this.state.farmServiceMonth}</span>;
                default:
                  return <i className="f7-icons">right</i>;
              }
            })()}
          </Link>
        </div>
      );
    } else {
      return null;
    }
  }
}

function mapStateToProps(state) {
  const { userState, pageState } = state;
  return { userState, pageState };
}

Header.propTypes = {
  userState: PropTypes.object.isRequired,
  pageState: PropTypes.object.isRequired
  //title: PropTypes.string.isRequired,
  //f7router: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(Header);
