import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "framework7-icons";
import { Page, Link, Button, Preloader, Input } from "framework7-react";
import "framework7-icons";
import { apiCall, apiConfig } from "../api";
import md5 from "js-md5";
import {
  checkPhone,
  temporaryRepairWxKeyboardHide
} from "../../public/js/utils";
import { login } from "../actions/UserAction";
import "../css/pageLogin.less";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.codeTime = this.codeTime.bind(this);
    this.refPhone = React.createRef();
    this.refPassword = React.createRef();
    this.refCode = React.createRef();

    this.state = {
      type: "password",
      phone: "",
      password: "",
      code: "",
      codeLeftTime: 0,
      loading: false
    };
  }

  componentDidMount() {
    this.isComponentMounted = true;
    const that = this;
    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;
    });

    let lastCodeTime = localStorage.getItem("smsCodeTimeLogin")
      ? parseFloat(localStorage.getItem("smsCodeTimeLogin"))
      : 0;
    let lastCodeLeft = localStorage.getItem("smsCodeLeftLogin")
      ? parseInt(localStorage.getItem("smsCodeLeftLogin"))
      : 0;
    if (
      lastCodeTime &&
      lastCodeTime + lastCodeLeft * 1000 > new Date().getTime()
    ) {
      //距离上次发送短信验证码小于60秒
      this.codeTime(lastCodeLeft);
      return;
    }
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  changeType(type) {
    if (!this.isComponentMounted) {
      return false;
    }
    this.setState({
      type: type
    });
  }

  changeCode(e) {
    if (!this.isComponentMounted) {
      return false;
    }
    let code = e.target.value;
    this.setState(() => ({
      code: code
    }));
  }

  clearCode(e) {
    if (!this.isComponentMounted) {
      return false;
    }
    this.setState(() => ({
      code: ""
    }));
  }

  changePhone(e) {
    if (!this.isComponentMounted) {
      return false;
    }
    let phone = e.target.value;
    this.setState(() => ({
      phone: phone
    }));
  }

  clearPhone(e) {
    if (!this.isComponentMounted) {
      return false;
    }
    this.setState(() => ({
      phone: ""
    }));
  }

  changePassword(e) {
    if (!this.isComponentMounted) {
      return false;
    }
    let password = e.target.value;
    this.setState(() => ({
      password: password
    }));
  }

  clearPassword(e) {
    if (!this.isComponentMounted) {
      return false;
    }
    this.setState(() => ({
      password: ""
    }));
  }

  onBlur() {
    temporaryRepairWxKeyboardHide();
  }

  codeTime(initTime) {
    apiCall(
      "/sms/sendSmscode",
      "POST",
      {
        phone: this.state.phone,
        token: apiConfig.token
      },
      data => {
        //console.log(data);
        if (!this.isComponentMounted) {
          return false;
        }
        this.setState(
          () => ({
            loading: true
          }),
          () => {
            if (initTime <= 0 || !initTime) {
              initTime = 60;
            }
            if (this.codeInterval) {
              clearInterval(this.codeInterval);
            }
            this.codeInterval = setInterval(() => {
              if (!this.isComponentMounted) {
                return false;
              }
              this.setState(
                prevState => ({
                  codeLeftTime: initTime--,
                  loading: false
                }),
                () => {
                  this.$f7.preloader.hide();
                  localStorage.setItem(
                    "smsCodeTimeLogin",
                    new Date().getTime()
                  );
                  localStorage.setItem(
                    "smsCodeLeftLogin",
                    this.state.codeLeftTime
                  );
                  if (this.state.codeLeftTime <= 0) {
                    clearInterval(this.codeInterval);
                  }
                }
              );
            }, 1000);
          }
        );
      },
      err => {
        console.log(err);
        this.$f7.dialog.alert(JSON.stringify(err));
      }
    );
  }

  sendCode() {
    let lastCodeTime = localStorage.getItem("smsCodeTimeLogin")
      ? parseFloat(localStorage.getItem("smsCodeTimeLogin"))
      : 0;
    let lastCodeLeft = localStorage.getItem("smsCodeLeftLogin")
      ? parseInt(localStorage.getItem("smsCodeLeftLogin"))
      : 0;
    if (
      lastCodeTime &&
      lastCodeTime + lastCodeLeft * 1000 > new Date().getTime()
    ) {
      //距离上次发送短信验证码小于60秒
      return;
    }
    if (!this.state.phone) {
      this.$f7.dialog.alert("请输入手机号", () => {
        //this.refPhone.current.focus();
      });
      return;
    }
    if (!checkPhone(this.state.phone)) {
      this.$f7.dialog.alert("手机号格式不正确，请重新输入", () => {
        //this.refPhone.current.focus();
      });
      return;
    }
    this.codeTime();
  }

  login() {
    // type:1密码/2验证码
    let type = 1;
    let code = md5(this.state.password);
    if (this.state.type == "code") {
      type = 2;
      code = this.state.code;
    }

    if (!this.state.phone) {
      this.$f7.dialog.alert("请输入手机号", () => {
        //this.refPhone.current.state.inputFocused = true;
      });
      return;
    }
    if (!checkPhone(this.state.phone)) {
      this.$f7.dialog.alert("手机号格式不正确，请重新输入", () => {
        //this.refPhone.current.focus();
      });
      return;
    }

    if (type == 1) {
      if (!this.state.password) {
        this.$f7.dialog.alert("请输入密码", () => {
          //this.refPassword.current.focus();
        });
        return;
      }
    }

    if (type == 2) {
      if (!this.state.code) {
        this.$f7.dialog.alert("请输入验证码", () => {
          //this.refCode.current.focus();
        });
        return;
      }
    }

    apiCall(
      "/sms/login",
      "POST",
      {
        phone: this.state.phone,
        code: code,
        type: type,
        token: apiConfig.token
      },
      data => {
        //console.log(data);
        if (data.code == apiConfig.errorCode1000) {
          this.$f7.dialog.alert(data.msg);
        } else {
          this.$f7.toast
            .create({
              text: "登录成功",
              position: "center",
              closeTimeout: 2000,
              on: {
                close: () => {
                  this.props.dispatch(
                    login({ uphone: this.state.phone, sign: data.content })
                  );
                  // this.$f7.views.main.router.back(
                  //   this.$f7.view.current.history[
                  //     this.$f7.view.current.history.length - 2
                  //   ],
                  //   { force: true }
                  // );
                  this.$f7.views.main.router.navigate(
                    this.$f7.view.current.history[
                      this.$f7.view.current.history.length - 2
                    ],
                    {
                      props: {}
                    }
                  );
                }
              }
            })
            .open();
        }
      },
      (xhr, status) => {
        console.log(xhr, status);
        this.$f7.dialog.alert(JSON.stringify(xhr));
      }
    );
  }

  render() {
    return (
      <Page name="Login">
        <div />
        <div>
          <div>
            <div
              className={this.state.type == "password" ? "focus" : ""}
              onClick={this.changeType.bind(this, "password")}>
              密码登录
            </div>
            <div
              className={this.state.type == "code" ? "focus" : ""}
              onClick={this.changeType.bind(this, "code")}>
              验证码登录
            </div>
          </div>
        </div>

        <div
          className={
            "login-password " + (this.state.type == "password" ? "" : " hide")
          }>
          <div>
            <div>
              <span>账号</span>
              <Input
                type="tel"
                placeholder="请输入手机号"
                clearButton={true}
                onChange={this.changePhone.bind(this)}
                onInputClear={this.clearPhone.bind(this)}
                value={this.state.phone}
                ref={this.refPhone}
                onBlur={this.onBlur.bind(this)}
              />
            </div>
            <div>
              <span>密码</span>
              <Input
                type="password"
                placeholder="请输入密码"
                clearButton={true}
                onChange={this.changePassword.bind(this)}
                onInputClear={this.clearPassword.bind(this)}
                value={this.state.password}
                ref={this.refPassword}
                onBlur={this.onBlur.bind(this)}
              />
            </div>
          </div>

          <div>
            <Button onClick={this.login.bind(this)}>登录</Button>
          </div>
          <div>
            <Link href="/register">快速注册</Link>
            <Link href="/retrieve">找回密码</Link>
          </div>
        </div>

        <div
          className={
            "login-code " + (this.state.type == "code" ? "" : " hide")
          }>
          <div>
            <div>
              <span>账号</span>
              <Input
                type="tel"
                placeholder="请输入手机号"
                clearButton={true}
                onChange={this.changePhone.bind(this)}
                onInputClear={this.clearPhone.bind(this)}
                value={this.state.phone}
                ref={this.refPhone}
                onBlur={this.onBlur.bind(this)}
              />
            </div>
            <div>
              <span>验证码</span>
              <Input
                type="tel"
                placeholder="请输入验证码"
                clearButton={true}
                onChange={this.changeCode.bind(this)}
                onInputClear={this.clearCode.bind(this)}
                //value={this.state.code}
                ref={this.refCode}
                onBlur={this.onBlur.bind(this)}
                maxlength={6}
              />
              <div
                onClick={this.sendCode.bind(this)}
                className={
                  this.state.codeLeftTime > 0 || this.state.loading == true
                    ? "sended"
                    : ""
                }>
                {this.state.loading == false &&
                  (this.state.codeLeftTime > 0
                    ? "" + this.state.codeLeftTime + " 秒"
                    : "获取验证码")}
                {this.state.loading == true && <Preloader />}
              </div>
            </div>
          </div>
          <div>
            <Button onClick={this.login.bind(this)}>登录</Button>
          </div>
          <div>提示：未注册的手机账号，登录时将自动注册账号</div>
        </div>
      </Page>
    );
  }
}

function mapStateToProps(state) {
  const { userState, pageState } = state;
  return { userState, pageState };
}

Login.propTypes = {
  userState: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(Login);
