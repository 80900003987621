import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "framework7-icons";
import { Page } from "framework7-react";
import "../css/pageConsulateInfo.less";
import ImgHeader from "../images/im_me.png";
import ImgExpert from "../images/expert2.png";
import ImgSample from "../images/sample31.png";
import { apiCall, apiConfig } from "../api";
import NoData from "../components/NoData";

class ConsulateInfo extends React.Component {
  constructor(props) {
    super(props);

    this.consulateId = this.$f7route.params.consulateId;
    this.isHotwirekb = this.$f7route.params.isHotwirekb;

    this.state = {
      info: {}
    };
  }

  componentDidMount() {
    this.isComponentMounted = true;
    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      if (this.isHotwirekb) {
        apiCall(
          "/hotWireKb/getHotWireKbById",
          "GET",
          {
            id: this.consulateId
          },
          data => {
            //console.log(data);
            if (data.code == apiConfig.errorCode1000) {
              //this.$f7.dialog.alert(data.msg);
            } else {
              if (this.isComponentMounted) {
                this.setState({ info: data.content });
              }
            }
          },
          err => {
            console.log(err);
            //this.$f7.dialog.alert(JSON.stringify(err));
          }
        );
      } else {
        apiCall(
          "/reply/getQuestionInfo",
          "POST",
          {
            qid: this.consulateId
          },
          data => {
            //console.log(data);
            if (data.code == apiConfig.errorCode1000) {
              //this.$f7.dialog.alert(data.msg);
            } else {
              if (this.isComponentMounted) {
                this.setState({ info: data.content });
              }
            }
          },
          err => {
            console.log(err);
            //this.$f7.dialog.alert(JSON.stringify(err));
          }
        );
      }
    });
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  render() {
    return (
      <Page name="ConsulateInfo">
        {!this.isHotwirekb && JSON.stringify(this.state.info) != "{}" && (
          <React.Fragment>
            {this.state.info.consult_time && (
              <div className="ConsulateInfoTime">
                {this.state.info.consult_time}
              </div>
            )}
            {this.state.info.content && (
              <div className="ConsulateInfoMyItem">
                <div>
                  <span />
                  {this.state.info.content}
                </div>
                <div>
                  <img src={ImgHeader} />
                </div>
              </div>
            )}
            {this.state.info.reply_time && (
              <div className="ConsulateInfoTime">
                {this.state.info.reply_time}
              </div>
            )}
            {this.state.info.reply && (
              <div className="ConsulateInfoExpertItem">
                <div>
                  <img src={ImgExpert} />
                </div>
                <div>
                  <span />
                  {this.state.info.reply}
                </div>
              </div>
            )}
          </React.Fragment>
        )}
        {this.isHotwirekb && JSON.stringify(this.state.info) != "{}" && (
          <React.Fragment>
            {this.state.info.createDate && (
              <div className="ConsulateInfoTime">
                {this.state.info.createDate}
              </div>
            )}
            {this.state.info.title && (
              <div className="ConsulateInfoMyItem">
                <div>
                  <span />
                  {this.state.info.title}
                </div>
                <div>
                  <img src={ImgHeader} />
                </div>
              </div>
            )}
            {this.state.info.updateDate && (
              <div className="ConsulateInfoTime">
                {this.state.info.updateDate}
              </div>
            )}
            {this.state.info.texts1 && (
              <div className="ConsulateInfoExpertItem">
                <div>
                  <img src={ImgExpert} />
                </div>
                <div>
                  <span />
                  <div
                    dangerouslySetInnerHTML={{ __html: this.state.info.texts1 }}
                  />
                </div>
              </div>
            )}
          </React.Fragment>
        )}
        {JSON.stringify(this.state.info) == "{}" && <NoData />}
        {/**
        <div className="ConsulateInfoTime">2018-09-21 10:23</div>
        <div className="ConsulateInfoMyItem">
          <div>
            <span />
            请问专家这是什么病？怎么治理？能吃吗？谢谢请问专家这是什么病？怎么治理？能吃吗？谢谢
          </div>
          <div>
            <img src={ImgHeader} />
          </div>
        </div>
        <div className="ConsulateInfoMyItem">
          <div className="NoPadding">
            <img src={ImgSample} />
          </div>
          <div>
            <img src={ImgHeader} />
          </div>
        </div>
        <div className="ConsulateInfoTime">2018-09-23 14:45</div>
        <div className="ConsulateInfoExpertItem">
          <div>
            <img src={ImgExpert} />
          </div>
          <div>
            <span />
            茭白锈病，一般每亩（666.7平方米）用97%可湿性粉剂400倍液，每5-7天一次，连喷三四次。
          </div>
        </div>
      */}
        {/**
        <div className="ConsulateInfoAsk" />
        */}
      </Page>
    );
  }
}

function mapStateToProps(state) {
  const { userState } = state;
  return { userState };
}

ConsulateInfo.propTypes = {
  userState: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(ConsulateInfo);
