import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "framework7-icons";
import { Page, Link, Button } from "framework7-react";
import "framework7-icons";
import { apiCall, apiConfig } from "../api";
import "../css/pageTvlogin.less";
import {
  showHeader,
  hideHeader,
  changeFooterIndex
} from "../actions/PageAction";

class TVLogin extends React.Component {
  constructor(props) {
    super(props);

    this.goto = this.goto.bind(this);
    this.state = {
      uuid: this.props.userState.uuid
    };
  }

  componentDidUpdate(prevProps, prevState) {
    let newState = {};
    if (prevProps.userState.uuid != this.props.userState.uuid) {
      newState.uuid = this.props.userState.uuid;
    }
    if (JSON.stringify(newState) != "{}") {
      this.setState(newState);
    }
  }

  componentDidMount() {
    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      app.on("pageBeforeIn", page => {
        if (page.name == "TVLogin") {
          self.props.dispatch(showHeader("益申农TV版登录确认", true));
          self.props.dispatch(changeFooterIndex(0));
        }
      });
    });
  }

  goto(url) {
    this.$f7.views.main.router.navigate(url, {
      props: {}
    });
  }

  tvlogin() {
    apiCall(
      "/sms/getUserInfoByCode",
      "POST",
      {
        phone: this.props.userState.userinfo.uphone,
        sign: this.props.userState.userinfo.sign,
        guid: this.state.uuid
      },
      data => {
        if (data.code == apiConfig.errorCode1000) {
          this.$f7.dialog.alert(data.msg);
        } else {
          this.$f7.toast
            .create({
              text: data.content,
              position: "center",
              closeTimeout: 2000,
              on: {
                close: () => {
                  this.$f7.views.main.router.navigate("/", {
                    props: {}
                  });
                }
              }
            })
            .open();
        }
      },
      err => {
        this.$f7.dialog.alert(JSON.stringify(err));
      }
    );
  }

  render() {
    return (
      <Page name="TVLogin">
        <div className="tvbg" />
        <div className="tvbutton">
          <Button onClick={this.tvlogin.bind(this)}>确认登录</Button>
        </div>
      </Page>
    );
  }
}

function mapStateToProps(state) {
  const { userState, pageState } = state;
  return { userState, pageState };
}

TVLogin.propTypes = {
  userState: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(TVLogin);
