export function loginPath(userinfo, url) {
  let loginPath = [
    "/my",
    "/affair",
    "/consulatemy",
    "/consulateask",
    "/message"
  ];

  if (JSON.stringify(userinfo) != "{}") {
    // 已登录
    return url;
  }
  // 未登录
  if (
    loginPath.find(element => {
      return element == url;
    })
  ) {
    // 需要登录
    return "/login";
  } else {
    // 不需要登录
    return url;
  }
}
