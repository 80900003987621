import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "framework7-icons";
import {
  Page,
  Toolbar,
  Tabs,
  Tab,
  Link,
  Swiper,
  SwiperSlide
} from "framework7-react";
import InfiniteScroll from "../components/InfiniteScroll/";
import NoData from "../components/NoData";
import CulturePanel from "../components/CulturePanel/";
import "../css/pageFarmService.less";
import { apiCall, apiConfig } from "../api";
import { farmServiceMonth } from "../actions/PageAction";

class FarmService extends React.Component {
  constructor(props) {
    super(props);

    this.userId = this.$f7route.params.userId;

    this.onRef = this.onRef.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.renderData = this.renderData.bind(this);

    this.state = {
      triggerFarmServiceMonth: false,
      pageIndex: 1,
      ext1: "",
      ext2: "",
      ext3: "",
      products: [
        // {
        //   id: 1,
        //   title: "马陆葡萄",
        //   description: "第一批通过上海市优质农产品认证的水果",
        //   img: ImgSample
        // },
      ],
      typeList: [
        {
          id: 0,
          text: "全部",
          tagValue: "",
          focus: true
        }
      ],
      monthList: [
        {
          id: 0,
          text: "全部",
          tagValue: "",
          focus: true
        }
      ]
    };
  }

  componentDidMount() {
    this.isComponentMounted = true;
    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      apiCall(
        "/cms/tag",
        "POST",
        {
          columnId: apiConfig.farmserviceColumnId,
          typeId: 1
        },
        data => {
          data.map(type => {
            this.state.typeList.push({
              id: type.id,
              text: type.tagName,
              tagValue: type.tagValue,
              focus: false
            });
          });

          if (this.isComponentMounted) {
            this.setState(prev => ({
              typeList: this.state.typeList
            }));
          }
        },
        function(xhr, status) {
          //console.log(xhr, status);
        }
      );

      apiCall(
        "/cms/tag",
        "POST",
        {
          columnId: apiConfig.farmserviceColumnId,
          typeId: 2
        },
        data => {
          data.map(type => {
            this.state.monthList.push({
              id: type.id,
              text: type.tagName,
              tagValue: type.tagValue,
              focus: false
            });
          });

          if (this.isComponentMounted) {
            this.setState(prev => ({
              monthList: this.state.monthList
            }));
          }
        },
        function(xhr, status) {
          //console.log(xhr, status);
        }
      );
    });
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
    this.props.dispatch(farmServiceMonth("月份筛选"));
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.pageState.triggerFarmServiceMonth !=
      this.props.pageState.triggerFarmServiceMonth
    ) {
      if (this.isComponentMounted) {
        this.setState(
          () => ({
            triggerFarmServiceMonth: !this.state.triggerFarmServiceMonth
          }),
          () => {}
        );
      }
    }
  }

  onRef(ref) {
    this.InfiniteScroll = ref;
  }

  handleTypeClick(index, id) {
    //console.log("handleTypeClick," + index + "," + id);
    const app = this.$f7;
    const $ = this.$$;
    const swiper = app.swiper.get(".FarmServiceSubNavbarSwiper");
    swiper.slides.map(function(index) {
      $(swiper.slides[index]).removeClass("swiper-active");
    });
    $(swiper.slides[index]).addClass("swiper-active");
    swiper.update();
    swiper.slideTo(index, 500);
    // swiper.slideReset();
    let typeList = this.state.typeList.map(type => {
      type.focus = false;
      if (type.id == id) {
        type.focus = true;
      }
      return type;
    });
    if (this.isComponentMounted) {
      this.setState(
        () => ({
          typeList,
          pageIndex: 1
        }),
        () => {
          this.InfiniteScroll.reInfinite();
        }
      );
    }
  }

  handleMonthClick(id, text) {
    const app = this.$f7;
    const $ = this.$$;
    let monthList = this.state.monthList.map(month => {
      month.focus = false;
      if (month.id == id) {
        month.focus = true;
      }
      return month;
    });
    if (this.isComponentMounted) {
      this.setState(
        () => ({
          monthList,
          triggerFarmServiceMonth: !this.state.triggerFarmServiceMonth,
          pageIndex: 1
        }),
        () => {
          this.InfiniteScroll.reInfinite();
          this.props.dispatch(farmServiceMonth(text));
        }
      );
    }
  }

  fetchData(func) {
    let ext1Arr = this.state.typeList.filter(type => type.focus);
    let ext2Arr = this.state.monthList.filter(month => month.focus);
    apiCall(
      "/app/getCmsNews",
      "POST",
      {
        channelId: apiConfig.channelId,
        columnId: apiConfig.farmserviceColumnId,
        ext1: ext1Arr.length ? ext1Arr[0].tagValue : "",
        ext2: ext2Arr.length ? ext2Arr[0].tagValue : "",
        ext3: this.state.ext3,
        page: this.state.pageIndex,
        size: apiConfig.pageSize
      },
      data => {
        //console.log("fetchData", this.state.pageIndex, data);

        // if (this.isComponentMounted) {
        //   this.setState(prev => ({
        //     pageIndex: prev.pageIndex + 1
        //   }));
        // }

        if (func && data.data.list.length != 0) func(data.data.list);
        if (func && data.data.list.length == 0) func(data.data.list, true);
      },
      function(xhr, status) {
        //console.log(xhr, status);
      }
    );
  }

  renderData(data, isDataEnd) {
    //console.log(data);
    if (data.length == 0 && !isDataEnd) {
      if (this.isComponentMounted) {
        this.setState(prev => ({
          products: []
        }));
      }
    } else {
      let products = data.map(item => {
        return {
          id: item.id,
          title: item.title,
          description: item.abstractor,
          img: item.previewImage
        };
      });
      //console.log(products);
      if (this.isComponentMounted) {
        this.setState(prev => ({
          products:
            prev.pageIndex > 1 ? prev.products.concat(products) : products,
          pageIndex: prev.pageIndex + 1
        }));
      }
    }
  }

  render() {
    return (
      <InfiniteScroll
        pageName="FarmService"
        fetchData={this.fetchData}
        renderData={this.renderData}
        onRef={this.onRef}
        externalClass={this.state.triggerFarmServiceMonth ? "noscroll" : ""}>
        <div className="FarmServiceSubNavbar hide">
          <Swiper
            params={{
              speed: 500,
              slidesPerView: "auto",
              slidesOffsetBefore: 15,
              slidesOffsetAfter: 15
              // preventClicks: true,
              // freeMode: true
            }}
            className="FarmServiceSubNavbarSwiper">
            {this.state.typeList.map((item, index) => (
              <div
                className={
                  (item.focus ? "swiper-active" : "") + " swiper-slide"
                }
                key={item.id}
                onClick={this.handleTypeClick.bind(this, index, item.id)}>
                <SwiperSlide>{item.text}</SwiperSlide>
              </div>
            ))}
          </Swiper>
        </div>

        <div
          className={
            "page-content-wrap " +
            (this.state.triggerFarmServiceMonth ? "noscroll" : "")
          }>
          {this.state.products.length > 0 &&
            this.state.products.map((item, index) => (
              <CulturePanel
                key={index}
                link={"/information/" + item.id}
                img={item.img}
                title={item.title}
                description={item.description}
                classType="simplevertical"
              />
            ))}
          {this.state.products.length == 0 && <NoData />}
        </div>

        <div
          className={
            "FarmServiceMonth " +
            (this.state.triggerFarmServiceMonth ? "" : "hide")
          }>
          <div>
            {this.state.monthList.map((month, index) => (
              <div
                key={index}
                onClick={this.handleMonthClick.bind(
                  this,
                  month.id,
                  month.text
                )}>
                <div className={month.focus ? "focus" : ""}>{month.text}</div>
              </div>
            ))}
          </div>
        </div>
      </InfiniteScroll>
    );
  }
}

function mapStateToProps(state) {
  const { userState, pageState } = state;
  return { userState, pageState };
}

FarmService.propTypes = {
  userState: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(FarmService);
