import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "framework7-icons";
import { Page, Link, Button, Input, Radio } from "framework7-react";
import "../css/pageConsulateAsk.less";
import { apiCall, apiConfig } from "../api";
import { checkPhone, detectPhone } from "../../public/js/utils";
import AutoImg from "../components/AutoImg";
import {
  changeConsulatePictures,
  changeConsulatePictureIndex
} from "../actions/UserAction";

class ConsulateAsk extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isIphone: detectPhone() == "iphone" ? true : false,
      uname: this.props.userState.userinfo.uname
        ? this.props.userState.userinfo.uname
        : "",
      uphone: this.props.userState.userinfo.uphone
        ? this.props.userState.userinfo.uphone
        : "",
      content: "",
      pictures: []
      //this.props.userState.consulatePictures
    };
  }

  componentDidUpdate(prevProps, prevState) {
    let newState = {};
    if (
      prevProps.userState.userinfo.uname != this.props.userState.userinfo.uname
    ) {
      newState.uname = this.props.userState.userinfo.uname;
    }
    if (
      prevProps.userState.userinfo.uphone !=
      this.props.userState.userinfo.uphone
    ) {
      newState.uphone = this.props.userState.userinfo.uphone;
    }
    if (
      prevProps.userState.consulatePictures !=
      this.props.userState.consulatePictures
    ) {
      newState.pictures = this.props.userState.consulatePictures;
    }
    if (JSON.stringify(newState) != "{}") {
      this.setState(newState);
    }
  }

  componentDidMount() {
    this.isComponentMounted = true;
    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      //console.log(app.views.current.history);
    });
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  unameClear() {
    this.setState(() => ({
      uname: ""
    }));
  }

  unameChange(e) {
    let uname = e.target.value;
    this.setState(() => ({
      uname: uname
    }));
  }

  uphoneClear() {
    this.setState(() => ({
      uphone: ""
    }));
  }

  uphoneChange(e) {
    let uphone = e.target.value;
    this.setState(() => ({
      uphone: uphone
    }));
  }

  contentChange(e) {
    let content = e.target.value;
    this.setState(() => ({
      content: content
    }));
  }

  submitConsulate() {
    if (!this.state.uname.trim()) {
      this.$f7.dialog.alert("请先填写咨询人");
      return;
    }
    if (!this.state.uphone.trim()) {
      this.$f7.dialog.alert("请先填写联系电话");
      return;
    }
    if (!checkPhone(this.state.uphone)) {
      this.$f7.dialog.alert("联系电话格式不正确，请重新输入", () => {
        //this.refPhone.current.focus();
      });
      return;
    }
    if (!this.state.content.trim()) {
      this.$f7.dialog.alert("请先填写内容明细");
      return;
    }

    apiCall(
      "/reply/SaveQuestionToPC",
      "POST",
      {
        content: this.state.content,
        consult_user: this.state.uname,
        nmbr: this.state.uphone,
        user: this.props.userState.userinfo.uid,
        consult_type: 11, //渠道 11：移动app
        QuestionURL: this.state.pictures
      },
      data => {
        //console.log(data);
        if (data.code == apiConfig.errorCode1000) {
          this.$f7.dialog.alert(data.msg);
        } else {
          this.$f7.views.main.router.navigate("/consulatemy", {
            props: {}
          });
        }
      },
      err => {
        console.log(err);
        this.$f7.dialog.alert(JSON.stringify(err));
      }
    );
  }

  selectFile(e) {
    let files = e.target.files;
    for (let i = 0; i < files.length; i++) {
      let file = files[i];

      if (!file.type.match("image.*")) {
        //不是图片
        this.$f7.dialog.alert("只能上传图片类型");
        break;
      }
      // if (file.size > 1024 * 1024 * 2) {
      //   this.$f7.dialog.alert("图片不能大于2M");
      //   break;
      // }

      let data = new FormData();
      data.append("file", file);
      data.append("channel", apiConfig.channelId);
      apiCall(
        "/cms/uploadImage",
        "POST",
        data,
        result => {
          let url = result.data.src;
          let pictures = this.state.pictures;
          pictures.push(url);
          this.setState(
            () => ({
              pictures: pictures
            }),
            () => {
              this.props.dispatch(changeConsulatePictures(pictures));
            }
          );
        },
        (error, status) => {
          this.$f7.dialog.alert(JSON.stringify(status));
        },
        true
      );
    }

    // let reader = new FileReader();
    // reader.readAsDataURL(file);
    // reader.onload = e => {
    //   // e.target.result
    // };
  }

  viewImage(index) {
    this.props.dispatch(changeConsulatePictureIndex(index));
    this.$f7.views.main.router.navigate("/viewimage/" + index, {
      props: {}
    });
  }

  render() {
    return (
      <Page name="ConsulateAsk">
        <div className="ConsulateAskForm">
          <div>
            <div>
              <span>*</span>咨询人
            </div>
            <Input
              type="text"
              placeholder="请输入咨询人"
              clearButton={true}
              value={this.state.uname}
              onInputClear={this.unameClear.bind(this)}
              onChange={this.unameChange.bind(this)}
            />
          </div>

          <div>
            <div>
              <span>*</span>联系电话
            </div>
            <Input
              type="text"
              placeholder="请输入联系电话"
              clearButton={true}
              value={this.state.uphone}
              onInputClear={this.uphoneClear.bind(this)}
              onChange={this.uphoneChange.bind(this)}
            />
          </div>

          <div>
            <div>
              <span>*</span>内容明细
            </div>
            <Input
              type="textarea"
              value={this.state.content}
              onChange={this.contentChange.bind(this)}
            />
          </div>

          {/**
            <div>
              <div>所在地区</div>
              <Input
                type="text"
                placeholder="请输入所在地区"
                clearButton={true}
              />
            </div>

            <div>
              <div>所在单位</div>
              <Input
                type="text"
                placeholder="请输入所在单位"
                clearButton={true}
              />
            </div>

            <div>
              <div>E-mail</div>
              <Input type="text" placeholder="请输入E-mail" clearButton={true} />
            </div>

            <div>
              <div>
                <span>*</span>问题标题
              </div>
              <Input type="textarea" />
            </div>

            <div className="ConsulateAskFormExtra">
              <span>问题信息是否愿意公开？</span>
              <Radio
                name="caPublic"
                value="yes"
                className="Radio"
                defaultChecked
              />{" "}
              是
              <Radio name="caPublic" value="no" className="Radio" /> 否
            </div>

          */}
        </div>

        {/**
        <input type="file" accept="image/*" capture="camera">
        <input type="file" accept="video/*" capture="camcorder">
        <input type="file" accept="audio/*" capture="microphone">
        accept表示打开的系统文件目录
        capture表示的是系统所捕获的默认设备，camera：照相机；camcorder：摄像机；microphone：录音；
        其中还有一个属性multiple，支持多选，当支持多选时，multiple优先级高于capture，所以只用写成：<input type="file" accept="image/*" multiple>
        */}

        <div className="PictureContainer ">
          {this.state.pictures.map((picture, index) => (
            <div
              className="picture"
              key={index}
              onClick={this.viewImage.bind(this, index)}>
              <AutoImg src={picture} ratio={1} />
            </div>
          ))}

          {this.state.pictures.length < 3 && (
            <label
              className="picture addPicture"
              htmlFor="ConsulateAskAddPicture">
              <input
                id="ConsulateAskAddPicture"
                type="file"
                accept="image/*"
                onChange={this.selectFile.bind(this)}
              />
            </label>
          )}

          {/**
          {this.state.pictures.length < 3 &&
            (this.state.isIphone && (
              <label
                className="picture addPicture"
                htmlFor="ConsulateAskAddPicture">
                <input
                  id="ConsulateAskAddPicture"
                  type="file"
                  accept="image/*"
                  onChange={this.selectFile.bind(this)}
                />
              </label>
            ))}

          {this.state.pictures.length < 3 &&
            (!this.state.isIphone && (
              <label
                className="picture addPicture"
                htmlFor="ConsulateAskAddPicture">
                <input
                  id="ConsulateAskAddPicture"
                  type="file"
                  accept="image/*"
                  capture="camera"
                  onChange={this.selectFile.bind(this)}
                />
              </label>
            ))}
          
          */}
        </div>

        <div className="SubmitBtn">
          <Button onClick={this.submitConsulate.bind(this)}>提交</Button>
        </div>
      </Page>
    );
  }
}

function mapStateToProps(state) {
  const { userState, pageState } = state;
  return { userState, pageState };
}

ConsulateAsk.propTypes = {
  userState: PropTypes.object.isRequired,
  pageState: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(ConsulateAsk);
