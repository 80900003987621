import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "framework7-icons";
import { Page, Link, Input, Button } from "framework7-react";
import "framework7-icons";
import "../css/pageEditname.less";

class Editname extends React.Component {
  componentDidMount() {
    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;
    });
  }

  render() {
    return (
      <Page name="Editname">
        <div>
          <Input type="text" placeholder="请输入您的真实姓名" clearButton />
        </div>
        <div>
          <Button>保存</Button>
        </div>
      </Page>
    );
  }
}

function mapStateToProps(state) {
  const { userState } = state;
  return { userState };
}

Editname.propTypes = {
  userState: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(Editname);
