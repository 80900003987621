import React from "react";
import { Link } from "framework7-react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "framework7-icons";
import "./informationPanel.less";
import AutoImg from "../AutoImg/";

class InformationPanel extends React.Component {
  componentDidMount() {
    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;
      // this.$f7.views.main.router
    });
  }

  render() {
    return (
      <Link className="InformationPanel" href={this.props.link}>
        <div>{this.props.title}</div>
        <div>
          {this.props.img && typeof this.props.img != "object" && (
            <AutoImg src={this.props.img} ratio={2} />
          )}
          {this.props.img &&
            typeof this.props.img == "object" &&
            this.props.img.map((item, index) => (
              <div key={index}>
                <AutoImg src={item} ratio={4 / 3} />
              </div>
            ))}
        </div>
        <div>
          日期：{this.props.date}&nbsp;&nbsp;
          {this.props.source && "作者：" + this.props.source}
        </div>
        {(this.props.tags || this.props.views) && (
          <div>
            <div>
              {this.props.tags.map((item, key) => (
                <span key={key}>#{item}</span>
              ))}
            </div>
            <div>
              <span />
              {this.props.views} 个浏览
            </div>
          </div>
        )}
      </Link>
    );
  }
}

function mapStateToProps(state) {
  const { userState } = state;
  return { userState };
}

InformationPanel.propTypes = {
  userState: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  //img: PropTypes.any.isRequired,
  //date: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired
};

export default connect(mapStateToProps)(InformationPanel);
