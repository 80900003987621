import React from "react";
import { Link } from "framework7-react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "framework7-icons";
import "./CommunityPanel.less";
import AutoImg from "../AutoImg/";

class CommunityPanel extends React.Component {
  componentDidMount() {
    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;
      // this.$f7.views.main.router
    });
  }

  render() {
    let stars = [];
    for (let i = 0; i < this.props.rate; i++) {
      stars.push(true);
    }
    for (let i = this.props.rate; i < 5; i++) {
      stars.push(false);
    }
    return (
      <Link className="CommunityPanel" href={this.props.link}>
        <div>
          <AutoImg src={this.props.img} ratio={2} />
        </div>
        <div>{this.props.title}</div>
        <div>
          {stars.length > 0 && (
            <div>
              {stars.map((star, si) => (
                <span className={star ? "star" : ""} key={si} />
              ))}
            </div>
          )}
          {this.props.views && (
            <div>
              <span />
              <span>{this.props.views} 个预览</span>
            </div>
          )}
        </div>
        <div>
          <div>
            <span />
            <span>{this.props.location}</span>
          </div>
          {this.props.distance && <div>{this.props.distance}</div>}
        </div>
      </Link>
    );
  }
}

function mapStateToProps(state) {
  const { userState } = state;
  return { userState };
}

CommunityPanel.propTypes = {
  userState: PropTypes.object.isRequired,
  link: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  //rate: PropTypes.number.isRequired,
  //views: PropTypes.number.isRequired,
  location: PropTypes.string.isRequired
  //distance: PropTypes.string.isRequired
};

export default connect(mapStateToProps)(CommunityPanel);
