import React from "react";
import { Link, Swiper, SwiperSlide } from "framework7-react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "framework7-icons";
import "./carousel.less";
import ImgDefault from "../../images/default_43.png";

class Carousel extends React.Component {
  constructor(props) {
    super(props);
    this.getImgWidthAndHeight = this.getImgWidthAndHeight.bind(this);

    this.state = {
      data: []
    };

    this.mounted = false;
  }

  componentDidMount() {
    this.mounted = true;
    this.$f7ready(async f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      let result = this.props.data.concat();
      this.props.data.reduce(
        async (previousValue, currentValue, currentIndex, arr) => {
          await previousValue;
          try {
            result[currentIndex].style = await this.getImgWidthAndHeight(
              currentValue.img
            );
          } catch (e) {
            //console.log("ERROR", e);
          }
          if (currentIndex >= arr.length - 1) {
            if (this.mounted) {
              this.setState(
                {
                  data: result.filter(r => !!r.style)
                },
                () => {
                  // const swiper = app.swiper.get(".Carousel");
                  // swiper.on("tap", () => {
                  //   console.log(arguments, swiper.clickedIndex);
                  // });
                }
              );
            }
          }
          return Promise.resolve();
        },
        Promise.resolve()
      );

      // Promise.all(
      //   this.props.data.map(item => {
      //     return this.getImgWidthAndHeight(item.img);
      //   })
      // ).then(result => {
      //   result.forEach((data, index) => {
      //     this.props.data[index].style = data;
      //   });
      //   if (this.mounted) {
      //     this.setState(
      //       {
      //         data: this.props.data
      //       },
      //       () => {
      //         // const swiper = app.swiper.get(".Carousel");
      //         // swiper.on("tap", () => {
      //         //   console.log(arguments, swiper.clickedIndex);
      //         // });
      //       }
      //     );
      //   }
      // });
    });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getImgWidthAndHeight(imgsrc) {
    const designRatio = 750 / 400;
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.onload = function() {
        let style = {
          background: "url(" + imgsrc + ")",
          backgroundPosition: "center center"
        };
        if (designRatio > this.width / this.height) {
          style.backgroundSize = "100% auto";
        } else if (designRatio < this.width / this.height) {
          style.backgroundSize = "auto 100%";
        } else {
          style.backgroundSize = "100% 100%";
        }

        resolve(style);
      };
      img.onerror = function(e) {
        reject(e);
      };
      img.src = imgsrc;
    });
  }

  onClick(url, e) {
    this.$f7.views.main.router.navigate(url);
  }

  render() {
    if (this.state.data.length == 0) {
      return null;
    }
    /**
     
        <div
        data-swiper="{'loop':true, 'autoplay':{ delay: 3000 }}"
        data-pagination='{"el": ".swiper-pagination"}'
        className="Carousel swiper-container swiper-init "
      >
        <div className="swiper-pagination" />
        <div className="swiper-wrapper">
          {this.state.data.map((item, index) => (
            <div
              className="swiper-slide"
              key={index}
              onClick={this.onClick.bind(this, item.url)}
            >
              <div style={item.style} />
            </div>
          ))}
        </div>
        {this.props.showWeather && (
          <div slot="after-wrapper" className="weather">
            <div className="Today">
              <div>
                <div>今天</div>
                <div>
                  {this.props.weather[0]
                    ? this.props.weather[0].description
                    : ""}
                </div>
              </div>
            </div>
            <div className="Nextday">
              <div>
                <div>明天</div>
                <div>
                  {this.props.weather[1]
                    ? this.props.weather[1].description
                    : ""}
                </div>
              </div>
            </div>
          </div>
        )}
        </div>
     */
    return (
      <Swiper
        pagination
        className="Carousel"
        params={{
          autoplay: { delay: 3000 },
          loop: true,
          // initialSlide: 0
          // loopedSlides: this.props.imgs.length
          // speed: 500,
          // slidesPerView: 1
          // slidesOffsetBefore: 0,
          // slidesOffsetAfter: 0,
          on: {
            tap: e => {
              if (e.target.dataset.url) {
                this.$f7.views.main.router.navigate(e.target.dataset.url);
              }
            }
          }
        }}>
        {this.state.data.map((item, index) => (
          <SwiperSlide key={index} className="swiper-slide">
            {/**onTap={this.onClick.bind(this, item.url)} */}
            <div style={item.style} data-url={item.url} />
            {item.title && (
              <div className="slide-title">
                <div>{item.title}</div>
              </div>
            )}
          </SwiperSlide>
        ))}
        {this.props.showWeather && (
          <div slot="after-wrapper" className="weather">
            <div className="Today">
              <div>
                <div>今天</div>
                <div>
                  {this.props.weather[0]
                    ? this.props.weather[0].description
                    : ""}
                </div>
              </div>
            </div>
            <div className="Nextday">
              <div>
                <div>明天</div>
                <div>
                  {this.props.weather[1]
                    ? this.props.weather[1].description
                    : ""}
                </div>
              </div>
            </div>
          </div>
        )}
      </Swiper>
    );
  }
}

function mapStateToProps(state) {
  const { userState, pageState } = state;
  return { userState, pageState };
}

Carousel.propTypes = {
  userState: PropTypes.object.isRequired,
  pageState: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired
};

export default connect(mapStateToProps)(Carousel);
