import { apiCall, apiConfig } from "../api";

export const userActionType = {
  LOGIN: Symbol("LOGIN"),
  LOGOUT: Symbol("LOGOUT"),
  REGISTER_CODE: Symbol("REGISTER_CODE"),
  RETRIEVE_CODE: Symbol("RETRIEVE_CODE"),
  CHANGEPASSWORD: Symbol("CHANGEPASSWORD"),
  SETCODE: Symbol("SETCODE"),
  CHANGEPHONE: Symbol("CHANGEPHONE"),
  CHANGEGROUP: Symbol("CHANGEGROUP"),
  CHANGEUUID: Symbol("CHANGEUUID"),
  CHANGECONSULATEPICTURES: Symbol("CHANGECONSULATEPICTURES"),
  CHANGECONSULATEPICTUREINDEX: Symbol("CHANGECONSULATEPICTUREINDEX"),
  UPDATEFOCUSPRICES: Symbol("UPDATEFOCUSPRICES")
};

// // thunk action creator
// export function loginUser() {
//   return dispatch => {
//     dispatch({ type: userActionType.REGISTERUSER_START });
//     // return a promise, but it is not required
//     dispatch({
//       type: userActionType.LOGINUSER_SUCCESS
//     });
//   };
// }

// export function logoutUser() {
//   return { type: userActionType.LOGOUTUSER };
// }

// export function login(userinfo) {
//   return { type: userActionType.LOGIN, userinfo };
// }

export function updateUserInfo(preuserinfo, callback) {
  apiCall(
    "/sms/getUserInfo",
    "POST",
    {
      phone: preuserinfo.uphone,
      sign: preuserinfo.sign
    },
    data => {
      //console.log(data);
      if (
        data.code == apiConfig.errorCode1000 ||
        data.code == apiConfig.errorCode9999
      ) {
        //console.log(data.msg);
        if (callback) callback({});
        //this.$f7.dialog.alert(data.msg);
      } else {
        let userinfo = {};
        userinfo.sign = preuserinfo.sign;
        userinfo.uphone = data.content.baseInfo.uphone
          ? data.content.baseInfo.uphone
          : "";
        userinfo.uid = data.content.baseInfo.userId
          ? data.content.baseInfo.userId
          : "";
        userinfo.uname = data.content.baseInfo.uname
          ? data.content.baseInfo.uname
          : "";
        userinfo.ucard = data.content.baseInfo.idCard
          ? data.content.baseInfo.idCard
          : "";
        userinfo.ugroup = data.content.baseInfo.ogName
          ? data.content.baseInfo.ogName
          : "";
        userinfo.ugroupcode = data.content.baseInfo.ogId
          ? data.content.baseInfo.ogId
          : "";
        userinfo.ustate = data.content.baseInfo.ustateStr
          ? parseInt(data.content.baseInfo.ustateStr)
          : false;
        userinfo.ubind = data.content.baseInfo.bindStateStr
          ? parseInt(data.content.baseInfo.bindStateStr)
          : false;
        userinfo.favourite = data.content.favourite;
        userinfo.myNews = data.content.myNews;
        userinfo.versions = data.content.baseInfo.versions
          ? data.content.baseInfo.versions
          : apiConfig.typeAccountCountry;
        if (callback) callback(userinfo);
      }
    },
    (xhr, status) => {
      console.log(xhr, status);
      //this.$f7.dialog.alert(JSON.stringify(xhr));
    }
  );
}

export function login(preuserinfo) {
  return dispatch => {
    updateUserInfo(preuserinfo, userinfo => {
      dispatch({
        type: userActionType.LOGIN,
        userinfo: userinfo
      });
    });
  };
}

export function logout() {
  return { type: userActionType.LOGOUT };
}

export function registerCode(phone, code) {
  return { type: userActionType.REGISTER_CODE, phone, code };
}

export function retrieveCode(phone, code) {
  return { type: userActionType.RETRIEVE_CODE, phone, code };
}

export function changePassword(prepassword, newpassword) {
  return { type: userActionType.CHANGEPASSWORD, prepassword, newpassword };
}

export function setCode(code) {
  return { type: userActionType.SETCODE, code };
}

export function changePhone(phone, code) {
  return { type: userActionType.CHANGEPHONE, phone, code };
}

export function changeGroup(group, groupCode) {
  return { type: userActionType.CHANGEGROUP, group, groupCode };
}

export function changeUUID(uuid) {
  return { type: userActionType.CHANGEUUID, uuid };
}

export function changeConsulatePictures(pictures) {
  return { type: userActionType.CHANGECONSULATEPICTURES, pictures };
}

export function changeConsulatePictureIndex(index) {
  return { type: userActionType.CHANGECONSULATEPICTUREINDEX, index };
}

export function updateFocusPrices(prices) {
  return { type: userActionType.UPDATEFOCUSPRICES, prices };
}
