import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "framework7-icons";
import { Page, Swiper, SwiperSlide } from "framework7-react";
import "../css/pageExpert.less";
import ImgExpert1 from "../images/expert1.png";
import ImgExpert2 from "../images/expert2.png";

class Expert extends React.Component {
  constructor(props) {
    super(props);
    this.troggle = this.troggle.bind(this);
    this.state = {
      days: [
        { id: 1, dayname: "星期三", day: "10-31", isFocused: true },
        { id: 2, dayname: "星期四", day: "11-01", isFocused: false },
        { id: 3, dayname: "星期五", day: "11-02", isFocused: false },
        { id: 4, dayname: "星期六", day: "11-03", isFocused: false },
        { id: 5, dayname: "星期天", day: "11-04", isFocused: false },
        { id: 6, dayname: "星期一", day: "11-05", isFocused: false },
        { id: 7, dayname: "星期二", day: "11-06", isFocused: false }
      ],
      experts: [
        {
          id: 1,
          name: "徐兴国",
          title: "畜牧兽医",
          img: ImgExpert1,
          department: "上海农科院畜牧兽医所门诊部",
          feature: "畜牧饲养及疾病防治",
          description:
            "男，1953年生，兽医师，退休前工作与上海农科院畜牧门诊部。工作在生产第一线多年，做了大量的出诊及实验室工作，纪磊了丰富的畜牧饲养及疾病防治方面的经验。",
          showDescription: false,
          days: ["10-31"]
        },
        {
          id: 2,
          name: "王骑",
          title: "研究院",
          img: ImgExpert2,
          department: "市农委农业技术推广研究员",
          feature: "西甜瓜和食用菌",
          description:
            "男，1948年生，兽医师，退休前工作与上海农科院畜牧门诊部。工作在生产第一线多年，做了大量的出诊及实验室工作，纪磊了丰富的畜牧饲养及疾病防治方面的经验。",
          showDescription: false,
          days: ["10-31", "11-01"]
        }
      ]
    };
  }

  componentDidMount() {
    this.isComponentMounted = true;
    const _this = this;
    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      const swiper = app.swiper.get(".ExpertSwiper");
      swiper.on("tap", () => {
        // swiper.slides.map(function(index) {
        //   $(swiper.slides[index]).removeClass("swiper-active");
        // });
        // $(swiper.slides[swiper.clickedIndex]).addClass("swiper-active");
        //swiper.slideTo(swiper.clickedIndex, 500);
        if (_this.isComponentMounted) {
          _this.setState((prevState, prevProps) => {
            prevState.days.map(day => {
              day.isFocused = false;
              return day;
            });
            prevState.days[swiper.clickedIndex].isFocused = true;
            return {
              days: prevState.days
            };
          });
        }
      });
    });
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  troggle(expertId) {
    const _this = this;
    return e => {
      _this.state.experts.map(expert => {
        if (expert.id == expertId) {
          expert.showDescription = !expert.showDescription;
        }
      });
      if (_this.isComponentMounted) {
        _this.setState(prevState => ({ experts: prevState.experts }));
      }
    };
  }

  render() {
    const _this = this;
    return (
      <Page name="Expert">
        <Swiper
          params={{
            speed: 500,
            slidesPerView: "auto",
            slidesOffsetBefore: 0,
            slidesOffsetAfter: 0
          }}
          className="ExpertSwiper"
        >
          {this.state.days.map(day => (
            <SwiperSlide
              key={day.id}
              className={day.isFocused ? "swiper-active" : ""}
            >
              <div>{day.dayname}</div>
              <div>{day.day}</div>
            </SwiperSlide>
          ))}
        </Swiper>

        <div className="ExpertList">
          {this.state.experts.map(expert => {
            let showExpert = false;
            expert.days.map(expertDay => {
              _this.state.days.map(day => {
                if (day.day == expertDay && day.isFocused) {
                  showExpert = true;
                }
              });
            });
            if (!showExpert) return;
            else
              return (
                <div className="ExpertItem" key={expert.id}>
                  <div>
                    <div>
                      <img src={expert.img} />
                    </div>
                    <div>
                      <div>
                        <span>{expert.name}</span>
                        <span>{expert.title}</span>
                      </div>
                      <div>{expert.department}</div>
                      <div>擅长：{expert.feature}</div>
                    </div>
                    <div onClick={this.troggle(expert.id)}>
                      {expert.showDescription ? "收起介绍" : "专家介绍"}
                      <i className="f7-icons">
                        {expert.showDescription ? "up" : "down"}
                      </i>
                    </div>
                  </div>
                  <div className={expert.showDescription ? "show" : ""}>
                    <div>{expert.description}</div>
                  </div>
                </div>
              );
          })}
        </div>
      </Page>
    );
  }
}

function mapStateToProps(state) {
  const { userState } = state;
  return { userState };
}

Expert.propTypes = {
  userState: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(Expert);
