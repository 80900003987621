import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "framework7-icons";
import { Page, Link, Toolbar, Tabs, Tab } from "framework7-react";
import "../css/pageCommunityDetail.less";
import { apiCall, apiConfig } from "../api";

class CommunityDetail extends React.Component {
  constructor(props) {
    super(props);
    this.communityId = this.$f7route.params.communityId;
    this.state = {
      detail: []
    };
  }

  componentDidMount() {
    const _this = this;
    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      this.isComponentMounted = true;

      apiCall(
        "/app/getYinongInfoById",
        "POST",
        {
          id: this.communityId
        },
        data => {
          if (this.isComponentMounted) {
            this.setState(
              {
                detail: data.content.detail
              },
              () => {}
            );
          }
        },
        (xhr, status) => {
          //console.log(xhr, status);
          this.$f7.dialog.alert(xhr);
        }
      );
    });
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  render() {
    return (
      <Page name="CommunityDetail">
        <div className="community-info-abstruct">
          <div>
            <div>{this.state.detail.ynName}</div>
            <div>
              <span />
              {this.state.detail.address}
            </div>
            <div
              className={
                parseInt(this.state.detail.type) ==
                apiConfig.typeCommunityProfessional
                  ? "pro"
                  : ""
              }
            />
          </div>
          <div>
            <div>
              编码：{this.state.detail.num ? this.state.detail.num : "无数据"}
            </div>
            <div>
              负责人：
              {this.state.detail.managerName
                ? this.state.detail.managerName
                : "无数据"}
            </div>
          </div>
          <div>
            <div>
              信息员：
              {this.state.detail.messengerName
                ? this.state.detail.messengerName
                : "无数据"}
            </div>
            <div>
              信息员联系方式：
              {this.state.detail.messengerPhone
                ? this.state.detail.messengerPhone
                : "无数据"}
            </div>
          </div>
        </div>

        {this.state.detail.summary && (
          <div className="community-info-abstruct-2">
            <div>
              <Link className="topDiv" href="###">
                <div>概况</div>
              </Link>
              <div>
                <div>{this.state.detail.summary}</div>
              </div>
            </div>
          </div>
        )}

        {this.state.detail.business && (
          <div className="community-info-abstruct-2">
            <div>
              <Link className="topDiv" href="###">
                <div>主营业务</div>
              </Link>
              <div>
                <div>{this.state.detail.business}</div>
              </div>
            </div>
          </div>
        )}

        {this.state.detail.serviceContent && (
          <div className="community-info-abstruct-2">
            <div>
              <Link className="topDiv" href="###">
                <div>服务内容</div>
              </Link>
              <div>
                <div>{this.state.detail.serviceContent}</div>
              </div>
            </div>
          </div>
        )}

        {this.state.detail.wechatImg && this.state.detail.wechatName && (
          <div className="community-info-abstruct-2">
            <div>
              <Link className="topDiv" href="###">
                <div>微信公众号</div>
              </Link>
              <div>
                <div className="center">
                  <img src={this.state.detail.wechatImg} />
                  <div>微信公众号名称：{this.state.detail.wechatName}</div>
                </div>
              </div>
            </div>
          </div>
        )}

        {this.state.detail.shopUrl && (
          <div className="community-info-abstruct-2">
            <div>
              <Link className="topDiv" href="###">
                <div>网店地址</div>
              </Link>
              <div>
                <div>
                  <Link href="{this.state.detail.shopUrl}">
                    {this.state.detail.shopUrl}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </Page>
    );
  }
}

function mapStateToProps(state) {
  const { userState } = state;
  return { userState };
}

CommunityDetail.propTypes = {
  userState: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(CommunityDetail);
