import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "framework7-icons";
import { Page, Searchbar, Swiper, SwiperSlide } from "framework7-react";
import "../css/pageFollowproducts.less";

class Followproducts extends React.Component {
  constructor(props) {
    super(props);
    this.changeCategory = this.changeCategory.bind(this);
    this.deleteFocusedCategory = this.deleteFocusedCategory.bind(this);
    this.focusCategory = this.focusCategory.bind(this);

    this.state = {
      categories: [
        {
          categoryId: 1,
          categoryName: "南方水果",
          isSelected: true,
          subs: [
            { categoryId: 1001, categoryName: "番石榴", isFocused: true },
            { categoryId: 1002, categoryName: "释迦果", isFocused: false },
            { categoryId: 1003, categoryName: "莲雾", isFocused: false },
            { categoryId: 1004, categoryName: "荔枝", isFocused: false },
            { categoryId: 1005, categoryName: "龙眼", isFocused: false },
            { categoryId: 1006, categoryName: "榴莲", isFocused: false },
            { categoryId: 1007, categoryName: "山竹", isFocused: false },
            { categoryId: 1008, categoryName: "柠檬", isFocused: false },
            { categoryId: 1009, categoryName: "菠萝", isFocused: false }
          ]
        },
        {
          categoryId: 2,
          categoryName: "瓜类水果",
          isSelected: false,
          subs: [
            { categoryId: 2001, categoryName: "番石榴2", isFocused: false },
            { categoryId: 2002, categoryName: "释迦果", isFocused: false },
            { categoryId: 2003, categoryName: "莲雾", isFocused: false },
            { categoryId: 2004, categoryName: "荔枝", isFocused: false },
            { categoryId: 2005, categoryName: "龙眼", isFocused: false },
            { categoryId: 2006, categoryName: "榴莲", isFocused: false },
            { categoryId: 2007, categoryName: "山竹", isFocused: false },
            { categoryId: 2008, categoryName: "柠檬", isFocused: false },
            { categoryId: 2009, categoryName: "菠萝", isFocused: false },
            { categoryId: 20010, categoryName: "火龙果", isFocused: false }
          ]
        },
        {
          categoryId: 3,
          categoryName: "落叶水果",
          isSelected: false,
          subs: [
            { categoryId: 3001, categoryName: "番石榴3", isFocused: false },
            { categoryId: 3002, categoryName: "释迦果", isFocused: false },
            { categoryId: 3003, categoryName: "莲雾", isFocused: false },
            { categoryId: 3004, categoryName: "荔枝", isFocused: false },
            { categoryId: 3005, categoryName: "龙眼", isFocused: false },
            { categoryId: 3006, categoryName: "榴莲", isFocused: false },
            { categoryId: 3007, categoryName: "山竹", isFocused: false },
            { categoryId: 3008, categoryName: "柠檬", isFocused: false },
            { categoryId: 3009, categoryName: "菠萝", isFocused: false },
            { categoryId: 30010, categoryName: "火龙果", isFocused: false },
            { categoryId: 30011, categoryName: "番石榴", isFocused: false }
          ]
        },
        {
          categoryId: 4,
          categoryName: "浆果类",
          isSelected: false,
          subs: [
            { categoryId: 4001, categoryName: "番石榴4", isFocused: false },
            { categoryId: 4002, categoryName: "释迦果", isFocused: false },
            { categoryId: 4003, categoryName: "莲雾", isFocused: false },
            { categoryId: 4004, categoryName: "荔枝", isFocused: false },
            { categoryId: 4005, categoryName: "龙眼", isFocused: false },
            { categoryId: 4006, categoryName: "榴莲", isFocused: false },
            { categoryId: 4007, categoryName: "山竹", isFocused: false },
            { categoryId: 4008, categoryName: "柠檬", isFocused: false }
          ]
        },
        {
          categoryId: 5,
          categoryName: "粮食作物",
          isSelected: false,
          subs: [
            { categoryId: 5001, categoryName: "番石榴5", isFocused: false },
            { categoryId: 5002, categoryName: "释迦果", isFocused: false },
            { categoryId: 5003, categoryName: "莲雾", isFocused: false },
            { categoryId: 5004, categoryName: "荔枝", isFocused: false },
            { categoryId: 5005, categoryName: "龙眼", isFocused: false },
            { categoryId: 5006, categoryName: "榴莲", isFocused: false },
            { categoryId: 5007, categoryName: "山竹", isFocused: false },
            { categoryId: 5008, categoryName: "柠檬", isFocused: false },
            { categoryId: 5009, categoryName: "菠萝", isFocused: false },
            { categoryId: 50010, categoryName: "火龙果", isFocused: false }
          ]
        },
        {
          categoryId: 6,
          categoryName: "薯类",
          isSelected: false,
          subs: [
            { categoryId: 6001, categoryName: "番石榴6", isFocused: false },
            { categoryId: 6002, categoryName: "释迦果", isFocused: false },
            { categoryId: 6003, categoryName: "莲雾", isFocused: false },
            { categoryId: 6004, categoryName: "荔枝", isFocused: false },
            { categoryId: 6005, categoryName: "龙眼", isFocused: false },
            { categoryId: 6006, categoryName: "榴莲", isFocused: false },
            { categoryId: 6007, categoryName: "山竹", isFocused: false },
            { categoryId: 6008, categoryName: "柠檬", isFocused: false },
            { categoryId: 6009, categoryName: "菠萝", isFocused: false },
            { categoryId: 60010, categoryName: "火龙果", isFocused: false }
          ]
        },
        {
          categoryId: 7,
          categoryName: "经济作物",
          isSelected: false,
          subs: [
            { categoryId: 7001, categoryName: "番石榴7", isFocused: false },
            { categoryId: 7002, categoryName: "释迦果", isFocused: false },
            { categoryId: 7003, categoryName: "莲雾", isFocused: false },
            { categoryId: 7004, categoryName: "荔枝", isFocused: false },
            { categoryId: 7005, categoryName: "龙眼", isFocused: false },
            { categoryId: 7006, categoryName: "榴莲", isFocused: false },
            { categoryId: 7007, categoryName: "山竹", isFocused: false },
            { categoryId: 7008, categoryName: "柠檬", isFocused: false },
            { categoryId: 7009, categoryName: "菠萝", isFocused: false }
          ]
        },
        {
          categoryId: 8,
          categoryName: "叶菜类蔬菜",
          isSelected: false,
          subs: [
            { categoryId: 8001, categoryName: "番石榴8", isFocused: false },
            { categoryId: 8002, categoryName: "释迦果", isFocused: false },
            { categoryId: 8003, categoryName: "莲雾", isFocused: false },
            { categoryId: 8004, categoryName: "荔枝", isFocused: false },
            { categoryId: 8005, categoryName: "龙眼", isFocused: false },
            { categoryId: 8006, categoryName: "榴莲", isFocused: false },
            { categoryId: 8007, categoryName: "山竹", isFocused: false },
            { categoryId: 8008, categoryName: "柠檬", isFocused: false },
            { categoryId: 8009, categoryName: "菠萝", isFocused: false },
            { categoryId: 80010, categoryName: "火龙果", isFocused: false },
            { categoryId: 80011, categoryName: "番石榴", isFocused: false }
          ]
        },
        {
          categoryId: 9,
          categoryName: "根茎类蔬菜",
          isSelected: false,
          subs: [
            { categoryId: 9001, categoryName: "番石榴9", isFocused: false },
            { categoryId: 9002, categoryName: "释迦果", isFocused: false },
            { categoryId: 9003, categoryName: "莲雾", isFocused: false },
            { categoryId: 9004, categoryName: "荔枝", isFocused: false },
            { categoryId: 9005, categoryName: "龙眼", isFocused: false },
            { categoryId: 9006, categoryName: "榴莲", isFocused: false },
            { categoryId: 9007, categoryName: "山竹", isFocused: false },
            { categoryId: 9008, categoryName: "柠檬", isFocused: false },
            { categoryId: 9009, categoryName: "菠萝", isFocused: false },
            { categoryId: 90010, categoryName: "火龙果", isFocused: false }
          ]
        },
        {
          categoryId: 10,
          categoryName: "茄果瓜类",
          isSelected: false,
          subs: [
            { categoryId: 10001, categoryName: "番石榴10", isFocused: false },
            { categoryId: 10002, categoryName: "释迦果", isFocused: false },
            { categoryId: 10003, categoryName: "莲雾", isFocused: false },
            { categoryId: 10004, categoryName: "荔枝", isFocused: false },
            { categoryId: 10005, categoryName: "龙眼", isFocused: false },
            { categoryId: 10006, categoryName: "榴莲", isFocused: false },
            { categoryId: 10007, categoryName: "山竹", isFocused: false },
            { categoryId: 10008, categoryName: "柠檬", isFocused: false },
            { categoryId: 10009, categoryName: "菠萝", isFocused: false },
            { categoryId: 100010, categoryName: "火龙果", isFocused: false },
            { categoryId: 100011, categoryName: "番石榴", isFocused: false },
            { categoryId: 100012, categoryName: "释迦果", isFocused: false }
          ]
        },
        {
          categoryId: 11,
          categoryName: "南方水果",
          isSelected: false,
          subs: [
            { categoryId: 11001, categoryName: "番石榴11", isFocused: false },
            { categoryId: 11002, categoryName: "释迦果", isFocused: false },
            { categoryId: 11003, categoryName: "莲雾", isFocused: false },
            { categoryId: 11004, categoryName: "荔枝", isFocused: false },
            { categoryId: 11005, categoryName: "龙眼", isFocused: false },
            { categoryId: 11006, categoryName: "榴莲", isFocused: false },
            { categoryId: 11007, categoryName: "山竹", isFocused: false },
            { categoryId: 11008, categoryName: "柠檬", isFocused: false },
            { categoryId: 11009, categoryName: "菠萝", isFocused: false },
            { categoryId: 110010, categoryName: "火龙果", isFocused: false },
            { categoryId: 110011, categoryName: "番石榴", isFocused: false }
          ]
        },
        {
          categoryId: 12,
          categoryName: "瓜类水果",
          isSelected: false,
          subs: [
            { categoryId: 12001, categoryName: "番石榴12", isFocused: false },
            { categoryId: 12002, categoryName: "释迦果", isFocused: false },
            { categoryId: 12003, categoryName: "莲雾", isFocused: false },
            { categoryId: 12004, categoryName: "荔枝", isFocused: false },
            { categoryId: 12005, categoryName: "龙眼", isFocused: false },
            { categoryId: 12006, categoryName: "榴莲", isFocused: false },
            { categoryId: 12007, categoryName: "山竹", isFocused: false },
            { categoryId: 12008, categoryName: "柠檬", isFocused: false },
            { categoryId: 12009, categoryName: "菠萝", isFocused: false },
            { categoryId: 120010, categoryName: "火龙果", isFocused: false },
            { categoryId: 120011, categoryName: "番石榴", isFocused: false }
          ]
        },
        {
          categoryId: 13,
          categoryName: "落叶水果",
          isSelected: false,
          subs: [
            { categoryId: 13001, categoryName: "番石榴13", isFocused: false },
            { categoryId: 13002, categoryName: "释迦果", isFocused: false },
            { categoryId: 13003, categoryName: "莲雾", isFocused: false },
            { categoryId: 13004, categoryName: "荔枝", isFocused: false },
            { categoryId: 13005, categoryName: "龙眼", isFocused: false },
            { categoryId: 13006, categoryName: "榴莲", isFocused: false },
            { categoryId: 13007, categoryName: "山竹", isFocused: false },
            { categoryId: 13008, categoryName: "柠檬", isFocused: false },
            { categoryId: 13009, categoryName: "菠萝", isFocused: false },
            { categoryId: 130010, categoryName: "火龙果", isFocused: false },
            { categoryId: 130011, categoryName: "番石榴", isFocused: false }
          ]
        },
        {
          categoryId: 14,
          categoryName: "浆果类",
          isSelected: false,
          subs: [
            { categoryId: 14001, categoryName: "番石榴14", isFocused: false },
            { categoryId: 14002, categoryName: "释迦果", isFocused: false },
            { categoryId: 14003, categoryName: "莲雾", isFocused: false },
            { categoryId: 14004, categoryName: "荔枝", isFocused: false },
            { categoryId: 14005, categoryName: "龙眼", isFocused: false },
            { categoryId: 14006, categoryName: "榴莲", isFocused: false },
            { categoryId: 14007, categoryName: "山竹", isFocused: false },
            { categoryId: 14008, categoryName: "柠檬", isFocused: false },
            { categoryId: 14009, categoryName: "菠萝", isFocused: false },
            { categoryId: 140010, categoryName: "火龙果", isFocused: false },
            { categoryId: 140011, categoryName: "番石榴", isFocused: false }
          ]
        },
        {
          categoryId: 15,
          categoryName: "粮食作物",
          isSelected: false,
          subs: [
            { categoryId: 15001, categoryName: "番石榴15", isFocused: false },
            { categoryId: 15002, categoryName: "释迦果", isFocused: false },
            { categoryId: 15003, categoryName: "莲雾", isFocused: false },
            { categoryId: 15004, categoryName: "荔枝", isFocused: false },
            { categoryId: 15005, categoryName: "龙眼", isFocused: false },
            { categoryId: 15006, categoryName: "榴莲", isFocused: false },
            { categoryId: 15007, categoryName: "山竹", isFocused: false },
            { categoryId: 15008, categoryName: "柠檬", isFocused: false },
            { categoryId: 15009, categoryName: "菠萝", isFocused: false },
            { categoryId: 150010, categoryName: "火龙果", isFocused: false },
            { categoryId: 150011, categoryName: "番石榴", isFocused: false },
            { categoryId: 150012, categoryName: "释迦果", isFocused: false }
          ]
        },
        {
          categoryId: 16,
          categoryName: "南方水果",
          isSelected: false,
          subs: [
            { categoryId: 16001, categoryName: "番石榴16", isFocused: false },
            { categoryId: 16002, categoryName: "释迦果", isFocused: false },
            { categoryId: 16003, categoryName: "莲雾", isFocused: false },
            { categoryId: 16004, categoryName: "荔枝", isFocused: false },
            { categoryId: 16005, categoryName: "龙眼", isFocused: false },
            { categoryId: 16006, categoryName: "榴莲", isFocused: false },
            { categoryId: 16007, categoryName: "山竹", isFocused: false },
            { categoryId: 16008, categoryName: "柠檬", isFocused: false },
            { categoryId: 16009, categoryName: "菠萝", isFocused: false },
            { categoryId: 160010, categoryName: "火龙果", isFocused: false },
            { categoryId: 160011, categoryName: "番石榴", isFocused: false },
            { categoryId: 160012, categoryName: "释迦果", isFocused: false }
          ]
        },
        {
          categoryId: 17,
          categoryName: "瓜类水果",
          isSelected: false,
          subs: [
            { categoryId: 17001, categoryName: "番石榴17", isFocused: false },
            { categoryId: 17002, categoryName: "释迦果", isFocused: false },
            { categoryId: 17003, categoryName: "莲雾", isFocused: false },
            { categoryId: 17004, categoryName: "荔枝", isFocused: false },
            { categoryId: 17005, categoryName: "龙眼", isFocused: false },
            { categoryId: 17006, categoryName: "榴莲", isFocused: false },
            { categoryId: 17007, categoryName: "山竹", isFocused: false },
            { categoryId: 17008, categoryName: "柠檬", isFocused: false },
            { categoryId: 17009, categoryName: "菠萝", isFocused: false },
            { categoryId: 170010, categoryName: "火龙果", isFocused: false },
            { categoryId: 170011, categoryName: "番石榴", isFocused: false }
          ]
        },
        {
          categoryId: 18,
          categoryName: "落叶水果",
          isSelected: false,
          subs: [
            { categoryId: 18001, categoryName: "番石榴18", isFocused: false },
            { categoryId: 18002, categoryName: "释迦果", isFocused: false },
            { categoryId: 18003, categoryName: "莲雾", isFocused: false },
            { categoryId: 18004, categoryName: "荔枝", isFocused: false },
            { categoryId: 18005, categoryName: "龙眼", isFocused: false },
            { categoryId: 18006, categoryName: "榴莲", isFocused: false },
            { categoryId: 18007, categoryName: "山竹", isFocused: false },
            { categoryId: 18008, categoryName: "柠檬", isFocused: false },
            { categoryId: 18009, categoryName: "菠萝", isFocused: false },
            { categoryId: 180010, categoryName: "火龙果", isFocused: false },
            { categoryId: 180011, categoryName: "番石榴", isFocused: false }
          ]
        },
        {
          categoryId: 19,
          categoryName: "浆果类",
          isSelected: false,
          subs: [
            { categoryId: 19001, categoryName: "番石榴19", isFocused: false },
            { categoryId: 19002, categoryName: "释迦果", isFocused: false },
            { categoryId: 19003, categoryName: "莲雾", isFocused: false },
            { categoryId: 19004, categoryName: "荔枝", isFocused: false },
            { categoryId: 19005, categoryName: "龙眼", isFocused: false },
            { categoryId: 19006, categoryName: "榴莲", isFocused: false },
            { categoryId: 19007, categoryName: "山竹", isFocused: false },
            { categoryId: 19008, categoryName: "柠檬", isFocused: false },
            { categoryId: 19009, categoryName: "菠萝", isFocused: false },
            { categoryId: 190010, categoryName: "火龙果", isFocused: false },
            { categoryId: 190011, categoryName: "番石榴", isFocused: false },
            { categoryId: 190012, categoryName: "释迦果", isFocused: false },
            { categoryId: 190013, categoryName: "莲雾", isFocused: false },
            { categoryId: 190014, categoryName: "荔枝", isFocused: false }
          ]
        },
        {
          categoryId: 20,
          categoryName: "粮食作物",
          isSelected: false,
          subs: [
            { categoryId: 20001, categoryName: "番石榴20", isFocused: false },
            { categoryId: 20002, categoryName: "释迦果", isFocused: false },
            { categoryId: 20003, categoryName: "莲雾", isFocused: false },
            { categoryId: 20004, categoryName: "荔枝", isFocused: false },
            { categoryId: 20005, categoryName: "龙眼", isFocused: false },
            { categoryId: 20006, categoryName: "榴莲", isFocused: false },
            { categoryId: 20007, categoryName: "山竹", isFocused: false },
            { categoryId: 20008, categoryName: "柠檬", isFocused: false },
            { categoryId: 20009, categoryName: "菠萝", isFocused: false },
            { categoryId: 200010, categoryName: "火龙果", isFocused: false },
            { categoryId: 200011, categoryName: "番石榴", isFocused: false },
            { categoryId: 200012, categoryName: "释迦果", isFocused: false },
            { categoryId: 200013, categoryName: "莲雾", isFocused: false }
          ]
        },
        {
          categoryId: 21,
          categoryName: "薯类",
          isSelected: false,
          subs: [
            { categoryId: 21001, categoryName: "番石榴21", isFocused: false },
            { categoryId: 21002, categoryName: "释迦果", isFocused: false },
            { categoryId: 21003, categoryName: "莲雾", isFocused: false },
            { categoryId: 21004, categoryName: "荔枝", isFocused: false },
            { categoryId: 21005, categoryName: "龙眼", isFocused: false },
            { categoryId: 21006, categoryName: "榴莲", isFocused: false },
            { categoryId: 21007, categoryName: "山竹", isFocused: false },
            { categoryId: 21008, categoryName: "柠檬", isFocused: false },
            { categoryId: 21009, categoryName: "菠萝", isFocused: false },
            { categoryId: 210010, categoryName: "火龙果", isFocused: false },
            { categoryId: 210011, categoryName: "番石榴", isFocused: false },
            { categoryId: 210012, categoryName: "释迦果", isFocused: false },
            { categoryId: 210013, categoryName: "莲雾", isFocused: false },
            { categoryId: 210014, categoryName: "荔枝", isFocused: false }
          ]
        },
        {
          categoryId: 22,
          categoryName: "经济作物",
          isSelected: false,
          subs: [
            { categoryId: 22001, categoryName: "番石榴22", isFocused: false },
            { categoryId: 22002, categoryName: "释迦果", isFocused: false },
            { categoryId: 22003, categoryName: "莲雾", isFocused: false },
            { categoryId: 22004, categoryName: "荔枝", isFocused: false },
            { categoryId: 22005, categoryName: "龙眼", isFocused: false },
            { categoryId: 22006, categoryName: "榴莲", isFocused: false },
            { categoryId: 22007, categoryName: "山竹", isFocused: false },
            { categoryId: 22008, categoryName: "柠檬", isFocused: false },
            { categoryId: 22009, categoryName: "菠萝", isFocused: false },
            { categoryId: 220010, categoryName: "火龙果", isFocused: false },
            { categoryId: 220011, categoryName: "番石榴", isFocused: false },
            { categoryId: 220012, categoryName: "释迦果", isFocused: false },
            { categoryId: 220013, categoryName: "莲雾", isFocused: false },
            { categoryId: 220014, categoryName: "荔枝", isFocused: false },
            { categoryId: 220015, categoryName: "龙眼", isFocused: false },
            { categoryId: 220016, categoryName: "榴莲", isFocused: false },
            { categoryId: 220017, categoryName: "山竹", isFocused: false },
            { categoryId: 220018, categoryName: "柠檬", isFocused: false },
            { categoryId: 220019, categoryName: "菠萝", isFocused: false },
            { categoryId: 220020, categoryName: "火龙果", isFocused: false }
          ]
        },
        {
          categoryId: 23,
          categoryName: "叶菜类蔬菜",
          isSelected: false,
          subs: [
            { categoryId: 23001, categoryName: "番石榴23", isFocused: false },
            { categoryId: 23002, categoryName: "释迦果", isFocused: false },
            { categoryId: 23003, categoryName: "莲雾", isFocused: false },
            { categoryId: 23004, categoryName: "荔枝", isFocused: false },
            { categoryId: 23005, categoryName: "龙眼", isFocused: false },
            { categoryId: 23006, categoryName: "榴莲", isFocused: false },
            { categoryId: 23007, categoryName: "山竹", isFocused: false },
            { categoryId: 23008, categoryName: "柠檬", isFocused: false },
            { categoryId: 23009, categoryName: "菠萝", isFocused: false },
            { categoryId: 230010, categoryName: "火龙果", isFocused: false },
            { categoryId: 230011, categoryName: "番石榴", isFocused: false },
            { categoryId: 230012, categoryName: "释迦果", isFocused: false },
            { categoryId: 230013, categoryName: "莲雾", isFocused: false },
            { categoryId: 230014, categoryName: "荔枝", isFocused: false },
            { categoryId: 230015, categoryName: "龙眼", isFocused: false },
            { categoryId: 230016, categoryName: "榴莲", isFocused: false },
            { categoryId: 230017, categoryName: "山竹", isFocused: false },
            { categoryId: 230018, categoryName: "柠檬", isFocused: false },
            { categoryId: 230019, categoryName: "菠萝", isFocused: false },
            { categoryId: 230020, categoryName: "火龙果", isFocused: false }
          ]
        },
        {
          categoryId: 24,
          categoryName: "根茎类蔬菜",
          isSelected: false,
          subs: [
            { categoryId: 24001, categoryName: "番石榴24", isFocused: false },
            { categoryId: 24002, categoryName: "释迦果", isFocused: false },
            { categoryId: 24003, categoryName: "莲雾", isFocused: false },
            { categoryId: 24004, categoryName: "荔枝", isFocused: false },
            { categoryId: 24005, categoryName: "龙眼", isFocused: false },
            { categoryId: 24006, categoryName: "榴莲", isFocused: false },
            { categoryId: 24007, categoryName: "山竹", isFocused: false },
            { categoryId: 24008, categoryName: "柠檬", isFocused: false },
            { categoryId: 24009, categoryName: "菠萝", isFocused: false },
            { categoryId: 240010, categoryName: "火龙果", isFocused: false },
            { categoryId: 240011, categoryName: "番石榴", isFocused: false },
            { categoryId: 240012, categoryName: "释迦果", isFocused: false },
            { categoryId: 240013, categoryName: "莲雾", isFocused: false },
            { categoryId: 240014, categoryName: "荔枝", isFocused: false },
            { categoryId: 240015, categoryName: "龙眼", isFocused: false },
            { categoryId: 240016, categoryName: "榴莲", isFocused: false },
            { categoryId: 240017, categoryName: "山竹", isFocused: false },
            { categoryId: 240018, categoryName: "柠檬", isFocused: false },
            { categoryId: 240019, categoryName: "菠萝", isFocused: false },
            { categoryId: 240020, categoryName: "火龙果", isFocused: false }
          ]
        }
      ],
      focusedCategories: [
        { categoryId: 1001, categoryName: "番石榴" },
        { categoryId: 921001, categoryName: "水稻" },
        { categoryId: 921002, categoryName: "小麦" },
        { categoryId: 921003, categoryName: "大闸蟹" },
        { categoryId: 921004, categoryName: "小龙虾" },
        { categoryId: 921005, categoryName: "青菜" },
        { categoryId: 921006, categoryName: "小白菜" },
        { categoryId: 921007, categoryName: "卷心菜" }
      ]
    };
  }

  componentDidMount() {
    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      const swiper = app.swiper.get(".followedProductsSwiper");
      swiper.on("tap", function() {
        swiper.slides.map(function(index) {
          $(swiper.slides[index]).removeClass("swiper-active");
        });
        $(swiper.slides[swiper.clickedIndex]).addClass("swiper-active");
      });
    });
  }

  changeCategory(categoryId) {
    const self = this;
    const $ = self.$$;
    return function(e) {
      $(e.target)
        .siblings()
        .map((key, item) => {
          $(item).removeClass("focus");
        });
      $(e.target).addClass("focus");
      self.setState((prevState, prevProps) => {
        prevState.categories.map(category => {
          if (category.categoryId == categoryId) {
            category.isSelected = true;
          } else {
            category.isSelected = false;
          }
        });
        return { categories: prevState.categories };
      });
    };
  }

  deleteFocusedCategory(categoryId) {
    const self = this;
    const $ = self.$$;
    return function(e) {
      self.setState((prevState, prevProps) => {
        let nextFocusedCategories = prevState.focusedCategories.filter(item => {
          if (item.categoryId == categoryId) {
            return false;
          } else {
            return true;
          }
        });
        let nextCategories = prevState.categories.map(parent => {
          parent.subs.map(item => {
            if (item.categoryId == categoryId) {
              item.isFocused = false;
            }
            return item;
          });
          return parent;
        });
        return {
          focusedCategories: nextFocusedCategories,
          categories: nextCategories
        };
      });
    };
  }

  focusCategory(categoryId, categoryName) {
    const self = this;
    const $ = self.$$;
    return function(e) {
      self.setState((prevState, prevProps) => {
        prevState.focusedCategories.unshift({
          categoryId,
          categoryName
        });
        let nextCategories = prevState.categories.map(parent => {
          parent.subs.map(item => {
            if (item.categoryId == categoryId) {
              item.isFocused = true;
            }
            return item;
          });
          return parent;
        });
        return {
          focusedCategories: prevState.focusedCategories,
          categories: nextCategories
        };
      });
    };
  }

  render() {
    const self = this;
    return (
      <Page name="Followproducts">
        <Searchbar
          className="FollowproductsSearchbar"
          placeholder="请输入要搜索的内容"
          form={false}
          clearButton={true}
          disableButton={false}
        />

        <div className="chooseFollow">
          <div>
            {self.state.categories.map((item, index) => (
              <div
                key={item.categoryId}
                className={index == 0 ? "focus" : ""}
                onClick={this.changeCategory(item.categoryId)}
              >
                {item.categoryName}
              </div>
            ))}
          </div>
          <div>
            {self.state.categories.map(parent => {
              if (!parent.isSelected) {
                return false;
              }
              return parent.subs.map(item => (
                <div key={item.categoryId}>
                  <div>{item.categoryName}</div>
                  {item.isFocused ? (
                    <div className="focused">已关注</div>
                  ) : (
                    <div
                      onClick={this.focusCategory(
                        item.categoryId,
                        item.categoryName
                      )}
                    >
                      关注
                    </div>
                  )}
                </div>
              ));
            })}
          </div>
        </div>

        <div className="followedProducts">
          <div>已关注的农产品</div>
          <div>
            <Swiper
              params={{
                speed: 500,
                slidesPerView: "auto",
                slidesOffsetBefore: 0,
                slidesOffsetAfter: 0
              }}
              className="followedProductsSwiper"
            >
              {self.state.focusedCategories.map((item, index) => (
                <SwiperSlide
                  key={item.categoryId}
                  className={index == 0 ? "swiper-active" : ""}
                >
                  {item.categoryName}
                  <span onClick={this.deleteFocusedCategory(item.categoryId)} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </Page>
    );
  }
}

function mapStateToProps(state) {
  const { userState } = state;
  return { userState };
}

Followproducts.propTypes = {
  userState: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(Followproducts);
