import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "framework7-icons";
import { Page, Link } from "framework7-react";
import CategoryPicker from "../components/CategoryPicker/";
import "framework7-icons";
import { apiCall, apiConfig, callOrigin, uploadRegId } from "../api";
import "../css/pageMy.less";
import ImgHeader from "../images/im_me.png";
import ImgHeaderCun from "../images/account-cun.jpg";

class My extends React.Component {
  constructor(props) {
    super(props);

    this.getLocation = this.getLocation.bind(this);
    this.getregId = this.getregId.bind(this);
    this.callPhone = this.callPhone.bind(this);
    this.goto = this.goto.bind(this);
    this.getPageShow = this.getPageShow.bind(this);

    this.selectedCallback = this.selectedCallback.bind(this);

    let pageShow = this.getPageShow(this.props.userState.userinfo);
    let versions = this.props.userState.userinfo.versions
      ? this.props.userState.userinfo.versions
      : apiConfig.typeAccountCountry;

    this.state = {
      versions: versions,
      userHeaderImg:
        versions == apiConfig.typeAccountPerson ? ImgHeader : ImgHeaderCun,
      authenticationShow: pageShow.authenticationShow,
      authenticationNextUrl: pageShow.authenticationNextUrl,
      userGroup: "新义村",
      initSelectedValue: [2, 27, 33],
      groupNodes: [
        [
          { parentId: 0, id: 1, name: "浦东新区" },
          { parentId: 0, id: 2, name: "静安区" }
        ],
        [
          { parentId: 1, id: 21, name: "外冈镇" },
          { parentId: 1, id: 22, name: "嘉定工业区" },
          { parentId: 2, id: 27, name: "嘉定工业区2" }
        ],
        [
          { parentId: 21, id: 31, name: "仙龙村" },
          { parentId: 21, id: 32, name: "胜利村" },
          { parentId: 22, id: 33, name: "新义村" },
          { parentId: 22, id: 34, name: "朱家桥村" },
          { parentId: 27, id: 35, name: "黎明村" }
        ]
      ]
    };
  }

  getPageShow(userinfo) {
    let authenticationShow = "未认证";
    let authenticationNextUrl = "/authentication";
    if (userinfo.ustate == apiConfig.userStateReal) {
      authenticationNextUrl = "/authenticationresult";
      if (userinfo.ubind == apiConfig.userBindWaiting) {
        authenticationShow = "等待审核";
      } else if (userinfo.ubind == apiConfig.userBindSuccess) {
        authenticationShow = "已认证";
      } else if (userinfo.ubind == apiConfig.userBindFail) {
        authenticationShow = "认证失败";
      }
    }
    return {
      authenticationShow,
      authenticationNextUrl
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      typeof this.props.userState.userinfo.ubind == "number" &&
      this.props.userState.userinfo.ubind != prevProps.userState.userinfo.ubind
    ) {
      let pageShow = this.getPageShow(this.props.userState.userinfo);
      if (this.isComponentMounted) {
        this.setState(pageShow);
      }
    }
    if (
      this.props.userState.userinfo.versions !=
      prevProps.userState.userinfo.versions
    ) {
      if (this.isComponentMounted) {
        this.setState({
          versions: this.props.userState.userinfo.versions
        });
      }
    }
  }

  componentDidMount() {
    this.isComponentMounted = true;
    const that = this;
    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;
    });
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  selectedCallback(values, displayValues) {
    if (this.isComponentMounted) {
      this.setState({
        userGroup: displayValues[2]
      });
    }
  }

  getLocation() {
    this.$f7.dialog.alert("** start getLocation **");
    callOrigin(
      () => {
        window.webkit.messageHandlers.getLocation.postMessage("");
      },
      () => {
        android.getLocation();
      }
    );
  }

  getregId() {
    callOrigin(
      () => {
        window.webkit.messageHandlers.getregId.postMessage("");
      },
      () => {
        uploadRegId(android.getregId());
      }
    );
  }

  callPhone() {
    callOrigin(
      null,
      phone => {
        android.callPhone(phone);
      },
      ["15807198623"]
    );
  }

  goto(url) {
    this.$f7.views.main.router.navigate(url, {
      props: {}
    });
  }

  gotoAffair() {
    if (JSON.stringify(this.props.userState.userinfo) == "{}") {
      this.$f7.toast
        .create({
          text: "请先登录",
          position: "center",
          closeTimeout: 2000,
          on: {
            close: () => {
              this.$f7.views.main.router.navigate("/login", {
                props: {}
              });
            }
          }
        })
        .open();
      return;
    }
    if (
      this.props.userState.userinfo.ubind != apiConfig.userBindSuccess &&
      this.props.userState.userinfo.uphone != "15851575410"
    ) {
      this.$f7.toast
        .create({
          text: "身份认证审核通过后才能查看",
          position: "center",
          closeTimeout: 2000,
          on: {
            close: () => {}
          }
        })
        .open();
      return;
    }
    this.$f7.views.main.router.navigate("/affair", {
      props: {}
    });
  }

  selectFile(e) {
    let files = e.target.files;
    for (let i = 0; i < files.length; i++) {
      let file = files[i];

      if (!file.type.match("image.*")) {
        //不是图片
        this.$f7.dialog.alert("只能上传图片类型");
        break;
      }
      if (file.size > 1024 * 1024 * 2) {
        this.$f7.dialog.alert("图片不能大于2M");
        break;
      }

      let data = new FormData();
      data.append("file", file);
      data.append("channel", apiConfig.channelId);
      apiCall(
        "/cms/uploadImage",
        "POST",
        data,
        result => {
          let url = result.data.src;
          this.setState(
            () => ({
              userHeaderImg: url
            }),
            () => {
              // undo change user header image url on the server
            }
          );
        },
        (error, status) => {
          this.$f7.dialog.alert(JSON.stringify(status));
        },
        true
      );
    }
  }

  render() {
    return (
      <Page name="My">
        <div>
          <React.Fragment>
            <img src={this.state.userHeaderImg} />
            <label
              className="picture addPicture"
              htmlFor="ConsulateAskAddPicture">
              <input
                id="ConsulateAskAddPicture"
                type="file"
                accept="image/*"
                onChange={this.selectFile.bind(this)}
              />
            </label>
          </React.Fragment>
          )}
        </div>

        <div>
          {/**
          <div>
            <div />
          </div>
          <div>
            <div />
          </div>
          <div>
            <div />
          </div>
          */}
        </div>

        <div className="MyItem ">
          {false &&
            this.state.versions == apiConfig.typeAccountPerson &&
            this.props.userState.userinfo.uname && (
              <Link>
                {/** href="/editname" */}
                <div className="iconUser">姓名</div>
                <div className="unselect">
                  {/** className=undo */}
                  <div>
                    {this.props.userState.userinfo.uname
                      ? this.props.userState.userinfo.uname
                      : ""}
                  </div>
                </div>
              </Link>
            )}

          {false &&
            this.props.userState.userinfo.ustate == apiConfig.userStateReal &&
            this.props.userState.userinfo.ubind == apiConfig.userBindSuccess &&
            this.props.userState.userinfo.ugroup && (
              <div>
                {/** id="picker-user-group" */}
                <div className="iconGroup">所属村组</div>
                <div className="unselect">
                  <div>{this.props.userState.userinfo.ugroup}</div>
                </div>
              </div>
            )}
          {this.state.versions == apiConfig.typeAccountCountry && (
            <Link onClick={this.gotoAffair.bind(this)}>
              {/** bill */}
              <div className="iconBill">阳光村务</div>
              <div className="undo">
                <div>&nbsp;</div>
              </div>
            </Link>
          )}
        </div>

        {this.state.versions == apiConfig.typeAccountPerson && (
          <div className="MyItem mt20">
            <Link href={this.state.authenticationNextUrl}>
              <div className="iconIdcard">身份认证</div>
              <div className="">
                <div>{this.state.authenticationShow}</div>
              </div>
            </Link>
            <Link onClick={this.gotoAffair.bind(this)}>
              {/** bill */}
              <div className="iconBill">涉农账单</div>
              <div>&nbsp;</div>
            </Link>
          </div>
        )}

        <div className="MyItem mt20 ">
          <Link href="/followproducts" className="hide">
            <div className="iconFollow">
              <div>我关注的农产品</div>
              <div>
                水稻、草莓、小龙虾、大闸蟹、水稻、草莓、小龙虾、大闸蟹、水稻、草莓、小龙虾、大闸蟹、水稻、草莓、小龙虾、大闸蟹
              </div>
            </div>
            <div>&nbsp;</div>
          </Link>
          <Link href="/message">
            <div className="iconMsg">消息</div>
            <div>&nbsp;</div>
          </Link>
          <Link href="/setting">
            <div className="iconSet">设置</div>
            <div>&nbsp;</div>
          </Link>
        </div>

        {this.state.versions == apiConfig.typeAccountPerson &&
          JSON.stringify(this.props.userState.userinfo) == "{}" && (
            <div className="MyItemLogout">
              <Link href="/login">登录</Link>
            </div>
          )}

        {/**
        <div className="MyItemLogout" onClick={this.getLocation}>
          <Link>getLocation</Link>
        </div>
         

        <div className="MyItemLogout" onClick={this.getregId}>
          <Link>getregId</Link>
        </div>

        <div className="MyItemLogout" onClick={this.callPhone}>
          <Link>callPhone 15807198623</Link>
        </div>
        */}

        <CategoryPicker
          nodes={this.state.groupNodes}
          parentElement="#picker-user-group"
          selectedCallback={this.selectedCallback}
          initSelectedValue={this.state.initSelectedValue}
        />

        {/**
           <Img src={ImgHeader} ratio={2} />
         */}
      </Page>
    );
  }
}

function mapStateToProps(state) {
  const { userState, pageState } = state;
  return { userState, pageState };
}

My.propTypes = {
  userState: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(My);
