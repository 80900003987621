import { createStore, applyMiddleware, compose } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import rootReducer from "./reducers";

const loggerMilddleware = createLogger();

export default function configureStore(preloadedState) {
  let middlewares = [];
  middlewares.push(thunkMiddleware);
  if (process.env.NODE_ENV === "development") {
    middlewares.push(loggerMilddleware);
  }
  let middlewareEnhancer = applyMiddleware(...middlewares);
  let enhancers = [middlewareEnhancer];
  if (process.env.NODE_ENV === "development") {
    //enhancers.push(monitorReducerEnhancer);
  }
  let composedEnhancers = composeWithDevTools(...enhancers);
  if (process.env.NODE_ENV === "production") {
    composedEnhancers = compose(...enhancers);
  }

  const store = createStore(rootReducer, preloadedState, composedEnhancers);

  // reducer hot reloading
  if (process.env.NODE_ENV !== "production" && module.hot) {
    module.hot.accept("./reducers", () => store.replaceReducer(rootReducer));
  }

  return store;
}
