import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "framework7-icons";
import { Page, Link } from "framework7-react";
import InfiniteScroll from "../components/InfiniteScroll/";
import NoData from "../components/NoData";
import Carousel from "../components/Carousel";
import CategorySelect from "../components/CategorySelect/";
import { apiCall, apiConfig } from "../api";
import "../css/pageFeatureproducts.less";

class Featureproducts extends React.Component {
  constructor(props) {
    super(props);
    this.selectedCallback = this.selectedCallback.bind(this);
    this.onRef = this.onRef.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.renderData = this.renderData.bind(this);
    this.state = {
      pageIndex: 1,
      ext1: "",
      ext2: "",
      ext3: "",
      categories: [
        // {
        //   id: 1,
        //   name: "区域",
        //   child: [
        //     {
        //       name: "马陆",
        //       value: "malu",
        //       id: 4
        //     }
        //   ]
        // }
      ],
      products: [
        // {
        //   id: 1,
        //   title: "马陆葡萄",
        //   description: "第一批通过上海市优质农产品认证的水果",
        //   img: ImgSample
        // },
      ],
      banners: []
    };
  }

  componentDidMount() {
    this.isComponentMounted = true;
    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      apiCall(
        "/app/getCmsNews",
        "POST",
        {
          channelId: apiConfig.channelId,
          columnId: apiConfig.featureColumnId,
          ext1: this.state.ext1,
          ext2: this.state.ext2,
          ext3: this.state.ext3,
          page: this.state.pageIndex,
          size: apiConfig.pageSize
        },
        data => {
          //console.log("constructor", this.state.pageIndex, data);
          if (this.state.categories.length == 0) {
            let categories = [];
            data.data.tags.forEach(tags => {
              let cate = {
                id: tags.type_id,
                name: tags.type_name,
                child: []
              };
              cate.child = tags.data.map(tag => {
                return {
                  id: tag.id,
                  name: tag.tagName,
                  value: tag.tagValue
                };
              });
              categories.push(cate);
            });
            if (this.isComponentMounted) {
              this.setState(prev => ({
                categories: categories
              }));
            }
          }

          if (this.state.banners.length == 0) {
            let banners = [];
            data.data.banner.forEach(item => {
              if (item.previewImage) {
                banners.push({
                  img: item.previewImage,
                  url: "/information/" + item.id,
                  title: item.title
                });
              }
            });
            if (this.isComponentMounted) {
              this.setState({
                banners
              });
            }
          }
        },
        function(xhr, status) {
          //console.log(xhr, status);
        }
      );
    });
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  selectedCallback(selectedCategory, parentIndex) {
    let state = {
      ext1: "",
      ext2: "",
      ext3: "",
      pageIndex: 1
    };
    state["ext" + parentIndex] = selectedCategory.value;
    if (this.isComponentMounted) {
      this.setState(
        prev => state,
        () => {
          this.InfiniteScroll.reInfinite();
        }
      );
    }
  }

  onRef(ref) {
    this.InfiniteScroll = ref;
  }

  fetchData(func) {
    const that = this;

    if (this.state.categories.length == 0) return;

    apiCall(
      "/app/getCmsNews",
      "POST",
      {
        channelId: apiConfig.channelId,
        columnId: apiConfig.featureColumnId,
        ext1: this.state.ext1,
        ext2: this.state.ext2,
        ext3: this.state.ext3,
        page: this.state.pageIndex,
        size: apiConfig.pageSize
      },
      data => {
        //console.log("fetchData", this.state.pageIndex, data);

        if (this.isComponentMounted) {
          this.setState(prev => ({
            pageIndex: prev.pageIndex + 1
          }));
        }

        if (func) func(data.data ? data.data.list : []);
      },
      function(xhr, status) {
        //console.log(xhr, status);
      }
    );
  }

  renderData(data) {
    //console.log(data);
    if (data.length == 0) {
      if (this.isComponentMounted) {
        this.setState(prev => ({
          products: []
        }));
      }
    } else {
      let products = data.map(item => {
        return {
          id: item.id,
          title: item.title,
          description: item.abstractor,
          img: item.previewImage
        };
      });
      //console.log(products);
      if (this.isComponentMounted) {
        this.setState(prev => ({
          products: prev.products.concat(products)
        }));
      }
    }
  }

  render() {
    return (
      <InfiniteScroll
        pageName="Featureproducts"
        fetchData={this.fetchData}
        renderData={this.renderData}
        onRef={this.onRef}
      >
        {this.state.banners.length > 0 && (
          <Carousel data={this.state.banners} />
        )}

        {this.state.categories.length > 0 && (
          <CategorySelect
            nodes={this.state.categories}
            selectedCallback={this.selectedCallback}
          />
        )}

        {this.state.products.length > 0 &&
          this.state.products.map((item, index) => (
            <Link
              className="FeatureProductItem"
              key={item.id}
              href={"/featureproductinfo/" + item.id}
            >
              <div className={index >= 3 ? "nomark" : ""}>
                {index < 3 && <span>{index + 1}</span>}
                {item.title}
              </div>
              <div>{item.description}</div>
              <div>
                <img src={item.img} />
              </div>
            </Link>
          ))}

        {this.state.products.length == 0 && <NoData />}
      </InfiniteScroll>
    );
  }
}

function mapStateToProps(state) {
  const { userState, pageState } = state;
  return { userState, pageState };
}

Featureproducts.propTypes = {
  userState: PropTypes.object.isRequired,
  pageState: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(Featureproducts);
