import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "framework7-icons";
import { Page, Swiper, SwiperSlide, Link } from "framework7-react";
import InfiniteScroll from "../components/InfiniteScroll/";
import NoData from "../components/NoData";
import Carousel from "../components/Carousel";
import CategorySelect from "../components/CategorySelect/";
import { apiCall, apiConfig } from "../api";
import "../css/pageSites.less";
import SitePanel from "../components/SitePanel";

class Sites extends React.Component {
  constructor(props) {
    super(props);
    this.selectedCallback = this.selectedCallback.bind(this);
    this.onRef = this.onRef.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.renderData = this.renderData.bind(this);
    this.state = {
      pageIndex: 1,
      ext1: "",
      ext2: "",
      ext3: "",
      selectedName: "",
      // categories: [
      //   { categoryId: 0, categoryName: "全部" },
      //   { categoryId: 1, categoryName: "民宿" },
      //   { categoryId: 2, categoryName: "游山" },
      //   { categoryId: 3, categoryName: "江流湖泊" },
      //   { categoryId: 4, categoryName: "赏花" },
      //   { categoryId: 5, categoryName: "美食" },
      //   { categoryId: 6, categoryName: "文化" }
      // ],
      categories: [
        // {
        //   id: 1,
        //   name: "区域",
        //   child: [
        //     {
        //       name: "马陆",
        //       id: 4,
        //       value: "malu"
        //     }
        //   ]
        // }
      ],
      products: [
        // {
        //   id: 1,
        //   img: ImgCountry,
        //   categories: [
        //     { categoryId: 4, categoryName: "马陆" },
        //     { categoryId: 24, categoryName: "古村古镇" }
        //   ],
        //   title: "浦东新区祝桥镇星火村浦东新区祝桥镇",
        //   distance: "5km",
        //   description:
        //     "在这里，有传统，百年早教古树高大挺拔，枝繁叶茂，区级文物保护单位刘敦天竺堂岿然屹立，仓艺红木家具展示厅古色古香......在这里，也有时尚，“乐活有机农法”培育出健康美味的农产品，特色经济果蔬“乐活有机农法”培育出健康美味的农产品，特色经济果蔬"
        // }
      ],
      banners: []
    };
  }

  componentDidMount() {
    this.isComponentMounted = true;
    const _this = this;
    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;
    });

    apiCall(
      "/app/getCmsNews",
      "POST",
      {
        channelId: apiConfig.channelId,
        columnId: apiConfig.siteColumnId,
        ext1: this.state.ext1,
        ext2: this.state.ext2,
        ext3: this.state.ext3,
        page: this.state.pageIndex,
        size: apiConfig.pageSize
      },
      data => {
        //console.log("constructor", this.state.pageIndex, data);
        if (this.state.categories.length == 0) {
          let categories = [];
          data.data.tags.forEach(tags => {
            let cate = {
              id: tags.type_id,
              name: tags.type_name,
              child: []
            };
            cate.child = tags.data.map(tag => {
              return {
                id: tag.id,
                name: tag.tagName,
                value: tag.tagValue
              };
            });
            categories.push(cate);
          });
          if (this.isComponentMounted) {
            this.setState(prev => ({
              categories: categories
            }));
          }
        }

        if (this.state.banners.length == 0) {
          let banners = [];
          data.data.banner.forEach(item => {
            if (item.previewImage) {
              banners.push({
                img: item.previewImage,
                url: "/information/" + item.id,
                title: item.title
              });
            }
          });
          if (this.isComponentMounted) {
            this.setState({
              banners
            });
          }
        }
      },
      function(xhr, status) {
        //console.log(xhr, status);
      }
    );
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  selectedCallback(selectedCategory, parentIndex) {
    let state = {
      ext1: "",
      ext2: "",
      ext3: "",
      pageIndex: 1,
      selectedName: selectedCategory.name
    };
    state["ext" + parentIndex] = selectedCategory.value;
    if (this.isComponentMounted) {
      this.setState(
        prev => state,
        () => {
          this.InfiniteScroll.reInfinite();
        }
      );
    }
  }

  onRef(ref) {
    this.InfiniteScroll = ref;
  }

  fetchData(func) {
    const that = this;

    if (this.state.categories.length == 0) return;

    apiCall(
      "/app/getCmsNews",
      "POST",
      {
        channelId: apiConfig.channelId,
        columnId: apiConfig.siteColumnId,
        ext1: this.state.ext1,
        ext2: this.state.ext2,
        ext3: this.state.ext3,
        page: this.state.pageIndex,
        size: apiConfig.pageSize
      },
      data => {
        //console.log("fetchData", this.state.pageIndex, data);

        if (this.isComponentMounted) {
          this.setState(prev => ({
            pageIndex: prev.pageIndex + 1
          }));
        }

        if (func) func(data.data.list);
      },
      function(xhr, status) {
        //console.log(xhr, status);
      }
    );
  }

  renderData(data) {
    //console.log(data);
    if (data.length == 0) {
      if (this.isComponentMounted) {
        this.setState(prev => ({
          products: []
        }));
      }
    } else {
      let products = data.map(item => {
        return {
          id: item.id,
          title: item.title,
          description: item.abstractor,
          img: item.previewImage,
          distance: item.distance ? item.distance : ""
        };
      });
      if (this.isComponentMounted) {
        this.setState(prev => ({
          products: prev.products.concat(products)
        }));
      }
    }
  }

  render() {
    const self = this;
    return (
      <InfiniteScroll
        pageName="Sites"
        fetchData={this.fetchData}
        renderData={this.renderData}
        onRef={this.onRef}
      >
        {this.state.banners.length > 0 && (
          <Carousel data={this.state.banners} />
        )}

        {this.state.categories.length > 0 && (
          <CategorySelect
            nodes={this.state.categories}
            selectedCallback={this.selectedCallback}
          />
        )}

        <div>
          {this.state.products.length > 0 &&
            this.state.products.map((item, index) => {
              return (
                <SitePanel
                  key={item.id}
                  link={"/siteinfo/" + item.id}
                  img={item.img}
                  tags={[this.state.selectedName]}
                  title={item.title}
                  //rate={4}
                  //location={"地址暂未获取"}
                />
              );
            })}

          {this.state.products.length == 0 && <NoData />}
        </div>
      </InfiniteScroll>
    );
  }
}

function mapStateToProps(state) {
  const { userState } = state;
  return { userState };
}

Sites.propTypes = {
  userState: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(Sites);
