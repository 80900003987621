import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "framework7-icons";
import { Page, Link } from "framework7-react";
// import F2 from "@antv/f2";
import "../css/pageBill.less";

class Bill extends React.Component {
  constructor(props) {
    super(props);
    this.userId = this.$f7route.params.userId;

    this.state = {};
  }

  componentDidMount() {
    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      var data1 = [
        {
          name: "总资产",
          percent: 0.6,
          a: "1"
        },
        {
          name: "总负债",
          percent: 0.4,
          a: "1"
        }
      ];
      var chart1 = new F2.Chart({
        id: "bill-chart-1",
        pixelRatio: window.devicePixelRatio,
        padding: [0, 0, 0, 0]
      });
      chart1.source(data1);
      chart1.legend(false);
      chart1.tooltip(false);
      chart1.axis(false);
      chart1.coord("polar", {
        transposed: true,
        radius: 1,
        startAngle: 0 + data1[1].percent * Math.PI,
        endAngle: 2 * Math.PI + data1[1].percent * Math.PI
      });
      chart1
        .interval()
        .position("a*percent")
        .color("name", ["#1951be", "#00bd43"])
        .adjust("stack")
        .animate({
          appear: {
            duration: 1200,
            easing: "bounceOut"
          }
        });
      chart1.render();

      var data2 = [
        {
          name: "总收入",
          percent: 0.7,
          a: "1"
        },
        {
          name: "总支出",
          percent: 0.3,
          a: "1"
        }
      ];
      var chart2 = new F2.Chart({
        id: "bill-chart-2",
        pixelRatio: window.devicePixelRatio,
        padding: [0, 0, 0, 0]
      });
      chart2.source(data2);
      chart2.legend(false);
      chart2.tooltip(false);
      chart2.axis(false);
      chart2.coord("polar", {
        transposed: true,
        radius: 1,
        startAngle: 0 + data2[1].percent * Math.PI,
        endAngle: 2 * Math.PI + data2[1].percent * Math.PI
      });
      chart2
        .interval()
        .position("a*percent")
        .color("name", ["#00b8d0", "#ff890b"])
        .adjust("stack")
        .animate({
          appear: {
            duration: 1200,
            easing: "bounceOut"
          }
        });
      chart2.render();
    });
  }

  render() {
    const self = this;
    return (
      <Page name="Bill" className="Bill">
        <Link className="bill-title" href="/billasset">
          <div>
            <span>资产概况</span>
            <span>(万元)</span>
          </div>
          <div>
            <span>2017年6月-2018年1月</span>
          </div>
        </Link>
        <div className="bill-zc-show">
          <div>总资产：551.70</div>
          <div>
            <canvas id="bill-chart-1" />
          </div>
          <div>总负债：410.40</div>
        </div>
        <div className="bill-zc-show bill-zc-show-2">
          <div>总收入：157.60</div>
          <div>
            <canvas id="bill-chart-2" />
          </div>
          <div>总支出：52.40</div>
        </div>

        <Link className="bill-title bill-title-sn" href="/billsubsidy">
          <div>
            <span>涉农补贴</span>
          </div>
          <div>
            <span>2017年-2018年</span>
          </div>
        </Link>
        <div className="bill-sn">
          <div>
            <div>农作物种植面积</div>
            <div>450.00&nbsp;亩</div>
          </div>
          <div>
            <div>实际补贴金额</div>
            <div>8092.00&nbsp;元</div>
          </div>
        </div>
        <div className="bill-sn">
          <div>
            <div>其他</div>
          </div>
          <div>
            <div>实际补贴金额</div>
            <div>890.00&nbsp;元</div>
          </div>
        </div>

        <Link className="bill-title bill-title-td" href="/billland">
          <div>
            <span>土地承包/土地流转</span>
          </div>
          <div>&nbsp;</div>
        </Link>
        <div className="bill-td">
          <div>本户农村土地承包确权面积</div>
          <div>2.40&nbsp;亩</div>
        </div>
        <div className="bill-td">
          <div>本户土地委托流转面积</div>
          <div>68.00&nbsp;亩</div>
        </div>

        <Link className="bill-title bill-title-cw" href="/affair">
          <div>
            <span>阳光村务</span>
          </div>
          <div>&nbsp;</div>
        </Link>
      </Page>
    );
  }
}

function mapStateToProps(state) {
  const { userState } = state;
  return { userState };
}

Bill.propTypes = {
  userState: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(Bill);
