import React from "react";
import { Link } from "framework7-react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "framework7-icons";
import "./CulturePanel.less";
import AutoImg from "../AutoImg/";

class CulturePanel extends React.Component {
  componentDidMount() {
    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;
      // this.$f7.views.main.router
    });
  }

  render() {
    let stars = [];
    for (let i = 0; i < this.props.rate; i++) {
      stars.push(true);
    }
    for (let i = this.props.rate; i < 5; i++) {
      stars.push(false);
    }

    if (this.props.classType == "horizontal") {
      return (
        <Link
          className={
            this.props.classType ? "CulturePanel-" + this.props.classType : ""
          }
          href={this.props.link}
        >
          <div>
            {this.props.img && <AutoImg src={this.props.img} ratio={1.31} />}
          </div>
          <div>
            <div>{this.props.title}</div>
            <div>{this.props.description}</div>
          </div>
        </Link>
      );
    } else if (this.props.classType == "simplevertical") {
      return (
        <Link
          className={
            this.props.classType ? "CulturePanel-" + this.props.classType : ""
          }
          href={this.props.link}
        >
          <div>{this.props.title}</div>
          <div>
            {this.props.img && <AutoImg src={this.props.img} ratio={2} />}
          </div>
        </Link>
      );
    } else {
      return (
        <Link className={"CulturePanel "} href={this.props.link}>
          <div>
            {this.props.img && <AutoImg src={this.props.img} ratio={2} />}
          </div>
          <div>{this.props.title}</div>
          <div>{this.props.description}</div>
        </Link>
      );
    }
  }
}

function mapStateToProps(state) {
  const { userState } = state;
  return { userState };
}

CulturePanel.propTypes = {
  userState: PropTypes.object.isRequired,
  link: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

export default connect(mapStateToProps)(CulturePanel);
