import Index from "./pages/Index";
import Example from "./pages/Example";
import My from "./pages/My";
import Infos from "./pages/Infos";
import Products from "./pages/Products";
import Country from "./pages/Country";
import Consulate from "./pages/Consulate";
import Prices from "./pages/Prices";
import Editname from "./pages/Editname";
import Mapelement from "./pages/Map";
import Authentication from "./pages/Authentication";
import Followproducts from "./pages/Followproducts";
import Sites from "./pages/Sites";
import SiteInfo from "./pages/SiteInfo";
import Featureproducts from "./pages/Featureproducts";
import FeatureproductInfo from "./pages/FeatureproductInfo";
import Culture from "./pages/Culture";
import CultureInfo from "./pages/CultureInfo";
import Community from "./pages/Community";
import CommunityType from "./pages/CommunityType";
import CommunityInfo from "./pages/CommunityInfo";
import Consulaterecords from "./pages/Consulaterecords";
import Expert from "./pages/Expert";
import Information from "./pages/Information";
import ConsulateMy from "./pages/ConsulateMy";
import ConsulateInfo from "./pages/ConsulateInfo";
import Bill from "./pages/Bill";
import BillAsset from "./pages/BillAsset";
import BillInAndOut from "./pages/BillInAndOut";
import BillSubsidy from "./pages/BillSubsidy";
import BillLand from "./pages/BillLand";
import CommunityChoose from "./pages/CommunityChoose";
import CommunityDetail from "./pages/CommunityDetail";
import CitizenService from "./pages/CitizenService";
import Affair from "./pages/Affair";
import ConsulateAsk from "./pages/ConsulateAsk";
import News from "./pages/News";
import Message from "./pages/Message";
import Login from "./pages/Login";
import Register from "./pages/Register";
import RegisterPassword from "./pages/RegisterPassword";
import Retrieve from "./pages/Retrieve";
import RetrievePassword from "./pages/RetrievePassword";
import Setting from "./pages/Setting";
import ChangePassword from "./pages/ChangePassword";
import ChangePhone from "./pages/ChangePhone";
import FarmService from "./pages/FarmService";
import AuthenticationSelectgroup from "./pages/AuthenticationSelectgroup";
import AuthenticationResult from "./pages/AuthenticationResult";
import AffairInfo from "./pages/AffairInfo";
import TVLogin from "./pages/TVLogin";
import Viewimage from "./pages/Viewimage";
import ConsulateSub from "./pages/ConsulateSub";
import AffairInfoCommon from "./pages/AffairInfoCommon";
import ConsulateSearch from "./pages/ConsulateSearch";
import AuthenticationThird from "./pages/AuthenticationThird";

export default function(store) {
  function redirect(preurl) {
    return (route, resolve, reject) => {
      //console.log("** redirect **");
      if (JSON.stringify(store.getState().userState.userinfo) == "{}") {
        resolve("/login");
      } else {
        reject(preurl);
      }
    };
  }

  function loginRoute(preurl, component) {
    //console.log(JSON.stringify(store.getState().userState.userinfo));
    let route = { path: preurl, component: component };
    if (JSON.stringify(store.getState().userState.userinfo) == "{}") {
      route.redirect = redirect(preurl);
    }
    //console.log(route);
    return route;
  }

  let routes = [
    {
      path: "/",
      component: Index
      //   on: {
      //     pageBeforeIn: function(event, page) {
      //       console.log("pageBeforeIn");
      //     },
      //     pageAfterIn: function(event, page) {
      //       console.log("pageAfterIn");
      //     },
      //     pageInit: function(event, page) {
      //       console.log("pageInit");
      //       console.log(arguments);
      //     },
      //     pageBeforeRemove: function(event, page) {
      //       console.log("pageBeforeRemove");
      //     }
      //   },
      //   redirect(route, resolve, reject) {
      //     console.log(arguments);
      //     if (route.query.user) {
      //       // redirect to such url
      //       resolve("/example/?user=" + route.query.user);
      //     }
      //     // otherwise do nothing
      //     else reject();
      //   }
    },
    // {
    //   path: "/about",
    //   async(routeTo, routeFrom, resolve, reject) {
    //     // dynamic import component; returns promise
    //     const reactComponent = () => import("./pages/About");
    //     reactComponent().then(rc => {
    //       resolve({ component: rc.default });
    //     });
    //   }
    // },
    {
      path: "/example",
      component: Example
    },
    {
      path: "/login",
      component: Login
    },
    {
      path: "/register",
      component: Register
    },
    {
      path: "/registerpassword",
      component: RegisterPassword
    },
    {
      path: "/retrieve",
      component: Retrieve
    },
    {
      path: "/changepassword",
      component: ChangePassword
    },
    {
      path: "/changephone",
      component: ChangePhone
    },
    {
      path: "/retrievepassword",
      component: RetrievePassword
    },
    {
      path: "/farmservice",
      component: FarmService
    },
    {
      path: "/infos",
      component: Infos
    },
    {
      path: "/products",
      component: Products
    },
    {
      path: "/consulate",
      component: Consulate
    },
    {
      path: "/consulatesub/:ext",
      component: ConsulateSub
    },
    {
      path: "/consulate/select/(.*)",
      redirect: "/consulate"
    },
    {
      path: "/country",
      component: Country
    },
    {
      path: "/prices",
      component: Prices
    },
    {
      path: "/editname",
      component: Editname
    },
    {
      path: "/map",
      component: Mapelement
    },
    {
      path: "/authentication",
      component: Authentication
    },
    {
      path: "/authenticationthird",
      component: AuthenticationThird
    },
    {
      path: "/authenticationselectgroup",
      component: AuthenticationSelectgroup
    },
    {
      path: "/authenticationresult",
      component: AuthenticationResult
    },
    {
      path: "/followproducts",
      component: Followproducts
    },
    {
      path: "/sites",
      component: Sites
    },
    {
      path: "/citizenservice",
      component: CitizenService
    },
    {
      path: "/affair",
      component: Affair
    },
    {
      path: "/affairinfo/:infoType",
      component: AffairInfo
    },
    {
      path: "/affairinfocommon/:infoType",
      component: AffairInfoCommon
    },
    {
      path: "/siteinfo/:siteId",
      component: SiteInfo
    },
    {
      path: "/featureproducts",
      component: Featureproducts
    },
    {
      path: "/featureproductinfo/:productId",
      component: FeatureproductInfo
    },
    {
      path: "/culture/:columnId",
      component: Culture
    },
    {
      path: "/cultureinfo/:cultureId",
      component: CultureInfo
    },
    {
      path: "/community",
      component: Community
    },
    {
      path: "/communitychoose",
      component: CommunityChoose
    },
    {
      path: "/communitytype/:typeId",
      component: CommunityType
    },
    {
      path: "/consulaterecords",
      component: Consulaterecords
    },
    {
      path: "/consulatemy",
      component: ConsulateMy
    },
    {
      path: "/consulateask",
      component: ConsulateAsk
    },
    {
      path: "/consulatesearch",
      component: ConsulateSearch
    },
    {
      path: "/consulateinfo/:consulateId/:isHotwirekb?",
      component: ConsulateInfo
    },
    {
      path: "/communityinfo/:communityId",
      component: CommunityInfo
    },
    {
      path: "/communitydetail/:communityId",
      component: CommunityDetail
    },
    {
      path: "/expert",
      component: Expert
    },
    {
      path: "/information/:informationId/:isHotwirekb?",
      component: Information
    },
    {
      path: "/viewimage/:index",
      component: Viewimage
    },
    {
      path: "/bill",
      component: Bill
    },
    {
      path: "/billasset",
      component: BillAsset
    },
    {
      path: "/billinandout",
      component: BillInAndOut
    },
    {
      path: "/billsubsidy",
      component: BillSubsidy
    },
    {
      path: "/billland",
      component: BillLand
    },
    {
      path: "/news",
      component: News
    },
    {
      path: "/message",
      component: Message
    },
    {
      path: "/my/select/(.*)",
      redirect: "/my"
    },
    {
      path: "/my",
      component: My
    },
    {
      path: "/setting",
      component: Setting
    },
    {
      path: "/tvlogin",
      component: TVLogin
    }
  ];

  //routes.push(loginRoute("/my", My));

  return routes;
}
