import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "framework7-icons";
import { Page, Link, Toolbar, Tabs, Tab } from "framework7-react";
import "../css/pageCommunityInfo.less";
import NoData from "../components/NoData/";
import InformationPanel from "../components/InformationPanel/";
import CulturePanel from "../components/CulturePanel/";
import { apiCall, apiConfig } from "../api";
import AutoImg from "../components/AutoImg/";
import ImgSample from "../images/sample11.png";
import SitePanel from "../components/SitePanel";

class CommunityInfo extends React.Component {
  constructor(props) {
    super(props);
    this.troggle = this.troggle.bind(this);
    this.packup = React.createRef();
    this.communityId = this.$f7route.params.communityId;
    this.state = {
      detail: [],
      isShowTab: "activities",
      tabs: {
        activities: { name: "最新活动", list: [], focus: true },
        products: { name: "特色农产品", list: [], focus: false },
        cultures: { name: "文化产品", list: [], focus: false },
        travels: { name: "休闲旅游", list: [], focus: false }
      }
    };
  }

  componentDidMount() {
    this.isComponentMounted = true;
    const _this = this;
    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      //console.log(_this.props.communityId);

      apiCall(
        "/app/getYinongInfoById",
        "POST",
        {
          id: this.communityId
        },
        data => {
          let isShowTab = "activities";
          let columnCounts = 0;
          if (data.content.activity.length != 0) {
            columnCounts++;
          }
          if (data.content.ncp.length != 0) {
            columnCounts++;
          }
          if (data.content.whcp.length != 0) {
            columnCounts++;
          }
          if (data.content.lycp.length != 0) {
            columnCounts++;
          }
          if (columnCounts > 1) {
            isShowTab = "tab";
          } else if (columnCounts == 1) {
            if (data.content.activity.length != 0) {
              isShowTab = "activities";
            }
            if (data.content.ncp.length != 0) {
              isShowTab = "products";
            }
            if (data.content.whcp.length != 0) {
              isShowTab = "cultures";
            }
            if (data.content.lycp.length != 0) {
              isShowTab = "travels";
            }
          }
          let isFocus1 = data.content.activity.length ? true : false;
          let isFocus2 = false,
            isFocus3 = false,
            isFocus4 = false;
          if (!isFocus1) {
            // test
            isFocus2 = true;
          }
          if (this.isComponentMounted) {
            this.setState(
              {
                detail: data.content.detail,
                isShowTab: isShowTab,
                tabs: {
                  activities: {
                    name: "最新活动",
                    list: data.content.activity,
                    focus: isFocus1
                  },
                  products: {
                    name: "特色农产品",
                    list: data.content.ncp,
                    // list: [
                    //   {
                    //     id: 305,
                    //     title: "飓风葡萄",
                    //     img: ImgSample,
                    //     description: "绿色纯正，自然之星"
                    //   }
                    // ],
                    focus: isFocus2
                  },
                  cultures: {
                    name: "文化产品",
                    list: data.content.whcp,
                    // list: [
                    //   {
                    //     id: 305,
                    //     title: "菊花文化节",
                    //     img: ImgSample,
                    //     description: "绿色纯正，自然之星"
                    //   }
                    // ],
                    focus: isFocus3
                  },
                  travels: {
                    name: "休闲旅游",
                    list: data.content.lycp,
                    // list: [
                    //   {
                    //     id: 305,
                    //     title: "灯塔村草莓主题游乐园",
                    //     img: ImgSample,
                    //     tags: ["民宿", "游乐园"],
                    //     rate: 4,
                    //     location: "上海市嘉定区工业园区灯塔村1-28号"
                    //   }
                    // ],
                    focus: isFocus4
                  }
                }
              },
              () => {}
            );
          }
        },
        (xhr, status) => {
          //console.log(xhr, status);
          this.$f7.dialog.alert(xhr);
        }
      );
    });
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  troggle(e) {
    const className = this.packup.current.className;
    if (className) {
      this.packup.current.className = "";
      e.target.innerHTML = "收起";
    } else {
      this.packup.current.className = "packup";
      e.target.innerHTML = "展开";
    }
  }

  clickTab(key, e) {
    this.state.tabs.activities.focus = key == "activities" ? true : false;
    this.state.tabs.products.focus = key == "products" ? true : false;
    this.state.tabs.cultures.focus = key == "cultures" ? true : false;
    this.state.tabs.travels.focus = key == "travels" ? true : false;

    if (this.isComponentMounted) {
      this.setState(prevState => {
        return {
          tabs: prevState.tabs
        };
      });
    }
  }

  render() {
    let tabHead = [],
      tabBody = [],
      focusKey = "";
    for (let key in this.state.tabs) {
      if (this.state.tabs[key].focus) {
        focusKey = key;
      }
      if (this.state.tabs[key].list.length == 0) {
        continue;
      }
      tabHead.push(
        <Link
          tabLink={"#communityInfo" + key}
          tabLinkActive={this.state.tabs[key].focus}
          key={key}
          onClick={this.clickTab.bind(this, key)}>
          <div>{this.state.tabs[key].name}</div>
          <span />
        </Link>
      );
    }
    tabBody.push(
      <Tab
        id={"#communityInfoTab"}
        className="page-content"
        tabActive={true}
        key={"#communityInfoTab"}>
        {this.state.tabs[focusKey].list.length > 0 &&
          this.state.tabs[focusKey].list.map(item => {
            if (focusKey == "activities") {
              return (
                <InformationPanel
                  key={item.id}
                  title={item.title}
                  img={item.previewImage}
                  date={item.showCreateDate ? item.showCreateDate : ""}
                  source={item.infoFrom ? item.infoFrom : ""}
                  link={"/information/" + item.id}
                  //tags={["信息", "培训", "学习"]}
                  //views={6782}
                />
              );
            } else if (focusKey == "cultures" || focusKey == "products") {
              return (
                <CulturePanel
                  key={item.id}
                  link={"/cultureinfo/" + item.id}
                  img={item.previewImage}
                  title={item.title}
                  description={item.abstractor}
                />
              );
            } else if (focusKey == "travels") {
              return (
                <SitePanel
                  key={item.id}
                  link={"/siteinfo/" + item.id}
                  img={item.previewImage}
                  title={item.title}
                  location={"地址暂未获取"}
                  rate={5}
                  //tags={item.tags}
                />
              );
            }
          })}
        {this.state.tabs[focusKey].list == 0 && <NoData />}
      </Tab>
    );

    return (
      <Page name="CommunityInfo">
        {this.state.detail.images && (
          <AutoImg src={this.state.detail.images} ratio={1.875} />
        )}

        <div className="community-info-abstruct">
          <div>
            <div>{this.state.detail.ynName}</div>
            <div>
              <span />
              {this.state.detail.address}
            </div>
            <div
              className={
                parseInt(this.state.detail.type) ==
                apiConfig.typeCommunityProfessional
                  ? "pro"
                  : ""
              }
            />
          </div>
          <div>
            <div>
              编码：{this.state.detail.num ? this.state.detail.num : "无数据"}
            </div>
            <div>
              负责人：
              {this.state.detail.managerName
                ? this.state.detail.managerName
                : "无数据"}
            </div>
          </div>
          <div>
            <div>
              信息员：
              {this.state.detail.messengerName
                ? this.state.detail.messengerName
                : "无数据"}
            </div>
            <div>
              信息员联系方式：
              {this.state.detail.messengerPhone
                ? this.state.detail.messengerPhone
                : "无数据"}
            </div>
          </div>
        </div>

        <div className="community-info-abstruct-2">
          <Link
            className="topDiv"
            href={"/communitydetail/" + this.communityId}>
            <div>概况</div>
            <span>查看更多</span>
          </Link>
          <div>
            <div>
              {this.state.detail.summary ? this.state.detail.summary : "无数据"}
            </div>
          </div>
        </div>

        {this.state.isShowTab == "activities" && (
          <React.Fragment>
            <div className="TitleGap">
              <div>最新活动</div>
            </div>
            {this.state.tabs.activities.list.length > 0 &&
              this.state.tabs.activities.list.map(item => (
                <InformationPanel
                  key={item.id}
                  title={item.title}
                  img={item.previewImage}
                  date={item.showCreateDate ? item.showCreateDate : ""}
                  source={item.enterpriseName ? item.enterpriseName : ""}
                  link={"/information/" + item.id}
                  //tags={["信息", "培训", "学习"]}
                  //views={6782}
                />
              ))}
            {this.state.tabs.activities.list.length == 0 && <NoData />}
          </React.Fragment>
        )}

        {this.state.isShowTab == "cultures" && (
          <React.Fragment>
            <div className="TitleGap">
              <div>文化产品</div>
            </div>
            {this.state.tabs.cultures.list.length > 0 &&
              this.state.tabs.cultures.list.map(item => (
                <CulturePanel
                  key={item.id}
                  link={"/cultureinfo/" + item.id}
                  img={item.previewImage}
                  title={item.title}
                  description={item.abstractor}
                />
              ))}
            {this.state.tabs.cultures.list.length == 0 && <NoData />}
          </React.Fragment>
        )}

        {this.state.isShowTab == "products" && (
          <React.Fragment>
            <div className="TitleGap">
              <div>特色农产品</div>
            </div>
            {this.state.tabs.products.list.length > 0 &&
              this.state.tabs.products.list.map(item => (
                <CulturePanel
                  key={item.id}
                  link={"/cultureinfo/" + item.id}
                  img={item.previewImage}
                  title={item.title}
                  description={item.abstractor}
                />
              ))}
            {this.state.tabs.products.list.length == 0 && <NoData />}
          </React.Fragment>
        )}

        {this.state.isShowTab == "travels" && (
          <React.Fragment>
            <div className="TitleGap">
              <div>休闲旅游</div>
            </div>
            {this.state.tabs.travels.list.length > 0 &&
              this.state.tabs.travels.list.map(item => (
                <SitePanel
                  key={item.id}
                  link={"/siteinfo/" + item.id}
                  img={item.previewImage}
                  title={item.title}
                  location={"地址暂未获取"}
                  rate={5}
                  //tags={item.tags}
                />
              ))}
            {this.state.tabs.travels.list.length == 0 && <NoData />}
          </React.Fragment>
        )}

        {this.state.isShowTab == "tab" && (
          <React.Fragment>
            <div className="CommunityInfoTab">
              <Toolbar tabbar>{tabHead.map(item => item)}</Toolbar>
              <Tabs animated>{tabBody.map(item => item)}</Tabs>
            </div>
          </React.Fragment>
        )}
      </Page>
    );
  }
}

function mapStateToProps(state) {
  const { userState } = state;
  return { userState };
}

CommunityInfo.propTypes = {
  userState: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(CommunityInfo);
