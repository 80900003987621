import React from "react";
import { Swiper, SwiperSlide } from "framework7-react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "./CategorySelect.less";

/**
 * nodes: [
        {
          id: 1,
          name: "区域",
          child: [
            {
              name: "马陆",
              id: 4
            },
          ]
        },
 */
class CategorySelect extends React.Component {
  constructor(props) {
    super(props);
    this.handleParentClick = this.handleParentClick.bind(this);
    this.state = {
      activeParentIndex: 1,
      activeParentId: this.props.nodes[0].id,
      activeChildId: this.props.nodes[0].child[0].id,
      nodes: this.props.nodes
    };
  }

  componentDidMount() {
    const _this = this;
    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      this.isComponentMounted = true;

      const swiper = app.swiper.get(".CategorySelectSwiper");
      _this.swiper = swiper;
      // swiper.on("tap", function() {
      //   console.log(swiper.clickedIndex);

      //   swiper.slides.map(function(index) {
      //     $(swiper.slides[index]).removeClass("swiper-active");
      //   });
      //   $(swiper.slides[swiper.clickedIndex]).addClass("swiper-active");
      //   //swiper.slideTo(swiper.clickedIndex, 500);

      //   let currentParentIndex;
      //   _this.state.nodes.forEach((node, index) => {
      //     if (node.id == _this.state.activeParentId) {
      //       currentParentIndex = index;
      //     }
      //   });

      //   _this.setState(
      //     prevState => {
      //       return {
      //         activeChildId:
      //           prevState.nodes[currentParentIndex].child[swiper.clickedIndex]
      //             .id
      //       };
      //     },
      //     function() {
      //       _this.props.selectedCallback(
      //         _this.state.nodes[currentParentIndex].child[swiper.clickedIndex],
      //         _this.state.activeParentIndex
      //       );
      //     }
      //   );
      // });

      //init
      const width = parseFloat(this.$$(".CategorySelectParent")[0].scrollWidth);
      let left = width * 0.5;
      _this.$$(".activedPoint").css({ left: left + "px" });

      _this.props.selectedCallback(
        _this.state.nodes[0].child[0],
        _this.state.activeParentIndex
      );
    });
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  handleParentClick(index, id) {
    const _this = this;
    return e => {
      _this.$$(".CategorySelectParent").removeClass("actived");
      _this.$$(e.target).addClass("actived");
      const width = parseFloat(_this.$$(e.target)[0].scrollWidth);
      let left = width * (index + 0.5);
      _this.$$(".activedPoint").css({ left: left + "px" });
      if (!_this.isComponentMounted) {
        return false;
      }
      _this.setState(
        prevState => {
          return {
            activeParentIndex: index + 1,
            activeParentId: id,
            activeChildId: prevState.nodes[index].child[0].id
          };
        },
        function() {
          _this.swiper.slideTo(0, 500);
          _this.props.selectedCallback(
            _this.state.nodes[index].child[0],
            _this.state.activeParentIndex
          );
        }
      );
    };
  }

  handleChildClick(index) {
    //console.log("handleChildClick" + index);

    const app = this.$f7;
    const $ = this.$$;
    const swiper = app.swiper.get(".CategorySelectSwiper");
    swiper.slides.map(function(index) {
      $(swiper.slides[index]).removeClass("swiper-active");
    });
    $(swiper.slides[index]).addClass("swiper-active");
    //swiper.slideTo(swiper.clickedIndex, 500);

    let currentParentIndex;
    this.state.nodes.forEach((node, index) => {
      if (node.id == this.state.activeParentId) {
        currentParentIndex = index;
      }
    });

    if (!this.isComponentMounted) {
      return false;
    }
    this.setState(
      prevState => {
        return {
          activeChildId: prevState.nodes[currentParentIndex].child[index].id
        };
      },
      () => {
        this.props.selectedCallback(
          this.state.nodes[currentParentIndex].child[index],
          this.state.activeParentIndex
        );
      }
    );
  }

  render() {
    const nodes = this.state.nodes;
    return (
      <div className="CategorySelect">
        <div>
          {nodes.map((parent, index) => {
            return (
              <div
                key={parent.id}
                className={
                  (this.state.activeParentId == parent.id ? "actived" : "") +
                  " CategorySelectParent"
                }
                onClick={this.handleParentClick(index, parent.id)}
              >
                {parent.name}
              </div>
            );
          })}
        </div>
        <div>
          <div className="activedPoint" data-actived="1" />
          <Swiper
            params={{
              speed: 500,
              slidesPerView: "auto",
              slidesOffsetBefore: 0,
              slidesOffsetAfter: 0
            }}
            className="CategorySelectSwiper"
          >
            {nodes.map((parent, parentIndex) => {
              if (parent.id != this.state.activeParentId) {
                return;
              }
              return parent.child.map((child, childIndex) => {
                return (
                  <div
                    className="swiperSlideContainer swiper-slide"
                    key={childIndex}
                    onClick={this.handleChildClick.bind(this, childIndex)}
                  >
                    <SwiperSlide
                      className={
                        (this.state.activeChildId == child.id
                          ? "swiper-active"
                          : "") + " CategorySelectChild"
                      }
                      key={childIndex}
                    >
                      {child.name}
                    </SwiperSlide>
                  </div>
                );
              });
            })}
          </Swiper>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { userState } = state;
  return { userState };
}

CategorySelect.propTypes = {
  userState: PropTypes.object.isRequired,
  nodes: PropTypes.array.isRequired,
  selectedCallback: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(CategorySelect);
