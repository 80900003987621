import React from "react";
import { Link } from "framework7-react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "./VillageGroupPicker.less";
import { apiCall, apiConfig } from "../../api";

/**
 * 
 * // [
        //   { parentId: 0, id: 1, name: "浦东新区" },
        //   { parentId: 0, id: 2, name: "静安区" }
        // ],
        // [
        //   { parentId: 1, id: 21, name: "外冈镇" },
        //   { parentId: 1, id: 22, name: "嘉定工业区" },
        //   { parentId: 2, id: 27, name: "嘉定工业区2" }
        // ],
        // [
        //   { parentId: 21, id: 31, name: "仙龙村" },
        //   { parentId: 21, id: 32, name: "胜利村" },
        //   { parentId: 22, id: 33, name: "灯塔村" },
        //   { parentId: 22, id: 34, name: "朱家桥村" },
        //   { parentId: 27, id: 35, name: "黎明村" }
        // ]
 */
class VillageGroupPicker extends React.Component {
  constructor(props) {
    super(props);
    this.updateCols = this.updateCols.bind(this);
    this.getVillage = this.getVillage.bind(this);
    this.updateNodes = this.updateNodes.bind(this);
    this.updatePicker = this.updatePicker.bind(this);

    this.state = {
      parentElement: this.props.parentElement,
      nodes: [[], [], []],
      initSelectedValue: this.props.initSelectedValue
        ? this.props.initSelectedValue
        : [],
      initShowSelectedValue: false,
      picker: null,
      pickerColInitTimes: 0
    };
  }

  getVillage(parentId, level) {
    return new Promise((resolve, reject) => {
      if (level > 3) {
        resolve([]);
        return;
      }
      apiCall(
        "/cms/area",
        "POST",
        {
          organizeid: parentId,
          type: level
        },
        data => {
          //console.log(data);
          if (data.code == apiConfig.errorCode1000) {
            //this.$f7.dialog.alert(data.msg);
            console.log(data.tag);
            reject(1000);
          } else {
            resolve(
              data.map(item => {
                return {
                  parentId: parentId,
                  id: item.organizeid,
                  name: item.name
                };
              })
            );
          }
        },
        (xhr, status) => {
          console.log(xhr, status);
          //this.$f7.dialog.alert(JSON.stringify(xhr));
          reject(500);
        }
      );
    });
  }

  // level 1 2 3
  updateNodes(parentId, level, callback) {
    let nodes = this.state.nodes;
    if (level > 3) {
      if (callback) callback();
      return;
    }
    let alreadyHaveNodes = false;
    for (let k = 0; k < nodes[level - 1].length; k++) {
      if (nodes[level - 1][k].parentId == parentId) {
        // 已有数据
        alreadyHaveNodes = nodes[level - 1][k].id;
        //console.log(alreadyHaveNodes);
        break;
      }
    }

    if (alreadyHaveNodes) {
      ++level;
      if (level <= 3) {
        //console.log(data, level);
        this.updateNodes(alreadyHaveNodes, level, callback);
      } else {
        if (callback) callback();
      }
    } else {
      this.getVillage(parentId, level).then(data => {
        nodes[level - 1] = nodes[level - 1].concat(data);
        if (this.isComponentMounted) {
          this.setState(
            () => ({ nodes }),
            () => {
              ++level;
              if (level <= 3) {
                //console.log(data, level);
                this.updateNodes(data[0].id, level, callback);
              } else {
                if (callback) callback();
              }
            }
          );
        }
      });
    }
  }

  updateCols() {
    const that = this;
    return new Promise(resolve => {
      let cols = [];
      this.state.nodes.forEach((item, itemIndex) => {
        let itemId = [],
          itemName = [];
        item.forEach(function(node) {
          let parentId;
          if (itemIndex > 0) {
            parentId = cols[itemIndex - 1].values[0];
            if (that.state.initSelectedValue.length) {
              parentId = that.state.initSelectedValue[itemIndex - 1];
            }
          }
          if (itemIndex == 0 || node.parentId == parentId) {
            itemId.push(node.id);
            itemName.push(node.name);
          }
        });
        cols.push({
          values: itemId,
          displayValues: itemName,
          onChange: (index => {
            return (picker, value, displayValue) => {
              if (!this.isComponentMounted) {
                return false;
              }
              this.setState(
                prev => ({
                  pickerColInitTimes: ++prev.pickerColInitTimes
                }),
                () => {
                  // index 0 1 2
                  if (
                    that.state.pickerColInitTimes <= that.state.nodes.length
                  ) {
                    return;
                  }
                  //console.log(index, picker, value, displayValue);

                  let parentId = value;
                  this.updateNodes(value, index + 2, () => {
                    for (let i = index + 1; i < that.state.nodes.length; i++) {
                      let changedValues = [],
                        changedDisplayValues = [];
                      for (let j = 0; j < that.state.nodes[i].length; j++) {
                        if (that.state.nodes[i][j].parentId == parentId) {
                          changedValues.push(that.state.nodes[i][j].id);
                          changedDisplayValues.push(
                            that.state.nodes[i][j].name
                          );
                        }
                      }
                      if (!changedValues.length) {
                        that.$f7.dialog.alert(
                          "该目录下没有子目录，请联系管理人员"
                        );
                        return false;
                      } else {
                        parentId = changedValues[0];
                        picker.cols[i].replaceValues(
                          changedValues,
                          changedDisplayValues
                        );
                      }
                    }
                  });
                }
              );
            };
          })(cols.length)
        });
      });
      resolve(cols);
    });
  }

  updatePicker(parentId, level) {
    this.updateNodes(parentId, level, () => {
      this.updateCols().then(cols => {
        this.state.picker = this.$f7.picker.create({
          inputEl: this.props.parentElement,
          routableModals: false,
          url: "",
          rotateEffect: true,
          openIn: "sheet",
          cssClass: "picker-custom",
          renderToolbar: function() {
            return (
              '<div class="toolbar toolbar-custom toolbar-picker-user-group">' +
              '<div class="toolbar-inner">' +
              '<div class="left">' +
              '<a href="#" class="link sheet-close popover-close">取消</a>' +
              "</div>" +
              '<div class="right">' +
              '<a href="#" class="link sheet-close popover-close user-group-sure">完成</a>' +
              "</div>" +
              "</div>" +
              "</div>"
            );
          },
          cols: cols,
          on: {
            open: picker => {
              this.$$(".popup-backdrop").addClass("show");

              if (
                !this.state.initShowSelectedValue &&
                this.state.initSelectedValue.length
              ) {
                picker.cols[0].setValue(this.state.initSelectedValue[0]);
                this.state.initShowSelectedValue = true;
              }

              picker.$el.find(".user-group-sure").on("click", () => {
                this.props.selectedCallback(picker.value, picker.displayValue);
              });
            },
            close: picker => {
              this.$$(".popup-backdrop").removeClass("show");
            },
            change: (picker, value, displayValue) => {}
          }
        });
      });
    });
  }

  componentDidMount() {
    this.isComponentMounted = true;

    this.updatePicker(0, 1);
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  render() {
    return null;
  }
}

function mapStateToProps(state) {
  const { userState, pageState } = state;
  return { userState, pageState };
}

VillageGroupPicker.propTypes = {
  parentElement: PropTypes.string.isRequired,
  selectedCallback: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(VillageGroupPicker);
