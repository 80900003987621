import { combineReducers } from "redux";
import userState from "./UserReducer";
import pageState from "./PageReducer";

const rootReducer = combineReducers({
  userState,
  pageState
});

export default rootReducer;
