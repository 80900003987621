import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "framework7-icons";
import { Page, Link, Button, Preloader, Input } from "framework7-react";
import "../css/pageChangePassword.less";
import { changePassword, setCode } from "../actions/UserAction";
import { apiCall, apiConfig } from "../api";

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);

    this.refCode = React.createRef();
    this.codeTime = this.codeTime.bind(this);

    this.state = {
      prepassword: "",
      newpassword: "",
      code: "",
      codeLeftTime: 0,
      loading: false
    };
  }

  changePrePassword(e) {
    if (!this.isComponentMounted) {
      return false;
    }
    let password = e.target.value;
    this.setState(
      () => ({
        prepassword: password
      }),
      () => {
        this.props.dispatch(changePassword(password, this.state.newpassword));
      }
    );
  }

  clearPrePassword(e) {
    if (!this.isComponentMounted) {
      return false;
    }
    this.setState(
      () => ({
        prepassword: ""
      }),
      () => {
        this.props.dispatch(changePassword("", this.state.newpassword));
      }
    );
  }

  changeNewPassword(e) {
    if (!this.isComponentMounted) {
      return false;
    }
    let password = e.target.value;
    this.setState(
      () => ({
        newpassword: password
      }),
      () => {
        this.props.dispatch(changePassword(this.state.prepassword, password));
      }
    );
  }

  clearNewPassword(e) {
    if (!this.isComponentMounted) {
      return false;
    }
    this.setState(
      () => ({
        newpassword: ""
      }),
      () => {
        this.props.dispatch(changePassword(this.state.prepassword, ""));
      }
    );
  }

  changeCode(e) {
    if (!this.isComponentMounted) {
      return false;
    }
    let code = e.target.value;
    this.setState(
      () => ({
        code: code
      }),
      () => {
        this.props.dispatch(setCode(code));
      }
    );
  }

  clearCode(e) {
    if (!this.isComponentMounted) {
      return false;
    }
    this.setState(
      () => ({
        code: ""
      }),
      () => {
        this.props.dispatch(setCode(""));
      }
    );
  }

  codeTime(initTime) {
    apiCall(
      "/sms/codeByPassword", //  /sms/sendSmscode
      "POST",
      {
        phone: this.props.userState.userinfo.uphone,
        token: apiConfig.token
      },
      data => {
        if (!this.isComponentMounted) {
          return false;
        }
        this.setState(
          () => ({
            loading: true
          }),
          () => {
            if (initTime <= 0 || !initTime) {
              initTime = 60;
            }
            if (this.codeInterval) {
              clearInterval(this.codeInterval);
            }
            this.codeInterval = setInterval(() => {
              if (!this.isComponentMounted) {
                return false;
              }
              this.setState(
                prevState => ({
                  codeLeftTime: initTime--,
                  loading: false
                }),
                () => {
                  this.$f7.preloader.hide();
                  localStorage.setItem("smsCodeTime", new Date().getTime());
                  localStorage.setItem("smsCodeLeft", this.state.codeLeftTime);
                  if (this.state.codeLeftTime <= 0) {
                    clearInterval(this.codeInterval);
                  }
                }
              );
            }, 1000);
          }
        );
      },
      err => {
        //console.log(err);
        this.$f7.dialog.alert(JSON.stringify(err));
      }
    );
  }

  sendCode() {
    let lastCodeTime = localStorage.getItem("smsCodeTime")
      ? parseFloat(localStorage.getItem("smsCodeTime"))
      : 0;
    let lastCodeLeft = localStorage.getItem("smsCodeLeft")
      ? parseInt(localStorage.getItem("smsCodeLeft"))
      : 0;
    if (
      lastCodeTime &&
      lastCodeTime + lastCodeLeft * 1000 > new Date().getTime()
    ) {
      //距离上次发送短信验证码小于60秒
      return;
    }
    this.codeTime();
  }

  componentDidMount() {
    this.isComponentMounted = true;
    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;
    });
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  render() {
    return (
      <Page name="ChangePassword">
        <div>
          <div>
            <div>账号</div>
            <div>{this.props.userState.userinfo.uphone}</div>
          </div>
          {/**
           <div>
            <div>原始密码</div>
            <div>
              <Input
                type="password"
                placeholder="请输入原始密码"
                clearButton={true}
                onChange={this.changePrePassword.bind(this)}
                onInputClear={this.clearPrePassword.bind(this)}
              />
            </div>
          </div>
          */}
          <div>
            <div>验证码</div>
            <div>
              <Input
                type="tel"
                placeholder="请输入验证码"
                clearButton={true}
                onChange={this.changeCode.bind(this)}
                onInputClear={this.clearCode.bind(this)}
                //value={this.state.code}
                ref={this.refCode}
                maxlength={6}
              />
            </div>
            <div
              onClick={this.sendCode.bind(this)}
              className={
                this.state.codeLeftTime > 0 || this.state.loading == true
                  ? "sended"
                  : ""
              }>
              {this.state.loading == false &&
                (this.state.codeLeftTime > 0
                  ? "" + this.state.codeLeftTime + " 秒"
                  : "获取验证码")}
              {this.state.loading == true && <Preloader />}
            </div>
          </div>
          <div>
            <div>密码</div>
            <div>
              <Input
                type="password"
                placeholder="请设置密码"
                clearButton={true}
                onChange={this.changeNewPassword.bind(this)}
                onInputClear={this.clearNewPassword.bind(this)}
              />
            </div>
          </div>
        </div>
      </Page>
    );
  }
}

function mapStateToProps(state) {
  const { userState } = state;
  return { userState };
}

ChangePassword.propTypes = {
  userState: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(ChangePassword);
