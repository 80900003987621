import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "framework7-icons";
import { Page } from "framework7-react";
import { showHeader, changeFooterIndex } from "../actions/PageAction";
import Carousel from "../components/Carousel";
import { apiCall, apiConfig } from "../api";
import "../css/pageFeatureproductInfo.less";
import AutoImg from "../components/AutoImg";

class FeatureproductInfo extends React.Component {
  constructor(props) {
    super(props);
    this.productId = this.$f7route.params.productId;
    this.state = {};
  }
  componentDidMount() {
    this.isComponentMounted = true;
    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      self.props.dispatch(changeFooterIndex(0));

      apiCall(
        "/app/getCmsNewsById",
        "POST",
        {
          cmsId: this.productId
        },
        data => {
          // console.log(data);
          if (this.isComponentMounted) {
            this.setState(data.content.detail, () => {
              //this.props.dispatch(showHeader(this.state.title, true));
            });
          }
        },
        (xhr, status) => {
          //console.log(xhr, status);
          this.$f7.dialog.alert(xhr);
        }
      );
    });
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  render() {
    return (
      <Page name="FeatureproductInfo">
        <div>{this.state.title}</div>
        <div
          dangerouslySetInnerHTML={{
            __html:
              "日期：" +
              this.state.createDate +
              (!!this.state.enterpriseName
                ? "&nbsp;&nbsp;&nbsp;&nbsp;作者：" + this.state.enterpriseName
                : "")
          }}
        />

        {/**<Carousel data={[{ img: this.state.previewImage, url: "" }]} /> */}
        {this.state.previewImage && (
          <AutoImg src={this.state.previewImage} ratio={1.875} />
        )}

        <div className="TitleGap bgGray">
          <div>亮点</div>
        </div>
        <div className="InfoContent bold">{this.state.keyWord}</div>
        <div className="TitleGap bgGray">
          <div>详情介绍</div>
        </div>
        <div
          className="InfoContent"
          dangerouslySetInnerHTML={{ __html: this.state.content }}
        />
      </Page>
    );
  }
}

function mapStateToProps(state) {
  const { userState } = state;
  return { userState };
}

FeatureproductInfo.propTypes = {
  userState: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(FeatureproductInfo);
