import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "framework7-icons";
import { Page, Toolbar, Tabs, Tab, Link } from "framework7-react";
import "../css/pageNews.less";
import InformationPanel from "../components/InformationPanel";
import ImgNews1 from "../images/im_news_1.png";
import ImgNews2 from "../images/imgsample40.png";
import ImgNews3 from "../images/imgsample41.png";
import ImgNews4 from "../images/imgsample42.png";

class News extends React.Component {
  constructor(props) {
    super(props);

    this.userId = this.$f7route.params.userId;

    this.state = {
      listtab1: [
        {
          id: "201801",
          title:
            "上海市农业农村委员会关于《上海市美丽乡村建设项目和资金管理办法（试行）》的政策解读",
          link: "/information/344"
        },
        {
          id: "201802",
          title:
            "关于印发修订后的《上海市农业生态和农产品安全专项资金管理办法》的通知",
          link: "/information/344"
        }
      ],
      listtab2: [
        {
          id: 1,
          title: "群居蝗虫 可产生毒素防御天敌",
          img: ImgNews1,
          date: "2019年1月24日",
          source: "科技日报",
          link: "/information/344"
        },
        {
          id: 2,
          title: "基因编辑让虫卵只能孵化出不育雄性昆虫",
          img: [ImgNews2, ImgNews3, ImgNews4],
          date: "2019年1月24日",
          source: "科技日报",
          link: "/information/344"
        }
      ]
    };
  }

  componentDidMount() {
    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;
    });
  }

  render() {
    const self = this;
    return (
      <Page name="News" className="News">
        <div className="Toolbar">
          <Toolbar tabbar>
            <Link tabLink="#Toolbar-1" tabLinkActive>
              政策资讯
            </Link>
            <Link tabLink="#Toolbar-2">科普中国</Link>
          </Toolbar>
          <Tabs animated>
            <Tab id="Toolbar-1" className="page-content" tabActive>
              <Link className="NewsItem">
                <div>
                  上海市农业农村委员会关于《上海市美丽乡村建设项目和资金管理办法（试行）》的政策解读
                </div>
              </Link>
              <Link className="NewsItem">
                <div>
                  上海市农业农村委员会关于《上海市美丽乡村建设项目和资金管理办法（试行）》的政策解读
                </div>
              </Link>
            </Tab>
            <Tab id="Toolbar-2" className="page-content">
              {this.state.listtab2.length > 0 &&
                this.state.listtab2.map((item, index) => (
                  <InformationPanel
                    key={item.id}
                    title={item.title}
                    img={item.img}
                    date={item.date}
                    source={item.source ? item.source : ""}
                    link={item.link}
                  />
                ))}
            </Tab>
          </Tabs>
        </div>
      </Page>
    );
  }
}

function mapStateToProps(state) {
  const { userState } = state;
  return { userState };
}

News.propTypes = {
  userState: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(News);
