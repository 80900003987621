import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "framework7-icons";
import { Page, Link, Button, Preloader, Input } from "framework7-react";
import "../css/pageChangePhone.less";
import { apiCall, apiConfig } from "../api";
import { checkPhone } from "../../public/js/utils";
import { changePhone } from "../actions/UserAction";

class ChangePhone extends React.Component {
  constructor(props) {
    super(props);

    this.codeTime = this.codeTime.bind(this);

    this.state = { phone: "", code: "", codeLeftTime: 0, loading: false };
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }
  componentDidMount() {
    this.$f7ready(f7 => {
      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      this.isComponentMounted = true;

      let lastCodeTime = localStorage.getItem("smsCodeTimeChangePhone")
        ? parseFloat(localStorage.getItem("smsCodeTimeChangePhone"))
        : 0;
      let lastCodeLeft = localStorage.getItem("smsCodeLeftChangePhone")
        ? parseInt(localStorage.getItem("smsCodeLeftChangePhone"))
        : 0;
      if (
        lastCodeTime &&
        lastCodeTime + lastCodeLeft * 1000 > new Date().getTime()
      ) {
        //距离上次发送短信验证码小于60秒
        this.codeTime(lastCodeLeft);
        return;
      }
    });
  }

  codeTime(initTime) {
    apiCall(
      "/sms/sendSmscode",
      "POST",
      {
        phone: this.state.phone,
        token: apiConfig.token
      },
      data => {
        //console.log(data);
        if (!this.isComponentMounted) {
          return;
        }
        this.setState(
          () => ({
            loading: true
          }),
          () => {
            if (initTime <= 0 || !initTime) {
              initTime = 60;
            }
            if (this.codeInterval) {
              clearInterval(this.codeInterval);
            }
            this.codeInterval = setInterval(() => {
              if (!this.isComponentMounted) {
                return;
              }
              this.setState(
                prevState => ({
                  codeLeftTime: initTime--,
                  loading: false
                }),
                () => {
                  this.$f7.preloader.hide();
                  localStorage.setItem(
                    "smsCodeTimeChangePhone",
                    new Date().getTime()
                  );
                  localStorage.setItem(
                    "smsCodeLeftChangePhone",
                    this.state.codeLeftTime
                  );
                  if (this.state.codeLeftTime <= 0) {
                    clearInterval(this.codeInterval);
                  }
                }
              );
            }, 1000);
          }
        );
      },
      err => {
        console.log(err);
        this.$f7.dialog.alert(JSON.stringify(err));
      }
    );
  }

  sendCode() {
    let lastCodeTime = localStorage.getItem("smsCodeTimeChangePhone")
      ? parseFloat(localStorage.getItem("smsCodeTimeChangePhone"))
      : 0;
    let lastCodeLeft = localStorage.getItem("smsCodeLeftChangePhone")
      ? parseInt(localStorage.getItem("smsCodeLeftChangePhone"))
      : 0;
    if (
      lastCodeTime &&
      lastCodeTime + lastCodeLeft * 1000 > new Date().getTime()
    ) {
      //距离上次发送短信验证码小于60秒
      return;
    }
    if (!this.state.phone) {
      this.$f7.dialog.alert("请输入手机号", () => {
        //this.refPhone.current.focus();
      });
      return;
    }
    if (!checkPhone(this.state.phone)) {
      this.$f7.dialog.alert("手机号格式不正确，请重新输入", () => {
        //this.refPhone.current.focus();
      });
      return;
    }
    this.codeTime();
  }

  changePhone(e) {
    let phone = e.target.value;
    if (!this.isComponentMounted) {
      return;
    }
    this.setState(
      () => ({
        phone: phone
      }),
      () => {
        this.props.dispatch(changePhone(phone, this.state.code));
      }
    );
  }

  clearCode(e) {
    if (!this.isComponentMounted) {
      return;
    }
    this.setState(
      () => ({
        code: ""
      }),
      () => {
        this.props.dispatch(changePhone(this.state.phone, ""));
      }
    );
  }

  changeCode(e) {
    if (!this.isComponentMounted) {
      return;
    }
    let code = e.target.value;
    this.setState(
      () => ({
        code: code
      }),
      () => {
        this.props.dispatch(changePhone(this.state.phone, code));
      }
    );
  }

  clearPhone(e) {
    if (!this.isComponentMounted) {
      return;
    }
    this.setState(
      () => ({
        phone: ""
      }),
      () => {
        this.props.dispatch(changePhone("", this.state.code));
      }
    );
  }

  render() {
    return (
      <Page name="ChangePhone">
        <div>
          <div>
            <div>新手机号</div>
            <div>
              <Input
                type="tel"
                placeholder="请输入新手机号作为您的账号"
                clearButton={true}
                onChange={this.changePhone.bind(this)}
                onInputClear={this.clearPhone.bind(this)}
                value={this.state.phone}
              />
            </div>
          </div>
          <div>
            <div>验证码</div>
            <div>
              <Input
                type="tel"
                placeholder="请输入短信验证码"
                clearButton={true}
                onChange={this.changeCode.bind(this)}
                onInputClear={this.clearCode.bind(this)}
                value={this.state.code}
                maxlength={6}
              />
            </div>
            <div
              onClick={this.sendCode.bind(this)}
              className={
                this.state.codeLeftTime > 0 || this.state.loading == true
                  ? "sended"
                  : ""
              }>
              {this.state.loading == false &&
                (this.state.codeLeftTime > 0
                  ? "" + this.state.codeLeftTime + " 秒"
                  : "获取验证码")}
              {this.state.loading == true && <Preloader />}
            </div>
          </div>
        </div>
      </Page>
    );
  }
}

function mapStateToProps(state) {
  const { userState } = state;
  return { userState };
}

ChangePhone.propTypes = {
  userState: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(ChangePhone);
